import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import logo from '../Images/logo-driscolls-white.png';
import UpArrow from '../Images/SVG/Up-Arrow.svg';
import SignOutIcon from '../Images/SVG/log-out.svg';
import UserDetailsIcon from '../Images/SVG/UserDetails.svg';
import { withOktaAuth } from '@okta/okta-react';
import { DrcTranslate, DrcEnvironmentMarker, DrcMediaQueries } from 'drc/driscolls-react-components/index';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import MiniDrawer from './MiniDrawer';
import DrcLanguageHeaderButton from './DrcLanguageHeaderButton';
import { withRouter } from 'routes/withRouter';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { designColors, drawerWidth } from '../data/constants';
import { setUserLang } from '../actions/MasterActions';
import { setUserIsAdmin, openDrawer } from '../actions/actions';
import { languagesSupportedMap } from '../data/translations.js';
import { isRoleAdmin } from '../data/roleUtilities';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { useNavigate } from 'react-router-dom';
import { userBusinessUnit } from 'utils/helper';
import i18next from 'i18next';
import { ROUTES } from 'constants/routes';
import { Box, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        color: '#fff !important',
        backgroundColor: `${designColors.blue} !important`,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        }
        // '& .MuiListItemText-root': {
        //     color: `${designColors.blue} !important`
        // }
    },
    logo: {
        display: 'flex',
        height: '35px',
        paddingRight: '30px',
        ['@media ' + DrcMediaQueries.mobileL]: {
            paddingLeft: '14px',
            display: 'none'
        }
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        }
    },
    appBarShift: {
        marginLeft: drawerWidth,
        // width: `calc(100% - ${drawerWidth}px)`,
        width: '100%',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap'
        // color: '#fff !important',
        // backgroundColor: `${designColors.blue} !important`
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },

    /* Start 'User Dropdown menu'*/
    userMenuTarget: {
        justifyContent: 'right',
        display: 'flex',
        backgroundColor: `${designColors.blue} !important`,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        },
        '& $menuIndicator img': {
            transform: 'rotate(-180deg)'
        },
        // &:focus = onclick
        // &:hover = ophover
        '&:focus': {
            // show dropdown menu
            '& $userMenuDropDown': {
                visibility: 'visible',
                opacity: 1
            },
            //rotate accordion indicator
            '& $menuIndicator img': {
                transform: 'rotate(0deg)'
            }
        }
    },
    menuIndicator: {
        marginLeft: '10px',
        marginTop: '5px',
        '& img': {
            '-webkit-transition-duration': '0.2s',
            '-moz-transition-duration': '0.2s',
            '-o-transition-duration': '0.2s',
            'transition-duration': '0.2s',
            '-webkit-transition-property': '-webkit-transform',
            '-moz-transition-property': '-moz-transform',
            '-o-transition-property': '-o-transform',
            'transition-property': 'transform'
        }
    },
    userMenuDropDown: {
        transition: 'visibility 0.3s, opacity 0.3s',
        visibility: 'hidden',
        opacity: 0,
        right: '12px',
        top: '62px',
        display: 'block',
        borderRadius: '0 0 5px 5px',
        position: 'fixed',
        backgroundColor: `${designColors.blue} !important`,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        },
        width: 'auto',
        minWidth: '200px'
    },
    userMenuInfo: {
        cursor: 'default',
        justifyContent: 'left',
        paddingRight: '20px',
        marginLeft: 'auto'
    },
    userMenuItem: {
        cursor: 'pointer',
        position: 'relative',
        backgroundColor: `${designColors.blue} !important`,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        },
        padding: '10px 10px',
        '&:hover': {
            backgroundColor: `${designColors.hover} !important`,
            '& div:nth-child(2)': {
                display: 'block'
            }
        }
    },
    userMenuNestedItem: {
        cursor: 'pointer',
        position: 'relative',
        backgroundColor: `${designColors.blue} !important`,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        },
        padding: '10px',
        '&:hover': {
            backgroundColor: `${designColors.hover} !important`,
            display: 'block'
        }
    },
    userMenuNested: {
        display: 'none',
        position: 'absolute',
        top: 0,
        left: '-100px',
        width: 100,
        '&:hover': {
            display: 'block'
        }
    },
    userMenuNestedItemText: {
        fontSize: '14px'
    },
    userMenuItemLast: {
        marginBottom: '5px'
    },
    userMenuItemText: {
        marginLeft: '50px',
        marginTop: '-24px',
        fontSize: '14px'
    },
    userMenuItemIcon: {
        width: '20px',
        marginLeft: '10px'
    },
    dividerRow: {
        height: '1px',
        backgroundColor: '#FFFFFF',
        marginLeft: '10px',
        marginRight: '10px'
    },
    titleText: {
        height: '24px',
        fontFamily: 'Rubik',
        fontSize: '20px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '1.11px',
        textAlign: 'left',
        color: '#fff'
    },
    headerButton: {
        color: '#fff !important',
        '&:hover': {
            border: 'none',
            backgroundColor: '#ffffff00',
            color: `${designColors.hover}`
        },
        [theme.darkTheme]: {
            backgroundColor: '#0000',
            '&:hover': {
                border: 'none',
                color: `${designColors.hover}`,
                backgroundColor: '#0000'
            }
        }
    },
    menuButton: {
        display: 'none !important',
        ['@media ' + DrcMediaQueries.mobileL]: {
            display: 'block !important',
            paddingLeft: '24px !important'
        }
    }

    /* End 'User Dropdown menu*/
}));

function Header(props) {
    const classes = useStyles();
    const [allowedLinks, setAllowedLinks] = React.useState(props.allLinks);
    const [user, setUser] = React.useState('');
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    drawerOpen;
    const [dropdown, setDropdown] = React.useState(false);
    const navigate = useNavigate();
    const isDocUser = userBusinessUnit() === 'DOC';
    const userRole = JSON.parse(localStorage.getItem('GGS_UserRole'));

    useEffect(() => {
        checkAuthentication();
        (async () => {
            await getUser();
        })();
    }, [props.authState]);

    const checkAuthentication = () => {
        if (!props.authState) {
            //|| !props.authState?.isAuthenticated
            if (allowedLinks.length > 0) {
                setAllowedLinks([]);
            }
            return;
        }

        let token = props.authState?.accessToken;
        if (!token || token.length <= 0) {
            if (allowedLinks.length > 0) {
                setAllowedLinks([]);
            }
            return;
        } else {
            var allAllowedLinks = [];

            var matchedLinks = props.allLinks.filter((l) => {
                if ((l.requiresGroups || []).length > 0) {
                    return l.requiresGroups.some((rg) => DuAuthenticationUtilities.IsInGroup(token, rg));
                }

                return true;
            });

            if (DuAuthenticationUtilities.IsInGroup(token, [...window.config.OKTA_ADMIN_GROUPS, ...window.config.OKTA_GGS_ADMIN_GROUPS] || [])) {
                allAllowedLinks = matchedLinks;
            } else if (DuAuthenticationUtilities.IsInGroup(token, window.config.OKTA_USER_GROUPS || [])) {
                allAllowedLinks = matchedLinks.filter((link) => !link.requiresAdmin);
                allAllowedLinks = allAllowedLinks.map((link) => {
                    link.subLinks = (link.subLinks || []).filter((subLink) => !subLink.requiresAdmin);
                    return link;
                });
            } else if (DuAuthenticationUtilities.IsInGroup(token, window.config.OKTA_READ_ONLY_GROUPS || [])) {
                allAllowedLinks = matchedLinks.filter((link) => !link.requiresAdmin && !link.requiresRegular);
                allAllowedLinks = allAllowedLinks.map((link) => {
                    link.subLinks = (link.subLinks || []).filter((subLink) => !subLink.requiresAdmin && !subLink.requiresRegular);
                    return link;
                });
            }

            if (allowedLinks.length !== allAllowedLinks.length) {
                setAllowedLinks(allAllowedLinks);
            }
        }
    };

    const handleListItemClick = (itm, _index) => {
        navigate(itm.route);
    };
    const getUser = async () => {
        let token = await props.oktaAuth.getAccessToken();
        let userId = DuAuthenticationUtilities.GetUserId(token) || '';
        setUser(userId.split('.').join(' '));
    };

    const collapseMenu = () => {
        let elementToFocus = 'userMenu';
        if (dropdown) {
            elementToFocus = 'logo';
        }
        setDropdown(!dropdown);
        document.getElementById(elementToFocus).focus();
    };

    const handleAccountChange = (role) => {
        localStorage.setItem('GGS_UserRole', JSON.stringify(role));
        props.setUserIsAdmin({ isAdmin: isRoleAdmin(role), group: role.group });
        if (role?.group?.toLowerCase().indexOf('dota') > -1) {
            navigate(ROUTES.DASHBOARD);
            window.location.reload();
        }
    };
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                elevation={0}
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: props.drawerOpen
                })}
            >
                <Toolbar
                    position="static"
                    className={classes.titleText}
                    style={{ justifyContent: 'space-between', alignItems: 'center', minHeight: 70 }}
                >
                    <DrcEnvironmentMarker />
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={() => {
                            props.openDrawer(!props.drawerOpen);
                        }}
                        disableRipple
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Link to="/">
                        <img className={classes.logo} src={logo} id="logo" alt="logo" tabIndex="0" />
                    </Link>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyItems: 'right' }}>
                        <Typography variant="h5" noWrap style={{ paddingRight: '2rem', fontFamily: 'Rubik' }} className={classes.titleText}>
                            <DrcTranslate>{'Grower Settlement'}</DrcTranslate>
                        </Typography>
                        {props?.router?.location?.pathname !== '/' && props?.router?.location?.pathname !== '/InitializeApplication/' ? (
                            <Typography variant="body" noWrap style={{ paddingRight: '2rem', fontFamily: 'Rubik', fontSize: '10px' }}>
                                {userBusinessUnit()}
                            </Typography>
                        ) : (
                            ''
                        )}
                    </div>
                    <div style={{ justifyContent: 'right', paddingRight: '20px', marginLeft: 'auto' }}>
                        <DrcLanguageHeaderButton
                            className={classes.headerButton}
                            isSecondary
                            noStyle
                            handleLangChange={(code, _lang) => {
                                props.setUserLang(code);
                                localStorage.setItem('GGS_UserLang', code);
                                i18next.changeLanguage(code);
                            }}
                            LANGUAGES={languagesSupportedMap}
                        />
                    </div>
                    <div
                        tabIndex="1"
                        id="userMenu"
                        className={classes.userMenuTarget}
                        onBlur={() => {
                            setDropdown(false);
                        }}
                        onClick={() => {
                            collapseMenu();
                        }}
                    >
                        <div className={classes.userMenuInfo}>
                            {props.authState?.isAuthenticated && (
                                <>
                                    <Typography noWrap>
                                        <span style={{ textTransform: 'capitalize' }}>{`${user}`}</span>
                                        <br />
                                        {props.userIsAdmin ? (
                                            props.selectedRole.includes('Admin') ? (
                                                <DrcTranslate>{'Admin'}</DrcTranslate>
                                            ) : (
                                                <DrcTranslate>{'Approver'}</DrcTranslate>
                                            )
                                        ) : (
                                            <DrcTranslate>{'Grower Accountant'}</DrcTranslate>
                                        )}
                                    </Typography>
                                </>
                            )}
                        </div>
                        {props.authState?.isAuthenticated && (
                            <>
                                <div style={{ borderLeft: '3px solid white', height: '30px', paddingRight: '10px' }} />
                                <AccountCircleIcon fontSize="large" />
                                <div
                                    className={classes.menuIndicator}
                                    onClick={() => {
                                        collapseMenu();
                                    }}
                                >
                                    <img src={UpArrow} />
                                </div>
                            </>
                        )}
                        <div className={classes.userMenuDropDown}>
                            <div className={classes.dividerRow} />
                            <div
                                className={classes.userMenuItem + ' ' + classes.userMenuItemLast}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigate('/UserPreferences/');
                                }}
                            >
                                <img src={UserDetailsIcon} className={classes.userMenuItemIcon} />
                                <div className={classes.userMenuItemText}>
                                    <DrcTranslate>{'User Preference'}</DrcTranslate>
                                </div>
                            </div>
                            {!!props.userRoles.length && (
                                <>
                                    <div className={classes.dividerRow} />
                                    <div className={classes.userMenuItem} onClick={() => null}>
                                        <div>
                                            <SupervisorAccountIcon className={classes.userMenuItemIcon} />
                                            <div className={classes.userMenuItemText}>
                                                <DrcTranslate>{'Accounts'}</DrcTranslate>
                                            </div>
                                        </div>
                                        <div className={classes.userMenuNested}>
                                            {props.userRoles.map((role, indx) => {
                                                return (
                                                    <React.Fragment key={indx}>
                                                        <div
                                                            className={classes.userMenuNestedItem}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleAccountChange(role);
                                                            }}
                                                        >
                                                            <div className={classes.userMenuNestedItemText}>
                                                                <DrcTranslate>{role.display}</DrcTranslate>
                                                            </div>
                                                        </div>
                                                        <div className={classes.dividerRow} />
                                                    </React.Fragment>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className={classes.dividerRow} />
                            <div
                                className={classes.userMenuItem + ' ' + classes.userMenuItemLast}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    localStorage.removeItem('GGS_UserLang');
                                    localStorage.removeItem('GGS_UserRole');
                                    navigate('/logout/');
                                }}
                            >
                                <img src={SignOutIcon} className={classes.userMenuItemIcon} />
                                <div className={classes.userMenuItemText}>
                                    <DrcTranslate>{'Sign Out'}</DrcTranslate>
                                </div>
                            </div>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            {allowedLinks.length > 0 && <MiniDrawer allLinks={allowedLinks || []} handleListItemClick={handleListItemClick} />}
        </div>
    );
}
const mapStateToProps = (state) => ({
    drawerOpen: state.rootReducer.drawerOpen,
    userIsAdmin: state.rootReducer.userIsAdmin,
    selectedRole: state.rootReducer.selectedRole,
    userRoles: state.rootReducer.userRoles
});
export default connect(mapStateToProps, { openDrawer, setUserLang, setUserIsAdmin })(withOktaAuth(withRouter(Header)));
