import React from 'react';
import { withStyles } from '@mui/styles';
var environment = '';

const styles = (theme) => ({
    wrapper: {
        top: 0,
        left: 0,
        zIndex: 1200,
        pointerEvents: 'none',
        '@media print': {
            display: 'none'
        }
    },
    title: {
        position: 'fixed',
        color: theme.light.text.inverted,
        fontSize: '1.2rem',
        transform: 'rotate(-45deg)',
        textAlign: 'center',
        top: 6,
        left: -6,
        width: 48
    }
});

/**
 * @description DrcEnvironmentMarkerV2 is an environment specific marker for new Designs
 * @style pass props as svgStyle with height, width and viewBox
 * @class DrcEnvironmentMarkerV2
 * @property {object} classes Sets the style of marker
 * @example
 * <DrcEnvironmentMarkerV2 svgStyle={{ width: '51', height: '58' }} />
 * @donotprint true
 * @category Environment
 * @tags environment marker notice alert warning
 * @extends {React.Component}
 */

class DrcEnvironmentMarkerV2 extends React.Component {
    /**
     * Creates an instance of DrcEnvironmentMarkerV2.
     * @param {*} props
     * @memberof DrcEnvironmentMarkerV2
     */

    constructor(props) {
        super(props);

        var hostname = window.location.hostname.toLowerCase();

        if (hostname.includes('dev') || hostname.includes('localhost')) {
            environment = 'DEV';
        } else if (hostname.includes('stage') || hostname.includes('stg')) {
            environment = 'STG';
        } else if (hostname.includes('uat')) {
            environment = 'UAT';
        } else if (hostname.includes('qa') || hostname.includes('qat')) {
            environment = 'QAT';
        } else if (hostname.includes('tst') || hostname.includes('test')) {
            environment = 'TST';
        } else {
            environment = ''; //Production, do not show to user
        }
    }

    /**
     * @return {*}
     * @memberof DrcEnvironmentMarkerV2
     */

    render() {
        const { classes, svgStyle = {} } = this.props;
        if (environment.length === 0) {
            return null;
        }

        return (
            <div className={classes.wrapper}>
                <svg width={svgStyle.width || '85'} height={svgStyle.height || '97'} viewBox={svgStyle.viewBox || '0 0 85 97'}>
                    <defs>
                        <filter id="Path_2" x="5.206" y="9.813" width="75.402" height="87.18" filterUnits="userSpaceOnUse">
                            <feOffset dy="3" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="3" result="blur" />
                            <feFlood flood-opacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                        <filter id="DEV" x="1.613" y="19.456" width="65.829" height="66.323" filterUnits="userSpaceOnUse">
                            <feOffset dy="3" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="3" result="blur-2" />
                            <feFlood flood-opacity="0.161" />
                            <feComposite operator="in" in2="blur-2" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>
                    <g id="Group_1011" data-name="Group 1011" transform="translate(-150 0)">
                        <g id="tag-leaf-svgrepo-com" transform="translate(149.965)">
                            <path
                                id="Path_1"
                                data-name="Path 1"
                                d="M70.819,85.293a8.191,8.191,0,0,0-1.609-2.275,7.954,7.954,0,0,0-2.331-1.646,8.13,8.13,0,0,0-3.551-.8H39.783A8.27,8.27,0,0,0,33.9,83.018L2.476,114.443a8.306,8.306,0,0,0,0,11.763L26,149.752a8.488,8.488,0,0,0,4.42,2.312,8.214,8.214,0,0,0,5.549-.943,8.056,8.056,0,0,0,1.813-1.369l31.425-31.425a8.269,8.269,0,0,0,2.442-5.882V88.9A8.144,8.144,0,0,0,70.819,85.293Zm-11.3,15.259a5.545,5.545,0,1,1-3.921-9.47,4.885,4.885,0,0,1,1.369.185,5.2,5.2,0,0,1,2.552,1.443,5.437,5.437,0,0,1,1.424,2.423A5.564,5.564,0,0,1,59.517,100.553Z"
                                transform="translate(0 -67.2)"
                                fill="#fdda24"
                            />
                            <g transform="matrix(1, 0, 0, 1, 0.04, 0)" filter="url(#Path_2)">
                                <path
                                    id="Path_2-2"
                                    data-name="Path 2"
                                    d="M140.573,95.251l-9.688,9.688h0a5.549,5.549,0,0,1-7.847,7.848v0L85.6,150.22l11.771,11.771a8.29,8.29,0,0,0,4.42,2.3,8.377,8.377,0,0,0,7.35-2.3l31.428-31.428a8.27,8.27,0,0,0,2.433-5.885V101.136A8.292,8.292,0,0,0,140.573,95.251Z"
                                    transform="translate(-71.4 -79.44)"
                                    fill="#ffb655"
                                />
                            </g>
                            <path
                                id="Path_3"
                                data-name="Path 3"
                                d="M371.014,2.779A2.714,2.714,0,0,1,370.2,4.74L356.809,18.094l-9.877,9.84a5.436,5.436,0,0,0-1.424-2.423,5.2,5.2,0,0,0-2.552-1.443l9.914-9.9L366.279.819a2.758,2.758,0,0,1,3.921,0A2.683,2.683,0,0,1,371.014,2.779Z"
                                transform="translate(-285.991)"
                                fill="#46763a"
                            />
                            <path
                                id="Path_4"
                                data-name="Path 4"
                                d="M358.068,29.436a5.437,5.437,0,0,1,1.424,2.423l9.877-9.84L382.76,8.665a2.714,2.714,0,0,0,.814-1.961,2.685,2.685,0,0,0-.814-1.961c-.016-.016-.039-.021-.055-.037L358.016,29.4C358.032,29.412,358.053,29.42,358.068,29.436Z"
                                transform="translate(-298.551 -3.925)"
                                fill="#fff"
                            />
                        </g>
                        <g transform="matrix(1, 0, 0, 1, 150, 0)" filter="url(#DEV)">
                            <text
                                id="drc-environment-text"
                                data-name="DEV"
                                transform="matrix(0.69, -0.72, 0.72, 0.69, 25, 71)"
                                fill="#fff"
                                font-size="21"
                                font-family="Rubik"
                                style={{ fontSize: '21px' }}
                            >
                                <tspan x="0" y="0">
                                    {environment}
                                </tspan>
                            </text>
                        </g>
                    </g>
                </svg>
            </div>
        );
    }
}

export default withStyles(styles)(DrcEnvironmentMarkerV2);
