import React from 'react';
import { withStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

/**
 *@ignore
 */
const styles = (theme) => ({
    circularLabel: {
        position: 'relative',
        top: '-15px',
        left: '-40px',
        width: '40px',
        display: 'inline-block',
        textAlign: 'center'
    },
    linearLabel: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'center',
        padding: '10px'
    }
});

/**
 * @description DrcProgress inform users about the status of ongoing processes, such as loading an app, submitting a form, or saving updates
 * @class DrcProgress
 * @example <DrcProgress value={percentComplete} />
 * @category General
 * @tags ui progress percent
 * @property {boolean} isCircular circular theme
 * @property {number} value Percent of loading or process completed. Value between 0 to 100
 * @property {string} variant The variant to use. Use indeterminate when there is no progress value
 * @property {Object} classes custom styling classes
 * @property {Object} style sets the style of DrcProgress
 * @extends {React.Component}
 */
class DrcProgress extends React.Component {
    /**
     *
     *
     * @return {*}
     * @memberof DrcProgress
     */
    render() {
        const { isCircular, value, variant, style, classes, ...other } = this.props;

        var variantToUse = variant || 'determinate';

        const progress = (
            <React.Fragment>
                {isCircular ? (
                    <CircularProgress style={style} variant={variantToUse} value={value} {...other} />
                ) : (
                    <LinearProgress style={style} variant={variantToUse} value={value} {...other} />
                )}
                {variantToUse === 'determinate' || variantToUse === 'static' ? <span className={isCircular ? classes.circularLabel : classes.linearLabel}>{value}%</span> : null}
            </React.Fragment>
        );

        return progress;
    }
}

export default withStyles(styles)(DrcProgress);
