import React from 'react';
import { withStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers';
import { DuDateUtilities } from '@driscollsinc/driscolls-react-utilities';

/**
 *@ignore
 */
const styles = (theme) => ({
    root: {
        marginTop: '7px',
        marginBottom: '5px',
        '& .MuiInput-formControl': {
            marginTop: '10px'
        },
        '& .MuiSvgIcon-root': {
            color: theme.light.text.primary,
            [theme.darkTheme]: {
                color: theme.dark.text.primary
            }
        }
    },
    dialog: {
        '& .MuiPickersClock-squareMask': {
            cursor: 'pointer'
        },
        '& .MuiPickerDTToolbar-toolbar': {
            height: 'auto'
        },
        '& ..MuiPickersBasePicker-pickerView ': {
            minHeight: 'auto'
        }
    },
    labelError: {
        color: theme.light.text.errorTitle,
        fontSize: '1.25rem',
        [theme.darkTheme]: {
            color: theme.dark.text.errorTitle
        }
    }
});

const todaysDate = new Date();
const todaysFormattedDate = DuDateUtilities.ToString(todaysDate);

/**
 * @description DrcDateTimePicker gives dialog window or an inline popover to select a date and time
 * @property {string} label Field name
 * @property {*} value selected value
 * @property {boolean} disabled if readonly set to true
 * @property {function} onChange call back function to change the value
 * @example
 * <DrcDateTimePicker
        label={'Ordered Time'}
        value={new Date()}
        disabled
        onChange={() => {}}
    />
 * @category Input
 * @tags input date time picker
 * @class DrcDateTimePicker
 * @extends {React.Component}
 */
class DrcDateTimePicker extends React.Component {
    /**
     *
     *
     * @return {*}
     * @memberof DrcDateTimePicker
     */
    render() {
        const {
            classes,
            width,
            value,
            variant,
            onChange,
            label,
            style,
            helperText,
            required,
            InputProps,
            InputLabelProps,
            format,
            placeholder,
            showTodayButton,
            ...other
        } = this.props;
        return (
            <DatePicker
                className={classes.root}
                style={{ width: width || '100%', ...style }}
                margin="dense"
                inputVariant={variant || 'outlined'}
                label={
                    required ? (
                        <span>
                            {label || ''} <span className={classes.labelError}>*</span>
                        </span>
                    ) : (
                        label || ''
                    )
                }
                value={value}
                onChange={onChange}
                helperText={helperText || ''}
                placeholder={placeholder || todaysFormattedDate}
                format={format || 'MMM-dd-yyyy hh:mm a'}
                error={(helperText || '').length > 0}
                clearable={true}
                showTodayButton={showTodayButton}
                InputProps={{
                    ...InputProps
                }}
                InputLabelProps={{
                    ...InputLabelProps
                }}
                DialogProps={{ className: classes.dialog }}
                {...other}
            />
        );
    }
}

export default withStyles(styles)(DrcDateTimePicker);
