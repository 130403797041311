import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import DrcMain from '../Components/DrcMain';
import DrcPanel from '../Components/DrcPanel';
import Warning from '@mui/icons-material/Warning';

/**
 *@ignore
 */
const iconStyle = {
    fontSize: '4rem'
};

/**
 *@ignore
 */
const labelStyle = {
    display: 'inline-block',
    margin: '20px 20px 0 20px',
    position: 'relative',
    top: '-15px'
};

/**
 * @description The DrcPageNotFound is a page which can be displayed when user tries to reach an unreachable URL
 * @author stephen.weessies
 * @class DrcPageNotFound
 * @property {*}
 * @example
 * <DrcPageNotFound/>
 * @extends {React.Component} */
class DrcPageNotFound extends Component {
    /**
     * @description Main method to render the page
     * @return {*}
     * @memberof DrcGlossaryList
     */
    render() {
        return (
            <DrcMain transparent>
                <Translate>
                    {({ translate }) => (
                        <Helmet>
                            <title>{translate('general.notFound', null, { renderInnerHtml: false })}</title>
                        </Helmet>
                    )}
                </Translate>
                <DrcPanel error maxWidth="400px" style={{ textAlign: 'center' }}>
                    <Warning style={iconStyle} />
                    <h1 style={labelStyle}>
                        <Translate id="general.pageNotFound" />
                    </h1>
                    <Warning style={iconStyle} />
                </DrcPanel>
            </DrcMain>
        );
    }
}

export default DrcPageNotFound;
