const useGAAnalytics = () => {
    const logEvent = (eventName, eventInfo) => {
        const enviorment = (window.config.ENVIRONMENT_NAME === 'prod' ? '' : window.config.ENVIRONMENT_NAME).toUpperCase();
        window.config.logEvent(enviorment + '_' + eventName, eventInfo);
    };

    const logError = (errorName, errorInfo) => {
        const enviorment = (window.config.ENVIRONMENT_NAME === 'prod' ? '' : window.config.ENVIRONMENT_NAME).toUpperCase();
        window.config.logEvent(enviorment + '_' + 'exception -' + errorName, errorInfo);
    };

    return { logError, logEvent };
};
export default useGAAnalytics;
