import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NestedMenu from './NestedMenu';
import { openDrawer, showReportBackLinkAction } from '../actions/actions';
import { connect } from 'react-redux';
import { designColors, drawerWidth } from '../data/constants';
import { Route, useLocation } from 'react-router-dom';
import { DrcMediaQueries, DrcTooltip } from 'drc/driscolls-react-components/index';
import { ROUTES } from 'constants/routes';
import { userBusinessUnit } from 'utils/helper';
import useResponsive from 'hooks/v2/useResponsive';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        color: '#fff !important',
        backgroundColor: `${designColors.blue} !important`,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        }
    },
    listItemRoot: {
        color: '#fff !important',
        backgroundColor: `${designColors.blue} !important`,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        },
        fontWeight: 'bold',
        minWidth: 32
    },
    selected: {
        backgroundColor: '#fff !important',
        color: `${designColors.blue} !important`,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.grey['800']} !important`,
            color: '#fff !important'
        },
        fontWeight: 'bold'
    },
    menuBox: {
        display: 'flex',
        borderRadius: '10px',
        width: '45px',
        border: '2px solid white',
        padding: '8px',
        alignContent: 'center !important'
    },
    selectedBorder: {
        border: `2px solid ${designColors.blue}`
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: '100%',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        borderRadius: 0,
        justifyContent: 'end',
        color: '#fff !important'
    },
    hide: {
        display: 'none !important'
    },
    drawer: {
        top: '70px !important',
        border: 'none',
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        color: '#fff !important',
        backgroundColor: `${designColors.blue} !important`
    },
    drawerOpen: {
        border: 'none',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        top: '70px !important',
        color: '#fff',
        height: 'calc(100% - 70px)',
        backgroundColor: `${designColors.blue} !important`,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        }
    },
    drawerClose: {
        top: '70px !important',
        borderRight: 'none !important',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: theme.spacing(9) + 1,
        color: '#fff',
        backgroundColor: `${designColors.blue} !important`,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        },
        ['@media ' + DrcMediaQueries.mobileL]: {
            display: 'none'
        }
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    paperAnchorDockedLeft: {
        border: 'none'
    }
}));

function MiniDrawer(props) {
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const history = useLocation();
    const bu = userBusinessUnit();
    const isDesktop = useResponsive('up', 'md');
    useEffect(() => {
        let dataSetupRoutes = props.allLinks.reduce((acc, menu) => {
            menu.items?.map((itm) => acc.push(itm.route));
            return acc;
        }, []);
        if (
            (history.pathname === '/' ||
                (history.pathname === '/SettlementWorkbench/' && bu.toUpperCase() === 'DOC') ||
                history.pathname.indexOf(ROUTES.DASHBOARD) > -1) &&
            selectedIndex !== 0
        ) {
            setSelectedIndex(0);
        } else if ((history.pathname === '/PricingPool/' || history.pathname.indexOf(ROUTES.SETTLEMENT_WORKSPACE) > -1) && selectedIndex !== 1) {
            setSelectedIndex(1);
        } else if (dataSetupRoutes.includes(history.pathname) || (history.pathname.indexOf(ROUTES.BUSINESS_RULES) > -1 && selectedIndex !== 2)) {
            setSelectedIndex(2);
        } else if (history.pathname === '/Reports/' && selectedIndex !== 3) {
            setSelectedIndex(3);
        }
    }, [history.pathname, props.allLinks]);
    const handleDrawerOpen = () => {
        props.openDrawer(true);
    };

    const handleDrawerClose = () => {
        props.openDrawer(false);
    };

    const handleListItemClick = (itm, index) => {
        setSelectedIndex(index);
        props.showReportBackLinkAction(false);
        props.handleListItemClick(itm, index);
    };

    if (props.allLinks.length <= 0) return null;
    return (
        <>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: props.drawerOpen,
                    [classes.drawerClose]: !props.drawerOpen
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: props.drawerOpen,
                        [classes.drawerClose]: !props.drawerOpen,
                        [classes.paperAnchorDockedLeft]: true
                    })
                }}
            >
                {isDesktop && (
                    <IconButton
                        color="inherit"
                        aria-label="close drawer"
                        onClick={handleDrawerClose}
                        edge="start"
                        disableRipple
                        className={clsx(classes.menuButton, {
                            [classes.hide]: !props.drawerOpen
                        })}
                    >
                        <Box component="span" sx={{ p: 1, border: '1px solid white', borderRadius: 8 }}>
                            <ChevronLeftIcon />
                            <MenuIcon />
                        </Box>
                    </IconButton>
                )}
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    disableRipple
                    className={clsx(classes.menuButton, {
                        [classes.hide]: props.drawerOpen
                    })}
                >
                    <Box component="span" sx={{ p: 1, border: '1px solid white', borderRadius: 8 }}>
                        <MenuIcon />
                        <ChevronRightIcon />
                    </Box>
                </IconButton>
                <List>
                    {!!props.drawerOpen && selectedIndex !== 0 && <Divider variant="middle" style={{ backgroundColor: 'white' }} />}
                    {props.allLinks.map((menu, index) =>
                        menu.items ? (
                            <React.Fragment key={index}>
                                <NestedMenu
                                    menu={menu}
                                    index={index}
                                    onClick={(_event) => handleListItemClick(menu, menu.index)}
                                    setSelectedIndex={() => setSelectedIndex(menu.index)}
                                    handleDrawerOpen={handleDrawerOpen}
                                    selected={selectedIndex === menu.index}
                                    classes={classes}
                                />
                                {!!props.drawerOpen && selectedIndex !== menu.index && selectedIndex !== menu.index + 1 && (
                                    <Divider key={index} variant="middle" style={{ backgroundColor: 'white' }} />
                                )}
                            </React.Fragment>
                        ) : (
                            <React.Fragment key={index}>
                                <ListItem
                                    button
                                    key={index}
                                    onClick={(_event) => handleListItemClick(menu, menu.index)}
                                    selected={selectedIndex === menu.index}
                                    className={selectedIndex === menu.index ? classes.selected : classes.listItemRoot}
                                >
                                    {props.drawerOpen ? (
                                        <ListItemText key={index} primary={menu.name} /> //classes={{ root: classes.listItemRoot }}
                                    ) : (
                                        <DrcTooltip key={index} tipText={menu.name}>
                                            <div className={`${classes.menuBox} ${selectedIndex === menu.index ? classes.selectedBorder : null}`}>
                                                <ListItemIcon className={selectedIndex === menu.index ? classes.selected : classes.listItemRoot}>
                                                    {selectedIndex === menu.index ? menu.selectedIcon : menu.icon}
                                                </ListItemIcon>
                                            </div>
                                        </DrcTooltip>
                                    )}
                                </ListItem>
                                {!!props.drawerOpen && selectedIndex !== menu.index + 1 && selectedIndex !== menu.index && (
                                    <Divider key={index} variant="middle" style={{ backgroundColor: 'white' }} />
                                )}
                            </React.Fragment>
                        )
                    )}
                </List>
            </Drawer>
        </>
    );
}
const mapStateToProps = (state) => ({
    drawerOpen: state.rootReducer.drawerOpen
});

export default connect(mapStateToProps, { openDrawer, showReportBackLinkAction })(MiniDrawer);
