import React from 'react';
import { withStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';

/**
 *@ignore
 */
var styles = (theme) => ({
    root: {
        padding: theme.spacing(1, 2),
        marginBottom: theme.spacing(2),
        backgroundColor: theme.light.error,
        color: theme.light.text.inverted,
        textShadow: theme.light.variant.primary + '3px 2px 5px',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: theme.dark.error,
            color: theme.dark.text.secondary,
            textShadow: 'none'
        }
    },
    rootMessage: {
        backgroundColor: theme.light.accent.primary,
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: theme.dark.accent.primary
        }
    },
    rootWarning: {
        backgroundColor: theme.light.warning,
        color: theme.light.text.primary,
        textShadow: theme.light.variant.secondary + ' 3px 2px 8px',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: theme.dark.warning,
            color: theme.dark.text.primary,
            textShadow: theme.dark.text.primary + '3px 2px 8px'
        }
    },
    title: {
        marginTop: theme.spacing(1),
        fontSize: '1.5rem'
    }
});

/**
 * @description DrcBanner is a styled component to show Message or Error or Warnings
 * @class DrcBanner
 * @example
 * <DrcBanner isMessage title="Title">
        <strong>Message to be displayed</strong>
    </DrcBanner>
 * @category Layout
 * @tags banner header info information error warning message alert
 * @property {object} classes custom styles
 * @property {*} children children to be displayed Ex: List <ul></ul>
 * @property {*} isError If message type is error
 * @property {*} isWarning If message type is warning
 * @property {string} title Header for the banner
 * @extends {React.Component}
 */
class DrcBanner extends React.Component {
    /**
     *
     * @description Main method to show banner with title and content
     * @return {*}
     * @memberof DrcBanner
     */
    render() {
        const { isError, isWarning, title, children, classes } = this.props;

        var isMessage = !isError && !isWarning;

        return (
            <Paper className={`${classes.root} ${isMessage ? classes.rootMessage : ''} ${isWarning ? classes.rootWarning : ''}`}>
                <h3 className={classes.title}>{title}</h3>
                <p>{children}</p>
            </Paper>
        );
    }
}

export default withStyles(styles)(DrcBanner);
