import React from 'react';
import DrcIconButton from './DrcIconButton';
import DrcIcons from '../Utilities/DrcIcons';
import { withStyles } from '@mui/styles';

/**
 *
 *
 * @param {*} theme
 */
var styles = (theme) => ({
    notSelected: {
        border: 'solid 3px ' + theme.light.secondary,
        height: 50,
        width: 50,
        borderRadius: 25,
        margin: '8px 8px 8px 0',
        padding: 0,
        '&:focus': {
            borderColor: theme.light.secondary
        },
        '& img': {
            width: 25
        }
    },
    selected: {
        borderColor: theme.light.accent.primary + ' !important'
    }
});

/**
 *
 *
 * @description Clickable Button with berry icon
 * @class DrcBerryButton
 * @example <DrcBerryButton berry={1} disabled={false} selected={true} handleCheckboxChange=()=>{}/>
 * @category Input
 * @tags input button berries berry icon icons pretty
 * @extends {React.Component}
 * @property {boolean} selected Makes the button selected/primary
 * @property {boolean} disabled Disables the button
 * @property {string} title Displays text over button
 * @property {string} size The size of the button (small, medium, large)
 * @property {number} berry Berry code of the berry i.e 1,2,3 or 4
 */
class DrcBerryButton extends React.Component {
    /**
     * @description called on button Click
     *
     * @return {*}
     * @memberof DrcBerryButton
     *
     */
    handleClick = () => {
        if (!this.props.handleCheckboxChange) {
            return;
        }

        this.props.handleCheckboxChange(!this.props.selected, this.props.berry);
    };

    /**
     *
     *
     * @return {*}
     * @memberof DrcBerryButton
     */
    render() {
        const { style, selected, title, className, disabled, classes, hard, poly, line } = this.props;

        const berry = this.props.berry > 0 && this.props.berry <= 4 ? this.props.berry : null;
        const berryIcon = DrcIcons.GetBerryIcon(berry, false);

        return (
            <DrcIconButton
                className={`${classes.notSelected} ${selected ? classes.selected : null} ${className}`}
                style={{
                    ...style
                }}
                isText
                disabled={!!disabled}
                onClick={this.handleClick}
                title={title}
                noStyle
                hard={hard}
                poly={poly}
                line={line}
            >
                {berryIcon}
            </DrcIconButton>
        );
    }
}

export default withStyles(styles)(DrcBerryButton);
