export const enUS = {
    Blackberry: 'Blackberry',
    Blueberry: 'Blueberry',
    Raspberry: 'Raspberry',
    Strawberry: 'Strawberry',
    'Grower Settlement': 'Grower Settlement',
    'User Details': 'User Details',
    Admin: 'Admin',
    'Grower Accountant': 'Grower Accountant',
    'Settlement Workbench': 'Settlement Workbench',
    'Pricing Pool': 'Pricing Pool',
    'Settlement Pool': 'Settlement Pool',
    'Data Setup': 'Data Setup',
    Home: 'Home',
    'Pool Week': 'Pool Week',
    'Item UOM Conversion': 'Item UOM Conversion',
    'Item Shipping Rates': 'Item Shipping Rates',
    'Repackaging SKU Mapping': 'Repackaging SKU Mapping',
    'Grower Category': 'Grower Category',
    'Settlement Rate': 'Settlement Rate',
    'Settlement Record': 'Settlement Record',
    'Customer Code Category': 'Customer Code Category',
    'Receipt lines': 'Receipt lines',
    'VAT Rate Maintenance': 'VAT Rate Maintenance',
    'Forex Rate': 'Forex Rate',
    'Sales Invoice lines': 'Sales Invoice lines',
    'Packaging Deduction': 'Packaging Deduction',
    'Adjustments (Pooled)': 'Adjustments (Pooled)',
    HOMI: 'HOMI',
    PFQ: 'PFQ',
    'Adjustments (Non-Pooled)': 'Adjustments (Non-Pooled)',
    'Grower Financing': 'Grower Financing',
    Advances: 'Advances',
    'Packaging Issuance': 'Packaging Issuance',
    'Packaging Credit': 'Packaging Credit',
    'Plant Recovery': 'Plant Recovery',
    'Sign Out': 'Sign Out',
    'Updated Successfully': 'Updated Successfully',
    'Added Successfully': 'Added Successfully',
    'Deleted Successfully': 'Deleted Successfully',
    'An Error Occurred': 'An Error Occurred',
    'Duplicate Record': 'Duplicate Record',
    Process: 'Process',
    'Submit to Approval': 'Submit to Approval',
    Reports: 'Reports',
    'No Data To Show': 'No Data To Show',
    Export: 'Export',
    'Average Price': 'Average Price',
    'Turn Over Rate': 'Turn Over Rate',
    'Average Price & Turn Over Rate': 'Average Price & Turn Over Rate',
    Refresh: 'Refresh',
    'SETTLEMENT SHARE': 'SETTLEMENT SHARE',
    'Share %': 'Share %',
    Sales: 'Sales',
    Receipts: 'Receipts',
    WEIGHT: 'WEIGHT',
    'Expected vs Actual': 'Expected vs Actual',
    'Approved By : ': 'Approved By : ',
    'Submitted By : ': 'Submitted By : ',
    Reject: 'Reject',
    Approve: 'Approve',
    'Add Commodity from below': 'Add Commodity from below',
    'Selected Commodities': 'Selected Commodities',
    'To Date': 'To Date',
    Status: 'Status',
    'Goods In Transit': 'Goods In Transit',
    'Inventory On-hand': 'Inventory On-hand',
    'RECEIPT LINES': 'RECEIPT LINES',
    'From Date': 'From Date',
    'SUMMARY REVIEW': 'SUMMARY REVIEW',
    'Sales Invoice Lines': 'Sales Invoice Lines',
    'Service Deductions': 'Service Deductions',
    'Current Release Version:': 'Current Release Version:',
    'Config Settings': 'Config Settings',
    'Choose Filter': 'Choose Filter',
    'Business Unit': 'Business Unit',
    Year: 'Year',
    'Start Date': 'Start Date',
    'End Date': 'End Date',
    'Search Key Date': 'Search Key Date',
    Remaining: 'Remaining',
    'On Pricing Pool': 'On Pricing Pool',
    'On Settlement Pool': 'On Settlement Pool',
    'Preparing download': 'Preparing download',
    'Your download should start in a few seconds.': 'Your download should start in a few seconds.',
    Journals: 'Journals',
    'View Report': 'View Report',
    Submit: 'Submit',
    Cancel: 'Cancel',
    "Receipt's Quantity": "Receipt's Quantity",
    'Sales Amount': 'Sales Amount',
    Reset: 'Reset',
    Filter: 'Filter',
    Add: 'Add',
    Delete: 'Delete',
    Lookup: 'Lookup',
    'Pool Week Start Date': 'Pool Week Start Date',
    'Pool Week End Date': 'Pool Week End Date',
    'Created By': 'Created By',
    'Created Date Time': 'Created Date Time',
    'Modified By': 'Modified By',
    'Modified Date Time': 'Modified Date Time',
    Totals: 'Totals',
    'Pool Id': 'Pool Id',
    'Expected Qty': 'Expected Qty',
    Description: 'Description',
    QUANTITY: 'QUANTITY',
    Variance: 'Variance',
    "Driscoll's Farm Share%": "Driscoll's Farm Share%",
    'Grower Share%': 'Grower Share%',
    'Not Run': 'Not Run',
    Processing: 'Processing',
    Processed: 'Processed',
    'Pending Approval': 'Pending Approval',
    Approved: 'Approved',
    Rejected: 'Rejected',
    'Settlement Pool ID': 'Settlement Pool ID',
    Closed: 'Closed',
    Open: 'Open',
    Pricing: 'Pricing',
    Settlement: 'Settlement',
    'Select Report': 'Select Report',
    'Select Pool Week': 'Select Pool Week',
    'Select Commodity': 'Select Commodity',
    'Report Type': 'Report Type',
    'Enter Item ID': 'Enter Item ID',
    'Settlement Currency': 'Settlement Currency',
    Version: 'Version',
    'Search Grower ID': 'Search Grower ID',
    'Select Estimate Group': 'Select Estimate Group',
    'Package UOM': 'Package UOM',
    'Freight UOM': 'Freight UOM',
    Commodity: 'Commodity',
    'Repackage Item Id': 'Repackage Item Id',
    'Original Item Id': 'Original Item Id',
    'Customer Code': 'Customer Code',
    'Vendor Code': 'Vendor Code',
    'Grower Name': 'Grower Name',
    'Location Code': 'Location Code',
    'Family Entity': 'Family Entity',
    Geography: 'Geography',
    Application: 'Application',
    'Cooler Name': 'Cooler Name',
    Country: 'Country',
    State: 'State',
    'Lookup Type': 'Lookup Type',
    'Lookup Code': 'Lookup Code',
    'Repackage Movement': 'Repackage Movement',
    'Re-Process': 'Re-Process',
    'Rejected By:': 'Rejected By:',
    'Add Pricing Pool': 'Add Pricing Pool',
    'Edit Pricing Pool': 'Edit Pricing Pool',
    'Prepared By': 'Prepared By',
    'User Preference': 'User Preference',
    Accounts: 'Accounts',
    'POOL DEFINITIONS': 'POOL DEFINITIONS',
    Select: 'Select',
    'Grower Fixed Price': 'Grower Fixed Price',
    'Enter Item ID to search': 'Enter Item ID to search',
    WeeklySales: 'WeeklySales',
    InventoryMovement: 'InventoryMovement',
    AverageSalesPrice: 'AverageSalesPrice',
    TurnOverRateDomestic: 'TurnOverRateDomestic',
    TurnOverRateImport: 'TurnOverRateImport',
    ItemInventoryOutbound: 'ItemInventoryOutbound',
    Back: 'Back',
    New: 'New',
    'Estimate Group': 'Estimate Group',
    'Tray To Kilogram': 'Tray To Kilogram',
    'Item ID': 'Item ID',
    Actions: 'Actions',
    Currency: 'Currency',
    'Package Rate': 'Package Rate',
    'Freight Rate': 'Freight Rate',
    'Repackage Item Description': 'Repackage Item Description',
    'Original Item Description': 'Original Item Description',
    'Contact Email Id': 'Contact Email Id',
    Category: 'Category',
    Price: 'Price',
    'Effective Start Date': 'Effective Start Date',
    'Effective End Date': 'Effective End Date',
    'Application Full Name': 'Application Full Name',
    'Commission Rate': 'Commission Rate',
    'Share Grower Percent': 'Share Grower Percent',
    'Share Driscolls Percent': 'Share Driscolls Percent',
    'Mid Size Grower Percent': 'Mid Size Grower Percent',
    'Mid Size Driscolls Percent': 'Mid Size Driscolls Percent',
    'Start Date Active': 'Start Date Active',
    'End Date Active': 'End Date Active',
    'Exclude Average Pricing': 'Exclude Average Pricing',
    'VAT Percent': 'VAT Percent',
    Meaning: 'Meaning',
    'Opening Balance KG': 'Opening Balance KG',
    'Closing Balance KG': 'Closing Balance KG',
    'Sales Quantity': 'Sales Quantity',
    'Receipt Quantity': 'Receipt Quantity',
    'Sales Order Sold': 'Sales Order Sold',
    'Include Quality': 'Include Quality',
    'Damage Loss': 'Damage Loss',
    Remark: 'Remark',
    'Loading data': 'Loading data',
    'Account No': 'Account No',
    'Inactive Flag': 'Inactive Flag',
    'Select Item ID': 'Select Item ID',
    ADJUSTMENTS: 'ADJUSTMENTS',
    AMOUNT: 'AMOUNT',
    'Adjustment Amount': 'Adjustment Amount',
    'Import Cost': 'Import Cost',
    'DOC Approver': 'DOC Approver',
    'DOC User': 'DOC User',
    'Shipment Number': 'Shipment Number',
    'End Date should be greater than start date': 'End Date should be greater than start date',
    'Start date and End date overlap': 'Start date and End date overlap',
    'From Currency': 'From Currency',
    'To Currency': 'To Currency',
    'Exchange Rate': 'Exchange Rate',
    'Rate Date': 'Rate Date',
    Language: 'Language',
    'Shipment Details': 'Shipment Details',
    'Receipt Id': 'Receipt Id',
    Line: 'Line',
    Date: 'Date',
    'Vendor Name Chinese': 'Vendor Name Chinese',
    'Vendor Name': 'Vendor Name',
    'Item Number': 'Item Number',
    Quantity: 'Quantity',
    'Cost Center': 'Cost Center',
    Variety: 'Variety',
    'Qty Ordered': 'Qty Ordered',
    Memo: 'Memo',
    Location: 'Location',
    'QC Score': 'QC Score',
    'Invoice ID': 'Invoice ID',
    'Line Num': 'Line Num',
    'Invoice Date': 'Invoice Date',
    'Item Description': 'Item Description',
    'Pickup Location': 'Pickup Location',
    'Invent Trans ID': 'Invent Trans ID',
    'Invoice Amount': 'Invoice Amount',
    'Customer Name': 'Customer Name',
    'Customer Geography': 'Customer Geography',
    'Item Rate': 'Item Rate',
    'Sales ID': 'Sales ID',
    Item: 'Item',
    'Preferred Vendor': 'Preferred Vendor',
    'On Hand': 'On Hand',
    'Transaction Key': 'Transaction Key',
    'Document Number': 'Document Number',
    'Ship To': 'Ship To',
    'Quantity Memo': 'Quantity Memo',
    'Date Shipped': 'Date Shipped',
    'Date Received': 'Date Received',
    'Ship From Location': 'Ship From Location',
    'Last Modified Date': 'Last Modified Date',
    'Item Id': 'Item Id',
    Account: 'Account',
    Amount: 'Amount',
    'Berry Type': 'Berry Type',
    'Transaction Number': 'Transaction Number',
    Type: 'Type',
    Vendor: 'Vendor',
    'Grower ID': 'Grower ID',
    'Payment Date': 'Payment Date',
    'Total Kilos Received': 'Total Kilos Received',
    'Package Deduction': 'Package Deduction',
    'Gross Pool Amount': 'Gross Pool Amount',
    'Commission Net Pool Return': 'Commission Net Pool Return',
    'Freight Deduction': 'Freight Deduction',
    'Commission Net Fruit Return Price Per Kg': 'Commission Net Fruit Return Price Per Kg',
    'Average Price Per Kg': 'Average Price Per Kg',
    'VAT Net Fruit Return Price Per Kg': 'VAT Net Fruit Return Price Per Kg',
    'UOM Conversion Rate': 'UOM Conversion Rate',
    'VAT Net Pool Return': 'VAT Net Pool Return',
    'Average Price Per Tray': 'Average Price Per Tray',
    'NetFruit Return Price Per Kg': 'NetFruit Return Price Per Kg',
    'Total Trays Received': 'Total Trays Received',
    'VAT Share Grower': 'VAT Share Grower',
    'Net Fruit Before DAP share': 'Net Fruit Before DAP share',
    'DAP Share': 'DAP Share',
    'Submitted By': 'Submitted By',
    'Approved By': 'Approved By',
    'Settlement Unit Price With Repack': 'Settlement Unit Price With Repack',
    'Prelim WODamage Average Settlement Price': 'Prelim WODamage Average Settlement Price',
    'Prelim Average Settlement Price': 'Prelim Average Settlement Price',
    'Abnormal Sales Amount': 'Abnormal Sales Amount',
    'Settlement Unit Price': 'Settlement Unit Price',
    'Repackaging Sales Qty': 'Repackaging Sales Qty',
    'Final True Up Average Settlement Price': 'Final True Up Average Settlement Price',
    'Sold Qty': 'Sold Qty',
    'Receipt Qty': 'Receipt Qty',
    'Final Average Settlement Price': 'Final Average Settlement Price',
    'Sample Damage Sales Qty': 'Sample Damage Sales Qty',
    'Closing Qty': 'Closing Qty',
    'Normal Sales Unit Price': 'Normal Sales Unit Price',
    'Turn Over Rate WK2': 'Turn Over Rate WK2',
    'Opening Qty': 'Opening Qty',
    'Turn Over Rate Wk1': 'Turn Over Rate Wk1',
    'Turn Over Rate WK3': 'Turn Over Rate WK3',
    'Normal Sales Qty': 'Normal Sales Qty',
    'Abnormal Sales Qty': 'Abnormal Sales Qty',
    'In Transit': 'In Transit',
    'Normal Sales Amount': 'Normal Sales Amount',
    'Quality Issue Sales Qty': 'Quality Issue Sales Qty',
    'Abnormal Sales Unit Price': 'Abnormal Sales Unit Price',
    'D Shared Sales Qty': 'D Shared Sales Qty',
    'D Shared Sales Amount': 'D Shared Sales Amount',
    'D Shared Sales Unit Unit Price': 'D Shared Sales Unit Unit Price',
    'Service Deduction Amount': 'Service Deduction Amount',
    'Variance Normal Settlement Unit Price': 'Variance Normal Settlement Unit Price',
    'Variance Normal Settlement Unit PriceWith Repack': 'Variance Normal Settlement Unit PriceWith Repack',
    'Disposal Total': 'Disposal Total',
    'Last Week On Hand': 'Last Week On Hand',
    'Last Week In Transit': 'Last Week In Transit',
    DisposalQty: 'Disposal Qty',
    'Prelim WO Damage Average Settlement Price': 'Prelim WO Damage Average Settlement Price',
    Spec: 'Spec',
    SPEC: 'SPEC',
    'Turn Over Rate WK1': 'Turn Over Rate WK1',
    ReceiptQty: 'ReceiptQty',
    'Disposal Qty': 'Disposal Qty',
    'Sold Qty WK1': 'Sold Qty WK1',
    'Prelim Average Settlement Price WK1': 'Prelim Average Settlement Price WK1',
    'Sold Qty WK2': 'Sold Qty WK2',
    'Prelim Average Settlement Price WK2': 'Prelim Average Settlement Price WK2',
    'Sold Qty WK3': 'Sold Qty WK3',
    'Prelim Average Settlement Price WK3': 'Prelim Average Settlement Price WK3',
    Balance: 'Balance',
    Abbreviation: 'Abbreviation',
    'Pkg Net Pool Return Price Per KG': 'Pkg Net Pool Return Price Per KG',
    'Pkg Net Fruit Return Price Per Kg': 'Pkg Net Fruit Return Price Per Kg',
    'Submitted Date': 'Submitted Date',
    'Approved Date': 'Approved Date',
    'Repackage Item ID': 'Repackage Item ID',
    'Original Item ID': 'Original Item ID',
    'Short Name': 'Short Name',
    'Repackage Movement ID': 'Repackage Movement ID',
    'Enabled Flag': 'Enabled Flag',
    'Custom Clearance Fee': 'Custom Clearance Fee',
    Trays: 'Trays',
    'Container Number': 'Container Number',
    'Freight Custom To FDC': 'Freight Custom To FDC',
    'Repackaging Charge': 'Repackaging Charge',
    'Pool Week Average Price/Tray': 'Pool Week Average Price/Tray',
    'Pool Week Average Price/Kilo': 'Pool Week Average Price/Kilo',
    'Total PKG Ded': 'Total PKG Ded',
    'Total Freight Deduction': 'Total Freight Deduction',
    'Net Fruit Return Price/Kilo (Avg)': 'Net Fruit Return Price/Kilo (Avg)',
    'Net Fruit before DAP Share': 'Net Fruit before DAP Share',
    'Net Pool Return': 'Net Pool Return',
    'Pool Return': 'Pool Return',
    'VAT Part Share-Grower': 'VAT Part Share-Grower',
    'Enter Receipt Id': 'Enter Receipt Id',
    Apply: 'Apply',
    'Enter Item ID to Search': 'Enter Item ID to Search',
    'Inventory Movement': 'Inventory Movement',
    'Grower Settlement Report': 'Grower Settlement Report',
    'Receipt By Cost Center': 'Receipt By Cost Center',
    'Duplicate entry': 'Duplicate entry',
    'Summary Domestic': 'Summary Domestic',
    'Weekly Sales Report': 'Weekly Sales Report',
    'Average Sales Price': 'Average Sales Price',
    'Turn Over Rate Domestic': 'Turn Over Rate Domestic',
    'Turn Over Rate Import': 'Turn Over Rate Import',
    'Item Inventory Outbound': 'Item Inventory Outbound',
    'Aged Inventory': 'Aged Inventory',
    'Driscolls Xishuangbanna AP': 'Driscolls Xishuangbanna AP',
    'Driscolls Qujing AP': 'Driscolls Qujing AP',
    'Share Percentage': 'Share Percentage',
    'Share Yunnan': 'Share Yunnan',
    'Share Seven knot': 'Share Seven knot',
    'Driscolls Seven knot AP': 'Seven knot AP',
    'Share Seven knot KY': 'Share Seven knot KY',
    'Seven knot KY AP': 'Seven knot KY AP',
    'Pkg Net Return To Grower': 'Pkg Net Return To Grower',
    'Share Xishuangbanna': 'Share Xishuangbanna',
    'Driscolls Yunnan AP': 'Driscolls Yunnan AP',
    'Share Grower Allowance': 'Share Grower Allowance',
    'Share Qujing': 'Share Qujing',
    'Net Return To Grower': 'Net Return To Grower',
    'Packaging Issurance': 'Packaging Issurance',
    'Share Grower Deduction': 'Share Grower Deduction',
    'All Deduct Net Return To Grower': 'All Deduct Net Return To Grower',
    'Grower Percentage': 'Grower Percentage',
    'Preliminary SH Booking': 'Preliminary SH Booking',
    'Final SH Booking': 'Final SH Booking',
    'Preliminary Grower Booking': 'Preliminary Grower Booking',
    'Final Grower Booking': 'Final Grower Booking',
    'Preliminary Family Entity Booking': 'Preliminary Family Entity Booking',
    'Final Family Entity Booking': 'Final Family Entity Booking',
    'Receipt By Grower': 'Receipt By Grower',
    'User Preferences': 'User Preferences',
    'User Role': 'User Role',
    Save: 'Save',
    'Grower Own Percentage': 'Grower Own Percentage',
    'Family Entity Percentage': 'Family Entity Percentage',
    'DMSH Share': 'DMSH Share',
    'Family Entity Entry Booking': 'Family Entity Entry Booking',
    'Shanghai Entry Booking - Preliminary': 'Shanghai Entry Booking - Preliminary',
    'Shanghai Entry Booking - Final': 'Shanghai Entry Booking - Final',
    Code: 'Code',
    'Quantity Kg': 'Quantity Kg',
    'CC Allocation': 'CC Allocation',
    'CC Allocation Entry Booking - Preliminary': 'CC Allocation Entry Booking - Preliminary',
    'JE Code': 'Code',
    'JE Cost Center': 'Cost Center',
    'JE Shanghai': 'JE Shanghai',
    'Grower Amount': 'Grower Amount',
    'Driscolls Amount': 'Driscolls Amount',
    Total: 'Total',
    'Grower Own': 'Grower Own',
    'Account Number': 'Account Number',
    Debit: 'Debit',
    Credit: 'Credit',
    'Line Memo': 'Line Memo',
    'Account Name': 'Account Name',
    Subsidiary: 'Subsidiary',
    'JE - Sevenknot': 'JE - Sevenknot',
    'JE - Qujing': 'JE - Qujing',
    'JE - Xishuangbanna': 'JE - Xishuangbanna',
    'JE - Yunnan': 'JE - Yunnan',
    'JE - Shanghai': 'JE - Shanghai',
    Publish: 'Publish',
    Published: 'Published',
    Grower: 'Grower',
    Qty: 'Qty',
    'Last Week Repack SKU Transfer Back': 'Last Week Repack SKU Transfer Back',
    'Repack SKU Transfer Back': 'Repack SKU Transfer Back',
    Module: 'Module',
    Breeder: 'Breeder',
    'CC Percent': 'CC Percent',
    'DSA Service Fee': 'DSA Service Fee',
    'DSA Service Fee 2% / 5%': 'DSA Service Fee 2% / 5%',
    'Parent Vendor Code': 'Parent Vendor Code',
    'Import Settlement Rate': 'Import Settlement Rate',
    'Import Grower Settlement': 'Import Grower Settlement',
    'Add Grower': 'Add Grower',
    'Update Items': 'Update Items',
    'Pool ID': 'Pool ID',
    'Pool Status': 'Pool Status',
    Region: 'Region',
    'Destination Countries': 'Destination Countries',
    'Producing Area': 'Producing Area',
    'Import Receipt By Cost Center': 'Import Receipt By Cost Center',
    'Import Settlement Report': 'Import Settlement Report',
    'Transfer Rate': 'Transfer Rate',
    'Pool Week Average Price Per Kg': 'Pool Week Average Price Per Kg',
    'Domestic Freight': 'Domestic Freight',
    'Settled Average Price Per Tray': 'Settled Average Price Per Tray',
    'Unit Return Per Kg': 'Unit Return Per Kg',
    'Trans Net Fruit Return Price Per Kg': 'Trans Net Fruit Return Price Per Kg',
    'DSA Service Fee Cost': 'DSA Service Fee Cost',
    'Pooling Amount': 'Pooling Amount',
    'Advance Rate': 'Advance Rate',
    'Total Costs Deducted': 'Total Costs Deducted',
    'Direct Sales Return': 'Direct Sales Return',
    'Advance Paid': 'Advance Paid',
    'Summary CNY': 'Summary CNY',
    'Summary Peru': 'Summary Peru',
    'Summary Chile': 'Summary Chile',
    'Settled Average Price/Tray': 'Settled Average Price/Tray',
    'Net Fruit Return Price Per/Kilo (Avg)': 'Net Fruit Return Price Per/Kilo (Avg)',
    'Import Custom Clearance Fee': 'Import Custom Clearance Fee',
    'Freight From Custom To FDC': 'Freight From Custom To FDC',
    Others: 'Others',
    'Unit Return/Kg': 'Unit Return/Kg',
    'Grower Location': 'Grower Location',
    'Sub Grower': 'Sub Grower',
    'Remaining Payment': 'Remaining Payment',
    'Journal Account': 'Journal Account',
    'PDF Domestic Pre': 'PDF Domestic Pre',
    'PDF Domestic Final': 'PDF Domestic Final',
    'PDF Import Pre': 'PDF Import Pre',
    'PDF Import Final': 'PDF Import Final',
    'Do you want to freeze Pool Week?': 'Do you want to freeze Pool Week?',
    Confirm: 'Confirm',
    'Freeze GIT/On-hand Reports': 'Freeze GIT/On-hand reports',
    'PoolWeek Freezed Successfully': 'PoolWeek Freezed Successfully',
    'Publish Domestic': 'Publish Domestic',
    'Publish Import': 'Publish Import',
    'Partially Published': 'Partially Published',
    Failed: 'Failed',
    'Exclude Normal Sales Amt': 'Exclude Normal Sales Amt',
    'Exclude Normal Sales Qty': 'Exclude Normal Sales Qty',
    'Exclude Settlement Price Amt': 'Exclude Settlement Price Amt',
    'Exclude Settlement Price Qty': 'Exclude Settlement Price Qty',
    'No Files Found': 'No Files Found',
    'Downloaded Successfully': 'Downloaded Successfully',
    'Download Reports': 'Download Reports',
    'Import Data': 'Import Data',
    'Only *.csv file should be uploaded for acceptance.': 'Only *.csv file should be uploaded for acceptance.',
    'Choose a file ': 'Choose a file ',
    'or drag it here.': 'or drag it here.',
    'Please Enter a Valid File.': 'Please Enter a Valid File.',
    'Search Reports': 'Search Reports',
    'Preparing Files...': 'Preparing Files...',
    'Files are preparing to download.': 'Files are preparing to download.',
    "Business Rules": 'Business Rules',
    "Results loaded in":'Results loaded in',
    "Business Rules / History": 'Business Rules / History',
    "Business Rules Approval": "Business Rules Approval",
    "Edit Business Rule":"Edit Business Rule",
    "Create New Business Rule": "Create New Business Rule",
    "Copy":"Copy",
    Dashboard: "Dashboard",
    Exception: "Exception",
    Information: "Information"
    


};
