import React from 'react';
import DrcButton from './DrcButton';
import CloseIcon from '@mui/icons-material/Close';

/**
 * @description The DrcFilterItem is an individual item in the DrcFilter, used in DrcFilter.js
 * @author stephen.weessies
 * @class DrcFilterItem
 * @example
 * <DrcFilterItem key={filter.type} name={filter.name} type={filter.type} label={filter.label} onRemove={onRemove} />
 * @donotprint true
 * @category Input
 * @tags input filter item select search
 * @property {function} onRemove  remove handler for the filter item
 * @property {string} key key for the filter item if required
 * @property {string} name name for the filter item
 * @property {string} type type for the filter item
 * @property {string} label label for the filter item
 * @extends {React.Component} */
class DrcFilterItem extends React.Component {
    /**
     * Creates an instance of DrcFilterItem.
     * @memberof DrcFilterItem
     * @return {*}
     *
     */
    constructor(props) {
        super(props);

        this.onRemove = this.onRemove.bind(this);
    }
    /**
     * @description This method is used to remove the individual DrcFilterItem
     * @author stephen.weessies
     * @return {*} 
     * @memberof DrcFilterItem
     */
    onRemove() {
        this.props.onRemove(this.props.type);
    }
    /**
     *
     *
     * @return {*}
     * @memberof DrcKeyValueTable
     */
    render() {
        const { name, label } = this.props;

        return (
            <DrcButton
                isSecondary
                style={{
                    marginLeft: '0',
                    marginRight: '15px',
                    borderRadius: '18px',
                    padding: '3px 5px 1px 10px',
                    textTransform: 'none'
                }}
                onClick={this.onRemove}
            >
                {name}: {label} <CloseIcon style={{ height: '20px', marginTop: '-1px' }} />
            </DrcButton>
        );
    }
}

export default DrcFilterItem;
