import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
const useGetApiTokens = () => {
    const getTokenAndId = async (oktaAuth) => {
        const token = await oktaAuth.getAccessToken();
        const userOktaId = DuAuthenticationUtilities.GetOktaId(token);
        const userEmail = DuAuthenticationUtilities.GetUserId(token);
        return { token, userOktaId, userEmail };
    };

    return { getTokenAndId: getTokenAndId };
};

export default useGetApiTokens;
