import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { DrcButton, DrcTranslate } from 'drc/driscolls-react-components/index';
import GridStyles from '../../styles/gridStyles';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast } from '../../actions/actions';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { downloadFile, displayErrorMessage, makeColumns } from '../../utils/helper';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { Call } from '@driscollsinc/driscolls-display-rules';
import APIEndPoints from '../../services/api';
import { summaryPeruCol, designColors, currencySymbols } from '../../data/constants';
import PrimeReactTable from '../../components/PrimeReactTable';
import NoData from '../../components/NoData';
import { Typography } from '@mui/material';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import HeaderDate from './HeaderDate';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
const styles = (theme) => ({
    input: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'left'
        }
    },
    gridStyles: GridStyles.styles(theme, '300px', '300px'),
    tableContainer: {
        margin: theme.spacing(3)
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    icon: {
        marginRight: 5
    }
});

class ImportSettlementReport extends Component {
    state = {
        data: [],
        pageData: [],
        pageNum: 0,
        pageSize: 50,
        totalCount: 0,
        columns: []
    };

    excludingFields = ['CreatedBy', 'ModifiedDateTime', 'ModifiedBy', 'CreatedDateTime', 'SubmittedBy'];
    readOnly = true;
    uniqueKeyField = 'ItemID';
    widthIsSufficient = window.innerWidth > 955;
    widthIsInSufficient = false;

    componentDidMount() {
        this.props.loadFilteredData(this.loadData.bind(this));
        window.addEventListener('resize', this.updateDataTable);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDataTable);
    }
    updateTableData = (response, _hasFrozenColumns = false) => {
        let data = response?.display?.resultSet1 || [];
        this.props.filters.ReportType === 'Preliminary'
            ? (summaryPeruCol['DomesticFreight'] = 'Domestic Freight')
            : (summaryPeruCol['DomesticFreight'] = 'Total Trans');
        let columns = data.length
            ? makeColumns(
                  Object.keys(data[0]),
                  [...this.excludingFields, ...(this.props.filters.ReportType === 'Preliminary' ? ['RemainingPayment'] : [])],
                  null,
                  false,
                  [],
                  [],
                  { GrowerID: 'Grower ID', GrowerName: 'Grower Name', ...summaryPeruCol },
                  false,
                  currencySymbols.USD
              )
            : [];
        let start = Math.floor(this.state.pageNum / this.state.pageSize) * this.state.pageSize;
        this.setState({
            data,
            pageData: data.slice(start, start + this.state.pageSize) || [],
            totalCount: data.length || 0,
            columns
        });
    };
    updateDataTable = (_event, force = false) => {
        if (window.innerWidth > 955 && !this.widthIsSufficient) {
            this.widthIsSufficient = true;
            this.widthIsInSufficient = false;
            this.updateTableData(this.response || [], true);
        } else if (window.innerWidth <= 955 && !this.widthIsInSufficient) {
            this.widthIsInSufficient = true;
            this.widthIsSufficient = false;
            this.updateTableData(this.response || [], false);
        } else if (force) {
            this.updateTableData(this.response || [], true);
        }
    };

    loadData = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
        try {
            var data = await Call(
                {
                    name: 'SummaryReportsCR',
                    url: APIEndPoints.POST_IMPORT_GROWER_SETTLEMENT_REPORT(),
                    method: 'POST',
                    options: {},
                    data: {
                        SearchBy: loggedInUser,
                        ...this.props.filters
                    }
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }
            this.response = data;
            this.updateDataTable(null, true);
            this.props.hideLoadingScreenAction();
        } catch (error) {
            this.showError(error);
        }
    };

    onPage = (event) => {
        let start = Math.floor(event.first / this.state.pageSize) * this.state.pageSize;
        this.setState({ pageNum: event.first, pageData: this.state.data.slice(start, start + this.state.pageSize) });
    };

    showError = (err, msg) => {
        this.props.hideLoadingScreenAction();
        this.props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };
    exportData = async () => {
        try {
            this.props.showLoadingScreenAction(
                <React.Fragment>
                    <h4 style={{ fontSize: '1.5rem' }}>
                        <DrcTranslate>{'Preparing download'}</DrcTranslate>
                    </h4>
                    <div>
                        <DrcTranslate>{'Your download should start in a few seconds.'}</DrcTranslate>
                    </div>
                </React.Fragment>
            );
            this.props.hideLoadingScreenAction();
            downloadFile(
                `ImportSettlementReport_${this.props.poolWeek.value}_${this.props.filters.GrowerName}${
                    this.props.filters.SubGrower ? this.props.filters.SubGrower : ''
                }_${this.props.filters.ReportType}.xlsx`,
                this.state.data,
                { GrowerID: 'Grower ID', GrowerName: 'Grower Name', ...summaryPeruCol },
                [...this.excludingFields, ...(this.props.filters.ReportType === 'Preliminary' ? ['Remaining Payment'] : [])],
                currencySymbols.USD
            );
        } catch (err) {
            console.log(err);
        }
    };

    render() {
        const { classes } = this.props;
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        return (
            <div className={classes.tableContainer}>
                <div className={classes.actionContainer}>
                    <Typography variant="h6" noWrap style={{ fontWeight: 'bold', color: designColors.blue, textTransform: 'uppercase' }}>
                        <DrcTranslate>{'Import Grower Settlement'}</DrcTranslate>
                    </Typography>
                    {!!this.state.data.length && <HeaderDate recordStatus={this.props.recordStatus} />}
                    <DrcButton onClick={this.exportData} style={{ fontWeight: 'bold', color: designColors.blue }} disabled={!this.state.data.length}>
                        <VerticalAlignBottomIcon className={classes.icon} /> <DrcTranslate>{'Export'}</DrcTranslate>
                    </DrcButton>
                </div>
                {!!this.state.data.length ? (
                    <PrimeReactTable
                        paginator={this.state.data.length > 0}
                        currentPage={this.state.pageNum}
                        onPage={this.onPage}
                        columns={this.state.columns}
                        value={this.state.pageData}
                        gridStyles={classes.gridStyles}
                        totalRecords={this.state.totalCount}
                        resultCount={this.state.totalCount}
                        pageSize={this.state.pageSize}
                        uniqueKeyField={this.uniqueKeyField}
                        tableSelectionMode="checkbox"
                        showReport={true}
                        currentPageReportTemplate={'Showing {first} to {last} of {totalRecords} entries'}
                        // frozenWidth={'100px'}
                    />
                ) : (
                    <NoData />
                )}
            </div>
        );
    }
}
const mapStateToProps = ({ masterReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized
});
const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withStyles(styles)(ImportSettlementReport)));
