import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import dashboardTranslationEnglish from './i18n/en/dashboard-en-translations.json';
import dashboardTranslationSpanish from './i18n/es/dashboard-es-translations.json';
import dashboardTranslationChinese from './i18n/cn/dashboard-cn-translations.json';
import settlementTranslationEnglish from './i18n/en/settlement-en-translations.json';
import settlementTranslationSpanish from './i18n/es/settlement-es-translations.json';
import settlementTranslationChinese from './i18n/cn/settlement-cn-translations.json';
import businessrulesTranslationSpanish from './i18n/es/businessrules-es-translations.json';
import businessrulesTranslationChinese from './i18n/cn/businessrules-cn-translations.json';
import businessrulesTranslationEnglish from './i18n/en/businessrules-en-translations.json';
import { languagesSupportedMap } from 'data/translations.js';

export enum NAMESPACE {
    MAIN = 'main',
    DASHBOARD = 'dashboard',
    SETTLEMENT = 'settlement',
    BUSINESSRULES = 'businessrules'
}

//---Using different namespaces
const resources = {
    'en-US': {
        [NAMESPACE.DASHBOARD]: dashboardTranslationEnglish,
        [NAMESPACE.SETTLEMENT]: settlementTranslationEnglish,
        [NAMESPACE.BUSINESSRULES]: businessrulesTranslationEnglish
    },
    ES: {
        [NAMESPACE.DASHBOARD]: dashboardTranslationSpanish,
        [NAMESPACE.SETTLEMENT]: settlementTranslationSpanish,
        [NAMESPACE.BUSINESSRULES]: businessrulesTranslationSpanish
    },
    'zh-CN': {
        [NAMESPACE.DASHBOARD]: dashboardTranslationChinese,
        [NAMESPACE.SETTLEMENT]: settlementTranslationChinese,
        [NAMESPACE.BUSINESSRULES]: businessrulesTranslationChinese
    }
};
const defaultLanguage = localStorage.getItem('GGS_UserLang');
i18next.use(initReactI18next).init({
    debug: true,
    resources,
    lng: defaultLanguage
});

export default i18next;
