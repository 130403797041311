import React, { Component } from 'react';
import { Divider } from '@mui/material';
import DrcWrapper from '../../../components/DrcWrapper';
import PrimeReactTable from '../../../components/PrimeReactTable';
import Stepper from '../../../components/Stepper';
import { withStyles } from '@mui/styles';
import { DrcButton, DrcDialog, DrcTranslate, DrcDatePicker, DrcTooltip } from 'drc/driscolls-react-components/index';
import GridStyles from '../../../styles/gridStyles';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast, setPageTitleAction } from '../../../actions/actions';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { displayErrorMessage, getAppliedFilters, makeColumns, userBusinessUnit } from '../../../utils/helper';
import ActionsFormatter from '../../../components/cellFormatters/ActionFormatter';
import { ReactComponent as Add } from '../../../Images/SVG/Add_Enabled.svg';
import Grid from '@mui/material/Grid';
import MasterDataUtilities from '../../../data/MasterDataUtilities';
import { Call } from '@driscollsinc/driscolls-display-rules';
import APIEndPoints from '../../../services/api';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { decimal, maxlen, alphaNumeric, lettersOnly, maxValue } from '../../../utils/validations';
import GGSFormInput from '../../../components/GGSFormInput';
import Form from 'react-validation/build/form';
import { vatRateDsColumns, convertDateToIso } from '../../../data/constants';
import FilterListSharp from '@mui/icons-material/FilterListSharp';
import Filter from '../../../components/Filter/Filter';

const pageTitle = 'VAT Rate Maintenance';
const styles = (theme) => ({
    input: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'left'
        }
    },
    gridStyles: GridStyles.styles(theme, '300px', '300px'),
    tableContainer: {
        margin: theme.spacing(0, 3)
    },
    selectedClass: {
        width: '45px !important',
        padding: '0 10px !important'
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'end'
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    pageActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    errorClass: {
        color: 'red',
        fontSize: '10px',
        marginTop: '-2px'
    }
});

class VatRateMaintenance extends Component {
    state = {
        data: [],
        pageNum: 0,
        selectedRow: [],
        pageSize: 50,
        totalCount: 0,
        columns: [],
        rowData: null,
        editingRow: {},
        showAddNew: false,
        newRecord: {},
        invalidFields: [],
        dateError: ''
    };
    validationFields = {
        VATPercent: {
            key: 'VATPercent',
            validations: [decimal, maxValue],
            customProps: {}
        },
        Country: {
            key: 'Country',
            validations: [maxlen, lettersOnly],
            customProps: { maxlen: 255 }
        },
        State: {
            key: 'State',
            validations: [maxlen, lettersOnly],
            customProps: { maxlen: 255 }
        },
        EffectiveEndDate: {
            key: 'EffectiveEndDate',
            validations: [alphaNumeric],
            customProps: {}
        },
        EffectiveStartDate: {
            key: 'EffectiveStartDate',
            validations: [alphaNumeric],
            customProps: {}
        }
    };
    excludingFields = ['Actions', 'VATPercentID'];
    readOnly = false;
    uniqueKeyField = 'VATPercentID';
    widthIsSufficient = window.innerWidth > 955;
    widthIsInSufficient = false;

    componentDidMount() {
        this.loadData();
        window.addEventListener('resize', this.updateDataTable);
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDataTable);
    }

    componentDidUpdate(_prevProps, prevState) {
        if (
            this.state.newRecord.EffectiveStartDate !== prevState.newRecord.EffectiveStartDate ||
            this.state.newRecord.EffectiveEndDate !== prevState.newRecord.EffectiveEndDate ||
            this.state.editingRow.EffectiveStartDate !== prevState.editingRow.EffectiveStartDate ||
            this.state.editingRow.EffectiveEndDate !== prevState.editingRow.EffectiveEndDate
        )
            this.checkDateValidation();
    }

    checkDateValidation() {
        if (
            (new Date(this.state.newRecord.EffectiveStartDate) > new Date(this.state.newRecord.EffectiveEndDate) ||
                new Date(this.state.editingRow.EffectiveStartDate) > new Date(this.state.editingRow.EffectiveEndDate)) &&
            (this.state.editingRow.EffectiveEndDate || this.state.newRecord.EffectiveEndDate)
        ) {
            if (!this.state.dateError)
                return this.setState({
                    dateError: <DrcTranslate>{'End Date should be greater than start date'}</DrcTranslate>,
                    invalidFields: ['EffectiveEndDate']
                });
        } else {
            if (this.state.dateError) return this.setState({ dateError: '', invalidFields: [] });
        }
    }

    editData = (_event, data, _index) => {
        this.setState({ editingRow: { ...data, [data[this.uniqueKeyField]]: true }, invalidFields: [] });
    };

    cancelEdit = (_event, _rowData) => {
        this.setState({ editingRow: {}, invalidFields: [] });
    };
    cannotEdit = (_row) => !(this.props.isUserAdmin && this.props.selectedRole.includes('-Admin'));

    deleteData = (_rowData) => !this.props.isUserAdmin;

    confirmEdit = async (_event, _rowData) => {
        try {
            this.props.showLoadingScreenAction('Updating data');
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            let data = await Call(
                {
                    name: 'VatRateMaintenance',
                    url: APIEndPoints.POST_VAT_RATE(),
                    method: 'PUT',
                    options: {},
                    data: {
                        VATPercentID: parseInt(this.state.editingRow['VATPercentID']),
                        VATPercent: '' + this.state.editingRow['VATPercent'],
                        Country: '' + this.state.editingRow['Country'],
                        State: '' + this.state.editingRow['State'],
                        EffectiveStartDate: convertDateToIso(this.state.editingRow['EffectiveStartDate']),
                        EffectiveEndDate: this.state.editingRow['EffectiveEndDate']
                            ? convertDateToIso(this.state.editingRow['EffectiveEndDate'])
                            : '',
                        ModifiedDateTime: new Date().toISOString(),
                        ModifiedBy: loggedInUser
                    }
                },
                token
            );
            if (data.errors.length && !data.raw) {
                this.setState({ editingRow: {} });
                return this.showError(data.errors[0]);
            } else if ((data.display.Message || '').includes('start date and end date overlap')) {
                this.setState({ editingRow: {} });
                return this.showError(data.display.Message, 'Start date and End date overlap');
            } else {
                this.props.hideLoadingScreenAction();
                this.setState({ editingRow: {} });
                this.props.showToast(<DrcTranslate>{'Updated Successfully'}</DrcTranslate>, true);
                this.loadData();
            }
        } catch (error) {
            this.setState({ editingRow: {} });
            this.showError(error);
        }
    };
    cannotDelete = (_row) => null;

    addNewRecord = async () => {
        try {
            this.props.showLoadingScreenAction('Adding data');
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            let data = await Call(
                {
                    name: 'VatRateMaintenance',
                    url: APIEndPoints.POST_VAT_RATE(),
                    method: 'POST',
                    options: {},
                    data: {
                        Country: this.state.newRecord['Country'],
                        BusinessUnit: userBusinessUnit(),
                        VATPercent: this.state.newRecord['VATPercent'],
                        State: this.state.newRecord['State'] || '',
                        EffectiveStartDate: convertDateToIso(this.state.newRecord['EffectiveStartDate']),
                        EffectiveEndDate: this.state.newRecord['EffectiveEndDate'] ? convertDateToIso(this.state.newRecord['EffectiveEndDate']) : '',
                        CreatedBy: loggedInUser,
                        ModifiedBy: loggedInUser,
                        ModifiedDateTime: new Date().toISOString(),
                        CreatedDateTime: new Date().toISOString()
                    }
                },
                token
            );
            if (data.errors.length && !data.raw) {
                this.setState({ showAddNew: false, newRecord: {} });
                return this.showError(data.errors[0]);
            } else if ((data.display.Message || '').includes('start date and end date overlap')) {
                this.setState({ showAddNew: false, newRecord: {} });
                return this.showError(data.display.Message, 'Start date and End date overlap');
            } else {
                this.props.hideLoadingScreenAction();
                this.setState({ showAddNew: false, newRecord: {} });
                this.props.showToast(<DrcTranslate>{'Added Successfully'}</DrcTranslate>, true);
                this.loadData();
            }
        } catch (error) {
            this.showError(error);
        }
    };

    getActionsCell = (row) => {
        return (
            <ActionsFormatter
                rowEdit={true}
                uniqueKeyField={this.uniqueKeyField}
                objState={this}
                records={this.state.data}
                initRowEdit={this.editData}
                confirmEdit={this.confirmEdit}
                cancelEdit={this.cancelEdit}
                cannotDelete={this.cannotDelete}
                cannotEdit={this.cannotEdit}
                deleteAttribute={this.deleteData}
                row={row}
                checkRowValidation={() => !!this.state.invalidFields.length}
                rowUnderEditUniqueKeyValue={row[this.uniqueKeyField]}
            />
        );
    };

    updateTableData = (response, hasFrozenColumns = true) => {
        let data = response?.display?.Data || [];
        let columns = data.length
            ? makeColumns(
                  Object.keys(data[0]),
                  this.excludingFields,
                  this.getActionsCell,
                  true,
                  ['EffectiveStartDate', 'VATPercent', 'EffectiveEndDate'],
                  [],
                  vatRateDsColumns
              )
            : [];
        this.setState({
            data,
            totalCount: response?.display?.TotalCount || 0,
            columns,
            unfreeze: !hasFrozenColumns
        });
    };
    updateDataTable = (_event, force = false) => {
        if (window.innerWidth > 955 && !this.widthIsSufficient) {
            this.widthIsSufficient = true;
            this.widthIsInSufficient = false;
            this.updateTableData(this.response || [], true);
        } else if (window.innerWidth <= 955 && !this.widthIsInSufficient) {
            this.widthIsInSufficient = true;
            this.widthIsSufficient = false;
            this.updateTableData(this.response || [], false);
        } else if (force) {
            this.updateTableData(this.response || [], true);
        }
    };

    loadData = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            let data = await Call(
                {
                    name: 'VatRateMaintenance',
                    url: APIEndPoints.GET_VAT_RATE(userBusinessUnit(), getAppliedFilters(this.props.filters)),
                    method: 'GET',
                    options: {}
                    //fakeData: VatRateMaintenanceJson
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }
            this.response = data;
            this.updateDataTable(null, true);
            this.props.hideLoadingScreenAction();
        } catch (error) {
            this.showError(error);
        }
    };

    getRowEditor = (key, col, others) => {
        switch (key) {
            case 'EffectiveStartDate':
                return this.dateCellEditor(key, false, false);
            case 'EffectiveEndDate':
                return this.dateCellEditor(key, true, true);
            case 'VATPercent':
                let field = this.validationFields[key];
                return this.inputTextEditor(key, col, others, field.customProps, field.validations);
        }
    };

    inputTextEditor = (key, _col, _others, customProps, validations = []) => {
        return (
            <Form>
                <GGSFormInput
                    className={this.props.classes.input}
                    onChange={(e) =>
                        this.setState(() => ({
                            editingRow: { ...this.state.editingRow, [key]: e.target.value }
                        }))
                    }
                    value={this.state.editingRow[key]}
                    validations={validations}
                    {...customProps}
                    maxvalue={100}
                    validationDetected={(validate) => this.handleValidationDetected(validate, key)}
                />
            </Form>
        );
    };

    dateCellEditor = (key, clearable, errorMsg) => {
        return (
            <>
                <DrcDatePicker
                    clearable={clearable}
                    emptyLabel=" "
                    InputProps={{ readOnly: true }}
                    style={{ marginTop: `${errorMsg && this.state.dateError ? '19px' : '0'}` }}
                    selectedDate={this.state.editingRow[key]}
                    onChange={(e) => this.setState((prevState) => ({ editingRow: { ...prevState.editingRow, [key]: e } }))}
                />
                {errorMsg && this.state.dateError && (
                    <DrcTooltip tipText={this.state.dateError}>
                        <p style={{ color: 'red', fontSize: '10px', marginTop: '-5px', textOverflow: 'ellipsis' }}>{this.state.dateError || ''}</p>
                    </DrcTooltip>
                )}
            </>
        );
    };

    onPage = (event) => {
        this.setState({ pageNum: event.first });
        this.loadData();
    };

    /**
     * Method that is triggered when validation is detected. This will enable/disable the save button for the add-dialog
     * @function handleValidationDetected
     * @param {*} validate
     */
    handleValidationDetected = (validate, fieldName) => {
        if (validate === undefined) {
            return;
        }
        if (validate) {
            if (!this.state.invalidFields.includes(fieldName)) {
                let newFields = [...this.state.invalidFields];
                newFields.push(fieldName);
                this.setState({ invalidFields: newFields });
            }
        } else {
            if (this.state.invalidFields.includes(fieldName))
                this.setState((prevState) => ({ ...prevState, invalidFields: prevState.invalidFields.filter((field) => field !== fieldName) }));
        }
    };

    showError = (err, msg) => {
        this.props.hideLoadingScreenAction();
        this.props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    applyFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadData());
    };

    toggleShowFilter = () => this.setState({ showFilter: !this.state.showFilter });

    resetFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadData());
    };

    render() {
        const { classes } = this.props;
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        return (
            <DrcWrapper>
                <Stepper page={pageTitle} isDataSetup />
                <Divider />
                <div className={classes.filterContainer}>
                    <Filter
                        showFilter={this.state.showFilter}
                        applyFilter={this.applyFilter}
                        resetFilter={this.resetFilter}
                        filterNames={['Country', 'State']}
                    />
                </div>
                <div className={classes.pageActions}>
                    <div>
                        <DrcButton onClick={this.toggleShowFilter}>
                            <FilterListSharp className={classes.icon} />
                            <DrcTranslate>{'Filter'}</DrcTranslate>
                        </DrcButton>
                    </div>
                    <DrcButton
                        onClick={(_event) => {
                            this.setState({ showAddNew: true });
                        }}
                        noStyle
                        title="Add"
                        disabled={!(this.props.isUserAdmin && this.props.selectedRole.includes('-Admin'))}
                    >
                        <Add style={{ width: '50px', height: '20px', marginRight: '-10px' }} />
                        <DrcTranslate>{'Add'}</DrcTranslate>
                    </DrcButton>
                </div>
                <div className={classes.tableContainer}>
                    <PrimeReactTable
                        value={this.state.data}
                        columns={this.state.columns}
                        editMode="row"
                        frozenWidth={'80px'}
                        rowSelect={false}
                        selectionMode="multiple"
                        tableSelectionMode="checkbox"
                        selected={this.state.selectedRow}
                        selectedFrozen={true}
                        selectedClass={classes.selectedClass}
                        selectionChange={(e) => {
                            this.setState({ selectedRow: e.value });
                        }}
                        uniqueKeyField={this.uniqueKeyField}
                        editingRows={this.state.editingRow}
                        paginator={this.state.data.length > 0}
                        currentPage={this.state.pageNum}
                        pageSize={this.state.pageSize}
                        onPage={this.onPage}
                        totalRecords={this.state.totalCount}
                        editor={(key, col, others) => {
                            return this.getRowEditor(
                                key,
                                col,
                                others,
                                this.validationFields[key] ? this.validationFields[key].validations : [],
                                this.validationFields[key] ? this.validationFields[key].customProps : {}
                            );
                        }}
                    />
                </div>
                <DrcDialog
                    title={'Add New Record'}
                    open={this.state.showAddNew}
                    buttons={
                        <>
                            <DrcButton
                                onClick={() => {
                                    this.setState({ showAddNew: false, newRecord: {} });
                                }}
                            >
                                <DrcTranslate>{'Cancel'}</DrcTranslate>
                            </DrcButton>
                            <DrcButton
                                isPrimary
                                onClick={this.addNewRecord}
                                disabled={
                                    !!this.state.invalidFields.length ||
                                    !(this.state.newRecord.Country && this.state.newRecord.VATPercent && this.state.newRecord.EffectiveStartDate)
                                }
                            >
                                <DrcTranslate>{'Submit'}</DrcTranslate>
                            </DrcButton>
                        </>
                    }
                >
                    <Form>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <GGSFormInput
                                type="text"
                                label={'Country'}
                                required
                                validations={this.validationFields['Country'] ? this.validationFields['Country'].validations : []}
                                {...(this.validationFields['Country'] ? this.validationFields['Country'].customProps : {})}
                                className={this.props.classes.input}
                                onChange={(e) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, Country: e.target.value } }))}
                                value={this.state.newRecord['Country']}
                                validationDetected={(validate) => this.handleValidationDetected(validate, 'Country')}
                            />
                            <GGSFormInput
                                type="text"
                                label={'State'}
                                validations={this.validationFields['State'] ? this.validationFields['State'].validations : []}
                                {...(this.validationFields['State'] ? this.validationFields['State'].customProps : {})}
                                className={this.props.classes.input}
                                onChange={(e) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, State: e.target.value } }))}
                                value={this.state.newRecord['State']}
                                validationDetected={(validate) => this.handleValidationDetected(validate, 'State')}
                            />
                            <GGSFormInput
                                label={'VAT Percent'}
                                required
                                validations={this.validationFields['VATPercent'] ? this.validationFields['VATPercent'].validations : []}
                                {...(this.validationFields['VATPercent'] ? this.validationFields['VATPercent'].customProps : {})}
                                className={this.props.classes.input}
                                onChange={(e) =>
                                    this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, VATPercent: e.target.value } }))
                                }
                                value={this.state.newRecord['VATPercent']}
                                maxvalue={100}
                                validationDetected={(validate) => this.handleValidationDetected(validate, 'VATPercent')}
                            />
                            <DrcDatePicker
                                label={'Effective Start Date'}
                                selectedDate={this.state.newRecord['EffectiveStartDate'] || null}
                                required
                                InputProps={{ readOnly: true }}
                                onChange={(e) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, EffectiveStartDate: e } }))}
                            />
                            <DrcDatePicker
                                label={'Effective End Date'}
                                selectedDate={this.state.newRecord['EffectiveEndDate'] || null}
                                onChange={(e) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, EffectiveEndDate: e } }))}
                                InputProps={{ readOnly: true }}
                            />
                            <p className={classes.errorClass}>{this.state.dateError || ''}</p>
                        </Grid>
                    </Form>
                </DrcDialog>
            </DrcWrapper>
        );
    }
}
const mapStateToProps = ({ masterReducer, filterReducer, rootReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    pageTitle: rootReducer.pageTitle,
    filters: filterReducer.filters,
    isUserAdmin: rootReducer.userIsAdmin,
    selectedRole: rootReducer.selectedRole
});
const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withStyles(styles)(VatRateMaintenance)));
