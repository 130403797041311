import React from 'react';
import { DrcTranslate } from 'drc/driscolls-react-components/index';
import LabelFormatter from '../components/cellFormatters/LabelFormatter';
import Highlighter from '../components/cellFormatters/Highlighter';
import { currencyCols, dateFields, dateTimeFields, decimalFields, percentFields } from '../../src/data/constants';
import { DuDateUtilities, DuExcelUtilities } from '@driscollsinc/driscolls-react-utilities';
import NumberFormatter from '../components/cellFormatters/NumberFormatter';

const labelTemplate = (name) =>
    function labelComp(row) {
        return <LabelFormatter row={row} field={name} />;
    };

const dataHighlightTemplate = (name) =>
    function diffComp(row) {
        let formatter = <LabelFormatter row={row} field={name} />;
        if (row[name] == 1) {
            formatter = <Highlighter row={row} field={name} />;
        }
        return formatter;
    };

const dateFieldTemplate = (name) =>
    function dateComp(row) {
        return <span style={{ fontSize: 11 }}>{DuDateUtilities.FormatDateFromIso(row[name])}</span>;
    };

const dateTimeFieldTemplate = (name) =>
    function dateTimeComp(row) {
        return <span style={{ fontSize: 11 }}>{DuDateUtilities.FormatDateTimeFromIso(row[name])}</span>;
    };

const numericFormatter = (name, headerLength, isDecimal = false, currencySymbol) => {
    let isCurrency = currencyCols.includes(name);
    let isPercent = percentFields.includes(name) || false;
    return function numComp(row) {
        return (
            <NumberFormatter
                row={row}
                field={name}
                isDecimal={isDecimal}
                headerLength={headerLength}
                isPercent={isPercent}
                currencySymbol={isCurrency ? currencySymbol : ''}
            />
        );
    };
};
const getColumnTemplate = (key, headerLength, highlightText, currencySymbol) => {
    if (highlightText && 'TrayToKilogram' === key) {
        return dataHighlightTemplate(key);
    } else if (dateFields.includes(key)) {
        return dateFieldTemplate(key);
    } else if (dateTimeFields.includes(key)) {
        return dateTimeFieldTemplate(key);
    } else if (decimalFields.includes(key)) {
        return numericFormatter(key, headerLength, true, currencySymbol);
    } else return labelTemplate(key);
};

export const makeColumns = (
    data = [],
    excludingFields = [],
    actionsCell,
    hasActions = false,
    editableCols = [],
    frozenColumns = [],
    mapping = null,
    highlightText = false,
    currencySymbol = ''
) => {
    try {
        let maxWidthCols = ['ContactEmailId'];
        let excludedFields = ['getPreviousData', ...(excludingFields || [])];
        let columns = hasActions
            ? [{ key: 'Actions', name: <DrcTranslate>{'Actions'}</DrcTranslate>, columnTemplate: actionsCell, frozen: true, width: '80px' }]
            : [];
        let responseColumns = mapping ? Object.keys(mapping) : typeof data[0] === 'object' ? Object.keys(data[0]) : data;
        let columnNames = responseColumns;
        columns = [
            ...columns,
            ...columnNames
                .map((key) => {
                    if (Array.isArray(excludedFields) && !excludedFields.includes(key)) {
                        let editable = editableCols.includes(key);
                        let required = false;
                        let selectOptions = [];
                        let headerLength = (
                            (mapping ? <DrcTranslate>{mapping[key] || key}</DrcTranslate> : <DrcTranslate>{key}</DrcTranslate>).props.children || ''
                        ).length;
                        return {
                            // width: '100px',
                            key,
                            name: mapping ? <DrcTranslate>{mapping[key] || key}</DrcTranslate> : <DrcTranslate>{key}</DrcTranslate>,
                            editable: editable,
                            editorType: 'text',
                            required: required,
                            editorOptions: selectOptions,
                            columnTemplate: getColumnTemplate(key, headerLength, highlightText, currencySymbol),
                            ...{ ...(maxWidthCols.includes(key) ? { width: '200px' } : {}) },
                            ...{ ...(frozenColumns.includes(key) ? { frozen: true, width: '90px' } : {}) }
                        };
                    }
                    return null;
                })
                .filter((e) => e)
        ];
        return columns;
    } catch (err) {
        console.log(err);
    }
};

export const getKeyDate = (date = new Date()) => {
    return date.toISOString().slice(0, 10).replace(/-/g, ''); //i.e YYYYMMDD format
};

export const getYearStartDate = () => {
    return `${new Date().getFullYear()}-01-01`;
};
export const getLastYearStartDate = () => {
    return `${new Date().getFullYear() - 1}-01-01`;
};
export const getCurrentDate = () => {
    return new Date().toISOString().split('T')[0];
};

export const userBusinessUnit = () => {
    let userRole = JSON.parse(localStorage.getItem('GGS_UserRole') || '{}');
    let userGroup = userRole.group || 'DOTA'; //TODO we need a UI selector for selecting the Business unit
    if (userGroup.includes('DOC')) {
        return 'DOC';
    } else if (userGroup.includes('DOTA')) {
        return 'DOTA';
    } else if (userGroup.includes('DEMEA')) {
        return 'DEMEA';
    }
    return 'DOC'; //For user groups like Admin
};

export const makeOptions = (arr = [], label, value) => arr.map((itm) => ({ label: itm[label], value: itm[value], ...itm }));

export const getDownloadColumns = (column, excludedColumns = []) => {
    let colsToExclude = [...excludedColumns, 'getPreviousData'];
    let cols = Object.values(column)
        .filter((col) => !colsToExclude.includes(col))
        .map((key) => ({ name: key, key }));
    return cols;
};
export const percentageWithoutSymbol = (val = 0) => {
    return (val * 100).toFixed(2);
};
export const downloadFile = (fileName, data, columnMap, excludedColumns = [], currencySymbol = '') => {
    let colsToExclude = [...excludedColumns, 'getPreviousData'];
    let columnNames = columnMap
        ? getDownloadColumns(columnMap, excludedColumns)
        : Object.keys(data[0])
              .filter((col) => !colsToExclude.includes(col))
              .map((key) => ({ name: key, key }));
    let formattedData = data.map((row) => {
        const keyValues = Object.keys(row).map((key) => {
            let value = row[key] == -0 ? 0 : key === 'FinalAverageSettlementPrice' ? percentageWithoutSymbol(row[key]) : row[key];
            return {
                [columnMap[key]]: percentFields.includes(key)
                    ? `${value === null ? '' : value + '%'}`
                    : currencyCols.includes(key)
                    ? `${value === null ? '' : currencySymbol + value}`
                    : value
            };
        });
        return Object.assign({}, ...keyValues);
    });
    DuExcelUtilities.Write(fileName, columnNames, formattedData);
};

export const getAppliedFilters = (filters = []) => {
    return filters.reduce((query, filter) => {
        if (filter.isSelected) {
            if (filter.hasOptions && !filter.isCheckbox) {
                if (filter.isMulti) {
                    query += `&${filter.query}=` + encodeURIComponent(filter.selected.map((itm) => itm.value).join(','));
                } else {
                    query += `&${filter.query}=` + encodeURIComponent(filter.selected[0].value);
                }
            } else if (filter.hasOptions && filter.isCheckbox) {
                query += `&${filter.query}=` + encodeURIComponent(filter.selected.join(','));
            } else {
                query += `&${filter.query}=` + encodeURIComponent(filter.value);
            }
        }
        return query;
    }, '');
};

export const displayErrorMessage = (message = '') => {
    if ((message || '').toLowerCase().includes('duplicate')) {
        return 'Duplicate Entry';
    } else if ((message || '').toLowerCase().includes('overlap')) {
        return 'Start date and End date overlap';
    } else {
        return 'An Error Occurred';
    }
};

export const getLanguage = (language) => {
    let lang;
    if (language !== 'ES') {
        return (lang = language.split('-')[1].toLowerCase());
    } else {
        return (lang = language.toLowerCase());
    }
};

export const getNameFromEmail = (emailId) => {
    if (emailId) {
        return emailId.split('@')[0];
    } else {
        return null;
    }
};
export const allGroups = [...window['config'].OKTA_GGS_ADMIN_GROUPS, ...window['config'].OKTA_USER_GROUPS, ...window['config'].OKTA_ADMIN_GROUPS];
export const adminGroups = ([...window['config'].OKTA_GGS_ADMIN_GROUPS] || []).filter((item) => {
    return item.toUpperCase().indexOf(userBusinessUnit().toUpperCase()) > -1;
});

export const isValidRuleID = (ruleId) => {
    const pattern = /^[A-Za-z]{2,4}\d+$/;
    return pattern.test(ruleId);
};

export const updateBusinessTabCount = (tabs, selectedRuleId) => {
    const found = tabs.find((item) => item.value === parseInt(selectedRuleId));
    const countSpan = document.getElementById('bu_tab_' + found.value + '_count');
    countSpan.innerText = Math.max(0, parseInt(countSpan.innerText) - 1) + '';
};
