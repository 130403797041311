import { createTheme } from '@mui/material/styles';
import { DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';

/**
 * @description createSimpleTheme will create a custom theme based on the primary colors given as input parameters. It will check the window theme and create accordingly. The first input color will guide the light theme and the second input will guide the dark theme.
 * @method createSimpleTheme
 * @param {String} lightPrimary Hex code or HSL value of any color
 * @param {String} darkPrimary Hex code or HSL value of any color
 * @returns {*} theme object
 */
const createSimpleTheme = (lightPrimary, darkPrimary) => {
    return createTheme(DuThemeUtilities.CreateSimpleThemeConfig(lightPrimary, darkPrimary));
};

const DrcThemeUtilities = {
    CreateSimpleTheme: createSimpleTheme
};

export default DrcThemeUtilities;
