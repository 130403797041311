import React from 'react';
import { withStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import DrcImage from './DrcImage';
import DrcHeaderMenu from './DrcHeaderMenu';
import DrcMediaQueries from '../Utilities/DrcMediaQueries';
import LogoPng from '../Images/Logo.png';
import LogoWebP from '../Images/Logo.webp';
import FilterPng from '../Images/filter.png';
import FilterWebP from '../Images/filter.webp';

/**
 *
 * @ignore
 * @memberof DrcHeader
 */
const styles = (theme) => ({
    tooltip: {
        fontSize: '1rem'
    },
    appBar: {
        background: 'linear-gradient(180deg, ' + theme.light.header.color1 + ' 0%, ' + theme.light.header.color2 + ' 69%, ' + theme.light.header.color3 + ' 100%)',
        '& .MuiSvgIcon-root': {
            color: theme.light.accent.primary
        },
        '& .MuiButtonBase-root': {
            border: '1px solid ' + theme.light.accent.primary,
            padding: '4px'
        },
        [theme.darkTheme]: {
            background: 'linear-gradient(180deg, ' + theme.dark.header.color1 + ' 0%, ' + theme.dark.header.color2 + ' 69%, ' + theme.dark.header.color3 + ' 100%)',
            boxShadow: '0px 4px 10px 0px #060606'
        }
    },
    FilterIcon: {
        margin: '0 4px !important',
        verticalAlign: 'middle !important',
        height: '26px',
        width: '26px',
        cursor: 'pointer'
    },
    filters: {
        margin: '0 1rem ',
        zIndex: '+10'
    },
    toolbar: {
        maxWidth: '1280px',
        margin: 'auto',
        width: '100%',
        minHeight: theme.spacing(6),
        height: theme.spacing(6),
        maxHeight: theme.spacing(6),
        padding: theme.spacing(0, 3),
        ['@media ' + DrcMediaQueries.mobileL]: {
            padding: '0 !important'
        }
    },
    logo: {
        filter: 'drop-shadow(0px 5px 10px ' + theme.light.variant.secondary + ')',
        height: theme.spacing(8),
        margin: '0 16px 0 -8px',
        ['@media ' + DrcMediaQueries.mobileL]: {
            display: 'none'
        },
        [theme.darkTheme]: {
            filter: 'drop-shadow(0px 5px 10px ' + theme.dark.accent.primary + ')'
        }
    },
    titleContainer: {
        flexGrow: 1
    },
    title: {
        textShadow: theme.light.header.shadow + ' 4px 5px 6px',
        color: theme.light.accent.text.primary,
        ['@media ' + DrcMediaQueries.mobileL]: {
            marginLeft: '16px !important',
            fontSize: '1.4em'
        },
        [theme.darkTheme]: {
            textShadow: theme.dark.header.shadow + ' 4px 5px 6px',
            color: theme.dark.accent.text.primary
        }
    }
});

/**
 * @description DrcHeader is a styled header with menu and searchfilter
 * @class DrcHeader
 * @property {string} title The title that is shown in the Header
 * @property {array} allLinks The links used in the menu
 * @property {string} ancor The ancor for the menu
 * @property {string} className className for the header
 * @property {boolean} fullWidth when set to True the component will be shown in 100% width
 * @property {children} children will be shown as children in the header, under the heading before the filter. For example buttons
 * @property {boolean} noLogo When set to True no logo will be shown
 * @property {*} logo The logo to display instead of the default Logo
 * @property {bolean} showFilterControl Displays the filterControl
 * @property {*} showSavedFiltersPopup method to use in the filterControl
 * @example <DrcHeader noLogo={false} title={'example'} allLinks={links}, className='alternateClass' showFilterControl={false} />
 * @donotprint true
 * @category Layout
 * @tags layout header page head top
 * @extends {React.Component}
 */

class DrcHeader extends React.Component {
    /**
     * @return {*}
     * @memberof DrcHeader
     */
    render() {
        const { children, title, allLinks, classes, noLogo, fullWidth, logo, className, showFilterControl, anchor } = this.props;

        return (
            <div>
                <AppBar className={`${classes.appBar} ${className} appBar`}>
                    <Toolbar className={`${classes.toolbar} toolbar`} style={fullWidth ? { maxWidth: '100%' } : {}}>
                        {noLogo ? null : logo ? logo : <DrcImage src={LogoPng} webp={LogoWebP} className={`${classes.logo} logo`} alt="Driscoll's Logo" />}
                        <div className={`${classes.titleContainer} titleContainer`}>
                            <h1 className={`${classes.title} title`}>{title}</h1>
                            {children}
                        </div>
                        {showFilterControl && (
                            <div
                                className={classes.filters}
                                onClick={() => {
                                    this.props.showSavedFiltersPopup();
                                }}
                            >
                                <DrcImage src={FilterPng} title={'Saved Searches'} className={classes.FilterIcon} webp={FilterWebP} alt="Saved Searches" />
                            </div>
                        )}
                        <DrcHeaderMenu allLinks={allLinks} anchor={anchor} />
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

export default withStyles(styles)(DrcHeader);
