import React from 'react';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { Navigate } from 'react-router-dom';
import { setPageTitleAction, setUserRoles, setUserIsAdmin } from '../actions/actions';
import { lookupFilter } from '../actions/LookupAction';
import { vendorCodeOptions } from '../actions/VendorCodeActions';
import { DrcMain, DrcPanel, DrcProgress, DrcButton, DrcImage, DrcLoading } from 'drc/driscolls-react-components/index';
import FailPng from '../Images/fail.png';
import FailWebP from '../Images/fail.webp';
import { InitializeData } from '../services/InitializeData';
import APIEndPoints from '../services/api';
import { getCurrentDate, getKeyDate, getLastYearStartDate, userBusinessUnit } from '../utils/helper';
import {
    setMasterDataInitialized,
    setCurrentPoolWeek,
    setCurrentYearPoolWeek,
    setLookUpValues,
    setUserDetails,
    setUserLang,
    setUserPreference
} from '../actions/MasterActions';
import { LOOKUP_FIELDS } from '../data/constants';
import getUserRole, { isRoleAdmin } from '../data/roleUtilities';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { withLocalize } from 'react-localize-redux';

const pageTitle = 'Initializing';

class InitializeApplication extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            message: '',
            successCount: 0,
            errorCount: 0,
            totalCount: 0
        };

        this.statusUpdate = this.statusUpdate.bind(this);
        this.loadMasterData = this.loadMasterData.bind(this);
    }
    getMasterDataCalls({ userOktaId, loggedInUser, userRoles }) {
        let sessionLang = localStorage.getItem('GGS_UserLang');
        let sessionRole = JSON.parse(localStorage.getItem('GGS_UserRole') || '""');
        let userLang = sessionLang || 'en-US';
        const tokenGroups = DuAuthenticationUtilities.GetGroups(this.props.authState?.accessToken) || [];
        return [
            {
                name: 'UserPreference',
                url: APIEndPoints.GET_USER_PREFERENCES(userOktaId, loggedInUser),
                method: 'GET',
                options: {},
                supportedBUs: ['doc', 'dota'],
                returnFunction: (data) => {
                    let lang = '',
                        userRole;
                    this.props.setUserPreference(data?.display?.Data || []);
                    if (!sessionLang) {
                        lang = ((data?.display?.Data || []).find((itm) => itm.Name === 'Language') || {}).Value || 'en-US';
                        localStorage.setItem('GGS_UserLang', lang);
                    }
                    if (!sessionRole) {
                        let oktaGroup = ((data?.display?.Data || []).find((itm) => itm.Name === 'UserRole') || {}).Value || tokenGroups[0];
                        if (oktaGroup) {
                            userRole = userRoles.find((itm) => itm.group === oktaGroup);
                            localStorage.setItem('GGS_UserRole', JSON.stringify(userRole || ''));
                        }
                    }
                    this.updateUserPreference(lang, userRole);
                }
            },
            {
                name: 'poolweek',
                url: APIEndPoints.GET_CURRENT_POOL_WEEK(getKeyDate(new Date()), userBusinessUnit()),
                method: 'GET',
                options: {},
                supportedBUs: ['doc', 'dota'],
                returnFunction: (data) => {
                    this.props.setCurrentPoolWeek(data.display || []);
                }
            },
            {
                name: 'poolweek',
                url: APIEndPoints.GET_POOL_WEEK_BY_DATE(getLastYearStartDate(), getCurrentDate(), userBusinessUnit()),
                method: 'GET',
                options: {},
                supportedBUs: ['doc', 'dota'],
                returnFunction: (data) => {
                    this.props.setCurrentYearPoolWeek(data.display || []);
                }
            },
            {
                name: LOOKUP_FIELDS.ApplicationLookUp,
                url: APIEndPoints.GET_LOOK_UP_VALUES(userBusinessUnit(), 'Application'),
                method: 'GET',
                options: {},
                supportedBUs: ['doc'],
                returnFunction: (data) => {
                    this.props.setLookUpValues(LOOKUP_FIELDS.ApplicationLookUp, (data.display || {}).Data || []);
                }
            },
            {
                name: LOOKUP_FIELDS.CommodityLookup,
                url: APIEndPoints.GET_LOOK_UP_VALUES(userBusinessUnit(), 'Commodity'),
                method: 'GET',
                options: {},
                supportedBUs: ['doc'],
                returnFunction: (data) => {
                    this.props.setLookUpValues(LOOKUP_FIELDS.CommodityLookup, (data.display || {}).Data || []);
                }
            },
            {
                name: LOOKUP_FIELDS.CommodityOffsetWeekLookUp,
                url: APIEndPoints.GET_LOOK_UP_VALUES(userBusinessUnit(), 'CommodityOffsetWeek'),
                method: 'GET',
                options: {},
                supportedBUs: ['doc'],
                returnFunction: (data) => {
                    this.props.setLookUpValues(LOOKUP_FIELDS.CommodityOffsetWeekLookUp, (data.display || {}).Data || []);
                }
            },
            {
                name: LOOKUP_FIELDS.CoolerNameLookUp,
                url: APIEndPoints.GET_LOOK_UP_VALUES(userBusinessUnit(), 'CoolerName'),
                method: 'GET',
                options: {},
                supportedBUs: ['doc'],
                returnFunction: (data) => {
                    this.props.setLookUpValues(LOOKUP_FIELDS.CoolerNameLookUp, (data.display || {}).Data || []);
                }
            },
            {
                name: LOOKUP_FIELDS.CustomerCodeCategoryLookUp,
                url: APIEndPoints.GET_LOOK_UP_VALUES(userBusinessUnit(), 'CustomerCodeCategory'),
                method: 'GET',
                options: {},
                supportedBUs: ['doc'],
                returnFunction: (data) => {
                    this.props.setLookUpValues(LOOKUP_FIELDS.CustomerCodeCategoryLookUp, (data.display || {}).Data || []);
                }
            },
            {
                name: LOOKUP_FIELDS.EstimateGroupLookUp,
                url: APIEndPoints.GET_LOOK_UP_VALUES(userBusinessUnit(), 'EstimateGroup'),
                method: 'GET',
                options: {},
                supportedBUs: ['doc'],
                returnFunction: (data) => {
                    this.props.setLookUpValues(LOOKUP_FIELDS.EstimateGroupLookUp, (data.display || {}).Data || []);
                }
            },
            {
                name: LOOKUP_FIELDS.FamilyEntityLookUp,
                url: APIEndPoints.GET_LOOK_UP_VALUES(userBusinessUnit(), 'FamilyEntity'),
                method: 'GET',
                options: {},
                supportedBUs: ['doc'],
                returnFunction: (data) => {
                    this.props.setLookUpValues(LOOKUP_FIELDS.FamilyEntityLookUp, (data.display || {}).Data || []);
                }
            },
            {
                name: LOOKUP_FIELDS.GrowerCategoryLookUp,
                url: APIEndPoints.GET_LOOK_UP_VALUES(userBusinessUnit(), 'GrowerCategory'),
                method: 'GET',
                options: {},
                supportedBUs: ['doc'],
                returnFunction: (data) => {
                    this.props.setLookUpValues(LOOKUP_FIELDS.GrowerCategoryLookUp, (data.display || {}).Data || []);
                }
            },
            {
                name: LOOKUP_FIELDS.GrowerFixedCategoryLookUp,
                url: APIEndPoints.GET_LOOK_UP_VALUES(userBusinessUnit(), 'GrowerFixedCategory'),
                method: 'GET',
                options: {},
                supportedBUs: ['doc'],
                returnFunction: (data) => {
                    this.props.setLookUpValues(LOOKUP_FIELDS.GrowerFixedCategoryLookUp, (data.display || {}).Data || []);
                }
            },
            {
                name: LOOKUP_FIELDS.LocationLookUp,
                url: APIEndPoints.GET_LOOK_UP_VALUES(userBusinessUnit(), 'Location'),
                method: 'GET',
                options: {},
                supportedBUs: ['doc'],
                returnFunction: (data) => {
                    this.props.setLookUpValues(LOOKUP_FIELDS.LocationLookUp, (data.display || {}).Data || []);
                }
            },
            {
                name: LOOKUP_FIELDS.AdjustmentCategory,
                url: APIEndPoints.GET_LOOK_UP_VALUES(userBusinessUnit(), 'AdjustmentCategory'),
                method: 'GET',
                options: {},
                supportedBUs: ['doc'],
                returnFunction: (data) => {
                    this.props.setLookUpValues(LOOKUP_FIELDS.AdjustmentCategory, (data.display || {}).Data || []);
                }
            },
            {
                name: LOOKUP_FIELDS.UOMLookUp,
                url: APIEndPoints.GET_LOOK_UP_VALUES(userBusinessUnit(), 'UOM'),
                method: 'GET',
                options: {},
                supportedBUs: ['doc'],
                returnFunction: (data) => {
                    this.props.setLookUpValues(LOOKUP_FIELDS.UOMLookUp, (data.display || {}).Data || []);
                }
            },
            {
                name: LOOKUP_FIELDS.GrowerModule,
                url: APIEndPoints.GET_LOOK_UP_VALUES(userBusinessUnit(), 'GrowerModule'),
                method: 'GET',
                options: {},
                supportedBUs: ['doc'],
                returnFunction: (data) => {
                    this.props.setLookUpValues(LOOKUP_FIELDS.GrowerModule, (data.display || {}).Data || []);
                }
            },
            {
                name: LOOKUP_FIELDS.CommodityLookup,
                url: APIEndPoints.GET_LOOK_UP_VALUES(userBusinessUnit(), ''),
                method: 'GET',
                options: {},
                supportedBUs: ['doc'],
                returnFunction: (data) => {
                    let options = data.display.Data;
                    options = options.filter((value, index, self) => index === self.findIndex((t) => t.LookupType === value.LookupType));
                    this.props.lookupFilter(options);
                }
            },
            {
                name: 'GrowerList',
                url: APIEndPoints.GET_GROWER_LIST(null, null, userBusinessUnit(), this.props.userLang),
                method: 'GET',
                options: {},
                supportedBUs: ['doc'],
                returnFunction: (data) => {
                    let options = (data.display.Data || []).map((itm) => ({
                        ...itm,
                        label: `${itm.VendorCode} - ${itm.GrowerName}`,
                        value: itm.VendorCode
                    }));
                    this.props.vendorCodeOptions(options);
                }
            },
            {
                name: 'UserOktaDetails',
                url: APIEndPoints.POST_USER_DETAIL(),
                method: 'POST',
                options: {},
                data: {
                    userid: [userOktaId]
                },
                supportedBUs: ['doc', 'dota'],
                returnFunction: (data) => {
                    let user = (data.display || [])[0] || {};
                    this.props.setUserDetails(userLang, user);
                }
            }
        ];
    }
    statusUpdate(message, successCount, errorCount, totalCount) {
        this.setState({ message, successCount, errorCount, totalCount });
    }
    async updateUserPreference(lang, userRole) {
        var token = await this.props.oktaAuth.getAccessToken();
        let storedRole = userRole || JSON.parse(localStorage.getItem('GGS_UserRole') || '{}');
        var userIsAdmin = DuAuthenticationUtilities.IsInGroup(token, storedRole.group ? [storedRole.group] : window.config.OKTA_GGS_ADMIN_GROUPS);
        let isStoredRoleAdmin = isRoleAdmin(storedRole);
        const userLang = lang || localStorage.getItem('GGS_UserLang') || 'en-US';
        this.props.setUserIsAdmin({ isAdmin: storedRole.group ? isStoredRoleAdmin : userIsAdmin, group: storedRole.group || '' });
        this.props.setActiveLanguage(userLang);
        this.props.setUserLang(userLang);
    }
    async loadMasterData() {
        var token = await this.props.oktaAuth.getAccessToken();
        let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
        const userRoles = getUserRole(token);
        this.props.setUserRoles(userRoles);
        const userOktaId = DuAuthenticationUtilities.GetOktaId(token);
        const decodedToken = DuAuthenticationUtilities.DecodeToken(token);
        await InitializeData.LoadApis(
            this.props.authState,
            this.getMasterDataCalls({ userOktaId, loggedInUser, userRoles }),
            this.statusUpdate,
            (message) => {
                this.setState({ message });
                this.props.setMasterDataInitialized(true);
            }
        );
    }

    componentDidMount() {
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }

        this.loadMasterData();
    }

    render() {
        const { redirectUrl, isMasterDataInitialized } = this.props;
        const isDocUser = userBusinessUnit() === 'DOC';
        var percentComplete = Math.ceil(((this.state.successCount + this.state.errorCount) / this.state.totalCount) * 100);

        return isMasterDataInitialized ? (
            isDocUser ? (
                <Navigate to={redirectUrl} />
            ) : (
                <Navigate to="/Dashboard/" />
            )
        ) : (
            <DrcMain transparent>
                <DrcPanel maxWidth="500px">
                    {this.state.successCount + this.state.errorCount === this.state.totalCount && this.state.errorCount > 0 ? (
                        <DrcImage
                            src={FailPng}
                            webp={FailWebP}
                            style={{ position: 'absolute', height: 100, marginTop: -10, left: 'calc(50vw - 87px)' }}
                            alt="Thor and Callie are sorry they couldn't get the data you needed"
                        />
                    ) : null}
                    <DrcLoading
                        text={<h1>{this.state.message}</h1>}
                        hidden={this.state.successCount + this.state.errorCount == this.state.totalCount}
                    />
                    <DrcProgress value={percentComplete} />
                    {this.state.successCount + this.state.errorCount >= this.state.totalCount && this.state.errorCount > 0 ? (
                        <React.Fragment>
                            <hr />
                            <p>
                                We were unable to load {this.state.errorCount} out of {this.state.totalCount} important components for the site to
                                load properly. You may want to refresh the website or you can proceed with using the application knowing that some
                                aspects may not be working.
                            </p>
                            <hr />
                            <div className="row">
                                <div className="col-xs-6">
                                    <DrcButton
                                        poly
                                        line
                                        isPrimary
                                        fullWidth
                                        style={{ margin: '8px 0' }}
                                        onClick={() => {
                                            window.location.reload();
                                        }}
                                    >
                                        Try, Try Again
                                    </DrcButton>
                                </div>
                                <div className="col-xs-6">
                                    <DrcButton
                                        poly
                                        line
                                        isSecondary
                                        fullWidth
                                        style={{ margin: '8px 0' }}
                                        onClick={() => {
                                            this.props.setMasterDataInitialized(true);
                                        }}
                                    >
                                        Proceed to Website
                                    </DrcButton>
                                </div>
                            </div>
                        </React.Fragment>
                    ) : null}
                </DrcPanel>
            </DrcMain>
        );
    }
}

function mapStateToProps(state) {
    return {
        commonDialog: state.rootReducer.commonDialog,
        pageTitle: state.rootReducer.pageTitle,
        redirectUrl: state.masterReducer.redirectUrl,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        userIsAdmin: state.rootReducer.userIsAdmin
    };
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setMasterDataInitialized: (isInitialized) => dispatch(setMasterDataInitialized(isInitialized)),
    setUserRoles: (data) => dispatch(setUserRoles(data)),
    setUserIsAdmin: (data) => dispatch(setUserIsAdmin(data)),
    setCurrentPoolWeek: (data) => dispatch(setCurrentPoolWeek(data)),
    setLookUpValues: (lookup, data) => dispatch(setLookUpValues(lookup, data)),
    setUserDetails: (lang, profile) => dispatch(setUserDetails(lang, profile)),
    setUserLang: (lang) => dispatch(setUserLang(lang)),
    setCurrentYearPoolWeek: (data) => dispatch(setCurrentYearPoolWeek(data)),
    lookupFilter: (data) => dispatch(lookupFilter(data)),
    vendorCodeOptions: (data) => dispatch(vendorCodeOptions(data)),
    setUserPreference: (data) => dispatch(setUserPreference(data))
});

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withLocalize(InitializeApplication)));
