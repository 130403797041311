// DrcPageDataMaintenance.js
// This component manages data correlated to specific data type used in the
// application. It can edit, add, and delete data from the DB through the
// functions passed in.

// Props:
// type: Arg type String. The type represents the Data Type itself that is being
//      maintained by this page. It is used for titles and labels in the component.
// columns(recommended): Arg type Array of Objects. Objects must contain key and name
//      pair correlating to the Data types key and the display name for that key. There
//      is also an optional prop called validationType that denotes the validation on
//      the maintenance input options. Right now number is supported and by default a
//      value is treated as type string.
//      ex: [{key:"CriteriaOptions", name:"Berry Type"}, {key:"CriteriaOrder", name:"Type Id", validationType:"number"}]
// data: Arg type Array of objects. Contains the values currently contained in the
//      application. This is the data that will be displayed in the table and edited
//      in the maintenance page.
//      ex: [{CriteriaOptions: "Straw", CriteriaOrder: "1"}, {CriteriaOptions: "Rasp", CriteriaOrder: "2"}]
// onAdd: Arg type Function. Function that handles adding a new value for the data type.
// onEdit: Arg type Function. Function that handles editing a value for the data type.
// onDelete: Arg type Function. Function that handles deleting a value for the data type.
// readOnly(default=false): Arg type Boolean. If set to true, hides editing features of the
//      maintenance page.
// addDisabled(default=false): Arg type Boolean. This property disables add functions in DrcMaintenance.
// editDisabled(default=false): Arg type Boolean. This property disables edit functions in DrcMaintenance.
// settings: EnableAdd, readOnly etc.
// RowClick; for the grid
// frozenWidth: Set the with of the frozen Columns. When the column setup contains frozen columns this value is mandatory

import React, { Component } from 'react';
import DrcMain from '../Components/DrcMain';
import DrcPanel from '../Components/DrcPanel';
import DrcDataGrid from '../Components/DrcDataGrid';
import DrcButton from '../Components/DrcButton';
import DrcCheckbox from '../Components/DrcCheckbox';
import DrcMaintenanceDialog from '../Components/DrcMaintenanceDialog';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForever from '@mui/icons-material/DeleteForever';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import { withStyles } from '@mui/styles';
import { DuExcelUtilities, DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';
import { Translate } from 'react-localize-redux';

/**
 *@ignore
 */
const styles = (theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: '100%',
            width: '100%'
        }
    },
    legendTitle: {
        fontSize: '1.5rem'
    },
    actionButton: {
        minWidth: '32px',
        margin: '0px !important',
        padding: '4px'
    },
    actionButtonError: {
        color: theme.light.text.errorTitle,
        [theme.darkTheme]: {
            color: theme.dark.text.errorTitle
        }
    },
    required: {
        color: DuThemeUtilities.DefaultColors.primary.red
    },
    radio: {
        color: theme.light.accent.primary,
        [theme.darkTheme]: {
            color: theme.dark.accent.primary
        }
    }
});

export const MAINTENANCE_PAGE_CONSTANTS = {
    ORIENTATION_HORIZONTAL: 0,
    ORIENTATION_VERTICAL: 1,
    ACTION_HIDDEN: 0,
    ACTION_FIRST_FROZEN: 1,
    ACTION_LAST_FROZEN: 2,
    ACTION_FIRST: 3,
    ACTION_LAST: 4
};

const defaultColumnProperties = {
    resizable: true,
    filterable: true
};

const DEFAULT_TEXT = {
    PageTitle: 'Maintenance',
    AddBtn: <Translate id="buttons.add" />,
    ExportBtn: <Translate id="buttons.download" />,
    CancelBtn: <Translate id="buttons.cancel" />,
    SaveBtn: <Translate id="buttons.save" />,
    DeleteBtn: <Translate id="buttons.delete" />,
    ConfirmAcceptBtn: <Translate id="buttons.accept" />,
    ConfirmRejectBtn: <Translate id="buttons.oops" />,
    removeIllegalChars: <Translate id="HelpTexts.removeIllegalChars" />,
    useLargerValue: <Translate id="HelpTexts.useLargerValue" />,
    useSmallerValue: <Translate id="HelpTexts.useSmallerValue" />,
    requiredField: <Translate id="HelpTexts.requiredField" />,
    correctInput: <Translate id="HelpTexts.correctInput" />,
    addValidEmail: <Translate id="HelpTexts.addValidEmail" />,
    addValidUserId: <Translate id="HelpTexts.addValidUserId" />,
    requiredLengthTooSmall: <Translate id="HelpTexts.requiredLengthTooSmall" />,
    requiredLengthTooLarge: <Translate id="HelpTexts.requiredLengthTooLarge" />
};

const DEFAULT_OPTIONS = {
    EnableCheckBoxDelete: true,
    EnableDelete: true,
    EnableEdit: true,
    EnableAdd: true,
    EnableExport: true,
    EnableAddConfirmDialog: true,
    EnableEditConfirmDialog: true,
    Orientation: MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_HORIZONTAL,
    ActionColumnSetting: MAINTENANCE_PAGE_CONSTANTS.ACTION_LAST_FROZEN,
    ShowCount: true,
    OverrideAdd: null,
    OverrideEdit: null
};

/**
 * @description DrcPageDataMaintenance manages data correlated to specific data type used in the application
 * @example
 * <DrcPageDataMaintenance />
 * @property {*}
 * @class DrcPageDataMaintenance
 * @extends {Component}
 */
class DrcPageDataMaintenance extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: false,
            dialogEditOpen: false,
            dialogAddNewOpen: false,
            dialogText: '',
            dialogConfirm: () => {},
            type: '',
            oldValue: {},
            isEdit: false,
            editedValue: {},
            textOptions: {
                ...DEFAULT_TEXT,
                addEditText: this.props.textOptions.addEditText || <Translate id="HelpTexts.addNewType" data={{ type: this.props.type }} />,
                editText: this.props.textOptions.editText || <Translate id="HelpTexts.editType" data={{ type: this.props.type }} />,
                changeTypeConfirmTitle: this.props.textOptions.changeTypeConfirmTitle || <Translate id="HelpTexts.changeTypeConfirmTitle" data={{ type: this.props.type }} />,
                addNewTypeConfirmTitle: this.props.textOptions.addNewTypeConfirmTitle || <Translate id="HelpTexts.addNewTypeConfirmTitle" data={{ type: this.props.type }} />,
                deleteTypeConfirmTitle: this.props.textOptions.deleteTypeConfirmTitle || <Translate id="HelpTexts.deleteTypeConfirmTitle" data={{ type: this.props.type }} />,
                addTypeConfirmTitle: this.props.textOptions.addTypeConfirmTitle || <Translate id="HelpTexts.addTypeConfirmTitle" data={{ type: this.props.type }} />
            },
            settings: { ...DEFAULT_OPTIONS },
            columns: [],
            selectedRecords: [],
            pageSize: 50,
            rowUnderEditUniqueKey: {}
        };

        this.onDialogYes = this.onDialogYes.bind(this);
        this.onDialogNo = this.onDialogNo.bind(this);
        this.getCellActions = this.getCellActions.bind(this);
        this.onAddEditYes = this.onAddEditYes.bind(this);
        this.onAddEditNo = this.onAddEditNo.bind(this);
        this.openAddNew = this.openAddNew.bind(this);
        this.export = this.export.bind(this);
        this.onAddNewYes = this.onAddNewYes.bind(this);
        this.onAddNewNo = this.onAddNewNo.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.onPage = this.onPage.bind(this);
    }

    /**
     *@ignore
     */
    componentDidMount() {
        this.onLoad();
    }

    /**
     *@ignore
     */
    componentDidUpdate() {
        this.onLoad();
    }

    /**
     *
     * @description checks properties of default settings
     * @memberof DrcPageDataMaintenance
     */
    onLoad = () => {
        let stateChange = {};
        let optionsFromProps = {};

        if (typeof this.props.disableDelete === 'boolean') {
            optionsFromProps.EnableDelete = !this.props.readOnly && !this.props.disableDelete;
        }

        if (typeof this.props.disableEdit === 'boolean') {
            optionsFromProps.EnableEdit = !this.props.readOnly && !this.props.disableEdit;
        }

        if (typeof this.props.addBtn === 'boolean') {
            optionsFromProps.EnableAdd = this.props.addBtn;
        }

        if (typeof this.props.exportAllowed === 'boolean') {
            optionsFromProps.EnableExport = this.props.exportAllowed;
        }

        if (typeof this.props.orientation === 'string') {
            optionsFromProps.Orientation = this.props.orientation;
        }

        let settings = { ...DEFAULT_OPTIONS, ...optionsFromProps, ...(this.props.settings || {}) };

        if (!this.isEquivalent(this.state.settings, settings)) {
            stateChange.settings = settings;
        }

        let textOptions = {
            ...DEFAULT_TEXT,
            ...this.state.textOptions
        };

        if (!this.isEquivalent(this.state.textOptions, textOptions)) {
            stateChange.textOptions = textOptions;
        }

        if (Object.getOwnPropertyNames(stateChange).length > 0) {
            this.setState(stateChange);
        }
    };

    //TODO: Make this into a generic utility we can use elsewhere
    /**
     *@ignore
     */
    isEquivalent = (a, b) => {
        // Create arrays of property names
        var aProps = Object.getOwnPropertyNames(a);
        var bProps = Object.getOwnPropertyNames(b);

        // If number of properties is different,
        // objects are not equivalent
        if (aProps.length !== bProps.length) {
            return false;
        }

        for (var i = 0; i < aProps.length; i++) {
            var propName = aProps[i];

            // If values of same property are not equal,
            // objects are not equivalent
            if (a[propName] !== b[propName]) {
                return false;
            }
        }

        // If we made it this far, objects
        // are considered equivalent
        return true;
    };

    /**
     *@ignore
     */
    updateSelectedRecords = (checked, row) => {
        let records = [...this.state.selectedRecords];
        if (checked) {
            row.isSelected = checked;
            records.push(row);
        }
        // remove from selected records
        else {
            row.isSelected = false;
            let index = (records || []).findIndex((record) => record[this.state.settings.EnableCheckBoxDelete.key] === row[[this.state.settings.EnableCheckBoxDelete.key]]);
            if (index !== -1) {
                records.splice(index, 1);
            }
        }
        this.setState({ selectedRecords: records, row });
    };
    /**
     *@ignore
     */
    handleCheckBoxChange = (event, row) => {
        this.updateSelectedRecords(event.target.checked, row);
    };

    /**
     *@ignore
     */
    getCellActions(row, col, test, test2) {
        var actions = [];
        var key = this.props.columnKey ? row[this.props.columnKey] : null;

        if (this.state.settings.EnableCheckBoxDelete.access) {
            var isAllowed = this.props.rowCanDelete ? this.props.rowCanDelete(row) : true;
            if (isAllowed) {
                actions.push(
                    <DrcCheckbox
                        key={key ? `chk${key}` : undefined}
                        className={`${this.props.classes.actionButton} ${this.props.classes.actionButtonError}`}
                        checked={row.isSelected}
                        onChange={(event) => this.handleCheckBoxChange(event, row)}
                    />
                );
            }
        }

        if (this.state.settings.EnableDelete) {
            const isAllowed = this.props.rowCanDelete ? this.props.rowCanDelete(row) : true;
            if (isAllowed) {
                actions.push(
                    <DrcButton
                        key={key ? `delBtn${key}` : undefined}
                        size="small"
                        className={`${this.props.classes.actionButton} ${this.props.classes.actionButtonError}`}
                        onClick={() => {
                            this.setState({
                                dialogOpen: true,
                                dialogText: [
                                    <div style={{ fontSize: 20, marginBottom: 10 }} key="dialog-enable-delete-title">
                                        {this.state.textOptions.deleteTypeConfirmTitle}
                                    </div>,
                                    <DrcDataGrid
                                        key="dialog-enable-delete-datagrid"
                                        rows={[row]}
                                        columns={this.props.columns.map((c) => {
                                            return { ...c, filter: false };
                                        })}
                                        hideCount={true}
                                    />
                                ],
                                dialogConfirm: this.props.onDelete,
                                type: this.props.type,
                                oldValue: { ...row },
                                editedValue: { ...row },
                                isEdit: false
                            });
                        }}
                    >
                        <DeleteForever />
                    </DrcButton>
                );
            }
        }

        if (this.state.settings.EnableEdit) {
            const isAllowed = this.props.rowCanEdit ? this.props.rowCanEdit(row) : true;
            if (isAllowed) {
                actions.push(
                    <DrcButton
                        key={key ? `editBtn${key}` : undefined}
                        size="small"
                        className={this.props.classes.actionButton}
                        onClick={() => {
                            this.state.settings.OverrideEdit
                                ? this.state.settings.OverrideEdit(row)
                                : this.setState({
                                      dialogEditOpen: true,
                                      type: this.props.type,
                                      oldValue: { ...row },
                                      editedValue: { ...row },
                                      isEdit: true
                                  });
                        }}
                    >
                        <EditIcon />
                    </DrcButton>
                );
            }
        }

        return <span>{actions.length > 0 ? actions.reduce((prev, curr) => [prev, curr]) : null}</span>;
    }

    /**
     *@ignore
     */
    openAddNew() {
        this.setState({
            dialogAddNewOpen: true,
            oldValue: null,
            editedValue: null
        });
    }

    /**
     *
     * @description Function to export grid data to excel
     * @memberof DrcPageDataMaintenance
     */
    export() {
        DuExcelUtilities.Write(this.props.type + 'Maintenance.xlsx', this.getColumns(), this.props.data);
    }

    /**
     *
     * @description Function to add new record to grid
     * @return {*}
     * @memberof DrcPageDataMaintenance
     */
    onAddNewYes(dialogText) {
        this.setState({
            dialogOpen: true,
            dialogAddNewOpen: false,
            dialogText: dialogText,
            dialogConfirm: this.state.isEdit ? this.props.onEdit : this.props.onAdd,
            type: this.props.type
        });
    }

    /**
     *@ignore
     */
    onAddNewNo() {
        this.setState({
            dialogAddNewOpen: false,
            dialogConfirm: () => {},
            type: '',
            oldValue: {},
            isEdit: false
        });
    }

    /**
     *@ignore
     */
    onAddEditYes(dialogText) {
        this.setState({
            dialogOpen: true,
            dialogEditOpen: false,
            dialogText: dialogText,
            dialogConfirm: this.state.isEdit ? this.props.onEdit : this.props.onAdd,
            type: this.props.type
        });
    }

    /**
     *@ignore
     */
    generateDialogTextEditYes(editedValue) {
        let dialogText = '';
        if (this.state.isEdit) {
            if (typeof editedValue === 'object') {
                if (this.state.settings.EnableEditConfirmDialog === false) {
                    if (this.state.isEdit) {
                        this.props.onEdit(this.state.oldValue, editedValue);
                    } else {
                        this.props.onAdd(editedValue);
                    }
                    return;
                } else {
                    dialogText = [
                        <div style={{ fontSize: 20, marginBottom: 10 }} key="maintenance-dialog-edit-confirm-title">
                            {this.state.textOptions.changeTypeConfirmTitle}
                        </div>,
                        <div className="row" key="maintenance-dialog-edit-confirm-body">
                            <DrcDataGrid
                                className="col-sm-12"
                                rows={[this.state.oldValue]}
                                columns={this.props.columns.map((c) => {
                                    return { ...c, filter: false };
                                })}
                                hideCount={true}
                            />
                            <ArrowDownward className="col-sm-12" style={{ fontSize: 70 }} />
                            <DrcDataGrid
                                className="col-sm-12"
                                rows={this.props.gridDataFormatter ? this.props.gridDataFormatter([editedValue]) : [editedValue]}
                                columns={this.props.columns.map((c) => {
                                    return { ...c, filter: false };
                                })}
                                hideCount={true}
                            />
                        </div>
                    ];
                }
            } else {
                dialogText = this.props.textOptions.editDialogText ? (
                    this.props.textOptions.editDialogText([this.props.type, this.state.oldValue, editedValue])
                ) : (
                    <Translate id="HelpTexts.editDialogText" data={{ type: this.props.type, oldValue: this.state.oldValue, editedValue: editedValue }} />
                );
            }
        } else {
            if (typeof editedValue === 'object') {
                dialogText = [
                    <div style={{ fontSize: 20, marginBottom: 10 }} key="maintenance-dialog-edit-confirm-title">
                        {this.state.textOptions.addNewTypeConfirmTitle + ' '}
                    </div>,
                    <DrcDataGrid
                        key="maintenance-dialog-edit-confirm-datagrid"
                        rows={[editedValue]}
                        columns={this.props.columns.map((c) => {
                            return { ...c, filter: false };
                        })}
                        hideCount={true}
                    />
                ];
            } else {
                dialogText = this.props.textOptions.addNewTypeDialog ? (
                    this.props.textOptions.addNewTypeDialog([this.props.type, editedValue])
                ) : (
                    <Translate id="HelpTexts.addNewTypeDialog" data={{ type: this.props.type, editedValue: editedValue }} />
                );
            }
        }
        return dialogText;
    }

    /**
     *@ignore
     */
    generateDialogTextNewYes(editedValue) {
        let dialogText = '';
        if (typeof editedValue === 'object') {
            if (this.state.settings.EnableAddConfirmDialog === false) {
                if (this.state.isEdit) {
                    this.props.onEdit(this.state.oldValue, editedValue);
                } else {
                    this.props.onAdd(editedValue);
                }
                return;
            } else {
                dialogText = [
                    <div style={{ fontSize: 20, marginBottom: 10 }} key="dialog-enable-delete-title">
                        {this.state.textOptions.addTypeConfirmTitle}
                    </div>,
                    <DrcDataGrid
                        key="dialog-enable-delete-datagrid"
                        rows={this.props.gridDataFormatter ? this.props.gridDataFormatter([editedValue]) : [editedValue]}
                        columns={this.props.columns.map((c) => {
                            return { ...c, filter: false };
                        })}
                        hideCount={true}
                    />
                ];
            }
        } else {
            dialogText = this.props.textOptions.addNewTypeDialog ? (
                this.props.textOptions.addNewTypeDialog([this.props.type, editedValue])
            ) : (
                <Translate id="HelpTexts.addNewTypeDialog" data={{ type: this.props.type, editedValue: editedValue }} />
            );
        }

        return dialogText;
    }

    /**
     *@ignore
     */
    onAddEditNo() {
        this.setState(
            {
                dialogEditOpen: false,
                dialogConfirm: () => {},
                type: '',
                oldValue: {},
                isEdit: false,
                editedValue: {}
            }
            //() => this.buildEditArray()
        );
    }

    /**
     *
     * @description Function to check confirmation for deleting a record
     * @memberof DrcPageDataMaintenance
     */
    deleteConfirm() {
        this.setState({
            dialogOpen: true,
            dialogText: [
                <div style={{ fontSize: 20, marginBottom: 10 }} key="dialog-enable-delete-title">
                    {this.state.textOptions.deleteTypeConfirmTitle}
                </div>,
                <DrcDataGrid
                    key="dialog-enable-delete-datagrid"
                    rows={this.state.selectedRecords}
                    columns={this.props.columns.map((c) => {
                        return { ...c, filter: false };
                    })}
                    hideCount={true}
                />
            ],
            dialogConfirm: this.props.onCheckboxDelete,
            type: this.props.type,
            oldValue: [...this.state.selectedRecords],
            editedValue: [...this.state.selectedRecords],
            isEdit: false
        });
    }

    /**
     *
     * @description Function to construct columns
     * @return {*}
     * @memberof DrcPageDataMaintenance
     */
    getColumns() {
        var returnValue = [];
        if (this.props.columns === undefined) {
            Object.keys(this.props.data[0] || []).forEach(function (c) {
                returnValue.push({
                    key: c,
                    name: c,
                    ...defaultColumnProperties
                });
            });
        } else {
            this.props.columns.forEach(function (c) {
                returnValue.push(c);
            });
        }
        return returnValue;
    }

    /**
     *
     * Function to set width of actions based on actions type
     * @param {*} enableEdit
     * @param {*} enableDelete
     * @param {*} enableCheckboxDelete
     * @memberof DrcPageDataMaintenance
     */
    getActionWidth = (enableEdit, enableDelete, enableCheckboxDelete) => {
        // all 3 action buttons
        if (enableEdit && enableDelete && enableCheckboxDelete) {
            return 130;
        }
        // any 2 action buttons
        else if ((enableEdit && enableDelete) || (enableDelete && enableCheckboxDelete) || (enableEdit && enableCheckboxDelete)) {
            return 90;
        }
        // any one action button
        else if (enableEdit || enableDelete || enableCheckboxDelete) {
            return 70;
        }
    };

    /**
     *@ignore
     */
    onPage = (event, pageSize) => {
        if (this.state.first === event.first) {
            return;
        }

        this.setState({ first: event.first });

        if (this.props.virtualScroll && this.loadData) {
            let pageSizeValue = pageSize || this.props.pageSize || this.state.pageSize;
            this.loadData(event.first / pageSizeValue + 1);
        }
    };

    /* Cell Editing */
    /**
     *
     * @description Cell Editing
     * @param {*} columnProps
     * @param {*} value
     * @memberof DrcPageDataMaintenance
     */
    onEditorValueChange = async (columnProps, value) => {
        let isExisting = false;
        if (columnProps.unique && value !== '') {
            isExisting = await this.checkRecordExists(value, columnProps.field);
        }
        if (!isExisting) {
            let updatedRecords = [...columnProps.gridProps.value];
            //TODO need a better way to handle the payload
            updatedRecords[columnProps.gridProps.rowIndex][columnProps.field] = value;
            this.props.setEntityData(updatedRecords, this.props.totalRecords);
        } else {
            this.props.showToast(value + ' already exists in entity', 'error');
        }
    };

    //TODO it seems that this is not used
    /**
     *
     * @description Adding new record
     * @memberof DrcPageDataMaintenance
     */
    onAddNewRecord = () => {
        if (this.editMode === 'inline') {
            let record = this.props.records.length > 0 ? [...this.props.records][0] : { ...this.objNewRecord, BerryType: 'BLACK' };
            Object.keys(record).forEach((k) => (k !== 'BerryType' ? (record[k] = null) : null));
            record.new = true;
            record.Active = 1;
            record.ActiveStatus = 'Active';
            record[this.uniqueKeyField] = 1;
            let updatedRecords = [...this.props.records];
            updatedRecords.push(record);
            this.props.setEntityData(updatedRecords, this.props.totalRecords || 1);
            let originalRows = [];
            this.setState({ rowUnderEditUniqueKey: 1 }, () => {
                let index = 0;
                originalRows[index] = { ...this.props.records[index] };
                this.editSelection(null, record, index);
            });
        } else {
            this.setState({ showEditDialog: true, newRecord: true });
        }
    };

    /**
     * @description Checks for confirmation and opens dialog box
     * @memberof DrcPageDataMaintenance
     */
    onDialogYes(oldValue, editedValue) {
        if (this.state.isEdit) {
            this.state.dialogConfirm(oldValue, editedValue);
        } else {
            this.state.dialogConfirm(editedValue);
        }

        this.setState({
            dialogOpen: false,
            dialogConfirm: () => {},
            type: '',
            oldValue: {},
            editedValue: {},
            isEdit: false,
            helperText: [],
            selectedRecords: []
        });
    }

    /**
     * @description closes dialog window
     * @memberof DrcPageDataMaintenance
     */
    onDialogNo() {
        this.setState({
            dialogOpen: false,
            dialogConfirm: () => {},
            type: '',
            oldValue: {},
            editedValue: {},
            isEdit: false,
            helperText: []
        });
    }

    /**
     * @return {*}
     * @memberof DrcPageDataMaintenance
     */
    render() {
        const {
            className,
            fullWidth,
            type,
            children,
            data,
            onRowClick,
            pageSize,
            hideCount,
            classes,
            height,
            resultCount,
            minHeight,
            lazy,
            virtualScroll,
            onVirtualScroll,
            gridStyles,
            frozenWidth,
            customButtons,
            loading,
            loadData,
            paginator,
            editable,
            editMode,
            onEditorValueChange,
            rowUnderEditUniqueKey,
            currentPage,
            uniqueKeyField
        } = this.props;

        const { textOptions, settings, first } = this.state;
        const readOnly = !settings.EnableAdd && !settings.EnableDelete && !settings.EnableEdit;

        let columns = this.getColumns();
        columns = columns.map((c) => ({ ...defaultColumnProperties, ...c }));
        let actionWidth = null;

        if (!readOnly && settings.ActionColumnSetting !== MAINTENANCE_PAGE_CONSTANTS.ACTION_HIDDEN) {
            actionWidth = this.getActionWidth(settings.EnableEdit, settings.EnableDelete, settings.EnableCheckBoxDelete.access);

            if (settings.ActionColumnSetting === MAINTENANCE_PAGE_CONSTANTS.ACTION_FIRST_FROZEN) {
                columns = [{ key: 'actions', name: 'Actions', width: actionWidth, frozen: true, columnTemplate: this.getCellActions }, ...columns];
            } else if (settings.ActionColumnSetting === MAINTENANCE_PAGE_CONSTANTS.ACTION_LAST_FROZEN) {
                let frozenColumns = columns.filter((c) => !!c.frozen) || [];
                let normalColumns = columns.filter((c) => !c.frozen) || [];

                columns = [...frozenColumns, { key: 'actions', name: 'Actions', width: actionWidth, frozen: true, columnTemplate: this.getCellActions }, ...normalColumns];
            } else if (settings.ActionColumnSetting === MAINTENANCE_PAGE_CONSTANTS.ACTION_FIRST) {
                let frozenColumns = columns.filter((c) => !!c.frozen) || [];
                let normalColumns = columns.filter((c) => !c.frozen) || [];

                columns = [...frozenColumns, { key: 'actions', name: 'Actions', width: actionWidth, columnTemplate: this.getCellActions }, ...normalColumns];
            } else if (settings.ActionColumnSetting === MAINTENANCE_PAGE_CONSTANTS.ACTION_LAST) {
                columns = [...columns, { key: 'actions', name: 'Actions', width: actionWidth, columnTemplate: this.getCellActions }];
            }
        }

        let index = currentPage || first || 0;
        let rows = data || [];

        return (
            <React.Fragment>
                <DrcMain maxWidth={fullWidth ? '100%' : null} transparent className={`${classes.main} ${className}`}>
                    <DrcPanel maxWidth={'100%'}>
                        <div className="row">
                            <div className={settings.Orientation === MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_HORIZONTAL ? 'col-xs-8' : 'col-xs-12'}>
                                <h1 style={{ marginTop: 0 }}>
                                    {type} {textOptions.PageTitle}
                                </h1>
                            </div>
                            {settings.Orientation === MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_HORIZONTAL ? (
                                <div className="col-xs-4">
                                    {settings.EnableAdd ? (
                                        <DrcButton style={{ marginTop: 0 }} isPrimary onClick={settings.OverrideAdd ? settings.OverrideAdd : this.openAddNew} floatRight>
                                            {textOptions.AddBtn}
                                        </DrcButton>
                                    ) : null}
                                    {settings.EnableExport ? (
                                        <DrcButton style={{ marginTop: 0 }} isSecondary onClick={this.export} floatRight>
                                            {textOptions.ExportBtn}
                                        </DrcButton>
                                    ) : null}
                                    {settings.EnableCheckBoxDelete.access && !settings.EnableDelete ? (
                                        <DrcButton style={{ marginTop: 0 }} isSecondary onClick={this.deleteConfirm} floatRight disabled={!this.state.selectedRecords.length}>
                                            {textOptions.DeleteBtn}
                                        </DrcButton>
                                    ) : null}
                                    {customButtons}
                                </div>
                            ) : null}
                        </div>
                        <div className="row">
                            <DrcDataGrid
                                columns={columns}
                                onRowClick={onRowClick}
                                pageSize={pageSize || this.state.pageSize}
                                hideCount={hideCount}
                                className="MaintenanceGrid"
                                height={height}
                                resultCount={resultCount || rows.length}
                                minHeight={minHeight}
                                totalRecords={resultCount || rows.length}
                                lazy={lazy}
                                virtualScroll={virtualScroll}
                                onVirtualScroll={onVirtualScroll}
                                loading={loading}
                                gridStyles={gridStyles}
                                rows={rows}
                                frozenWidth={frozenWidth || actionWidth ? actionWidth + 'px' : null}
                                loadData={loadData}
                                virtualRowHeight={35}
                                loadingFunc={() => {
                                    return null;
                                }}
                                paginator={paginator}
                                currentPage={index}
                                onPage={this.onPage}
                                uniqueKeyField={uniqueKeyField}
                                rowUnderEditUniqueKey={rowUnderEditUniqueKey}
                                onEditorValueChange={onEditorValueChange || this.onEditorValueChange}
                                editMode={editMode}
                                editable={editable}
                            />
                        </div>
                    </DrcPanel>
                </DrcMain>
                {this.state.dialogOpen || this.state.dialogAddNewOpen || this.state.dialogEditOpen ? (
                    <DrcMaintenanceDialog
                        openAdd={this.state.dialogAddNewOpen}
                        openEdit={this.state.dialogEditOpen}
                        cancelBtnText={textOptions.CancelBtn}
                        saveBtnText={textOptions.SaveBtn}
                        onAddNewNo={this.onAddNewNo}
                        onAddNewYes={this.onAddNewYes}
                        onAddEditNo={this.onAddEditNo}
                        onAddEditYes={this.onAddEditYes}
                        title={this.state.isEdit ? this.state.textOptions.editText : this.state.textOptions.addEditText}
                        verticalOrientation={this.state.settings.Orientation === MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_VERTICAL}
                        columns={this.getColumns()}
                        confirmAcceptBtnText={textOptions.ConfirmAcceptBtn}
                        confirmRejectBtnText={textOptions.ConfirmRejectBtn}
                        confirmDialogTitle={this.state.dialogText}
                        confirmDialogOpen={this.state.dialogOpen}
                        isEdit={this.state.isEdit}
                        onDialogNo={this.onDialogNo}
                        onDialogYes={this.onDialogYes}
                        oldValue={this.state.oldValue}
                        editedValue={this.state.editedValue}
                        generateDialogTextNewYes={(editValue) => this.generateDialogTextNewYes(editValue)}
                        generateDialogTextEditYes={(editValue) => this.generateDialogTextEditYes(editValue)}
                    />
                ) : null}
                {children}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(DrcPageDataMaintenance);
