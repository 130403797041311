import React, { Component } from 'react';
import { DrcSelect, DrcTranslate, DrcButton, DrcInput } from 'drc/driscolls-react-components/index';
import { withStyles } from '@mui/styles';
import { Divider } from '@mui/material';
import DrcWrapper from '../../components/DrcWrapper';
import Stepper from '../../components/Stepper';
import { designColors, LOOKUP_FIELDS, reportOptions, currencyOptions, reportTypeOptions, versionOptions } from '../../data/constants';
import SettlementRecord from './SettlementRecord';
import AveragePriceReport from './AveragePriceReport';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast, setPageTitleAction } from '../../actions/actions';
import { setFilters, clearFilters, clearOneFilter } from '../../actions/FilterActions';
import { connect } from 'react-redux';
import { displayErrorMessage, makeOptions, userBusinessUnit } from '../../utils/helper';
import { withOktaAuth } from '@okta/okta-react';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import Grid from '@mui/material/Grid';
import APIEndPoints from '../../services/api';
import { Call } from '@driscollsinc/driscolls-display-rules';
import DomesticSummary from './DomesticSummary';
import FilterListSharp from '@mui/icons-material/FilterListSharp';
import ReceiptCostCenter from './ReceiptCostCenter';
import ReceiptByGrower from './ReceiptByGrower';
import JEShanghai from './JEShanghai';
import JeSevenknot from './JeSevenknot';
import JECCAllocation from './JeCcAllocation';
import SummaryReportsCR from './SummaryReportsCR';
import ImportReceiptByCostCenter from './ImportReceiptByCostCenter';
import ImportSettlementReport from './ImportSettlementReport';
import JESHReport from './JESHReport';

const pageTitle = 'Reports';
const styles = (theme) => ({
    cardContainer: {
        // backgroundColor: designColors.lightGrey,
        //borderTop: '2px solid #E0E0E0',
        // overflowX: 'auto',
        //paddingTop: '20px',
        // marginBottom: '30px',
        // borderRadius: '10px',
        margin: '5px',
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.grey['A700']} !important`,
            borderTop: '2px solid #000000'
        }
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px'
    },
    card: {
        backgroundColor: 'white',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
        borderRadius: '4px',
        padding: '10px 20px',
        [theme.darkTheme]: {
            backgroundColor: `#303030 !important`
        }
    },
    btnClass: {
        color: `${designColors.blue}`,
        cursor: 'pointer',
        fontWeight: 'bold',
        display: 'flex',
        flexWrap: 'wrap',
        float: 'right',
        margin: '20px'
    },
    root: {
        marginRight: 8,
        '& .select': {
            // top: 2,
            maxWidth: '400px',
            // minWidth: '200px',
            [theme.darkTheme]: {
                backgroundColor: `#303030 !important`
            }
        },
        '& .select > label': { fontColor: 'Green' },
        '& .select > div:first-of-type': {
            backgroundColor: theme.palette.common.white,
            [theme.darkTheme]: {
                backgroundColor: `#303030 !important`
            }
        }
    },
    input: {
        paddingRight: 8
    },
    error: {
        color: theme.light.text.errorTitle + ' !important',
        [theme.darkTheme]: {
            color: theme.dark.text.errorTitle + ' !important'
        },
        fontSize: '1rem !important'
    },
    helperText: {
        color: designColors.red
    }
});
const allGrowerSettelementName = { label: 'ALL', value: 'ALL', Geography: 'Domestic' };
const allImportGrowerSettelementName = { label: 'ALL', value: 'ALL', Geography: 'International', ParentVendorCode: '' };

class Reports extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            selectedReport: reportOptions[0],
            selectedGrower: null,
            selectedSubGrower: null,
            selectedCommodity: null,
            selectedCurrency: null,
            selectedReportType: null,
            selectedVersion: null,
            estimateGroup: null,
            growerCategory: null,
            selectedPoolWeek: (makeOptions(props?.router?.location?.state ?? props.currentPoolWeek, 'PoolWeek', 'PoolWeek') || [])[0],
            settlementRecordStatus: {},
            selectedItemId: null,
            selectedGrowerId: '',
            showFilter: true,
            urls: [],
            totalUrls: 0
        };
        this.state = { ...this.initialState };
    }

    async componentDidMount() {
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }
        await this.getFiltersData();
    }

    getFiltersData = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            let itemIdData = await Call(
                {
                    name: 'ItemUomConversion',
                    url: APIEndPoints.GET_ITEM_UOM_CONVERSION_LIST(null, null, userBusinessUnit(), this.props.userLang),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (itemIdData.errors.length && !itemIdData.raw) {
                return this.showError(itemIdData.errors[0]);
            }
            let growerResponse = await Call(
                {
                    name: 'GrowerList',
                    url: APIEndPoints.GET_GROWER_LIST(
                        this.state.pageNum / this.state.pageSize + 1,
                        this.state.pageSize,
                        userBusinessUnit(),
                        this.props.userLang
                    ),
                    method: 'GET',
                    options: {}
                },
                token
            );
            const growerOptions = makeOptions(growerResponse.raw.data.Data, 'GrowerName', 'VendorCode');
            this.setState({ itemIdOptions: makeOptions(itemIdData?.raw?.data?.Data || [], 'ItemID', 'ItemID'), growerOptions });
            this.props.hideLoadingScreenAction();
        } catch (error) {
            this.showError(error);
        }
    };

    showError = (err, msg) => {
        this.props.hideLoadingScreenAction();
        this.props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    getStatus = async () => {
        let token = await this.props.oktaAuth.getAccessToken();
        let response = await Call(
            {
                name: 'SettlementStatus',
                url: APIEndPoints.GET_SETTLEMENT_STATUS(this.state.selectedPoolWeek.value, userBusinessUnit()),
                method: 'GET',
                options: {}
            },
            token
        );
        this.setState({ settlementRecordStatus: (response?.raw?.data.Data || [])[0] || {} });
    };

    getSummaryData(loadSummaryData) {
        this.getSummaryFilteredData = loadSummaryData;
    }
    getAvgPriceReportData(loadAvgPriceData) {
        this.getAvgPriceFilteredData = loadAvgPriceData;
    }
    getSummaryReportData(loadSummaryReportData) {
        this.getSummaryReportFilteredData = loadSummaryReportData;
    }
    getDomesticSummaryData(loadDomesticSummary) {
        this.getDomesticSummary = loadDomesticSummary;
    }

    getGrowerSummaryFilters() {
        return {
            PoolWeek: +this.state.selectedPoolWeek?.value,
            BusinessUnit: userBusinessUnit(),
            GrowerID: this.state.selectedGrower?.value || '',
            GrowerName: this.state.selectedGrower?.label || '',
            ReportType: this.state.selectedReportType?.value,
            ReportName: this.state.selectedReport?.value
        };
    }
    receiptByGrowerFilter() {
        return {
            PoolWeek: +this.state.selectedPoolWeek?.value,
            BusinessUnit: userBusinessUnit(),
            ReportName: 'ReceiptByGrower',
            ReportType: this.state.selectedReportType?.value
        };
    }
    journalFilters() {
        return {
            PoolWeek: +this.state.selectedPoolWeek?.value,
            BusinessUnit: userBusinessUnit(),
            ReportName: this.state.selectedReport?.value,
            ReportType: this.state.selectedReportType?.value
        };
    }
    receiptCostCenterFilter() {
        return {
            PoolWeek: +this.state.selectedPoolWeek?.value,
            BusinessUnit: userBusinessUnit(),
            ReportName: 'ReceiptByCostCenter',
            ReportType: '',
            ...(this.state.growerCategory && this.state.growerCategory.value ? { Category: this.state.growerCategory.value } : {})
        };
    }
    importSettlementReportFilter() {
        return {
            PoolWeek: +this.state.selectedPoolWeek?.value,
            BusinessUnit: userBusinessUnit(),
            ReportName: 'ImportGrowerSettlement',
            ReportType: this.state.selectedReportType?.value,
            GrowerName: this.state.selectedGrower?.label,
            GrowerID: this.state.selectedGrower?.VendorCode,
            ...(this.state.selectedSubGrower && this.state.selectedSubGrower.value
                ? { GrowerName: this.state.selectedSubGrower.label, GrowerID: this.state.selectedSubGrower.VendorCode }
                : {})
        };
    }
    importReceiptByCostCenterFilter() {
        return {
            PoolWeek: +this.state.selectedPoolWeek?.value,
            BusinessUnit: userBusinessUnit(),
            ReportName: 'ImportReceiptByCostCenter',
            ReportType: ''
        };
    }

    getReceiptCostCenterData(loadCostCenter) {
        this.getReceiptCostCenter = loadCostCenter;
    }
    getImportSettlementData(loadImportSettlement) {
        this.getImportSettlementReport = loadImportSettlement;
    }
    getImportReceiptCostCenterData(loadImportReceiptByCostCenter) {
        this.getImportReceiptByCostCenter = loadImportReceiptByCostCenter;
    }
    getGrowerReceiptData(loadGrowerReceipt) {
        this.getGrowerReceipt = loadGrowerReceipt;
    }
    getShanghaiReceiptData(loadShanghaiReceipt) {
        this.getShanghaiReceipt = loadShanghaiReceipt;
    }
    getJeCcAllocationReceiptData(loadReceipt) {
        this.getJeCcAllocationReceipt = loadReceipt;
    }
    getJeSevenknotReceiptData(loadReceipt) {
        this.getJeSevenknotReceipt = loadReceipt;
    }

    getFilters() {
        let filter = ``;
        if (this.state.selectedCommodity) {
            filter += '&commodity=' + (this.state.selectedCommodity || []).map((itm) => itm.value).join(',');
        }
        if (this.state.selectedItemId) {
            filter += '&itemid=' + this.state.selectedItemId.value;
        }
        if (this.state.selectedGrowerId) {
            filter += '&growerid=' + this.state.selectedGrowerId;
        }
        if (this.state.selectedCurrency) {
            filter += '&settlementcurrency=' + this.state.selectedCurrency.value;
        }
        if (this.state.selectedReportType) {
            filter += '&reporttype=' + this.state.selectedReportType.value;
        }
        if (this.state.selectedVersion) {
            filter += '&version=' + this.state.selectedVersion.value;
        }
        if (this.state.estimateGroup && this.state.estimateGroup.value) {
            filter += '&estimategroup=' + this.state.estimateGroup.value;
        }
        if (this.state.growerCategory && this.state.growerCategory.value) {
            filter += '&category=' + this.state.growerCategory.value;
        }
        if (
            this.state.selectedReport &&
            !['Settlement Report', 'Average Price & Turn Over Rates', 'SummaryDomestic'].includes(this.state.selectedReport.value)
        ) {
            filter += '&reportname=' + this.state.selectedReport.value;
        }
        return filter;
    }

    loadFilteredData() {
        let filters = this.getFilters();
        if (['GrowerSettlementReport', 'SummaryCosta'].includes(this.state.selectedReport.value)) {
            this.getSummaryFilteredData();
        } else if (this.state.selectedReport.value === 'SummaryDomestic') {
            this.getDomesticSummary(filters);
        } else if (this.state.selectedReport.value === 'ReceiptByCostCenter') {
            this.getReceiptCostCenter(filters);
        } else if (this.state.selectedReport.value === 'ReceiptByGrower') {
            this.getGrowerReceipt(filters);
        } else if (this.state.selectedReport.value === 'Shanghai' || this.state.selectedReport.value === 'SH Report') {
            this.getShanghaiReceipt(filters);
        } else if (this.state.selectedReport.value === 'CCAllocation') {
            this.getJeCcAllocationReceipt(filters);
        } else if (['Yunnan', 'Xishuangbanna', 'Qujing', 'Sevenknot'].includes(this.state.selectedReport.value)) {
            this.getJeSevenknotReceipt(filters);
        } else if (['SummaryCNY', 'SummaryPeru', 'SummaryChile'].includes(this.state.selectedReport.value)) {
            this.getSummaryReportFilteredData(filters);
        } else if (this.state.selectedReport.value === 'ImportGrowerSettlement') {
            this.getImportSettlementReport(filters);
        } else if (this.state.selectedReport.value === 'ImportReceiptByCostCenter') {
            this.getImportReceiptByCostCenter(filters);
        } else {
            this.getAvgPriceFilteredData(filters);
        }
        this.getStatus();
    }
    toggleShowFilter = () => this.setState({ showFilter: !this.state.showFilter });

    isFilterDisabled() {
        return (
            (['ItemInventoryOutbound'].includes(this.state.selectedReport?.value) && !('' + (this.state.selectedItemId ?? '')).length) ||
            (['ImportGrowerSettlement', 'GrowerSettlementReport'].includes(this.state.selectedReport?.value) &&
                !(this.state.selectedPoolWeek && this.state.selectedGrower && this.state.selectedReportType)) ||
            ([
                'ReceiptByGrower',
                'Shanghai',
                'SH Report',
                'CCAllocation',
                'Yunnan',
                'Xishuangbanna',
                'Qujing',
                'Sevenknot',
                'SummaryCNY',
                'SummaryPeru',
                'SummaryChile',
                'ImportGrowerSettlement',
                'SummaryDomestic',
                'SummaryCosta'
            ].includes(this.state.selectedReport?.value) &&
                !(this.state.selectedPoolWeek && this.state.selectedReportType))
        );
    }

    handleBatchDownloadReports = async () => {
        try {
            this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
            let token = await this.props.oktaAuth.getAccessToken();
            let response = await Call(
                {
                    name: 'ReportFileKeys',
                    url: APIEndPoints.GET_REPORT_FILE_KEY(
                        this.state.selectedPoolWeek.PoolWeek,
                        this.state.selectedReport.value === 'GrowerSettlementReport' ? 'Domestic' : 'Import',
                        this.state.selectedReportType.value === 'Preliminary' ? 'Prelim' : 'Final',
                        userBusinessUnit()
                    ),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if ((response.errors.length && !response.raw) || response.raw.data.Status === 409) {
                return showError(response?.raw?.data?.Message || response.errors[0]);
            }

            const responseData = response?.raw?.data?.Data;
            this.setState({ totalUrls: response.raw.data.TotalCount });
            if (responseData.length === 0) this.props.showToast(<DrcTranslate>{'No Files Found'}</DrcTranslate>, false);
            else {
                responseData.map((file) => this.downloadReports(file.FileKey)); // to donwload multiple files if there are any
                this.props.showToast(<DrcTranslate>{'Files are preparing to download.'}</DrcTranslate>, true);
            }
            this.props.hideLoadingScreenAction();
        } catch (error) {
            this.props.hideLoadingScreenAction();
            console.log('error : ', error);
        }
    };

    downloadReports = async (fileKey) => {
        try {
            this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
            let token = await this.props.oktaAuth.getAccessToken();
            let response = await Call(
                {
                    name: 'ReportFileKeys',
                    url: `${window.config.BUS_URL}File/Download/${fileKey}`,
                    method: 'GET',
                    options: {}
                },
                token
            );
            if ((response.errors.length && !response.raw) || response.raw.data.Status === 409) {
                return this.showError(response?.raw?.data?.Message || response.errors[0]);
            }
            this.props.hideLoadingScreenAction();
            this.setState({ urls: [...this.state.urls, response.raw.data.url] });
        } catch (error) {
            this.props.hideLoadingScreenAction();
            console.log('error : ', error);
        }
    };

    downloadAllReports = (links) => {
        for (const element of links) {
            let frame = document.createElement('iframe');
            frame.src = element;
            frame['download'] = 1;
            document.body.appendChild(frame);
        }
        this.setState({ urls: [], totalUrls: 0 });
    };

    render() {
        const newFiltersImportGrowerSettlement = this.state.growerOptions ? [allImportGrowerSettelementName, ...this.state.growerOptions] : [];
        const newFiltersGrowerSettlement = this.state.growerOptions ? [allGrowerSettelementName, ...this.state.growerOptions] : [];
        const { classes } = this.props;
        const subGrowers = this.state.selectedGrower
            ? this.state.growerOptions.filter(
                  (grower) => grower.ParentVendorCode === this.state.selectedGrower.VendorCode && grower.Geography === 'International'
              )
            : [];
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        return (
            <DrcWrapper>
                <Stepper
                    history={this.props.history}
                    page="Reports"
                    showBackLink={this.props.showReportStepperBackLink}
                    poolweek={this.state.selectedPoolWeek?.value}
                    backLink={`/SettlementWorkbench/`}
                />
                <Divider />
                <div className={classes.cardContainer}>
                    {this.state.showFilter && (
                        <div className={classes.card}>
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DrcSelect
                                        label={<DrcTranslate>{'Select Report'}</DrcTranslate>}
                                        className={classes.root}
                                        value={this.state.selectedReport}
                                        options={reportOptions}
                                        onChange={(e) =>
                                            this.setState({
                                                selectedReport: e,
                                                selectedCommodity: null,
                                                selectedCurrency: null,
                                                selectedReportType: null,
                                                selectedVersion: null,
                                                estimateGroup: null,
                                                selectedItemId: null,
                                                selectedGrowerId: '',
                                                growerCategory: null,
                                                selectedGrower: null
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DrcSelect
                                        label={<DrcTranslate>{'Select Pool Week'}</DrcTranslate>}
                                        className={classes.root}
                                        value={this.state.selectedPoolWeek}
                                        options={makeOptions(this.props.yearPoolWeeks, 'PoolWeek', 'PoolWeek')}
                                        onChange={(e) => this.setState({ selectedPoolWeek: e })}
                                    />
                                </Grid>
                                {['GrowerSettlementReport'].includes(this.state.selectedReport.value) && (
                                    <>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <DrcSelect
                                                label={<DrcTranslate>{'Grower Name'}</DrcTranslate>}
                                                className={classes.root}
                                                value={this.state.selectedGrower}
                                                options={(newFiltersGrowerSettlement || []).filter((grower) => grower.Geography === 'Domestic')}
                                                onChange={(e) => this.setState({ selectedGrower: e })}
                                                required
                                            />
                                        </Grid>
                                    </>
                                )}
                                {['ImportGrowerSettlement'].includes(this.state.selectedReport.value) && (
                                    <>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <DrcSelect
                                                label={<DrcTranslate>{'Grower Name'}</DrcTranslate>}
                                                className={classes.root}
                                                value={this.state.selectedGrower}
                                                options={(newFiltersImportGrowerSettlement || []).filter(
                                                    (grower) => !grower.ParentVendorCode && grower.Geography === 'International'
                                                )}
                                                onChange={(e) => this.setState({ selectedGrower: e, selectedSubGrower: null })}
                                            />
                                        </Grid>
                                        {this.state.selectedGrower && !!subGrowers.length && (
                                            <Grid item xs={12} sm={6} md={3}>
                                                <DrcSelect
                                                    label={<DrcTranslate>{'Sub-Grower Name'}</DrcTranslate>}
                                                    className={classes.root}
                                                    value={this.state.selectedSubGrower}
                                                    options={subGrowers}
                                                    onChange={(e) => this.setState({ selectedSubGrower: e })}
                                                />
                                            </Grid>
                                        )}
                                    </>
                                )}
                                {['Settlement Report', 'Average Price & Turn Over Rates'].includes(this.state.selectedReport.value) && (
                                    <>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <DrcSelect
                                                label={<DrcTranslate>{'Select Commodity'}</DrcTranslate>}
                                                className={classes.root}
                                                isMulti
                                                value={this.state.selectedCommodity}
                                                options={makeOptions(this.props.commodityLookup, 'LookupCode', 'LookupCode')}
                                                onChange={(e) => this.setState({ selectedCommodity: e })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <DrcSelect
                                                label={<DrcTranslate>{'Report Type'}</DrcTranslate>}
                                                className={classes.root}
                                                value={this.state.selectedReportType}
                                                options={reportTypeOptions}
                                                onChange={(e) => this.setState({ selectedReportType: e })}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {['Settlement Report', 'Average Price & Turn Over Rates', 'ItemInventoryOutbound'].includes(
                                    this.state.selectedReport.value
                                ) && (
                                    <Grid item xs={12} sm={6} md={3}>
                                        <DrcSelect
                                            label={<DrcTranslate>{'Select Item ID'}</DrcTranslate>}
                                            required={!!['ItemInventoryOutbound'].includes(this.state.selectedReport.value)}
                                            className={classes.root}
                                            value={this.state.selectedItemId}
                                            options={
                                                this.state.selectedReport.value === 'ItemInventoryOutbound'
                                                    ? [{ label: 'All', value: 'ALL' }, ...this.state.itemIdOptions]
                                                    : this.state.itemIdOptions
                                            }
                                            onChange={(e) => this.setState({ selectedItemId: e })}
                                        />
                                    </Grid>
                                )}
                                {[
                                    'SummaryDomestic',
                                    'GrowerSettlementReport',
                                    'SummaryCosta',
                                    'ReceiptByGrower',
                                    'Shanghai',
                                    'SH Report',
                                    'CCAllocation',
                                    'Yunnan',
                                    'Xishuangbanna',
                                    'Qujing',
                                    'Sevenknot',
                                    'SummaryCNY',
                                    'SummaryPeru',
                                    'SummaryChile',
                                    'ImportGrowerSettlement'
                                ].includes(this.state.selectedReport.value) && (
                                    <Grid item xs={12} sm={6} md={3}>
                                        <DrcSelect
                                            label={<DrcTranslate>{'Report Type'}</DrcTranslate>}
                                            className={classes.root}
                                            value={this.state.selectedReportType}
                                            options={versionOptions}
                                            onChange={(e) => this.setState({ selectedReportType: e })}
                                            required={
                                                !![
                                                    'GrowerSettlementReport',
                                                    'SummaryCosta',
                                                    'ReceiptByGrower',
                                                    'SummaryCNY',
                                                    'SummaryPeru',
                                                    'SummaryChile',
                                                    'ImportGrowerSettlement',
                                                    'SummaryDomestic'
                                                ].includes(this.state.selectedReport?.value)
                                            }
                                        />
                                    </Grid>
                                )}

                                {this.state.selectedReport.value === 'Settlement Report' && (
                                    <>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <DrcSelect
                                                label={<DrcTranslate>{'Settlement Currency'}</DrcTranslate>}
                                                className={classes.root}
                                                value={this.state.selectedCurrency}
                                                options={currencyOptions}
                                                onChange={(e) => this.setState({ selectedCurrency: e })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <DrcSelect
                                                label={<DrcTranslate>{'Version'}</DrcTranslate>}
                                                className={classes.root}
                                                value={this.state.selectedVersion}
                                                options={versionOptions}
                                                onChange={(e) => this.setState({ selectedVersion: e })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <DrcInput
                                                label={<DrcTranslate>{'Search Grower ID'}</DrcTranslate>}
                                                inputProps={{
                                                    maxLength: 6,
                                                    type: 'number',
                                                    step: 1,
                                                    min: 0,
                                                    max: 999999
                                                }}
                                                className={classes.input}
                                                onChange={(e) => {
                                                    //Items ID is 6 digit number so max value it can have is 999999
                                                    if (e.target.value <= 999999 && e.target.value >= 0)
                                                        this.setState({ selectedGrowerId: e.target.value });
                                                }}
                                                value={this.state.selectedGrowerId}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {this.state.selectedReport.value === 'Average Price & Turn Over Rates' && (
                                    <Grid item xs={12} sm={6} md={3}>
                                        <DrcSelect
                                            label={<DrcTranslate>{'Select Estimate Group'}</DrcTranslate>}
                                            className={classes.root}
                                            value={this.state.estimateGroup}
                                            options={makeOptions(this.props.estimateGroupLookup, 'LookupCode', 'LookupCode')}
                                            onChange={(e) => this.setState({ estimateGroup: e })}
                                        />
                                    </Grid>
                                )}
                                {this.state.selectedReport.value === 'ReceiptByCostCenter' && (
                                    <Grid item xs={12} sm={6} md={3}>
                                        <DrcSelect
                                            label={<DrcTranslate>{'Select Grower Category'}</DrcTranslate>}
                                            className={classes.root}
                                            value={this.state.growerCategory}
                                            options={[
                                                { label: 'ALL', value: 'ALL' },
                                                ...makeOptions(this.props.growerCategoryLookup, 'LookupCode', 'LookupCode')
                                            ]}
                                            onChange={(e) => this.setState({ growerCategory: e })}
                                            isClearable
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                <DrcButton
                                    isPrimary
                                    onClick={() => {
                                        this.loadFilteredData();
                                    }}
                                    disabled={this.isFilterDisabled()}
                                >
                                    <DrcTranslate>{'Apply'}</DrcTranslate>
                                </DrcButton>
                                <DrcButton
                                    onClick={() => {
                                        this.setState({ ...this.initialState });
                                    }}
                                >
                                    <DrcTranslate>{'Reset'}</DrcTranslate>
                                </DrcButton>
                            </div>
                        </div>
                    )}
                </div>
                <div className={classes.actionContainer}>
                    <DrcButton onClick={this.toggleShowFilter}>
                        <FilterListSharp className={classes.icon} />
                        <DrcTranslate>{'Filter'}</DrcTranslate>
                    </DrcButton>
                    <div
                        style={{
                            display:
                                this.state.selectedReport.value === 'GrowerSettlementReport' ||
                                this.state.selectedReport.value === 'ImportGrowerSettlement'
                                    ? 'block'
                                    : 'none'
                        }}
                    >
                        <DrcButton
                            isPrimary
                            onClick={() => {
                                this.handleBatchDownloadReports();
                            }}
                            disabled={!this.state.selectedReportType}
                        >
                            <DrcTranslate>{'Search Reports'}</DrcTranslate>
                        </DrcButton>
                        <DrcButton
                            isPrimary
                            onClick={() => this.downloadAllReports(this.state.urls)}
                            disabled={this.state.urls.length === 0 ? true : this.state.urls.length != this.state.totalUrls}
                        >
                            {this.state.urls.length > 0 && this.state.urls.length != this.state.totalUrls ? (
                                <DrcTranslate>{'Preparing Files...'}</DrcTranslate>
                            ) : (
                                <DrcTranslate>{'Download Reports'}</DrcTranslate>
                            )}
                        </DrcButton>
                    </div>
                </div>
                {/* table starts here */}
                {['GrowerSettlementReport', 'SummaryCosta'].includes(this.state.selectedReport.value) ? (
                    <SettlementRecord
                        poolWeek={this.state.selectedPoolWeek}
                        loadFilteredData={this.getSummaryData.bind(this)}
                        filters={this.getGrowerSummaryFilters()}
                        recordStatus={this.state.settlementRecordStatus}
                    />
                ) : this.state.selectedReport.value === 'SummaryDomestic' ? (
                    <DomesticSummary
                        poolWeek={this.state.selectedPoolWeek}
                        loadFilteredData={this.getDomesticSummaryData.bind(this)}
                        filters={{ ...this.getGrowerSummaryFilters(), ReportName: 'SummaryDomestic' }}
                        recordStatus={this.state.settlementRecordStatus}
                    />
                ) : this.state.selectedReport.value === 'ReceiptByCostCenter' ? (
                    <ReceiptCostCenter
                        poolWeek={this.state.selectedPoolWeek}
                        loadFilteredData={this.getReceiptCostCenterData.bind(this)}
                        filters={this.receiptCostCenterFilter()}
                        recordStatus={this.state.settlementRecordStatus}
                    />
                ) : this.state.selectedReport.value === 'ReceiptByGrower' ? (
                    <ReceiptByGrower
                        poolWeek={this.state.selectedPoolWeek}
                        loadFilteredData={this.getGrowerReceiptData.bind(this)}
                        filters={this.receiptByGrowerFilter()}
                        recordStatus={this.state.settlementRecordStatus}
                    />
                ) : this.state.selectedReport.value === 'Shanghai' ? (
                    <JEShanghai
                        poolWeek={this.state.selectedPoolWeek}
                        loadFilteredData={this.getShanghaiReceiptData.bind(this)}
                        filters={this.journalFilters()}
                    />
                ) : this.state.selectedReport.value === 'SH Report' ? (
                    <JESHReport
                        poolWeek={this.state.selectedPoolWeek}
                        loadFilteredData={this.getShanghaiReceiptData.bind(this)}
                        filters={this.journalFilters()}
                    />
                ) : this.state.selectedReport.value === 'CCAllocation' ? (
                    <JECCAllocation
                        poolWeek={this.state.selectedPoolWeek}
                        loadFilteredData={this.getJeCcAllocationReceiptData.bind(this)}
                        filters={this.journalFilters()}
                    />
                ) : ['Yunnan', 'Xishuangbanna', 'Qujing', 'Sevenknot'].includes(this.state.selectedReport.value) ? (
                    <JeSevenknot
                        poolWeek={this.state.selectedPoolWeek}
                        loadFilteredData={this.getJeSevenknotReceiptData.bind(this)}
                        filters={this.journalFilters()}
                        reportType={this.state.selectedReport}
                    />
                ) : ['SummaryChile', 'SummaryPeru', 'SummaryCNY'].includes(this.state.selectedReport.value) ? (
                    <SummaryReportsCR
                        poolWeek={this.state.selectedPoolWeek}
                        loadFilteredData={this.getSummaryReportData.bind(this)}
                        filters={this.journalFilters()}
                        reportType={this.state.selectedReport}
                    />
                ) : this.state.selectedReport.value === 'ImportGrowerSettlement' ? (
                    <ImportSettlementReport
                        poolWeek={this.state.selectedPoolWeek}
                        loadFilteredData={this.getImportSettlementData.bind(this)}
                        filters={this.importSettlementReportFilter()}
                        recordStatus={this.state.settlementRecordStatus}
                    />
                ) : this.state.selectedReport.value === 'ImportReceiptByCostCenter' ? (
                    <ImportReceiptByCostCenter
                        poolWeek={this.state.selectedPoolWeek}
                        loadFilteredData={this.getImportReceiptCostCenterData.bind(this)}
                        filters={this.importReceiptByCostCenterFilter()}
                        recordStatus={this.state.settlementRecordStatus}
                    />
                ) : (
                    <AveragePriceReport
                        poolWeek={this.state.selectedPoolWeek}
                        loadFilteredData={this.getAvgPriceReportData.bind(this)}
                        filters={this.getFilters()}
                        recordStatus={this.state.settlementRecordStatus}
                        reportType={this.state.selectedReport}
                    />
                )}
            </DrcWrapper>
        );
    }
}

const mapStateToProps = ({ masterReducer, filterReducer, rootReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    pageTitle: rootReducer.pageTitle,
    currentPoolWeek: masterReducer.currentPoolWeek,
    yearPoolWeeks: masterReducer.yearPoolWeeks,
    commodityLookup: masterReducer[LOOKUP_FIELDS.CommodityOffsetWeekLookUp],
    estimateGroupLookup: masterReducer[LOOKUP_FIELDS.EstimateGroupLookUp],
    growerCategoryLookup: masterReducer[LOOKUP_FIELDS.GrowerCategoryLookUp],
    selectedFilters: filterReducer.selectedFilters,
    showReportStepperBackLink: rootReducer.showReportBackLink,
    userLang: masterReducer.userLang
});
const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    setFilters: (name, option) => dispatch(setFilters(name, option)),
    clearFilters: (message, type) => dispatch(clearFilters(message, type)),
    clearOneFilter: (filter) => dispatch(clearOneFilter(filter)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withStyles(styles)(Reports)));
