import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { withStyles } from '@mui/styles';

/**
 *@ignore
 */
var styles = (theme) => ({
    root: {
        padding: theme.spacing(2, 2),
        minWidth: '300px',
        color: theme.light.text.primary,
        backgroundColor: theme.light.primary,
        [theme.darkTheme]: {
            color: theme.dark.text.primary,
            backgroundColor: theme.dark.primary,
            border: '1px solid ' + theme.dark.buttonBorder,
            borderRadius: '5px'
        }
    },
    title: {
        fontSize: '1.5rem',
        marginBottom: theme.spacing(1),
        padding: theme.spacing(0, 0, 1),
        color: theme.light.accent.primary,
        '& h2': {
            fontSize: '1.5rem'
        },
        [theme.darkTheme]: {
            color: theme.dark.accent.primary
        }
    },
    titleError: {
        color: theme.light.text.errorTitle,
        [theme.darkTheme]: {
            color: theme.dark.text.errorTitle
        }
    },
    actions: {
        borderTop: '1px solid ' + theme.dark.buttonBorder + theme.important,
        margin: 0,
        marginTop: theme.spacing(1),
        padding: theme.spacing(1, 0, 0)
    },
    actionWrapper: {
        margin: theme.spacing(-1)
    },
    fullActionWrapper: {
        margin: theme.spacing(-1, 0),
        width: '100%'
    }
});

/**
 * @description Dialog window with customizable buttons sent as children
 * @property {*} buttons elements sent as action buttons
 * @property {String} title title of the dialog window
 * @property {*} children elements send as content of the dialog window
 * @property {Boolean} open flag to open the dialog
 * @property {*} onClose callback handler on close
 * @property {isError} flag to indicate if it is an error to customize CSS
 * @property {Boolean} isConfirmation If true, clicking the backdrop or hitting escape will not fire the onClose callback.
 * @property {Boolean} fullActionWrapper will make width 100%
 * @example
 * <DrcDialog
        isError={this.isError}
        title={"Common Dialog"}
        open={this.showDialog}
        buttons={
            <DrcButton poly line isError onClick={this.closeDialog}>
                OK
            </DrcButton>
        }
    >
        <div>Dialog Content</div>
    </DrcDialog>
 * @donotprint true
 * @category Layout
 * @tags layout dialog popup modal show close hide
 * @class DrcDialog
 * @extends {React.Component}
 */
class DrcDialog extends React.Component {
    /**
     *
     * @private
     * @return {*}
     * @memberof DrcDialog
     */
    render() {
        const { buttons, title, children, open, onClose, isError, classes, className, isConfirmation, fullActionWrapper } = this.props;

        return (
            <Dialog disableBackdropClick={isConfirmation} disableEscapeKeyDown={isConfirmation} open={open} onClose={onClose} className={className}>
                <div className={classes.root}>
                    <DialogTitle className={`${classes.title} ${isError ? classes.titleError : ''}`}>{title}</DialogTitle>
                    <div>{children}</div>
                    <DialogActions className={classes.actions}>
                        <div className={fullActionWrapper ? classes.fullActionWrapper : classes.actionWrapper}>{buttons}</div>
                    </DialogActions>
                </div>
            </Dialog>
        );
    }
}

export default withStyles(styles)(DrcDialog);
