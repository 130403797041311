import { DrcTranslate } from 'drc/driscolls-react-components/index';
import React from 'react';

export default function NoData() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', fontWeight: 'bold' }}>
            <DrcTranslate>{'No Data To Show'}</DrcTranslate>
        </div>
    );
}
