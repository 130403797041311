import { createContext } from 'react';
import { IAssignableCriteriaList, IBusinesRuleType } from 'types/BusinesRuleType';

interface IAppContext {
    assignmentListForItems: IAssignableCriteriaList;
    setAssignmentListForItems: (list: IAssignableCriteriaList) => void;
    businessRuleTypes: IBusinesRuleType[];
    setBusinessRuleTypes: (list: IBusinesRuleType[]) => void;
}

const ApplicationContext = createContext<IAppContext>({} as IAppContext);

export default ApplicationContext;
