import { CellContext } from '@tanstack/react-table';
import { DrcIcons } from 'drc/driscolls-react-components';

export const formulaColumns = [
    {
        accessorKey: 'RuleId',
        header: 'Rule ID',
        enableColumnFilter: true
    },
    {
        accessorKey: 'VersionId',
        header: 'Version Id',
        enableColumnFilter: true
    },
    {
        accessorKey: 'FormulaSeq',
        header: 'Formula Seq',
        enableColumnFilter: true
    },
    {
        accessorKey: 'FormulaType',
        header: 'Formula Type',
        enableColumnFilter: true
    },
    {
        accessorKey: 'CurrencyCode',
        header: 'Currency Code',
        enableColumnFilter: true
    },
    {
        accessorKey: 'FormulaValue',
        header: 'Formula Value',
        enableColumnFilter: true
    },
    {
        accessorKey: 'CreatedBy',
        header: 'Created By',
        enableColumnFilter: true
    },
    {
        accessorKey: 'CreatedDateTime',
        header: 'Created On',
        enableColumnFilter: true
    },
    {
        accessorKey: 'ModifiedBy',
        header: 'Modified By',
        enableColumnFilter: true
    },
    {
        accessorKey: 'ModifiedDateTime',
        header: 'Modified On',
        enableColumnFilter: true
    }
];

export const ruleDetailsColumns = [
    {
        accessorKey: 'RuleId',
        header: 'Rule ID',
        enableColumnFilter: true
    },
    {
        accessorKey: 'RuleTypeId',
        header: 'Rule Type Id',
        enableColumnFilter: true
    },
    {
        accessorKey: 'RuleName',
        header: 'Rule Name',
        enableColumnFilter: true
    },
    {
        accessorKey: 'VersionId',
        header: 'Version Id',
        enableColumnFilter: true
    },
    {
        accessorKey: 'UOM',
        header: 'UOM',
        enableColumnFilter: true
    },
    {
        accessorKey: 'PFQFlag',
        header: 'PFQ Flag',
        enableColumnFilter: true
    },
    {
        accessorKey: 'StartDate',
        header: 'Start Date',
        enableColumnFilter: true
    },
    {
        accessorKey: 'EndDate',
        header: 'End Date',
        enableColumnFilter: true
    },
    {
        accessorKey: 'Status',
        header: 'Status',
        enableColumnFilter: true
    },
    {
        accessorKey: 'Approver',
        header: 'Approver',
        enableColumnFilter: true
    },
    {
        accessorKey: 'CreatedBy',
        header: 'Created By',
        enableColumnFilter: true
    },
    {
        accessorKey: 'CreatedDateTime',
        header: 'Created On',
        enableColumnFilter: true
    },
    {
        accessorKey: 'ModifiedBy',
        header: 'Modified By',
        enableColumnFilter: true
    },
    {
        accessorKey: 'ModifiedDateTime',
        header: 'Modified On',
        enableColumnFilter: true
    }
];

export const itemAssignmentColumns = [
    {
        accessorKey: 'RuleId',
        header: 'Rule ID',
        enableColumnFilter: true
    },
    {
        accessorKey: 'ItemNbr',
        header: 'Item Nbr',
        enableColumnFilter: true
    },
    {
        accessorKey: 'ItemName',
        header: 'Item Name',
        enableColumnFilter: true
    },
    {
        accessorKey: 'BerryType',
        header: 'Berry Type',
        enableColumnFilter: true,
        cell: (info) => {
            const infoCasted = info as CellContext<any, string>;
            return DrcIcons.GetSmallBerryIcon(infoCasted.getValue());
        }
    },
    {
        accessorKey: 'FieldType',
        header: 'Field Type',
        enableColumnFilter: true
    },
    {
        accessorKey: 'VersionId',
        header: 'Version Id',
        enableColumnFilter: true
    },
    {
        accessorKey: 'CreatedBy',
        header: 'Created By',
        enableColumnFilter: true
    },
    {
        accessorKey: 'CreatedDateTime',
        header: 'Created On',
        enableColumnFilter: true
    },
    {
        accessorKey: 'ModifiedBy',
        header: 'Modified By',
        enableColumnFilter: true
    },
    {
        accessorKey: 'ModifiedDateTime',
        header: 'Modified On',
        enableColumnFilter: true
    }
];

export const ranchAssignmentColumns = [
    // {
    //     accessorKey: 'Action',
    //     header: 'Action',
    //     enableColumnFilter: true,
    //     size: 10
    // },
    {
        accessorKey: 'RanchId',
        header: 'Ranch Id',
        enableColumnFilter: true
    },
    {
        accessorKey: 'RanchNbr',
        header: 'RanchNbr',
        enableColumnFilter: true
    },
    {
        accessorKey: 'GrowerNbr',
        header: 'Grower Nbr',
        enableColumnFilter: true
    },
    {
        accessorKey: 'GrowerName',
        header: 'Grower Name',
        enableColumnFilter: true
    },
    {
        accessorKey: 'ProducingAreaCode',
        header: 'Producing AreaCode',
        enableColumnFilter: true
    },
    {
        accessorKey: 'ProducingAreaName',
        header: 'Producing AreaName',
        enableColumnFilter: true
    },
    {
        accessorKey: 'VersionId',
        header: 'Version Id',
        enableColumnFilter: true
    },
    {
        accessorKey: 'CreatedBy',
        header: 'Created By',
        enableColumnFilter: true
    },
    {
        accessorKey: 'CreatedDateTime',
        header: 'Created On',
        enableColumnFilter: true
    },
    {
        accessorKey: 'ModifiedBy',
        header: 'Modified By',
        enableColumnFilter: true
    },
    {
        accessorKey: 'ModifiedDateTime',
        header: 'Modified On',
        enableColumnFilter: true
    }
];

export const historyTableTabs: any[] = [
    {
        value: 'RuleDetail',
        label: 'Rule Detail'
    },
    {
        value: 'Formula',
        label: 'Formula'
    },
    {
        value: 'RanchAssignment',
        label: 'Ranch Assignment'
    },
    {
        value: 'ItemAssignment',
        label: 'Item Assignment'
    }
];
