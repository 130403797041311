import React from 'react';
import { withStyles } from '@mui/styles';
import { DuIconSrc } from '@driscollsinc/driscolls-react-utilities';

/**
 * @description return unordered list tag of the list item with berry image as custom style
 * @property {*} children list that needs to be custom styled
 * @property {String} type berry type
 * @example
 * <DrcList
 *      type={"BLUE"}
 * >
 *      <li>List Item 1</li>
 *      <li>List Item 2</li>  *
 * </DrcList>
 * @category Tables
 * @tags list table pretty berries
 * @class DrcList
 * @extends {React.Component}
 */
class DrcList extends React.Component {
    BerryType = (this.props.type || 'blue').toLowerCase();

    berryImage =
        this.BerryType === 'black'
            ? DuIconSrc.Black_Small_Png
            : this.BerryType === 'blue'
            ? DuIconSrc.Blue_Small_Png
            : this.BerryType === 'rasp'
            ? DuIconSrc.Rasp_Small_Png
            : DuIconSrc.Straw_Small_Png;

    /**
     *@ignore
     */
    styles = (theme) => ({
        list: {
            '& li': {
                listStyleImage: "url('" + this.berryImage + "')",
                padding: theme.spacing(0.25, 0),
                '& span': {
                    verticalAlign: 'top',
                    lineHeight: '23px',
                    display: 'inline-block'
                }
            }
        }
    });

    ul = (props) => <ul className={props.classes.list}>{props.children}</ul>;

    /**
     * @description styles the list item tag
     * @memberof DrcList
     */
    Styled = withStyles(this.styles)(this.ul);

    /**
     *
     * @returns {*}
     * @memberof DrcList
     */
    render() {
        const { children } = this.props;
        return <this.Styled>{children}</this.Styled>;
    }
}

export default DrcList;
