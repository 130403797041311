import React, { Fragment } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import ExpandedRowDetail from './ExpandedRowDetail';
import { designColors, getAccordionColor, statusTypes } from '../data/constants';
import NoData from './NoData';
import Checkbox from '@mui/material/Checkbox';
import { DrcIconButton, DrcTranslate } from 'drc/driscolls-react-components/index';
import DescriptionIcon from '@mui/icons-material/Description';
import { showReportBackLinkAction } from '../actions/actions';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'routes/withRouter';

const useStyles = makeStyles((theme) => ({
    legendItem: {
        '&': {
            color: theme.dark.primary,
            [theme.darkTheme]: {
                color: `${theme.light.primary} !important`
            },
            fontSize: 'x-small',
            fontWeight: 'bold',
            marginRight: 8,
            display: 'inline-block'
        },
        '&::before': {
            content: '""',
            display: 'inline-block',
            position: 'relative',
            minWidth: '12px',
            minHeight: '12px',
            borderRadius: '100%',
            marginRight: '5px',
            top: '2.5px'
        },
        '&[legendType=Approved]::before': {
            backgroundColor: getAccordionColor(theme, 'Approved')
        },
        '&[legendType="Not Run"]::before': {
            backgroundColor: getAccordionColor(theme, 'Not Run')
        },
        '&[legendType="Pending Approval"]::before': {
            backgroundColor: getAccordionColor(theme, 'Pending Approval')
        },
        '&[legendType="Processing"]::before': {
            backgroundColor: getAccordionColor(theme, 'Processing')
        },
        '&[legendType="Processed"]::before': {
            backgroundColor: getAccordionColor(theme, 'Processed')
        },
        '&[legendType="Rejected"]::before': {
            backgroundColor: getAccordionColor(theme, 'Rejected')
        },
        '&[legendType="Failed"]::before': {
            backgroundColor: getAccordionColor(theme, 'Failed')
        },
        '&[legendType="Published"]::before': {
            backgroundColor: getAccordionColor(theme, 'Published')
        },
        '&[legendType="Partially Published"]::before': {
            backgroundColor: getAccordionColor(theme, 'Partially Published')
        }
    },
    root: {
        width: '100%',
        // overflowX: 'auto',
        padding: '0 24px 0 24px'
    },
    table: {
        // minWidth: 650
        borderRadius: '4px',
        border: 'solid 0.5px #efefef'
    },
    tableRoot: {
        '& .MuiTableCell-head': {
            fontFamily: 'Rubik',
            backgroundColor: `${designColors.grey} !important`,
            [theme.darkTheme]: {
                backgroundColor: `${theme.palette.grey['600']} !important`
            }
        }
    },
    tableRootEven: {
        '& .MuiTableCell-body': {
            backgroundColor: `${theme.light.grid.even} !important`,
            [theme.darkTheme]: {
                backgroundColor: `${theme.palette.grey['800']} !important`
            }
        }
    },
    tableRootOdd: {
        '& .MuiTableCell-body': {
            backgroundColor: `${theme.light.grid.odd} !important`,
            [theme.darkTheme]: {
                backgroundColor: `${theme.palette.grey['800']} !important`
            }
        }
    },
    container: {
        // maxHeight: 440
        fontFamily: 'Rubik'
    },
    header: {
        fontWeight: 'bold',
        backgroundColor: designColors.grey,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.grey['800']} !important`,
            color: theme.palette.common.black
        }
    }
}));

const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    return (
        <>
            <TableRow {...otherProps} onClick={() => setIsExpanded(!isExpanded)}>
                <TableCell style={{ width: '40px', padding: 0 }}>
                    <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                        {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {children}
            </TableRow>
            {isExpanded && <>{expandComponent(setIsExpanded)}</>}
        </>
    );
};

function AccordionTable(props) {
    const theme = useTheme();
    const classes = useStyles(theme);

    const { selectedRows = [], handleCheckboxSelect = () => null } = props;

    const { isCheckboxDisabled = () => null } = props;
    return (
        <div className={classes.root}>
            <Fragment>
                <TableContainer className={classes.container}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow className={classes.tableRoot}>
                                <TableCell style={{ width: '40px', padding: 0 }}> </TableCell>
                                <TableCell padding="checkbox" style={{ paddingLeft: 10 }}></TableCell>
                                {(props.columns || []).map((col, indx) => (
                                    <TableCell
                                        key={indx}
                                        style={{
                                            width: col.width,
                                            ...(indx + 1 < (props.columns || []).length ? { borderRight: '1px solid rgba(224, 224, 224, 1)' } : {})
                                        }}
                                        className={classes.header}
                                    >
                                        <DrcTranslate>{col.name}</DrcTranslate>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!(props.data || []).length && (
                                <TableRow>
                                    <TableCell colSpan="7">
                                        <NoData />
                                    </TableCell>
                                </TableRow>
                            )}
                            {(props.data || []).map((row, indx) => (
                                <ExpandableTableRow
                                    indx={indx}
                                    className={`${classes.tableRoot} ${indx % 2 ? classes.tableRootEven : classes.tableRootOdd}`}
                                    key={indx}
                                    expandComponent={(setIsExpanded) => (
                                        <TableRow className={`${indx % 2 ? classes.tableRootEven : classes.tableRootOdd}`}>
                                            <TableCell colSpan="1"></TableCell>
                                            <TableCell colSpan="7">
                                                <ExpandedRowDetail data={row} setIsExpanded={setIsExpanded} onLoad={props.onLoad} />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                >
                                    <TableCell className="selectCheckbox" padding="checkbox">
                                        <Box
                                            component="span"
                                            // mr={1}
                                            style={{
                                                padding: '8px 0',
                                                border: `4px solid ${getAccordionColor(theme, row.Status)}`,
                                                borderRadius: 8,
                                                backgroundColor: `${getAccordionColor(theme, row.Status)}`
                                            }}
                                        ></Box>
                                        {props.rowSelect && (
                                            <Checkbox
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleCheckboxSelect(event.target.checked, row);
                                                }}
                                                disabled={isCheckboxDisabled(row)}
                                                className="selectCheckbox"
                                                checked={selectedRows.includes(row.PricingPoolID)}
                                            />
                                        )}
                                    </TableCell>
                                    {(props.columns || []).map((col, index) => (
                                        <TableCell
                                            key={index}
                                            style={{
                                                width: col.width,
                                                ...(index + 1 < (props.columns || []).length
                                                    ? { borderRight: '1px solid rgba(224, 224, 224, 1)' }
                                                    : {}),
                                                padding: '0px 16px'
                                            }}
                                        >
                                            {col.key === 'Reports' ? (
                                                <DrcIconButton
                                                    isText
                                                    onClick={() => {
                                                        props.showReportBackLinkAction(true);
                                                        props.router.history.push({
                                                            pathname: `/Reports/`,
                                                            state: [{ PoolWeek: props.selectedPoolWeek.PoolWeek }]
                                                        });
                                                    }}
                                                    disabled={row.Status === 'Not Run' || row.Status === 'Processing' || row.Status === 'Failed'}
                                                >
                                                    <DescriptionIcon
                                                        style={
                                                            row.Status === 'Not Run' || row.Status === 'Processing' || row.Status === 'Failed'
                                                                ? {}
                                                                : { color: `${designColors.blue}` }
                                                        }
                                                    />
                                                </DrcIconButton>
                                            ) : (
                                                row[col.key]
                                            )}
                                        </TableCell>
                                    ))}
                                </ExpandableTableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={7}>
                                    {statusTypes.map((itm, index) => (
                                        <span key={index} className={classes.legendItem} legendType={itm}>
                                            <DrcTranslate>{itm}</DrcTranslate>
                                        </span>
                                    ))}
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Fragment>
        </div>
    );
}

const mapStateToProps = ({ weeklySettlementStatusReducer }) => ({
    selectedPoolWeek: weeklySettlementStatusReducer.selectedPoolWeek
});
export default connect(mapStateToProps, {
    showReportBackLinkAction
})(withOktaAuth(withRouter(AccordionTable)));
