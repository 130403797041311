import React from 'react';
import { DrcTranslate } from 'drc/driscolls-react-components/index';
import { designColors } from '../../data/constants';
import { Typography } from '@mui/material';
import { DuDateUtilities } from '@driscollsinc/driscolls-react-utilities';
import Box from '@mui/material/Box';
export default function HeaderDate(props) {
    return (
        <Box>
            {props.recordStatus.SubmittedBy && (
                <Box>
                    <Typography variant="subtitle2" noWrap display="inline">
                        <DrcTranslate>{'Submitted By'}</DrcTranslate>
                    </Typography>
                    <Typography variant="subtitle2" noWrap display="inline" style={{ color: designColors.blue }}>
                        {' ' + props.recordStatus.SubmittedBy}
                    </Typography>
                    <Typography variant="subtitle2" noWrap display="inline" style={{ marginLeft: 8 }}>
                        <DrcTranslate>{'Submitted Date'}</DrcTranslate>
                    </Typography>
                    <Typography variant="subtitle2" noWrap display="inline" style={{ color: designColors.blue }}>
                        {' ' + DuDateUtilities.FormatDateTimeFromIso(props.recordStatus.SubmittedDate)}
                    </Typography>
                </Box>
            )}
            {(props.recordStatus.Status === 'Approved' || props.recordStatus.Status === 'Rejected') && (
                <Box>
                    <Typography variant="subtitle2" noWrap display="inline">
                        {props.recordStatus.Status === 'Rejected' ? (
                            <DrcTranslate>{'Rejected By: '}</DrcTranslate>
                        ) : (
                            <DrcTranslate>{'Approved By: '}</DrcTranslate>
                        )}
                    </Typography>
                    <Typography variant="subtitle2" noWrap display="inline" style={{ color: designColors.blue }}>
                        {props.recordStatus.ApprovedBy}
                    </Typography>
                    <Typography variant="subtitle2" noWrap display="inline" style={{ marginLeft: 8 }}>
                        {props.recordStatus.Status === 'Rejected' ? (
                            <DrcTranslate>{'Rejected Date: '}</DrcTranslate>
                        ) : (
                            <DrcTranslate>{'Approved Date: '}</DrcTranslate>
                        )}
                    </Typography>
                    <Typography variant="subtitle2" noWrap display="inline" style={{ color: designColors.blue }}>
                        {DuDateUtilities.FormatDateTimeFromIso(props.recordStatus.ApprovedDate)}
                    </Typography>
                </Box>
            )}
        </Box>
    );
}
