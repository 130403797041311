import React, { Component } from 'react';
import { withStyles, withTheme } from '@mui/styles';
import Select, { createFilter } from 'react-select';
import WindowedSelect from 'react-windowed-select';
import CreatableSelect from 'react-select/creatable';

/**
 *@ignore
 */
var styles = (theme) => ({
    root: {
        height: 35,
        margin: '5px 0 12px', //This is confirmed to work in the filters, if this needs changing we need to take a look at this and work on it a bit more
        paddingTop: '2px',
        '&.multi': {
            height: 'auto',
            marginBottom: -2
        },
        '& .select > div:first-of-type': {
            minHeight: 35
        },
        '&.multi .select > div:first-of-type': {
            height: 'auto'
        },
        '& .select': {
            top: -15
        }
    },
    label: {
        backgroundColor: theme.light.primary,
        color: theme.light.text.primary + (theme.light.text.primary.includes('#') ? (theme.light.text.primary.length > 5 ? 'aa' : 'a') : ''),
        fontSize: '.75rem',
        padding: '2px 4px',
        marginLeft: '10px',
        position: 'relative',
        top: '-8px',
        zIndex: 2,
        [theme.darkTheme]: {
            backgroundColor: theme.dark.primary,
            color: theme.dark.text.primary
        },
        '& span': {
            fontSize: '.75rem',
            color: theme.light.text.primary + (theme.light.text.primary.includes('#') ? (theme.light.text.primary.length > 5 ? 'aa' : 'a') : ''),
            [theme.darkTheme]: {
                color: theme.dark.text.primary + (theme.dark.text.primary.includes('#') ? (theme.dark.text.primary.length > 5 ? 'aa' : 'a') : '')
            }
        }
    },
    labelError: {
        color: theme.light.text.errorTitle + theme.important,
        [theme.darkTheme]: {
            color: theme.dark.text.errorTitle + theme.important
        }
    },
    pError: {
        margin: '-5px 12px 0',
        fontSize: '0.75rem',
        lineHeight: '1em',
        letterSpacing: '0.03333em',
        color: theme.light.text.errorTitle,
        [theme.darkTheme]: {
            color: theme.dark.text.errorTitle
        }
    }
});

/**
 *@ignore
 */
const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        position: 'absolute'
    }),
    control: (provided, state) => ({
        ...provided,
        outline: 'none',
        height: '35px',
        width: '100%',
        backgroundColor: 'transparent'
    }),
    menuPortal: (provided, state) => ({
        ...provided,
        zIndex: '1400'
    })
};

/**
 *
 * @description select all option
 * @constant SELECT_ALL_OPTION
 * @memberof DrcSelect
 */
const SELECT_ALL_OPTION = {
    label: 'Select ALL',
    value: 'ALL'
};

/**
 *
 * @description checks for dark mode
 * @constant colorSchemeWatch
 * @memberof DrcSelect
 */
var colorSchemeWatch = window.matchMedia('(prefers-color-scheme: dark)');

/**
 * @description DrcSelect is a styled Dropdown list in the Driscolls format and is customizable
 * @class DrcSelect
 * @example
 * let options = [{label :"option1", value: "value1"}, {label: "option2", value: "value2"}];
 * <DrcSelect label={'Ranch Number'} hiddenLabel={false} isCreatable={true} required={true}/>
 * @category Input
 * @tags input select multi typeahead important
 * @property {array} children options
 * @property {string} label name
 * @property {array} options options
 * @property {string} name name
 * @property {json} value value
 * @property {string} helperText helper text
 * @property {string} hideLabel hides label
 * @property {boolean} isCreatable creates option
 * @property {boolean} isWindowedSelect to render huge options list
 * @property {boolean} isMulti select multiple options from the list
 * @property {boolean} ignoreAccents ignoreAccents
 * @property {boolean} closeMenuOnSelect closes the menu on selection
 * @property {boolean} required required field
 * @property {json} theme theme
 * @property {json} className className
 * @extends {React.Component} */
class DrcSelect extends Component {
    /**
     * Creates an instance of DrcSelect.
     * @param {*} props
     * @memberof DrcSelect
     *
     */
    constructor(props) {
        super(props);

        this.state = {
            styleKey: '',
            styles: {}
        };

        this.matchMediaWatch = this.matchMediaWatch.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    /**
     *
     * @description method to check for dark mode
     * @param {*} e
     * @memberof DrcSelect
     */
    matchMediaWatch(e) {
        var isDarkTheme = e.matches;
        var isError = (this.props.helperText || '').length > 0;
        var styleKey = isDarkTheme.toString() + isError.toString();

        if (this.state.styleKey !== styleKey) {
            this.onLoad();
        }
    }

    /**
     *
     * @description method to apply styles for dark mode or light mode accordingly
     * @memberof DrcSelect
     */
    onLoad() {
        var isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
        var isError = (this.props.helperText || '').length > 0;
        var styleKey = isDarkTheme.toString() + isError.toString();

        if (this.state.styleKey !== styleKey) {
            const { theme } = this.props;

            var styles = {
                primary25: isDarkTheme ? theme.dark.accent.brightness(-10) : theme.light.accent.brightness(10),
                primary: isDarkTheme ? theme.dark.accent.primary : theme.light.accent.primary,
                neutral0: isDarkTheme ? theme.dark.primary : theme.light.primary, //option background
                neutral5: isDarkTheme ? theme.dark.variant.primary : theme.light.variant.primary, //Unknown
                neutral10: isDarkTheme ? theme.dark.secondary : theme.light.secondary, //multi-item-box
                neutral20: isError ? (isDarkTheme ? theme.dark.error : theme.light.error) : isDarkTheme ? theme.dark.buttonBorder : theme.light.buttonBorder, //Border
                neutral30: isError ? (isDarkTheme ? theme.dark.error : theme.light.error) : isDarkTheme ? theme.dark.text.primary : theme.light.text.primary, //Border Hover
                neutral80: isDarkTheme ? theme.dark.text.primary : theme.light.text.primary, //Text
                dangerLight: isDarkTheme ? theme.dark.buttonBorder : theme.light.buttonBorder, //multi-clear-btn
                danger: isDarkTheme ? theme.dark.text.primary : theme.light.text.primary //multi-clear-x
            };

            this.setState({ styleKey, styles });
        }
    }

    /**
     *@ignore
     */
    componentDidMount() {
        colorSchemeWatch.addListener(this.matchMediaWatch);
        this.onLoad();
    }

    /**
     *@ignore
     */
    componentDidUpdate() {
        this.onLoad();
    }

    /**
     *@ignore
     */
    componentWillUnmount() {
        colorSchemeWatch.removeListener(this.matchMediaWatch);
    }

    /**
     *
     * @description method to handle selection
     * @param {*} option
     * @param {*} actionType
     * @memberof DrcSelect
     */
    onChange(option, actionType) {
        if (this.props.filterChanged) {
            this.props.filterChanged({ value: option.value, label: option.label, type: this.props.name });
        }

        if (this.props.onChange) {
            if (this.props.selectAllAllowed) {
                if (option !== null && option.length > 0 && option[option.length - 1].label === SELECT_ALL_OPTION.label) {
                    this.props.onChange(this.props.options || this.props.children || [], actionType);
                } else {
                    this.props.onChange(option, actionType);
                }
            } else {
                this.props.onChange(option, actionType);
            }
        }
    }

    /**
     *
     *
     * @return {*}
     * @memberof DrcSelect
     */
    render() {
        const {
            className,
            children,
            options,
            label,
            onChange,
            value,
            selectStyle,
            helperText,
            hideLabel,
            classes,
            theme,
            isCreatable,
            isWindowedSelect,
            isMulti,
            required,
            ignoreAccents,
            closeMenuOnSelect,
            ...other
        } = this.props;

        var isError = (helperText || '').length > 0;
        var title = label || this.props.name;
        var name = this.props.name || label;
        var id;
        if (typeof title === 'object') {
            var child = title?.props?.children;
            id = typeof child === 'string' ? child.replace(/ /gi, '') || 'Unknown' : 'Unknown';
        } else {
            id = title.replace(/ /gi, '') || 'Unknown';
        }
        id = id[0].toLowerCase() + id.substring(1) + 'Lbl';

        var selectOptions = options || children || [];

        return (
            <div className={`${classes.root} ${isMulti ? 'multi' : ''} ${className || ''}`}>
                {!hideLabel ? (
                    <label className={`${classes.label} ${isError ? classes.labelError : ''}`} id={id}>
                        {required ? (
                            <span>
                                {title} <span className={classes.labelError}>*</span>
                            </span>
                        ) : (
                            title
                        )}
                    </label>
                ) : null}
                {!isCreatable ? (
                    isWindowedSelect ? (
                        <WindowedSelect
                            name={name}
                            aria-labelledby={id}
                            value={value}
                            onChange={this.onChange}
                            options={selectOptions}
                            className="select"
                            styles={customStyles}
                            menuPortalTarget={document.body}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    ...this.state.styles
                                }
                            })}
                            filterOption={createFilter({ ignoreAccents: false })}
                            isMulti={isMulti}
                            {...other}
                        />
                    ) : (
                        <Select
                            name={name}
                            aria-labelledby={id}
                            value={value}
                            onChange={this.onChange}
                            options={!!this.props.selectAllAllowed ? [SELECT_ALL_OPTION, ...selectOptions] : selectOptions}
                            className="select"
                            styles={customStyles}
                            menuPortalTarget={document.body}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    ...this.state.styles
                                }
                            })}
                            isMulti={isMulti}
                            closeMenuOnSelect={closeMenuOnSelect || !isMulti}
                            {...other}
                        />
                    )
                ) : (
                    <CreatableSelect
                        name={name}
                        aria-labelledby={id}
                        value={value}
                        onChange={this.onChange}
                        options={selectOptions}
                        className="select"
                        styles={customStyles}
                        menuPortalTarget={document.body}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                ...this.state.styles
                            }
                        })}
                        isMulti={isMulti}
                        {...other}
                    />
                )}
                {isError ? <p className={classes.pError}>{helperText}</p> : null}
            </div>
        );
    }
}

export default withTheme(withStyles(styles)(DrcSelect));
