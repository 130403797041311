import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { DrcButton, DrcTranslate } from 'drc/driscolls-react-components/index';
import GridStyles from '../../styles/gridStyles';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast } from '../../actions/actions';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { downloadFile, makeColumns } from '../../utils/helper';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { Call } from '@driscollsinc/driscolls-display-rules';
import APIEndPoints from '../../services/api';
import { designColors, summaryDomesticColumns } from '../../data/constants';
import PrimeReactTable from '../../components/PrimeReactTable';
import NoData from '../../components/NoData';
import { Typography } from '@mui/material';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import HeaderDate from './HeaderDate';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
const styles = (theme) => ({
    input: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'left'
        }
    },
    gridStyles: GridStyles.styles(theme, '300px', '300px'),
    tableContainer: {
        margin: theme.spacing(3)
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    icon: {
        marginRight: 5
    }
});

class DomesticSummary extends Component {
    state = {
        data: [],
        pageNum: 0,
        selectedRow: {},
        pageSize: 50,
        totalCount: 0,
        columns: [],
        rowData: null
    };

    excludingFields = ['CreatedBy', 'ModifiedDateTime', 'ModifiedBy', 'CreatedDateTime', 'SubmittedBy'];
    readOnly = true;
    uniqueKeyField = 'ItemID';
    widthIsSufficient = window.innerWidth > 955;
    widthIsInSufficient = false;

    componentDidMount() {
        this.props.loadFilteredData(this.loadData.bind(this));
        window.addEventListener('resize', this.updateDataTable);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDataTable);
    }

    getTotalRow = (response) => {
        let totalRow = (response?.raw?.data?.resultSet1 || []).reduce(
            (newRow, row) => {
                newRow.AveragePricePerKg = (newRow.AveragePricePerKg || 0) + +(row.AveragePricePerKg || 0);
                newRow.AveragePricePerTray = (newRow.AveragePricePerTray || 0) + +(row.AveragePricePerTray || 0);
                newRow.CommissionNetFruitReturnPricePerKg =
                    (newRow.CommissionNetFruitReturnPricePerKg || 0) + +(row.CommissionNetFruitReturnPricePerKg || 0);
                newRow.CommissionNetPoolReturn = (newRow.CommissionNetPoolReturn || 0) + +(row.CommissionNetPoolReturn || 0);
                newRow.DAPShare = (newRow.DAPShare || 0) + +(row.DAPShare || 0);
                newRow.FreightDeduction = (newRow.FreightDeduction || 0) + +(row.FreightDeduction || 0);
                newRow.GrossPoolAmount = (newRow.GrossPoolAmount || 0) + +(row.GrossPoolAmount || 0);
                newRow.NetFruitBeforeDAPshare = (newRow.NetFruitBeforeDAPshare || 0) + +(row.NetFruitBeforeDAPshare || 0);
                newRow.NormalSalesUnitPrice = (newRow.NormalSalesUnitPrice || 0) + +(row.NormalSalesUnitPrice || 0);
                newRow.PackageDeduction = (newRow.PackageDeduction || 0) + +(row.PackageDeduction || 0);
                newRow.PkgNetFruitReturnPricePerKg = (newRow.PkgNetFruitReturnPricePerKg || 0) + +(row.PkgNetFruitReturnPricePerKg || 0);
                newRow.TotalKilosReceived = (newRow.TotalKilosReceived || 0) + +(row.TotalKilosReceived || 0);
                newRow.TotalTraysReceived = (newRow.TotalTraysReceived || 0) + +(row.TotalTraysReceived || 0);
                newRow.VATNetFruitReturnPricePerKg = (newRow.VATNetFruitReturnPricePerKg || 0) + +(row.VATNetFruitReturnPricePerKg || 0);
                newRow.VATNetPoolReturn = (newRow.VATNetPoolReturn || 0) + +(row.VATNetPoolReturn || 0);
                newRow.VATShareGrower = (newRow.VATShareGrower || 0) + +(row.VATShareGrower || 0);
                return newRow;
            },
            { TotalSumSection: true }
        );
        return totalRow;
    };

    updateTableData = (response, hasFrozenColumns = false) => {
        let data = response?.display?.resultSet1 || [];
        let columns = data.length ? makeColumns(Object.keys(data[0]), this.excludingFields, null, false, [], [], summaryDomesticColumns()) : [];
        let newRow = this.getTotalRow(response);
        newRow = Object.keys(newRow).reduce((obj, key) => {
            obj[key] = (+newRow[key]).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            return obj;
        }, {});
        if (data.length) {
            data.push(newRow);
        }
        this.setState({
            data,
            totalCount: data.length || 0,
            columns,
            unfreeze: !hasFrozenColumns
        });
    };
    updateDataTable = (_event, force = false) => {
        if (window.innerWidth > 955 && !this.widthIsSufficient) {
            this.widthIsSufficient = true;
            this.widthIsInSufficient = false;
            this.updateTableData(this.response || [], true);
        } else if (window.innerWidth <= 955 && !this.widthIsInSufficient) {
            this.widthIsInSufficient = true;
            this.widthIsSufficient = false;
            this.updateTableData(this.response || [], false);
        } else if (force) {
            this.updateTableData(this.response || [], true);
        }
    };

    loadData = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
        try {
            var data = await Call(
                {
                    name: 'DomesticSummary',
                    url: APIEndPoints.GET_GROWER_SETTLEMENT_REPORT,
                    method: 'POST',
                    options: {},
                    data: {
                        SearchBy: loggedInUser,
                        ...this.props.filters
                    }
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }
            this.response = data;
            this.updateDataTable(null, true);
            this.props.hideLoadingScreenAction();
        } catch (error) {
            this.showError(error);
        }
    };

    onPage = (event) => {
        this.setState({ pageNum: event.first });
        this.loadData();
    };

    showError = (err, msg) => {
        this.props.hideLoadingScreenAction();
        this.props.showToast(<DrcTranslate>{msg || 'An Error Occurred'}</DrcTranslate>, false);
        console.error('error : ', err);
    };
    exportData = async () => {
        try {
            this.props.showLoadingScreenAction(
                <React.Fragment>
                    <h4 style={{ fontSize: '1.5rem' }}>
                        <DrcTranslate>{'Preparing download'}</DrcTranslate>
                    </h4>
                    <div>
                        <DrcTranslate>{'Your download should start in a few seconds.'}</DrcTranslate>
                    </div>
                </React.Fragment>
            );
            this.props.hideLoadingScreenAction();
            downloadFile(
                `DomesticSummary_${this.props.poolWeek.value}_${this.props.filters.ReportType}.xlsx`,
                this.state.data,
                summaryDomesticColumns(),
                []
            );
        } catch (err) {
            console.log(err);
        }
    };

    render() {
        const { classes } = this.props;
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        return (
            <div className={classes.tableContainer}>
                <div className={classes.actionContainer}>
                    <Typography variant="h6" noWrap style={{ fontWeight: 'bold', color: designColors.blue, textTransform: 'uppercase' }}>
                        <DrcTranslate>{'Summary Domestic'}</DrcTranslate>
                    </Typography>
                    {!!this.state.data.length && <HeaderDate recordStatus={this.props.recordStatus} />}
                    <DrcButton onClick={this.exportData} style={{ fontWeight: 'bold', color: designColors.blue }} disabled={!this.state.data.length}>
                        <VerticalAlignBottomIcon className={classes.icon} /> <DrcTranslate>{'Export'}</DrcTranslate>
                    </DrcButton>
                </div>
                {!!this.state.data.length ? (
                    <PrimeReactTable
                        paginator={this.state.data.length > 0}
                        currentPage={this.state.pageNum}
                        onPage={this.onPage}
                        columns={this.state.columns}
                        value={this.state.data}
                        gridStyles={classes.gridStyles}
                        totalRecords={this.state.totalCount}
                        resultCount={this.state.totalCount}
                        pageSize={this.state.totalCount}
                        uniqueKeyField={this.uniqueKeyField}
                        tableSelectionMode="checkbox"
                        showReport={true}
                        currentPageReportTemplate={'Showing {first} to {last} of {totalRecords} entries'}
                        // frozenWidth={'100px'}
                    />
                ) : (
                    <NoData />
                )}
            </div>
        );
    }
}
const mapStateToProps = ({ masterReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized
});
const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withStyles(styles)(DomesticSummary)));
