import React from 'react';
import Typography from '@mui/material/Typography';
import PoolDefinitions from '../ReceiptLines/poolDefinition';
import { withStyles } from '@mui/styles';
import { designColors, inventoryOnHandColumns } from '../../../data/constants';
import { DrcMain, DrcTranslate, DrcCollapsiblePanel, DrcButton } from 'drc/driscolls-react-components/index';
import APIEndPoints from '../../../services/api';
import { Call } from '@driscollsinc/driscolls-display-rules';
import { withOktaAuth } from '@okta/okta-react';
import { connect } from 'react-redux';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast } from '../../../actions/actions';
import GridStyles from '../../../styles/gridStyles';
import Divider from '@mui/material/Divider';
import { displayErrorMessage, getAppliedFilters, makeColumns, userBusinessUnit } from '../../../utils/helper';
import PrimeReactTable from '../../../components/PrimeReactTable';
import NoData from '../../../components/NoData';
import { withRouter } from 'routes/withRouter';
import PageHeader from '../PageHeader';
import Filter from '../../../components/Filter/Filter';
import FilterListSharp from '@mui/icons-material/FilterListSharp';

const styles = (theme) => ({
    cardContainer: {
        backgroundColor: designColors.lightGrey,
        borderTop: '2px solid #E0E0E0',
        width: '82%',
        padding: '20px',
        marginBottom: '30px',
        borderRadius: '10px',
        margin: '5px',
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.grey['A700']} !important`,
            borderTop: '2px solid #000000'
        }
    },
    gridStyles: GridStyles.styles(theme, 'calc(100vh - 340px)'),
    container: {
        width: '100%',
        maxWidth: 'none !important',
        margin: '8px 0',
        borderRadius: '8px'
    },
    heading: {
        color: designColors.blue,
        fontWeight: 'bold',
        [theme.darkTheme]: {
            color: `${theme.palette.common.white} !important`
        }
    },
    divider: {
        marginTop: 8,
        marginBottom: 14
    },
    rootStyles: {
        margin: 0
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    pageActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
});

class InventoryOnhand extends React.Component {
    state = {
        data: [],
        columns: [],
        pageNum: 0,
        pageSize: 50,
        showClose: false,
        WorksheetDate: new Date().toISOString(),
        showFilter: false
    };
    excludingFields = [];
    readOnly = true;
    uniqueKeyField = 'ItemID';
    widthIsSufficient = window.innerWidth > 955;
    widthIsInSufficient = false;
    componentDidMount() {
        this.loadData(0);
        window.addEventListener('resize', this.updateDataTable);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDataTable);
    }

    updateTableData = (response, hasFrozenColumns = false) => {
        let data = response?.display?.Data || [];
        let columns = data.length
            ? makeColumns(Object.keys(data[0]), this.excludingFields, null, false, [], ['ItemID'], inventoryOnHandColumns())
            : [];
        this.setState({
            data,
            totalCount: response?.display?.TotalCount || 0,
            columns,
            unfreeze: !hasFrozenColumns
        });
    };
    updateDataTable = (_event, force = false) => {
        if (window.innerWidth > 955 && !this.widthIsSufficient) {
            this.widthIsSufficient = true;
            this.widthIsInSufficient = false;
            this.updateTableData(this.response || [], true);
        } else if (window.innerWidth <= 955 && !this.widthIsInSufficient) {
            this.widthIsInSufficient = true;
            this.widthIsSufficient = false;
            this.updateTableData(this.response || [], false);
        } else if (force) {
            this.updateTableData(this.response || [], true);
        }
    };
    loadData = async (page = 0) => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            let data = await Call(
                {
                    name: 'InventoryOnHand',
                    url: APIEndPoints.GET_INVENTORY_ONHAND(
                        page / this.state.pageSize + 1,
                        this.state.pageSize,
                        userBusinessUnit(),
                        getAppliedFilters(this.props.filters),
                        this.props.selectedPoolWeek.PoolWeek || this.props.match.params.poolWeek
                    ),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }
            this.response = data;
            this.updateDataTable(null, true);
            this.props.hideLoadingScreenAction();
        } catch (error) {
            this.showError(error);
        }
    };

    getData = () => {
        this.loadData(0);
    };
    showError = (err, msg) => {
        this.props.hideLoadingScreenAction();
        this.props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };
    onPage = (event) => {
        this.setState({ pageNum: event.first });
        this.loadData(event.first);
    };
    applyFilter = () => {
        this.setState({ pageNum: 0 }, () => {
            this.loadData(0);
        });
    };
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.cardContainer}>
                <DrcCollapsiblePanel header={<PageHeader />} rootStyles={classes.rootStyles}>
                    <PoolDefinitions />
                </DrcCollapsiblePanel>
                <DrcMain className={classes.container}>
                    <Typography className={classes.heading}>
                        <DrcTranslate>{'Inventory On-hand'}</DrcTranslate>
                    </Typography>
                    <Divider className={classes.divider} />
                    <div className={classes.filterContainer}>
                        <Filter
                            showFilter={this.state.showFilter}
                            applyFilter={this.applyFilter}
                            resetFilter={this.applyFilter}
                            filterNames={['ItemID']}
                        />
                    </div>
                    <div className={classes.pageActions}>
                        <div>
                            <DrcButton
                                onClick={() => {
                                    this.setState({ showFilter: !this.state.showFilter });
                                }}
                            >
                                <FilterListSharp className={classes.icon} />
                                <DrcTranslate>{'Filter'}</DrcTranslate>
                            </DrcButton>
                        </div>
                    </div>
                    {this.state.data.length ? (
                        <PrimeReactTable
                            paginator={true}
                            currentPage={this.state.pageNum}
                            onPage={this.onPage}
                            columns={this.state.columns}
                            value={this.state.data}
                            gridStyles={classes.gridStyles}
                            totalRecords={this.state.totalCount}
                            resultCount={this.state.totalCount}
                            pageSize={this.state.pageSize}
                            uniqueKeyField={'ItemID'}
                            showReport={true}
                            currentPageReportTemplate={'Showing {first} to {last} of {totalRecords} entries'}
                            frozenWidth="150px"
                        />
                    ) : (
                        <NoData />
                    )}
                </DrcMain>
            </div>
        );
    }
}

const mapStateToProps = ({ masterReducer, weeklySettlementStatusReducer, SettlementWorkBenchDetailsReducer, filterReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    currentPoolWeek: masterReducer.currentPoolWeek,
    selectedPoolWeek: weeklySettlementStatusReducer.selectedPoolWeek,
    filteredId: SettlementWorkBenchDetailsReducer.filteredId,
    filters: filterReducer.filters
});
const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});
export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withRouter(withStyles(styles)(InventoryOnhand))));
