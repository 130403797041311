import { Dashboard, syncTranslations, type DashboardData, StatusWrapper } from '@driscollsinc/ggs-dashboard-module-ui';
import { withOktaAuth } from '@okta/okta-react';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'routes/withRouter';
import { setPageTitleAction, showToast } from 'actions/actions';
import { setSettlementWorkbenchData } from 'actions/settlementWorkbenchAction';
import DrcWrapper from 'components/DrcWrapper';
import useDashboard from 'hooks/v2/useDashboard';
import { format } from 'date-fns';
import Stepper from 'components/Stepper';
import MasterDataUtilities from 'data/MasterDataUtilities';
import { getLanguage } from 'utils/helper';
import useLang from 'hooks/v2/useLang';
import { NAMESPACE } from 'i18n';
import useGAAnalytics from 'hooks/v2/useGAAnalytics';
import { EventConstants } from 'data/EventConstants';
import { Breadcrumbs, Typography } from '@mui/material';
import { designColors } from 'data/constants';
import { getSectionVisibleValue } from '@mui/x-date-pickers/internals/hooks/useField/useField.utils';

function DashboardPage(props) {
    const { getDashboardData, syncOracle, syncError, getSyncEntities } = useDashboard(props.oktaAuth);
    const { logEvent, logError } = useGAAnalytics();
    const language = localStorage.getItem('GGS_UserLang');
    const { getTextTranslated } = useLang();

    useEffect(() => {
        syncTranslations(getLanguage(language));
    }, [language]);

    useEffect(() => {
        if (syncError) {
            props.showToast(getTextTranslated('Sync Failure', NAMESPACE.DASHBOARD), false);
        }
    }, [syncError]);

    if (!MasterDataUtilities.Check(props.isMasterDataInitialized)) {
        return MasterDataUtilities.Redirect();
    }

    const getData = async (poolweek: string): Promise<DashboardData> => {
        const { Information, ...Categories } = await getDashboardData(poolweek).then((data) => data);

        return {
            LastUpdatedDate: format(new Date(), 'PP'),
            Count: Information.map((info) => ({
                label: info.Label,
                value: parseFloat(info.DataValue?.replace(/,/g, '') ?? 0),
                displayValue: info.DataValue ?? '0.00',
                data: {
                    poolweek: poolweek,
                    type: 'Information',
                    datatype: [info.DataType]
                }
            })),
            Categories: Object.keys(Categories).map((key) => ({
                label: key,
                ...{
                    values: Categories[key].map((e) => ({
                        value: parseFloat(e.DataValue?.replace(/,/g, '') ?? 0),
                        displayValue: e.DataValue ?? 0,
                        label: e.Label,
                        data: {
                            poolweek: poolweek,
                            datatype: [e.DataType],
                            type: 'Exception'
                        }
                    }))
                }
            }))
        };
    };

    return (
        <DrcWrapper>
            <div style={{ padding: '0px 20px 20px 20px', height: '100%' }}>
                <Dashboard
                    onError={(err) => props.showToast(err.message)}
                    breadcrumbs={
                        <Breadcrumbs sx={{ color: 'black', '@media (prefers-color-scheme: dark)': { color: `#a7a7a7 !important` } }}>
                            <Typography sx={{ color: designColors.blue, '@media (prefers-color-scheme: dark)': { color: 'white' } }} fontWeight={500}>
                                Home
                            </Typography>
                            <Typography>Dashboard</Typography>
                        </Breadcrumbs>
                    }
                    title={'Dashboard'}
                    defaultWeek={props.currentPoolWeek.length ? props.currentPoolWeek[0].PoolWeek : 0}
                    availableWeeks={props.yearPoolWeeks.map((yr) => ({
                        week: yr.PoolWeek,
                        startDate: yr.PoolWeekStartDate,
                        endDate: yr.PoolWeekEndDate
                    }))}
                    getDashboardData={getData}
                    routeFormat={(exception) =>
                        `./Exceptions?poolweek=${exception.data.poolweek}&datatype=${decodeURIComponent(
                            exception.label
                        )}&label=${exception.data.datatype.join(',')}&Type=${exception.data.type}`
                    }
                    onSync={async (entities, poolweek) => {
                        await syncOracle(entities, poolweek);
                        logEvent(EventConstants.ORACLE_SYNC, { time: new Date().toISOString() });
                    }}
                    getEntityTypeList={async () => {
                        const results = await getSyncEntities();
                        return results;
                    }}
                    syncColumns={[
                        { header: 'Batch Id', accessorKey: 'BatchId' },
                        { header: 'Entity Name', accessorKey: 'EntityName' },
                        {
                            header: 'Status',
                            accessorKey: 'Status',
                            cell: ({getValue}) => {
                                return <StatusWrapper title={getValue()} status={getValue()} />;
                            }
                        },
                        { header: 'Error Records', accessorKey: 'ErrorRecords' },
                        { header: 'Modified By', accessorKey: 'ModifiedBy' },
                        { header: 'Processed Records', accessorKey: 'ProcessedRecords' },
                        { header: 'RunType', accessorKey: 'RunType' },
                        { header: 'Success Records', accessorKey: 'SuccessRecords' },
                        { header: 'Sync Date', accessorKey: 'SyncDateTime' }
                    ]}
                />
            </div>
        </DrcWrapper>
    );
}

const mapStateToProps = ({ masterReducer, settlementWorkbenchReducer, rootReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    total: settlementWorkbenchReducer.totalRecords,
    pageTitle: rootReducer.pageTitle,
    records: settlementWorkbenchReducer.records,
    currentPoolWeek: masterReducer.currentPoolWeek,
    yearPoolWeeks: masterReducer.yearPoolWeeks,
    showToast: rootReducer.showToast
});

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    showToast: (message, type) => dispatch(showToast(message, type)),
    setSettlementWorkbenchData: (data) => dispatch(setSettlementWorkbenchData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withRouter(DashboardPage)));
