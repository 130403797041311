import { Call } from '@driscollsinc/driscolls-display-rules';
import { userBusinessUnit } from 'utils/helper';
import { Store } from 'data/store';
import { EventConstants } from 'data/EventConstants';
const GET_METHIOD: string = 'GET';
const POST_METHIOD: string = 'POST';
const SUCCESS_STATUS_CODE = 200;
const MAX_RESPONSE_TIME = 30;
const enviorment = (window.config.ENVIRONMENT_NAME === 'prod' ? '' : window.config.ENVIRONMENT_NAME).toUpperCase();

const logSlowCall = (startTime, url) => {
    try {
        const responseTime = (new Date().getTime() - startTime) / 1000;
        if (responseTime > MAX_RESPONSE_TIME) {
            window.config.logEvent(enviorment + '_' + EventConstants.SLOW_SERVER_CALL, { url });
        }
    } catch (e) {}
};

const getCall = async (url: string, token: string, options: any): Promise<any> => {
    const state = Store.getState();
    const startTime = new Date().getTime();
    const response = await Call(
        {
            url: url,
            method: GET_METHIOD,
            options: options,
            headers: {
                'x-business-unit': userBusinessUnit(),
                'x-region': userBusinessUnit(),
                'x-language': state?.masterReducer?.userLang?.toLowerCase(),
                'Content-Type': 'application/json; charset=UTF-8'
            }
        },
        token
    );
    logSlowCall(startTime, url);

    return response;
};

const postCall = async (url: string, token: string, options: any, data: any): Promise<any> => {
    const state = Store.getState();
    const startTime = new Date().getTime();
    const response = await Call(
        {
            url: url,
            method: POST_METHIOD,
            options: options,
            headers: {
                'x-business-unit': userBusinessUnit(),
                'x-region': userBusinessUnit(),
                'x-language': state?.masterReducer?.userLang?.toLowerCase(),
                'Content-Type': 'application/json; charset=UTF-8'
            },
            data: data
        },
        token
    );
    logSlowCall(startTime, url);
    return response;
};

export { GET_METHIOD, POST_METHIOD, getCall, postCall, SUCCESS_STATUS_CODE };
