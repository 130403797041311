import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import PoolDefinitions from '../ReceiptLines/poolDefinition';
import { designColors, salesInvoiceLineColumns } from '../../../data/constants';
import { DrcMain, DrcTranslate, DrcCollapsiblePanel, DrcButton } from 'drc/driscolls-react-components/index';
import { makeStyles } from '@mui/styles';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast } from '../../../actions/actions';
import { Call } from '@driscollsinc/driscolls-display-rules';
import APIEndPoints from '../../../services/api';
import { displayErrorMessage, getAppliedFilters, makeColumns, userBusinessUnit } from '../../../utils/helper';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import GridStyles from '../../../styles/gridStyles';
import Divider from '@mui/material/Divider';
import PrimeReactTable from '../../../components/PrimeReactTable';
import NoData from '../../../components/NoData';
import { withRouter } from 'routes/withRouter';
import { SetFilterId } from '../../../actions/SettlementWorkBenchDetailsAction';
import PageHeader from '../PageHeader';
import Filter from '../../../components/Filter/Filter';
import { FilterListSharp } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        backgroundColor: designColors.lightGrey,
        borderTop: '2px solid #E0E0E0',
        width: '82%',
        padding: '20px',
        marginBottom: '30px',
        borderRadius: '10px',
        margin: '5px',
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.grey['A700']} !important`,
            borderTop: '2px solid #000000'
        }
    },
    container: {
        width: '100%',
        maxWidth: 'none !important',
        margin: '8px 0',
        borderRadius: '8px'
    },
    gridStyles: GridStyles.styles(theme, 'calc(100vh - 340px)'),
    heading: {
        color: designColors.blue,
        fontWeight: 'bold',
        [theme.darkTheme]: {
            color: `${theme.palette.common.white} !important`
        }
    },
    divider: {
        marginTop: 8,
        marginBottom: 14
    },
    searchIcon: {
        borderRadius: 4,
        padding: 8,
        backgroundColor: designColors.blue,
        color: 'white',
        '&:hover': {
            backgroundColor: designColors.blue,
            opacity: '0.9'
        }
    },
    rootStyles: {
        margin: 0
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    pageActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
}));

function SalesInvoice(props) {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [pageNum, setPageNum] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [totalRecords, setTotalRecords] = useState(50);
    const [showFilter, setShowFilter] = useState(false);

    useEffect(() => {
        getSalesLines();
    }, []);

    const getSalesLines = async (page = 0) => {
        props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await props.oktaAuth.getAccessToken();
        try {
            let response = await Call(
                {
                    name: 'SalesInvoiceLines',
                    url: APIEndPoints.GET_SALES_INVOICE_LINES(
                        page / pageSize + 1,
                        pageSize,
                        userBusinessUnit(),
                        props.selectedPoolWeek.PoolWeek || props.match.params.poolWeek,
                        getAppliedFilters(props.filters)
                    ),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (response.errors.length && !response.raw) {
                return showError(response.errors[0]);
            }
            let responseData = response?.display?.Data || [];
            let responseColumns = responseData.length
                ? makeColumns(Object.keys(responseData[0]), [], null, false, [], ['VendorID', 'InvoiceID'], salesInvoiceLineColumns())
                : [];
            setData(responseData || []);
            setTotalRecords(response?.display?.TotalCount || 0);
            setColumns(responseColumns);
            props.hideLoadingScreenAction();
        } catch (error) {
            showError(error);
        }
    };

    const onPage = (event) => {
        setPageNum(event.first);
        getSalesLines(event.first);
    };

    const showError = (err, msg) => {
        props.hideLoadingScreenAction();
        props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    const applyFilter = () => {
        setPageNum(0);
        getSalesLines();
    };

    const toggleShowFilter = () => setShowFilter(!showFilter);

    const resetFilter = () => {
        setPageNum(0);
        getSalesLines();
    };

    return (
        <div className={classes.cardContainer}>
            <DrcCollapsiblePanel header={<PageHeader />} rootStyles={classes.rootStyles}>
                <PoolDefinitions />
            </DrcCollapsiblePanel>
            <DrcMain className={classes.container}>
                <Typography className={classes.heading}>
                    <DrcTranslate>{'Sales Invoice Lines'}</DrcTranslate>
                </Typography>
                <Divider className={classes.divider} />
                <div className={classes.filterContainer}>
                    <Filter showFilter={showFilter} applyFilter={applyFilter} resetFilter={resetFilter} filterNames={['ItemID', 'InvoiceID']} />
                </div>
                <div className={classes.pageActions}>
                    <div>
                        <DrcButton onClick={toggleShowFilter}>
                            <FilterListSharp className={classes.icon} />
                            <DrcTranslate>{'Filter'}</DrcTranslate>
                        </DrcButton>
                    </div>
                </div>
                {data.length ? (
                    <PrimeReactTable
                        paginator={data.length > 0}
                        currentPage={pageNum}
                        onPage={onPage}
                        columns={columns}
                        value={data}
                        gridStyles={classes.gridStyles}
                        totalRecords={totalRecords}
                        resultCount={totalRecords}
                        pageSize={pageSize}
                        uniqueKeyField={'InvoiceID'}
                        showReport={true}
                        currentPageReportTemplate={'Showing {first} to {last} of {totalRecords} entries'}
                        frozenWidth="120px"
                    />
                ) : (
                    <NoData />
                )}
            </DrcMain>
        </div>
    );
}

const mapStateToProps = ({ masterReducer, weeklySettlementStatusReducer, filterReducer }) => ({
    currentPoolWeek: masterReducer.currentPoolWeek,
    selectedPoolWeek: weeklySettlementStatusReducer.selectedPoolWeek,
    filters: filterReducer.filters
});
const mapDispatchToProps = (dispatch) => ({
    SetFilterId: (filteredId) => dispatch(SetFilterId(filteredId)),
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withOktaAuth(SalesInvoice)));
