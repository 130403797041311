import React from 'react';
import DrcButton from './DrcButton';
import { withStyles } from '@mui/styles';

/**
 *@ignore
 */
var styles = (theme) => ({
    iconButton: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        minWidth: theme.spacing(5),
        borderRadius: theme.spacing(2.5)
    },
    normalBottomBorder: {
        borderBottomWidth: '1px',
        '&:hover': {
            borderBottomWidth: '1px'
        }
    }
});

/**
 * @description DrcIconButton is a styled and highly customizable icon button
 * @class DrcIconButton
 * @property {object} style Sets the style of DrcIconButton
 * @property {boolean} isPrimary Makes the icon button primary
 * @property {boolean} isSecondary Makes the icon button secondary
 * @property {boolean} isText Makes the button secondary if true
 * @property {*} children Child elements
 * @property {string} title Sets the title of button
 * @property {boolean} disabled Disables the icon button
 * @property {boolean} hard Sets the button theme to Hard
 * @property {boolean} poly Sets the button theme to Polymorphism
 * @property {boolean} line Sets the button theme to Bottomline
 * @example
 * <DrcIconButton
 *       onClick={() => {
 *           console.log('Icon button has been summoned');
 *       }}
 *   >
 *       <RefreshIcon />
 *   </DrcIconButton>
 * @category Input
 * @tags input button icon pretty important
 * @extends {React.Component}
 */
class DrcIconButton extends React.Component {
    /**
     * @return {*}
     * @memberof DrcIconButton
     */

    render() {
        const { style, isPrimary, isSecondary, isText, children, className, disabled, classes, hard, line, ...other } = this.props;

        var primary = isPrimary;
        if (!isSecondary && !isText) {
            primary = true;
        }

        var addNormalMargin = false;
        if (line && !hard) {
            addNormalMargin = true;
        }

        return (
            <DrcButton
                className={`${classes.iconButton} ${addNormalMargin ? classes.normalBottomBorder : null} ${className}`}
                style={{
                    ...style
                }}
                isPrimary={primary}
                isSecondary={isSecondary}
                disabled={!!disabled}
                noStyle
                hard={hard}
                line={line}
                {...other}
            >
                {children}
            </DrcButton>
        );
    }
}

export default withStyles(styles)(DrcIconButton);
