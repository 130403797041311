export const es = {
    Home: 'Hogar',
    'Grower Settlement': 'Asentamiento de productores',
    'Grower Accountant': 'Contador de productores',
    'User Preference': 'Preferencia de usuario',
    Accounts: 'Cuentas',
    'Sign Out': 'Desconectar',
    Admin: 'Administrador',
    Year: 'Año',
    Reset: 'Reiniciar',
    Filter: 'Filtrar',
    Add: 'Agregar',
    Delete: 'Borrar',
    Lookup: 'Buscar',
    Date: 'Fecha',
    "Business Rules": 'Reglas comerciales',
    "Results loaded in":'Resultados cargados en',
    "Business Rules / History": 'Reglas comerciales / Historia',
    "Edit Business Rule":"Editar regla comercial",
    "Create New Business Rule": "Crear nueva regla comercial",
    "Copy":"Copiar",
    "Business Rules Approval": "Aprobación de reglas comerciales",
    Dashboard: "Panel",
    Exception: "Excepción",
    Information:"Información"

}