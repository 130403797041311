import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Box, Typography, useTheme } from '@mui/material';
import { designColors, getAccordionColor } from '../../data/constants';
import { ReactComponent as NoteAdd } from '../../Images/SVG/Add_Enabled.svg';
import { ReactComponent as FileCopy } from '../../Images/SVG/Copy_Enabled.svg';
import { ReactComponent as Edit } from '../../Images/SVG/Edit_Enabled.svg';
import { ReactComponent as Delete } from '../../Images/SVG/Delete_Enabled.svg';
import { makeStyles } from '@mui/styles';
import PoolDefinitionDialog from '../../components/PoolDefinitionDialog/PoolDefinitionDialog';
const useStyles = makeStyles((theme) => ({
    iconText: {
        paddingRight: '8px',
        fontSize: '28px',
        width: '50px',
        height: '20px',
        marginRight: '-10px'
    },
    childAccordion: {
        backgroundColor: theme.light.primary,
        [theme.darkTheme]: {
            backgroundColor: `${theme.dark.primary} !important`
        },
        margin: '0px',
        '& .MuiAccordionSummary-root': {
            height: 40
        }
    }
}));
function SettlementPoolDetail(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [openPoolDefinitionDialog, setOpenPoolDefinitionDialog] = useState(false);
    return (props.settlementPoolsMap[props.id] || []).map((settlementPool, index) => (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '3px 0px' }} key={index}>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                        component="span"
                        mr={1}
                        style={{
                            padding: '12px 0',
                            border: `4px solid ${getAccordionColor(theme, 'Recommended')}`,
                            borderRadius: 8,
                            backgroundColor: `${getAccordionColor(theme, 'Recommended')}`
                        }}
                    />
                    <Typography
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpenPoolDefinitionDialog(!openPoolDefinitionDialog);
                        }}
                        style={{ fontWeight: 'bold', textTransform: 'capitalize', color: designColors.blue, ...props.titleStyle }}
                    >{`${settlementPool.Identification.PoolID} ${settlementPool.Identification.Description}`}</Typography>
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <NoteAdd onClick={() => console.log('adding')} className={classes.iconText} />
                    <Edit onClick={() => console.log('editing')} className={classes.iconText} />
                    <FileCopy onClick={() => console.log('copying')} className={classes.iconText} />
                    <Delete onClick={() => console.log('deleting')} className={classes.iconText} />
                </Box>
            </div>
            {openPoolDefinitionDialog && (
                <PoolDefinitionDialog selectedKey={settlementPool} data={settlementPool} setOpenPoolDefinitionDialog={setOpenPoolDefinitionDialog} />
            )}
        </>
    ));
}

const mapStateToProps = ({ masterReducer, pricingPoolReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    settlementPoolsMap: pricingPoolReducer.settlementPoolsMap
});

export default connect(mapStateToProps, null)(SettlementPoolDetail);
