import React from 'react';
import { DrcTooltip } from 'drc/driscolls-react-components/index';
import { makeStyles } from '@mui/styles';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import GridStyles from '../styles/gridStyles';

const useStyles = makeStyles((theme) => ({
    input: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'left'
        }
    },
    gridStyles: GridStyles.styles(theme, 'calc(100vh -280px)'),
    dialogWrapper: {
        '& .MuiDialog-paperWidthSm': {
            overflow: 'hidden',
            maxWidth: '900px !important', //TO override dialog maxWidth
            minHeight: '300px',
            maxHeight: '70vh'
        }
    },
    asyncItem: {
        height: '45px',
        width: '100%',
        alignSelf: 'center',
        marginTop: '1px'
    },
    deleteButton: {
        minWidth: '25px',
        maxWidth: '25px',
        margin: '4px 8px 8px 0',
        color: '#e34843',
        border: 'none',
        '&:hover': {
            border: 'none',
            backgroundColor: '#ffffff00',
            color: '#ec110a'
        },
        [theme.darkTheme]: {
            backgroundColor: '#0000',
            '&:hover': {
                border: 'none',
                color: '#ec110a',
                backgroundColor: '#0000'
            }
        }
    }
}));

export default function PrimeReactTable(props) {
    const classes = useStyles();
    const {
        value,
        editMode,
        frozenWidth,
        paginator,
        currentPage,
        onPage,
        totalRecords = 0,
        uniqueKeyField = null,
        editingRows = {},
        columns = [],
        selectionMode,
        selected = [],
        rowSelect = false,
        selectedFrozen,
        tableSelectionMode,
        selectedClass = {},
        selectionChange = () => null,
        onRowEditInit = () => null,
        onRowEditCancel = () => null,
        onRowClick = () => null,
        pageSize,
        editor = () => null
    } = props;
    var calculatedHeight = 60 * value.length;
    var heightAdjusted = parseInt(500);

    if (calculatedHeight < heightAdjusted) {
        heightAdjusted = calculatedHeight + 'px';
    } else {
        heightAdjusted = heightAdjusted + 'px';
    }
    var isPaginatorActive = paginator && totalRecords > 0;
    return (
        <div
            className={classes.gridStyles}
            style={{
                //  minHeight: '200px', maxHeight: '300px', minWidth: '868px',
                width: '100%'
            }}
        >
            <DataTable
                value={value}
                lazy={true}
                editMode={editMode || 'row'}
                frozenWidth={frozenWidth || null}
                onRowEditInit={onRowEditInit}
                onRowEditCancel={onRowEditCancel}
                paginator={isPaginatorActive}
                first={currentPage}
                onRowClick={onRowClick}
                rows={pageSize || 10}
                onPage={onPage}
                emptyMessage={props.emptyRowsMessage || 'No data to show'}
                totalRecords={totalRecords}
                columnResizeMode={props.columnResizeMode || 'expand'}
                rowClassName={props.rowClassName || null}
                resizableColumns={props.resizableColumns || true}
                virtualRowHeight={28}
                scrollable={true}
                {...(tableSelectionMode ? { selectionMode: tableSelectionMode } : {})}
                responsiveLayout="scroll"
                dataKey={uniqueKeyField}
                editingRows={editingRows}
                onRowEditChange={() => null}
                onRowEditComplete={() => null}
                scrollHeight={heightAdjusted || '500px'}
                scrollWidth={'100%'}
                style={{ height: 'auto' }}
                selection={selected}
                onSelectionChange={selectionChange}
                // stripedRows
                currentPageReportTemplate={'Showing {first} to {last} of {totalRecords} entries'}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
            >
                {/* <Column rowEditor headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }} header={'Action'}></Column> */}
                {rowSelect && (
                    <Column
                        headerStyle={{ width: '3em' }}
                        selectionMode={selectionMode || 'multiple'}
                        frozen={selectedFrozen || false}
                        // className={selectedClass}
                    ></Column>
                )}
                {columns.map((column, indx) => {
                    return (
                        <Column
                            // selectionMode={selectionMode || 'multiple'}
                            frozen={column.frozen || false}
                            // className={selectedClass}
                            editable={column.editable}
                            style={{ width: column.width || '150px', paddingLeft: 8 }}
                            key={indx}
                            field={column.key}
                            header={
                                <DrcTooltip tipText={column.name}>
                                    <span>{column.name}</span>
                                </DrcTooltip>
                            }
                            bodyStyle={{ ...(indx ? { paddingLeft: 10 } : { paddingLeft: 6 }) }}
                            body={column.columnTemplate ? column.columnTemplate : null}
                            // editor={(props) => (column.key !== 'Actions' ? nameEditor(column.key, props) : getActionsCell(props.rowData))}
                            {...(column.editable ? { editor: (props) => editor(column.key, column, props) } : undefined)}
                            // {...(column.editable ? { editor: (props) => inputTextEditor(column.key, props) } : undefined)}
                        ></Column>
                    );
                })}
            </DataTable>
        </div>
    );
}
