import React, { useEffect } from 'react';
import VerticalTabs from '../../components/VerticalTabs';
import { makeStyles } from '@mui/styles';
import ReceiptLines from './ReceiptLines';
import SalesInvoiceLines from './SalesInvoiceLines';
import Stepper from '../../components/Stepper';
import { useLocation } from 'react-router-dom';
import DrcWrapper from '../../components/DrcWrapper';
import PageActions from './PageActions';
import InventoryOnHand from './InventoryOnHand';
import ServiceDeductions from './ServiceDeductions';
import GoodsInTransit from './GoodsInTransit';
import RepackageMovement from './RepackageMovement';
import { connect } from 'react-redux';
import { showLoadingScreenAction, hideLoadingScreenAction } from '../../actions/actions';
import { setSelectedSettlementRecord, setSelectedPoolWeek } from '../../actions/WeeklySettlementStatusAction';
import { DrcTranslate } from 'drc/driscolls-react-components/index';
import { withOktaAuth } from '@okta/okta-react';
import { Call } from '@driscollsinc/driscolls-display-rules';
import {
    receiptLinesColumns,
    salesInvoiceLineColumns,
    inventoryOnHandColumns,
    goodsInTransitColumns,
    ServiceDeductionColumns,
    repackageMovementColumns,
    ShipmentDsColumns,
    AdjustmentDsColumns
} from '../../data/constants';
import APIEndPoints from '../../services/api';
import { userBusinessUnit, getAppliedFilters, downloadFile } from '../../utils/helper';
import ImportCost from './ImportCost/ImportCost';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import AmountAdjustments from './AmountAdjustments/AmountAdjustments';
import { withRouter } from 'routes/withRouter';

const useStyles = makeStyles((theme) => ({}));
function SettlementWorkbenchDetails(props) {
    const classes = useStyles();
    const location = useLocation();
    const [value, setValue] = React.useState(location.state || 0);
    const tabItems = [
        { name: 'Receipt lines', component: <ReceiptLines /> },
        { name: 'Sales Invoice lines', component: <SalesInvoiceLines /> },
        { name: 'Inventory On-hand', component: <InventoryOnHand /> },
        { name: 'Goods In Transit', component: <GoodsInTransit /> },
        { name: 'Service Deductions', component: <ServiceDeductions /> },
        { name: 'Repackage Movement', component: <RepackageMovement /> },
        { name: 'Import Cost', component: <ImportCost /> },
        { name: 'Adjustment Amount', component: <AmountAdjustments /> }
    ];

    useEffect(() => {
        if (props.yearPoolWeeks.length && !(props.selectedSettlementRecord || {}).PricingPoolID) {
            getData();
        }
    }, [props.yearPoolWeeks]);

    const getData = async () => {
        try {
            props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
            let token = await props.oktaAuth.getAccessToken();
            let response = await Call(
                {
                    name: 'WeeklySettlementStatusList',
                    url: APIEndPoints.GET_SETTLEMENT_RECORDS(userBusinessUnit(), props.selectedPoolWeek.PoolWeek),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if ((response.errors.length && !response.raw) || response.raw.data.Status === 409) {
                return showError(response.errors[0]);
            }
            props.setSelectedSettlementRecord((response.raw.data || [])[0] || {});
            props.setSelectedPoolWeek(props.yearPoolWeeks.find((item) => item.PoolWeek === props.match.params.poolWeek) || {});
            props.hideLoadingScreenAction();
        } catch (error) {
            props.hideLoadingScreenAction();
            console.log('error : ', error);
        }
    };

    const showError = (err, msg) => {
        props.hideLoadingScreenAction();
        console.error('error : ', err);
    };
    const exportData = async () => {
        try {
            props.showLoadingScreenAction(
                <React.Fragment>
                    <h4 style={{ fontSize: '1.5rem' }}>
                        <DrcTranslate>{'Preparing download'}</DrcTranslate>
                    </h4>
                    <div>
                        <DrcTranslate>{'Your download should start in a few seconds.'}</DrcTranslate>
                    </div>
                </React.Fragment>
            );
            let token = await props.oktaAuth.getAccessToken();
            let name = '',
                url = '',
                column = '';
            switch (value) {
                case 0:
                    name = 'ReceiptLines';
                    url = APIEndPoints.GET_RECEIPT_LINES(
                        null,
                        null,
                        userBusinessUnit(),
                        props.selectedPoolWeek.PoolWeek || props.match.params.poolWeek,
                        getAppliedFilters(props.filters)
                    );
                    column = receiptLinesColumns();
                    break;
                case 1:
                    name = 'SalesInvoiceLines';
                    url = APIEndPoints.GET_SALES_INVOICE_LINES(
                        null,
                        null,
                        userBusinessUnit(),
                        props.selectedPoolWeek.PoolWeek || props.match.params.poolWeek,
                        getAppliedFilters(props.filters)
                    );
                    column = salesInvoiceLineColumns();
                    break;
                case 2:
                    name = 'InventoryOnHand';
                    url = APIEndPoints.GET_INVENTORY_ONHAND(
                        null,
                        null,
                        userBusinessUnit(),
                        getAppliedFilters(props.filters),
                        props.selectedPoolWeek.PoolWeek || props.match.params.poolWeek
                    );
                    column = inventoryOnHandColumns();
                    break;
                case 3:
                    name = 'GoodsInTransit';
                    url = APIEndPoints.GET_INTRANSIT_INVENTORY_LINES(
                        null,
                        null,
                        userBusinessUnit(),
                        getAppliedFilters(props.filters),
                        props.selectedPoolWeek.PoolWeek || props.match.params.poolWeek
                    );
                    column = goodsInTransitColumns();
                    break;
                case 4:
                    name = 'ServiceDeductions';
                    url = APIEndPoints.GET_SERVICEDEDUCTIONS_LINES(
                        null,
                        null,
                        userBusinessUnit(),
                        getAppliedFilters(props.filters),
                        props.selectedPoolWeek.PoolWeek || props.match.params.poolWeek
                    );
                    column = ServiceDeductionColumns();
                    break;
                case 5:
                    name = 'RepackageMovement';
                    url = APIEndPoints.GET_REPACKAGE_MOVEMENT(
                        userBusinessUnit(),
                        null,
                        null,
                        `&poolweek=${props.selectedPoolWeek.PoolWeek || props.match.params.poolWeek}`
                    );
                    column = repackageMovementColumns();
                    break;
                case 6:
                    name = 'ShipmentRate';
                    url = APIEndPoints.GET_SHIPMENT_DETAILS(
                        null,
                        null,
                        userBusinessUnit(),
                        getAppliedFilters(props.filters),
                        props.selectedPoolWeek.PoolWeek || props.match.params.poolWeek
                    );
                    column = ShipmentDsColumns();
                    break;
                case 7:
                    name = 'ManualAdjustment';
                    url = APIEndPoints.GET_MANUAL_ADJUSTMENT(userBusinessUnit(), props.selectedPoolWeek.PoolWeek || props.match.params.poolWeek);
                    column = AdjustmentDsColumns;
                    break;
                default:
                    return;
            }
            var response = await Call(
                {
                    name: name,
                    url,
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (response.errors.length && !response.raw) {
                return showError(response.errors[0]);
            }
            let data = response?.display?.Data || [];
            props.hideLoadingScreenAction();

            downloadFile(`${name}.xlsx`, data, column, []);
        } catch (err) {
            console.log(err);
        }
    };
    if (!MasterDataUtilities.Check(props.isMasterDataInitialized)) {
        return MasterDataUtilities.Redirect();
    }
    return (
        <DrcWrapper>
            <Stepper
                poolweek={props.match.params.poolWeek}
                history={props.history}
                showBackLink={true}
                backLink={`/SettlementWorkbench/`}
                page="Settlement Workbench"
                tabName={(tabItems[value] || {}).name || ''}
            >
                <PageActions exportData={exportData} />
            </Stepper>
            <VerticalTabs tabItems={tabItems} handleChange={(val) => setValue(val)} value={value} />
        </DrcWrapper>
    );
}
const mapStateToProps = ({ masterReducer, weeklySettlementStatusReducer, SettlementWorkBenchDetailsReducer, filterReducer }) => ({
    currentPoolWeek: masterReducer.currentPoolWeek,
    selectedPoolWeek: weeklySettlementStatusReducer.selectedPoolWeek,
    selectedSettlementRecord: weeklySettlementStatusReducer.selectedSettlementRecord,
    filteredId: SettlementWorkBenchDetailsReducer.filteredId,
    yearPoolWeeks: masterReducer.yearPoolWeeks,
    isMasterDataInitialized: masterReducer.isInitialized,
    filters: filterReducer.filters
});

export default connect(mapStateToProps, { setSelectedSettlementRecord, setSelectedPoolWeek, showLoadingScreenAction, hideLoadingScreenAction })(
    withOktaAuth(withRouter(SettlementWorkbenchDetails))
);
