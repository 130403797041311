import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import IdentificationTab from './tabs/IdentificationTab';
import DestinationCountriesTab from './tabs/DestinationCountriesTab';
import GeneralTab from './tabs/GeneralTab';
import GrowerTab from './tabs/GrowerTab';
import ItemsTab from './tabs/ItemsTab';
import RegionTab from './tabs/RegionTab';
import ProducingAreaTab from './tabs/ProducingAreaTab';
import CommoditiesTab from '../PoolDefinitionDialog/tabs/CommoditiesTab';
import { designColors } from '../../data/constants';
//SVG
import { ReactComponent as IdentificationSelectedIcon } from '../../Images/SVG/Identification_Selected.svg';
import { ReactComponent as CommoditiesSelectedIcon } from '../../Images/SVG/commodities_Selected.svg';
import { ReactComponent as DestinationSelectedIcon } from '../../Images/SVG/DestinationCountries_Selected.svg';
import { ReactComponent as DetailsSelectedIcon } from '../../Images/SVG/General_Selected.svg';
import { ReactComponent as GrowerSelectedIcon } from '../../Images/SVG/Grower_Selected.svg';
import { ReactComponent as ItemsSelectedIcon } from '../../Images/SVG/Items_Selected.svg';
import { ReactComponent as RegionSelectedIcon } from '../../Images/SVG/Region_Selected.svg';
import { ReactComponent as ProducingAreaSelected } from '../../Images/SVG/ProducingArea_Selected.svg';
import { ReactComponent as IdentificationIcon } from '../../Images/SVG/Identification_UnSelected.svg';
import { ReactComponent as CommoditiesIcon } from '../../Images/SVG/commodities_UnSelected.svg';
import { ReactComponent as DestinationIcon } from '../../Images/SVG/DestinationCountries_UnSelected.svg';
import { ReactComponent as DetailsIcon } from '../../Images/SVG/General_UnSelected.svg';
import { ReactComponent as GrowerIcon } from '../../Images/SVG/Grower_UnSelected.svg';
import { ReactComponent as ItemsIcon } from '../../Images/SVG/Items_UnSelected.svg';
import { ReactComponent as RegionIcon } from '../../Images/SVG/Region_UnSelected.svg';
import { ReactComponent as ProducingArea } from '../../Images/SVG/ProducingArea_UnSelected.svg';

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.common.white,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.grey['900']} !important`
        },
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
        borderRadius: '4px',
        padding: '10px 20px',
        margin: 4
    },
    cardContent: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    headerLabel: {
        color: `${designColors.grey}`,
        fontWeight: 'bold',
        maxWidth: 100
    },
    headerSelectedLabel: {
        color: `${designColors.blue}`,
        fontWeight: 'bold',
        maxWidth: 100
    },
    iconText: {
        paddingRight: '10px',
        fontSize: '30px',
        cursor: 'pointer'
    },
    triangle: {
        width: 0,
        height: 0,
        borderLeft: '25px solid transparent',
        borderRight: '25px solid transparent',
        borderBottom: '20px solid white'
    },
    root: {
        '& .MuiTab-textColorInherit': {
            backgroundColor: 'white',
            margin: '0 4px',
            borderRadius: 8,
            opacity: 0.6
            // height: '145px'
        },
        '& .MuiTab-textColorInherit.Mui-selected': {
            backgroundColor: 'white',
            [theme.darkTheme]: {
                backgroundColor: `${theme.palette.grey['900']} !important`
            },
            // height: '150px',
            opacity: 1
        },
        '& .MuiTab-root': {
            minWidth: '125px !important'
        },
        '& .MuiTabs-indicator': {
            backgroundColor: '#EFEFEF',
            [theme.darkTheme]: {
                backgroundColor: `${theme.palette.grey['900']} !important`
            }
        }
    },
    boxWrapper: {
        backgroundColor: '#EFEFEF',
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.grey['600']} !important`
        }
    },
    closeIcon: {
        marginLeft: 'calc(100% - 32px)',
        fontSize: 'xx-large',
        cursor: 'pointer',
        color: theme.palette.common.black
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const HEADER_NAMES = [
    {
        name: 'Identification',
        icon: IdentificationIcon,
        activeIcon: IdentificationSelectedIcon
    },
    {
        name: 'Commodities',
        icon: CommoditiesIcon,
        activeIcon: CommoditiesSelectedIcon
    },
    {
        name: 'Region',
        icon: RegionIcon,
        activeIcon: RegionSelectedIcon
    },
    {
        name: 'Growers',
        icon: GrowerIcon,
        activeIcon: GrowerSelectedIcon
    },
    {
        name: 'Items',
        icon: ItemsIcon,
        activeIcon: ItemsSelectedIcon
    },
    {
        name: 'Destination Countries',
        icon: DestinationIcon,
        activeIcon: DestinationSelectedIcon
    },
    {
        name: 'Producing Area',
        icon: ProducingArea,
        activeIcon: ProducingAreaSelected
    },
    {
        name: 'General',
        icon: DetailsIcon,
        activeIcon: DetailsSelectedIcon
    }
];

// Component Main Function
const PoolDefinitionDialog = (props) => {
    const [value, setValue] = useState(0);

    const classes = useStyles();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabHeader = (item, index, isSelected) => {
        return (
            <Tab
                key={index}
                icon={isSelected ? <item.activeIcon style={{ height: 65, width: 65 }} /> : <item.icon style={{ height: 60, width: 60 }} />}
                label={<p className={`${isSelected ? classes.headerSelectedLabel : classes.headerLabel}`}>{item.name}</p>}
                {...a11yProps(index)}
                disableRipple
            />
        );
    };
    const selectedKey = props.selectedKey;
    return (
        <Box className={classes.boxWrapper}>
            <CloseIcon className={classes.closeIcon} onClick={() => props.setOpenPoolDefinitionDialog(false)} />
            <Tabs
                className={classes.root}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons="off"
            >
                {HEADER_NAMES.map((itm, indx) => tabHeader(itm, indx, value === indx))}
            </Tabs>

            <TabPanel style={{ padding: 0 }} value={value} index={0}>
                {/* <div className={classes.triangle} /> */}
                <div className={classes.card}>
                    <div className={classes.cardContent}>
                        <IdentificationTab selectedKey={selectedKey} data={props.data} />
                    </div>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                {/* <div className={classes.triangle} /> */}
                <div className={classes.card}>
                    <div className={classes.cardContent}>
                        <CommoditiesTab selectedKey={selectedKey} data={props.data} />
                    </div>
                </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
                {/* <div className={classes.triangle} /> */}
                <div className={classes.card}>
                    <div className={classes.cardContent}>
                        <RegionTab selectedKey={selectedKey} data={props.data} />
                    </div>
                </div>
            </TabPanel>
            <TabPanel value={value} index={3}>
                {/* <div className={classes.triangle} /> */}
                <div className={classes.card}>
                    <div className={classes.cardContent}>
                        <GrowerTab selectedKey={selectedKey} data={props.data} />
                    </div>
                </div>
            </TabPanel>
            <TabPanel value={value} index={4}>
                {/* <div className={classes.triangle} /> */}
                <div className={classes.card}>
                    <div className={classes.cardContent}>
                        <ItemsTab selectedKey={selectedKey} data={props.data} />
                    </div>
                </div>
            </TabPanel>
            <TabPanel value={value} index={5}>
                {/* <div className={classes.triangle} /> */}
                <div className={classes.card}>
                    <div className={classes.cardContent}>
                        <DestinationCountriesTab selectedKey={selectedKey} data={props.data} />
                    </div>
                </div>
            </TabPanel>
            <TabPanel value={value} index={6}>
                {/* <div className={classes.triangle} /> */}
                <div className={classes.card}>
                    <div className={classes.cardContent}>
                        <ProducingAreaTab selectedKey={selectedKey} data={props.data} />
                    </div>
                </div>
            </TabPanel>
            <TabPanel value={value} index={7}>
                {/* <div className={classes.triangle} /> */}
                <div className={classes.card}>
                    <div className={classes.cardContent}>
                        <GeneralTab selectedKey={selectedKey} data={props.data} />
                    </div>
                </div>
            </TabPanel>
        </Box>
    );
};

export default PoolDefinitionDialog;
