import React, { useEffect } from 'react';
import List from '@mui/material/List';
import { makeStyles } from '@mui/styles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { withRouter } from 'routes/withRouter';;

const useStyles = makeStyles((theme) => ({
    listItemRoot: {
        color: '#000 !important',
        backgroundColor: '#D8D8D8 !important',
        fontWeight: 'bold',
        minWidth: 32,
        paddingLeft: theme.spacing(4)
    },
    selected: {
        backgroundColor: '#fff !important',
        color: '#0076a5 !important',
        fontWeight: 'bold',
        paddingLeft: theme.spacing(4)
    }
}));

function DrcNestedMenuItem(props) {
    const classes = useStyles();
    const [selectedIndex, setSelectedSubitemIndex] = React.useState(-1);
    const { menu, selected, setSelectedIndex } = props;
    useEffect(() => {
        let routes = menu.items?.map((itm, indx) => {
            if (selected && itm.route.includes(props.router.location.pathname)) setSelectedSubitemIndex(indx);
            return itm.route;
        });
    }, []);
    return (
        <List component="div" disablePadding>
            {menu.items.map((itm, indx) => (
                <ListItem
                    button
                    className={selectedIndex === indx ? classes.selected : classes.listItemRoot}
                    key={indx}
                    onClick={(e) => {
                        e.stopPropagation();
                        setSelectedIndex();
                        setSelectedSubitemIndex(indx);
                        props.router.history.push(itm.route);
                    }}
                >
                    <ListItemText primary={itm.name} />
                </ListItem>
            ))}
        </List>
    );
}
export default withRouter(DrcNestedMenuItem);
