import { DrcTooltip } from 'drc/driscolls-react-components/index';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Typography from '@mui/material/Typography';
import { percentageWithoutSymbol } from '../../utils/helper';
const useStyles = (props) =>
    makeStyles((_theme) => ({
        dataTableCell: {
            fontSize: 11,
            ...(props.row.TotalSumSection ? { fontWeight: 'bold' } : {}),
            paddingRight: 8,
            /* Some maths applied here to float decimals to right correctly with respect to their header text
            width of table cell = 150
            pixel taken by once header char is ~5.5
            */
            // paddingRight: `${Math.max(150 - Math.min(150, props.headerLength * 5.5), 25)}px`,
            // float: 'right'
            width: `${Math.min(120, Math.max(70, props.headerLength * 5.5))}px`,
            textAlign: 'end'
        }
    }));
function NumberFormatter(props) {
    const classes = useStyles(props)();
    const { row, field, isDecimal, isPercent, currencySymbol = '' } = props;
    const value = row[field];
    return isPercent ? (
        <DrcTooltip tipText={value}>
            <Typography noWrap className={classes.dataTableCell}>
                {value == -0 ? 0 : value} {value ?? '' ? '%' : ''}
            </Typography>
        </DrcTooltip>
    ) : isDecimal ? (
        <DrcTooltip tipText={value}>
            <div style={{ display: 'flex' }}>
                <Typography className={classes.dataTableCell}>
                    {value == -0
                        ? `${currencySymbol}0`
                        : field === 'FinalAverageSettlementPrice'
                        ? percentageWithoutSymbol(value)
                        : currencySymbol + '' + (value ?? '')}
                </Typography>
                <Typography style={{ flex: 1 }}></Typography>
            </div>
        </DrcTooltip>
    ) : (
        <DrcTooltip tipText={value}>
            <Typography className={classes.dataTableCell}>{value}</Typography>
        </DrcTooltip>
    );
}

export default NumberFormatter;
