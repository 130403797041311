export const ROUTES = {
    ROOT: '/',
    SEPARATOR: '/',
    DASHBOARD: 'dashboard',
    SUPER_ADMIN: 'superadmin',
    ADMIN: 'admin',
    GROWER: 'grower',
    REPORT_BUILDER: 'reportbuilder',
    MAIN_DASHBOARD: 'main',
    LOGIN: '/login',
    INITIALIZE_APP: '/InitializeApplication/',
    INACTIVE_USER: '/inactiveUser/',
    OKTA_CALLBACK: '/implicit/callback',
    LOGOUT: '/LogOut',
    WILDCARD: '*',
    AUTHORING_REPORT: 'authoring',
    USER_PREFERENCE: '/UserPreferences',

    RESPONSE_PATH: 'support/response',
    ASSIGNMENT_ENTITY: 'user/assignment/entity/',
    EVENTS: 'events',
    EVENTSCREATE: 'createEvent',
    EVENTSVIEWALL: 'eventsList',
    EVENT_DETAILS: 'details',
    EVENT_EDIT: 'edit',
    LATEST: 'latest',
    SETKIOSK: 'setKioskMode',

    REPORTS: '/Reports/',

    //BusinessRules
    BUSINESS_RULES: '/BusinesRules/',
    BUSINESS_RULES_LIST: 'List',
    BUSINESS_RULES_CREATE: 'Create/:ruleType',
    BUSINESS_RULES_COPY: 'Copy/:ruleId',
    BUSINESS_RULES_EDIT: 'Edit/:ruleId',
    BUSINESS_RULES_APPROVE: 'Approval',
    BUSINESS_RULES_HISTORY: 'History/:ruleId',
    BUSINESS_RULES_ASSIGNMENTS: 'Assignments',
    EXCEPTIONS: 'Exceptions',

    PRICING_POOL: '/PricingPool/',
    RELEASE: '/Releases/',
    POOL_WEEK: 'Poolweek',
    WEEKLY_SETTLEMENT_STATUS: 'WeeklySettlementStatus',
    LOOK_UP: '/LookUp',
    ITEM_UOM_CONVERSION: '/ItemUomConversion',
    ITEM_SHIPPING_RATES: '/ItemShippingRates',
    SETTLEMENT_RATE: '/SettlementRate',
    PREPACKAGEING_SKU_NAPPING: '/RepackagingSkuMapping',
    GROWER_CATEGORY: '/GrowerCategory',
    GROWER_FIXED_PRICE: '/GrowerFixedPrice',
    CUSTOMER_CODE_CATEGORY: '/CustomerCodeCategory',
    VAT_RATE_MAINTENANCE: '/VatRateMaintenance',
    GROWER_PAERCENTAGE: '/GrowerPercentage',
    CONVERSION_RATE:"/ConversionRate",
    IMPORT_SETTLEMENT_RATE:"/ImportSettlementRate",
    JOURNAL_ACCOUNT:"/JournalAccount",
    SETTLEMENT_WORKBENCH_POOLWEEK_DETAILS:"/SettlementWorkbench/:poolWeek/Details",
    EXCEPTIONS:"Exceptions",

    //WorkSpace
    SETTLEMENT_WORKSPACE: '/SettlementWorkbench/',
    SETTLEMENT_WORKSPACE_DETAILS: 'Details',
    SETTLEMENT_WORKSPACE_BREAKDOWN: 'Breakdown',
    SETTLEMENT_APPROVE: 'ApprovePoolWeek',
    APPROVE: 'Approval'
};

export const getRoute = (route, ...args) => {
    switch (route) {
        case ROUTES.BUSINESS_RULES:
            return ROUTES.BUSINESS_RULES;
        case ROUTES.BUSINESS_RULES_LIST:
            return `${ROUTES.BUSINESS_RULES}${ROUTES.BUSINESS_RULES_LIST}?selectedTab=${args[0]}`;
        case ROUTES.BUSINESS_RULES_CREATE:
            return `${ROUTES.BUSINESS_RULES}Create/${args[0]}`;
        case ROUTES.BUSINESS_RULES_COPY:
            return `${ROUTES.BUSINESS_RULES}Copy/${args[0]}`;
        case ROUTES.BUSINESS_RULES_EDIT:
            return `${ROUTES.BUSINESS_RULES}Edit/${args[0]}`;
        case ROUTES.BUSINESS_RULES_APPROVE:
            return `${ROUTES.BUSINESS_RULES}${ROUTES.BUSINESS_RULES_APPROVE}`;
        case ROUTES.BUSINESS_RULES_HISTORY:
            return `${ROUTES.BUSINESS_RULES}History/${args[0]}`;
        case ROUTES.SETTLEMENT_APPROVE:
            const url = `${ROUTES.SETTLEMENT_WORKSPACE}${ROUTES.SETTLEMENT_APPROVE}`;
            return url;
    }
};
