import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DrcTranslate } from 'drc/driscolls-react-components/index';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {
    designColors,
    commissionRateOverlay,
    vatRateReturnOverlay,
    overlayLevelOne,
    overlayLevelTwo,
    currencySymbols,
    drawerWidth
} from '../../data/constants';
import { connect } from 'react-redux';
const useStyles = (props) =>
    makeStyles((theme) => ({
        root: {
            '& .MuiDialog-paperScrollPaper': {
                maxHeight: 'calc(100% - 200px)',
                backgroundColor: `${theme.palette.grey['400']} !important`,
                [theme.darkTheme]: {
                    backgroundColor: `${theme.palette.grey['700']} !important`
                },
                width: '100%',
                marginLeft: `${props.drawerOpen ? drawerWidth : '73'}px`,
                marginTop: 64
            },
            '& .MuiDialogContent-root': {
                backgroundColor: theme.palette.common.white,
                [theme.darkTheme]: {
                    backgroundColor: `${theme.palette.grey['A400']} !important`
                },
                margin: '0 8px 8px',
                borderRadius: 8
            },
            '& .MuiDialogTitle-root': {
                padding: '8px 0px 8px 10px'
            },
            '& .MuiDialog-paperWidthSm': {
                maxWidth: '800px !important', //TO override dialog maxWidth
                maxHeight: '80vh',
                minHeight: '80vh'
            }
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(0),
            top: theme.spacing(0)
        },
        wrapper: {
            display: 'flex',
            flexDirection: 'column'
        },
        wrapperItem: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingLeft: 8
        },
        labelWrapper: {
            display: 'flex',
            flexDirection: 'row'
        },
        formulaText: { margin: '0 8px', flex: 1, color: `${designColors.blue}`, fontSize: 11, fontWeight: 600 },
        text: {
            fontWeight: 'bold',
            margin: 2,
            flex: 1,
            fontSize: 11
        },
        numericVal: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        negativeVal: {
            color: designColors.red
        },
        totalCalc: {
            display: 'flex'
        }
    }));

function ScrollDialog(props) {
    const classes = useStyles(props)();

    const handleClose = () => {
        props.onClose();
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (props.open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [props.open]);

    const getFieldData = (obj, key) => {
        return (
            <div className={classes.wrapperItem}>
                <Typography variant="body2" noWrap className={classes.text}>
                    <DrcTranslate>{obj[key].name}</DrcTranslate>
                </Typography>
                <Typography variant="body2" noWrap className={classes.formulaText}>
                    {obj[key].formula}
                </Typography>
                <Typography
                    variant="body2"
                    noWrap
                    className={`${classes.text} ${classes.numericVal} ${props.rawData[key] < 0 ? classes.negativeVal : ''}`}
                >
                    {obj[key].hidePriceTag ? props.data[key] || 0 : `${currencySymbols[props.data.SettlementCurrency] || ''}${props.data[key] || 0}`}
                </Typography>
            </div>
        );
    };
    const grossSum = Object.keys({
        ...overlayLevelOne,
        ...overlayLevelTwo,
        ...commissionRateOverlay,
        ...vatRateReturnOverlay
    }).reduce((sum, key) => {
        sum += props.rawData[key] || 0;
        return sum;
    }, 0);

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                className={classes.root}
            >
                <DialogTitle id="scroll-dialog-title" disableTypography className={classes.dialogTitle}>
                    <div>
                        <Typography variant="body1" noWrap className={classes.text}>
                            <DrcTranslate>{'Grower Settlement ID'}</DrcTranslate> : {props.data.GrowerSettlementID}
                        </Typography>
                        <Typography variant="body1" noWrap className={classes.text}>
                            <DrcTranslate>{'Item ID'}</DrcTranslate> : {props.data.ItemID}
                        </Typography>
                    </div>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon style={{ cursor: 'pointer', justifyContent: 'center', alignItem: 'start' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers={false}>
                    <div id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
                        <div>
                            <div className={classes.wrapper}>{Object.keys(overlayLevelOne).map((key) => getFieldData(overlayLevelOne, key))}</div>
                            <Divider variant="fullWidth" style={{ marginBottom: 8 }} />
                            <div className={classes.wrapper}>{Object.keys(overlayLevelTwo).map((key) => getFieldData(overlayLevelTwo, key))}</div>
                            <Divider variant="fullWidth" style={{ marginBottom: 8 }} />
                            <div className={classes.wrapper}>
                                <Typography variant="body1" noWrap className={classes.text}>
                                    <DrcTranslate>{'COMMISSION RATE'}</DrcTranslate>
                                </Typography>
                                {Object.keys(commissionRateOverlay).map((key) => getFieldData(commissionRateOverlay, key))}
                            </div>
                            <Divider variant="fullWidth" style={{ marginBottom: 8 }} />
                            <div className={classes.wrapper}>
                                <Typography variant="body1" noWrap className={classes.text}>
                                    <DrcTranslate>{'VAT PART RETURN'}</DrcTranslate>
                                </Typography>
                                {Object.keys(vatRateReturnOverlay).map((key) => getFieldData(vatRateReturnOverlay, key))}
                            </div>
                            <Divider variant="fullWidth" style={{ marginBottom: 8 }} />
                            <div className={classes.totalCalc}>
                                <Typography variant="body1" noWrap className={classes.text}>
                                    <DrcTranslate>{'GROSS SETTLEMENT'}</DrcTranslate>
                                </Typography>
                                <Typography variant="body2" noWrap className={`${classes.text} ${classes.numericVal}`}>
                                    {`${currencySymbols[props.data.SettlementCurrency] || ''}${parseFloat(grossSum || 0).toFixed(4)}`}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => ({
    drawerOpen: state.rootReducer.drawerOpen
});

export default connect(mapStateToProps, null)(ScrollDialog);
