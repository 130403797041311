import React from 'react';
import { withStyles } from '@mui/styles';
import DrcKeyValueTable from './DrcKeyValueTable';
/**
 * @description The DrcGlossaryList is a customizable list with a table, easy way to display a list of key value data which is to be sent in an array of Objects as child to the component
 * @author stephen.weessies
 * @property {Object} ClassName Classes for styling the table
 * @property {Array} children data array to be displayed in a key value table
 * @class DrcGlossaryList
 * @example
 * <DrcGlossaryList>[{key:"color",value:"red"},{key:"weight":"01"}]</DrcGlossaryList>
 * @donotprint true
 * @category General
 * @tags label text glossary lookup data list
 * @extends {React.Component} */

/**
 * @property {object} styles style for the DrcGlossaryList
 */
const styles = (theme) => ({
    button: {
        margin: theme.spacing(1)
    }
});

class DrcGlossaryList extends React.Component {
    /**
     * @return {*}
     * @memberof DrcGlossaryList
     */
    render() {
        const { classes } = this.props;
        return <DrcKeyValueTable className={classes.button}>{this.props.children}</DrcKeyValueTable>;
    }
}

export default withStyles(styles)(DrcGlossaryList);
