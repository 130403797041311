import React, { useState, useEffect } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'routes/withRouter';;
import {  Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { Box } from '@mui/material';

import { DrcDialog, DrcButton, DrcTranslate, DrcChipsMultiSelect } from 'drc/driscolls-react-components/index';
import Form from 'react-validation/build/form';
import GGSFormInput from '../../GGSFormInput';
import { numeric, alphaNumeric, maxlen } from '../../../utils/validations';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';

import APIEndPoints from '../../../services/api';

import { Call } from '@driscollsinc/driscolls-display-rules';
import { ReactComponent as NoteAdd } from '../../../Images/SVG/Add_Enabled.svg';
import { showToast, showLoadingScreenAction, hideLoadingScreenAction } from '../../../actions/actions';
import { initialize } from 'react-localize-redux';

import PrimeReactTable from '../../../components/PrimeReactTable';
import { userBusinessUnit } from '../../../utils/helper';

const useStyles = makeStyles((theme) => ({}));

const ProducingAreaTab = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [editPoolTableProducingArea, setEditPoolTableProducingArea] = useState({});
    const [pageNum, setPageNum] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [onPage, setOnPage] = useState(1);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const columns = ['PoolID', 'VendorAccountNum', 'Description', 'DataAreaId'];

    const [areaData, setAreaData] = useState([]);
    const [areaConfig, setAreaConfig] = useState({ ProducingAreaSelected: '', ProducingAreaSelection: 0 });

    const initialAddPoolTableProducingArea = {
        PoolID: props.selectedKey.Identification.PoolID,
        DataareaID: props.selectedKey.Identification.DataareaID
    };

    useEffect(() => {
        setupData();
        loadData();
    }, []);

    const setupData = () => {
        setAreaConfig(props.selectedKey.ProducingArea);
        setEditPoolTableProducingArea({
            ...initialAddPoolTableProducingArea
        });
    };

    const showError = (err, msg) => {
        props.hideLoadingScreenAction();
        props.showToast(<DrcTranslate>{msg || 'An Error Occurred'}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    const updateArea = (areaArray) => {
        var updatedAreaData = [];
        if (areaConfig.ProducingAreaSelection === 0) {
            areaArray.forEach((area) => {
                updatedAreaData.push({ ...area, selected: true });
            });
        } else {
            let selectedArea = areaConfig.ProducingAreaSelected.split(',');
            areaArray.forEach((area) => {
                updatedAreaData.push({
                    ...area,
                    selected: selectedArea.contains((sel) => {
                        return sel === grower.ProducingAreaID;
                    })
                });
            });
        }
        return updatedAreaData;
    };

    const loadData = async () => {
        props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await props.oktaAuth.getAccessToken();
        try {
            var data = await Call(
                {
                    name: 'GrowerList',
                    url: APIEndPoints.GET_PRODUCING_AREA_LIST('', '', userBusinessUnit(), props.userLang, ''),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }

            var areaData = updateArea(data.converted.Data);
            setAreaData(areaData);

            props.hideLoadingScreenAction();
        } catch (error) {
            showError(error);
        }
    };

    const AddPoolTableProducingArea = async () => {
        props.showLoadingScreenAction('Posting data');

        try {
            let token = await props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            var data = await Call(
                {
                    name: 'PUT_POOLTABLEPRODUCINGAREA',
                    url: APIEndPoints.PUTPOST_POOLTABLEPRODUCINGAREA(),
                    method: 'POST',
                    options: {},
                    data: {
                        PoolTableArea: areaData
                            .filter((area) => {
                                return area.selected;
                            })
                            .map((area) => {
                                return { ProducingAreaID: area.ProducingAreaID, Description: area.Description };
                            }),
                        PoolID: editPoolTableProducingArea['PoolID'],
                        DataareaID: editPoolTableProducingArea['DataareaID'],
                        DateTime: new Date().toISOString(),
                        RequestedBy: loggedInUser
                    }
                },
                token
            );
            if (data.errors.length && !data.raw) {
                setShowAddDialog(false);
                setEditPoolTableProducingArea({ ...initialAddPoolTableProducingArea });
                return showError(data.errors[0]);
            } else if (data.display.Status === 409) {
                setShowAddDialog(false);
                setEditPoolTableProducingArea({ ...initialAddPoolTableProducingArea });
                return showError((data.display.Message, 'Duplicate Record'));
            } else {
                props.hideLoadingScreenAction();
                setShowAddDialog(false);
                setEditPoolTableProducingArea({ ...initialAddPoolTableProducingArea });
                props.showToast(<DrcTranslate>{'Added Successfully'}</DrcTranslate>, true);
            }
        } catch (error) {
            showError(error);
        }
        props.hideLoadingScreenAction();
    };

    const handleSelectChange = (area, isSelected) => {
        let newData = areaData.reduce((arr, data) => {
            if (area.key === data.ProducingAreaID) {
                data.selected = isSelected;
            }
            arr.push(data);
            return arr;
        }, []);
        setAreaData(newData);
    };

    return (
        <>
            <Typography>
                <DrcTranslate>{'Producing Area'}</DrcTranslate>
            </Typography>

            <Box sx={{ margin: 'auto', marginRight: 'inherit' }}>
                <NoteAdd onClick={() => setShowAddDialog(true)} style={{ width: '50px', height: '20px', marginRight: '-10px' }} />
            </Box>
            <DrcDialog
                title={<DrcTranslate>{'Select Procucing Area'}</DrcTranslate>}
                open={showAddDialog}
                buttons={
                    <>
                        <DrcButton
                            poly
                            line
                            primary
                            onClick={() => {
                                AddPoolTableProducingArea();
                            }}
                        >
                            <DrcTranslate>{'Save'}</DrcTranslate>
                        </DrcButton>
                        <DrcButton
                            poly
                            line
                            onClick={() => {
                                setEditPoolTableProducingArea({ ...initialAddPoolTableProducingArea });
                                setShowAddDialog(!showAddDialog);
                            }}
                        >
                            <DrcTranslate>{'Cancel'}</DrcTranslate>
                        </DrcButton>
                    </>
                }
            >
                <Form>
                    <DrcChipsMultiSelect
                        chips={areaData
                            .filter((area) => area.selected)
                            .map((area) => {
                                return { key: area.ProducingAreaID, value: area.ProducingAreaID, label: area.Description };
                            })}
                        options={areaData.map((area) => {
                            return { key: area.ProducingAreaID, value: area.ProducingAreaID, label: area.Description };
                        })}
                        onSelectChange={(area) => handleSelectChange(area, true)}
                        onChipDelete={(area) => handleSelectChange(area, false)}
                    />
                </Form>
            </DrcDialog>
        </>
    );
};

const mapStateToProps = ({ masterReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    userLang: masterReducer.userLang
});

export default connect(mapStateToProps, { showToast, showLoadingScreenAction, hideLoadingScreenAction })(withOktaAuth(ProducingAreaTab));
