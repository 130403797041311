import React from 'react';
import { withStyles } from '@mui/styles';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import { Column } from 'primereact/column';
import { ContextMenu } from 'primereact/contextmenu';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import DrcIcons from '../Utilities/DrcIcons';
import DrcDatagridEditor from './DrcDatagridEditor';
import DrcIconLabel from './DrcIconLabel';
import { DuDateUtilities } from '@driscollsinc/driscolls-react-utilities';
import DrcDateRangePicker from './DrcDateRangePicker';
import DrcDatePicker from './DrcDatepicker';
import DrcTimePicker from './DrcTimePicker';
import DrcTooltip from './DrcTooltip';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import LoadingGif from '../Images/loading.gif';
import styles from './DrcDataGridStyles';

//@doc https://www.primefaces.org/primereact/showcase/#/datatable/paginator
const PAGINATOR_TEMPLATE = 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport';
const ROWS_PER_PAGES = [50, 100, 200];

/**
 * @description Creates a data table with customized column filters. The data is paginated / lazy loaded.
 * @property {function} onRowClick  callback function handler for row click event
 * @property {Array} columns Array of column objects with details for each column and filter type.
 * example: columnArr = [
           {
               key: (String) Key for the associated row value
               name: (String) Display value for the column
               filter: (Boolean default false) Enables row filtering for this column default is Search
                       If defaultFilters prop is set to true(see below) all column filters will be enabled
               filterElement: (String of ['search', 'dropdown', 'multiSelect]) Dictates the type of filter for the column
               filterProps: (Object): {
                   options:[{value: , label:}, ... ]
                   template:(function that returns react component for multiselect dropdown item rendering)
                   placeholder: Placeholder for filter
               filterMatchMode: choice of "contains", "endsWith", "equals", "in"
                   default: 'contains'

           },
           ...
    ];
 * @category Tables
 * @tags layout table data rows columns filter actions
 * @property {Array} rows List of Objects, each is a row. Each row has key: value pairs that correlate to the keys in the columns 
    and the value gets displayed in the row 
 * Ex: 
  [
       { key:value, key:value, ... },
       ...
  ]
 * @property {Boolean} defaultFilters all column filters will be enabled if set true
 * @property {Array} contextMenu array of menuItems: 
  {   label: string with display label
      icon: string with primeicon ref
      command: function to handle context menu selection
  } 
 * @property {string} currentPageReportTemplate OverRide for the report format, the string should contain {first} {last} {totalRecords} 
 * @class DrcDataGrid
 * @example
    const columnArr = [
           {
               key: "key_1"
               name: "Name"
               filter: true
               filterElement: 'dropdown'
               filterProps: {
                   options:[{value: "type1" , label: "Filter 1"}, {value: "type2" , label: "Filter 2"}],
                   template: () => {},
                   placeholder: "Select Filter"
               }
               filterMatchMode: "equals",
               width: '60px'
           },
           ...
    ];
    <DrcDataGrid
        columns={columnArr}
        rows={tableData || []}
        onRowClick={(row) => this.handleRow(row, false)}
        height={height - 350}
        hideCount
        lazyvirtualScroll
        selectionChange={(selected) => { this.handleSelection(selected) }}
    />
 * @extends {React.PureComponent}
 */
class DrcDataGrid extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            height: 0,
            menu: [],
            columns: [],
            selectedFilters: {},
            multiSelectOptions: {},
            currentRows: [],
            from: 0,
            to: 0,
            filteredRecords: [],
            filteredRecordsCount: 0,
            selectAll: false
        };
        this.view = this.view.bind(this);
        this.delete = this.delete.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
    }

    /**
     *@ignore
     */
    componentDidMount() {
        if (this.props.contextMenu && this.props.contextMenu.length > 0) {
            this.constructContextMenu();
        }

        if (this.props.rows.length > 0) {
            this.setState({ currentRows: this.loadRows(0, this.props.pageSize) });
        }
    }

    /**
     *@ignore
     */
    componentDidUpdate(prevProps) {
        if (this.dt) {
            let filteredRecords = this.props.rows;

            if (filteredRecords.length !== this.state.filteredRecordsCount) {
                this.setState({
                    filteredRecords,
                    filteredRecordsCount: filteredRecords.length
                });
            }
        }

        if (!prevProps.rows.length && this.props.rows.length > 0) {
            this.setState({ currentRows: this.loadRows(0, this.props.pageSize) });
        }

        if (prevProps.rows.length > 0 && prevProps.rows.length !== this.props.rows.length) {
            let from = this.state.from;
            let to = this.state.to ? this.state.to : this.props.pageSize;

            this.setState({ currentRows: this.loadRows(from, to), to: this.props.pageSize });
        }
    }

    /**
     * @description creates context menu with label, icon and command handler and sets state variable menu
     * @memberof DrcDataGrid
     */
    constructContextMenu() {
        var contextMenu = [];
        this.props.contextMenu.forEach((menuItem) => {
            contextMenu.push({ label: menuItem.label, icon: menuItem.icon, command: (event) => menuItem.command(event) });
        });
        this.setState({
            menu: contextMenu
        });
    }

    /**
     * @description returns loader element
     * @memberof DrcDataGrid
     */
    loadingText = () => (
        <div style={{ width: '100%' }}>
            <img style={{ width: '100%', height: '2rem' }} src={LoadingGif} alt="Loading..." />
        </div>
    );

    /**
     *@ignore
     */
    editRow = (props, columns) => {
        //stub for edit row
    };

    /**
     * Prime React Data Table does not allow row edit with custom controls.
     * So we are doing our on row edit.
     */
    //TODO - Get the DrcComponents as editors
    /**
     * @description Editors inline - default ones - User can pass their own editors with columns as column editor. Returns is a styled grid editor in the Driscolls format and is customizable
     * @param {Object} props props of DrcDataGrid
     * @memberof DrcDataGrid
     */
    inputTextEditor = (props) => {
        let column = this.props.columns.find((item) => {
            return item.key === props.field;
        });
        return props.rowData[this.props.uniqueKeyField] === this.props.rowUnderEditUniqueKey ? (
            <DrcDatagridEditor
                options={column.editorOptions || []}
                editorType={column.editorType || 'text'}
                gridProps={props}
                rowData={props.rowData}
                field={props.field}
                unique={column.isUnique}
                required={column.required}
                columnEditorValidator={column.editorValidator}
                onEditorValueCommit={this.props.onEditorValueChange}
            />
        ) : props.body ? (
            props.body(props.rowData, props)
        ) : (
            this.resolveFieldData(props.rowData, props.field)
        );
    };

    /**
     * @description will return the value of each grid element. Depending upon the props passed it can return an editable field or an element or just the value
     * @param {Object} props DrcDataGrid props Object
     * @memberof DrcDataGrid
     */
    berryTypeSelector = (props) => {
        return props.rowData[this.props.uniqueKeyField] === this.props.rowUnderEditUniqueKey ? (
            <DrcDatagridEditor editorType="berryselect" gridProps={props} rowData={props.rowData} field={props.field} onEditorValueCommit={this.props.onEditorValueChange} />
        ) : props.body ? (
            props.body(props.rowData, props)
        ) : (
            this.resolveFieldData(props.rowData, props.field)
        );
    };

    /**
     * @description will return the field value of each grip depending upon the type of field
     * @param {Object} data data object of each element
     * @param {*} field field type passed to fetch value of a particular data key
     * @memberof DrcDataGrid
     */
    resolveFieldData = (data, field) => {
        if (data && field) {
            if (this.isFunction(field)) {
                return field(data);
            } else if (field.indexOf('.') === -1) {
                return data[field];
            } else {
                let fields = field.split('.');
                let value = data;
                for (var i = 0, len = fields.length; i < len; ++i) {
                    if (value == null) {
                        return null;
                    }
                    value = value[fields[i]];
                }
                return value;
            }
        } else {
            return null;
        }
    };

    /**
     * @description check if parameter passed is a function.
     * @param {Object} obj
     * @memberof DrcDataGrid
     */
    isFunction = (obj) => {
        return !!(obj && obj.constructor && obj.call && obj.apply);
    };

    /**
     * @description this will construct customizable column headers in data table with different filter features like selection dropdowns and date/time pickers
     * @property {Array} columns array of column data objects with filterElement and options accordingly.
     * @property {*} actionTemplate action template in case there are column filters and need user action to implement.
     * @property {Boolean} defaultFilters all column filters will be enabled if set true
     * @returns {Array} array of customized columns.
     * @memberof DrcDataGrid
     */
    constructColumns() {
        const { columns, rows, defaultFilters, actionTemplate } = this.props;
        var columnArray = [];
        if (this.props.rowSelect) {
            columnArray.push(
                <Column
                    selectionMode={this.props.selectionMode || 'multiple'}
                    style={this.props.selectedFrozen ? { flexGrow: 0, flexBasis: '30px', } : { width: '20px' }}
                    className={this.props.selectedClass}
                    frozen={this.props.selectedFrozen || false}
                />
            );
        }

        if (columns && columns.length > 0) {
            Object.values(columns).map((column) => {
                var options = column.filterProps && column.filterProps.options ? column.filterProps.options : this.getOptions(rows, column.key);

                var columnFilterComponent = this.getFilterComponent(column.filterElement, column.filterProps, options, column.key);
                var filterEnabled = column.filter === undefined ? defaultFilters : column.filter;
                var filterMatchMode =
                    filterEnabled && (column.filterElement === 'dateRange' || column.filterElement === 'berryType' || column.filterElement === 'customSelectFilter')
                        ? 'custom'
                        : filterEnabled && (column.filterMatchMode === 'search' || column.filterMatchMode === undefined)
                        ? 'contains'
                        : undefined;

                let filterFunction = null;

                if (column.filterProps && column.filterProps.customFilterFunction) {
                    filterFunction = column.filterProps.customFilterFunction;
                } else {
                    if (filterEnabled && column.filterElement === 'dateRange') {
                        filterFunction = this.dateFilter;
                    }

                    if (filterEnabled && column.filterElement === 'datePicker') {
                        filterFunction = this.datePickerFilterMethod;
                    }

                    if (filterEnabled && column.filterElement === 'timePicker') {
                        filterFunction = this.timePickerFilterMethod;
                    }

                    if (filterEnabled && column.filterElement === 'berryType') {
                        filterFunction = this.berryFilter;
                    }
                }
                //In line Edit Defaults
                let columnEditor = column.editor;
                if (this.props.editable && column.editable) {
                    columnEditor = column.key.toLowerCase() === 'actions' ? null : column.editor || this.inputTextEditor;
                }

                const cssStyle = {};
                if (column.width === 0) {
                    return null;
                }
                
                if (column.frozen) {
                    cssStyle.flexGrow = column.flexGrow || 1;
                    cssStyle.flexBasis = column.width > 0 ? column.width - 70 + 'px' : column.width || '100px';
                } else { 
                    cssStyle.flexGrow = column.flexGrow || 1;
                    cssStyle.width = column.width > 0 ? column.width + 'px' : column.width || '150px';
                }

                columnArray.push(
                    <Column
                        className={this.props.classes.dropdown}
                        key={column.key}
                        field={column.key}
                        header={
                            <DrcTooltip tipText={column.name}>
                                <span>{column.shortName || column.name}</span>
                            </DrcTooltip>
                        }
                        filter={filterEnabled}
                        filterElement={columnFilterComponent}
                        filterMatchMode={filterMatchMode}
                        filterFunction={filterFunction}
                        filterPlaceholder={column.filterPlaceholder || 'Filter...'}
                        loadingBody={this.props.loadingFunc || this.loadingText}
                        style={cssStyle}
                        frozen={column.frozen || false}
                        body={column.columnTemplate ? column.columnTemplate : null}
                        sortable={column.sortable}
                        editor={columnEditor}
                        editorValidator={this.props.editable ? column.editorValidator || null : null}
                    />
                );
            });
        } else if (rows.length > 0) {
            console.warn('No columns prop defined: If you wish to enable column filtering you must define your own columns.');
            Object.keys(rows[0]).map((key) => {
                columnArray.push(<Column key={key} field={key} header={key} loadingBody={this.props.loadingFunc || this.loadingText} style={{ width: '150px' }} />);
            });
        }
        if (actionTemplate) {
            columnArray.push(<Column frozen={true} body={actionTemplate} style={{ textAlign: 'center', width: '8em' }} />);
        }
        return columnArray;
    }

    /**
     * @description dateFilter OnChange event for the dateRange filter
     * @param {*} e event
     * @param {Object} range range object with start and end date values
     * @returns {Boolean} true if selected date is null or out of range
     * @memberof DrcDataGrid
     */
    dateFilter(e, range) {
        var date = new Date(e);
        //TODO
        //Check validation to make sure it doesn't throw console errors upon manual entry of column header filter
        //Might need to update this to look for invalid dates like datePickerFilterMethod
        if (range.startDate === null && range.endDate === null) {
            return true;
        }

        if (date >= range.startDate && date <= range.endDate) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * @description berryFilter onChange event for berry filter
     * @param {*} e event
     * @param {*} args to be compared with
     * @returns {Boolean} sends true if parameters match
     * @memberof DrcDataGrid
     */
    berryFilter(e, args) {
        if (e.key === args) {
            return true;
        }

        return false;
    }

    /**
     * @description getOptions generates options for DrcSelect component.
     * @param {Array} rows Array of row data
     * @param {String} key To customize the type of options
     * @returns {Array} Array of options with label and value
     * @memberof DrcDataGrid
     */
    getOptions(rows, key) {
        var optionsArray = [];
        if (key === 'BerryType') {
            rows.map((row) => {
                if (!optionsArray.some((option) => option.value === row.BerryType.key))
                    optionsArray.push({
                        label: (
                            <DrcIconLabel style={{ display: 'flex', marginLeft: '1rem' }} icon={DrcIcons.GetSmallBerryIcon(row.BerryType.key)}>
                                {row.BerryType.key}
                            </DrcIconLabel>
                        ),
                        value: row.BerryType.key
                    });
            });
        } else
            rows.forEach((row) => {
                if (!optionsArray.some((option) => option.value === row[key])) {
                    optionsArray.push({ label: row[key], value: row[key] });
                }
            });
        return optionsArray;
    }

    /**
     * @description getFilterComponent returns the onChange functions depending upon filter type
     * @param {String} element filter type
     * @param {*} props
     * @param {Array} options Array of filter
     * @param {*} key unique column key
     * @returns {function} onChange functions
     * @memberof DrcDataGrid
     */
    getFilterComponent(element, props, options, key) {
        switch (element) {
            case 'select':
                return this.selectFilter(props, options, key);
            case 'multiSelect':
                return this.multiSelectFilter(props, options, key);
            case 'datePicker':
                return this.datePickerFilterElement(props, key);
            case 'timePicker':
                return this.timePickerFilterElement(props, key);
            case 'dateRange':
                return this.dateRangeFilter(props, key);
            case 'berryType':
            case 'customSelectFilter': //to handle custom select type filters
                return this.customSelectFilter(element, props, options, key);
            case 'search':
            default:
                return undefined;
        }
    }

    //* DatePicker *//

    /**
     * @description datePickerChange OnChange event for the datePickerFilter.
     * @param {date} filterDate Picked date that was selected in the date-picker.
     * @param {*} key Identifying key for the filter element
     * @param {function} formatter Method that can transform the selected date to match format of data in the cell's
     */
    datePickerChange(filterDate, key, formatter) {
        var selectedFilters = {
            ...this.state.selectedFilters,
            [key]: { value: filterDate }
        };
        if (filterDate === null) selectedFilters[key] = null;
        if (formatter !== null && filterDate !== null) filterDate = formatter(filterDate);
        this.dt.filter({ value: filterDate }, key, 'custom');
        this.setState({ selectedFilters });
    }

    /**
     * @description datePickerFilterElement
     * @param {props} filterProps Props for the filterElement.
     * @param {*} key Identifying key for the filter element.
     * @returns {DrcDatePicker} DrcDatePicker
     */
    datePickerFilterElement(filterProps, key) {
        var formatter = filterProps ? filterProps.formatter : null;
        return (
            <DrcDatePicker
                key={key}
                selectedDate={this.state.selectedFilters[key] ? this.state.selectedFilters[key].value : null}
                onChange={(date) => this.datePickerChange(date, key, formatter)}
                required={false}
            />
        );
    }

    /**
     * @description datePickerFilterMethod Method that contains the logic for the filter.
     * @param {*} fieldDate Date from the cell of the datagrid.
     * @param {*} filterDate Date that is entered in the filter.
     * @returns {Boolean} Compared result of fieldDate and filterDate.
     */
    datePickerFilterMethod(fieldDate, filterDate) {
        if (filterDate.value === null || !(filterDate.value instanceof Date) || isNaN(filterDate.value)) {
            return true;
        }

        if (DuDateUtilities.ToPrettyDate(fieldDate) === DuDateUtilities.ToPrettyDate(filterDate.value)) {
            return true;
        }

        return false;
    }

    //* TimePicker *//
    /**
     * @description timePickerChange OnChange event for the datePickerFilter.
     * @param {Time} filterTime Picked time that was selected in the time-picker.
     * @param {*} key Identifying key for the filter element
     * @param {function} formatter Method that can transform the selected time to match format of data in the cell's
     */
    timePickerChange(filterTime, key, formatter) {
        var selectedFilters = {
            ...this.state.selectedFilters,
            [key]: { value: filterTime }
        };
        if (filterTime === null) selectedFilters[key] = null;

        if (formatter !== null && filterTime !== null) filterTime = formatter(filterTime);
        this.dt.filter({ value: filterTime }, key, 'custom');
        this.setState({ selectedFilters });
    }

    /**
     * @description timePickerFilterElement
     * @param {props} filterProps Props for the filterElement.
     * @param {*} key Identifying key for the filter element.
     * @returns {DrcTimePicker} DrcTimePicker
     */
    timePickerFilterElement(filterProps, key) {
        var formatter = filterProps ? filterProps.formatter : null;
        return (
            <DrcTimePicker
                key={key}
                value={this.state.selectedFilters[key] ? this.state.selectedFilters[key].value : null}
                onChange={(time) => this.timePickerChange(time, key, formatter)}
                clearable={true}
                required={false}
            />
        );
    }

    /**
     * @description timePickerFilterMethod Method that contains the logic for the filter.
     * @param {string} fieldTime Time from the cell of the datagrid.
     * @param {string} filterTime Time that is entered in the filter.
     * @returns {Boolean} Compared result of fieldTime and filterTime.
     */
    timePickerFilterMethod(fieldTime, filterTime) {
        //TODO
        //Check validation to make sure it doesn't throw console errors upon manual entry of column header filter
        //Might need to update this to look for invalid dates like datePickerFilterMethod
        if (filterTime === null || filterTime.value === null) return true;
        if (filterTime.value === fieldTime) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * @description selectFilter returns a dropdown button with single select
     * @param {*} props
     * @param {*} options Array of options
     * @param {String} key unique key
     * @returns {*} Dropdown button element
     * @memberof DrcDataGrid
     */
    selectFilter = (props, options, key) => {
        return (
            <Dropdown
                key={key}
                style={{ width: '100%', borderRadius: '4px' }}
                placeholder={props.placeholder || 'Select...'}
                value={this.state.selectedFilters[key]}
                options={options}
                onChange={(e) => this.onSelectChange(e, key)}
                appendTo={document.body}
                panelClassName={this.props.classes.select}
                showClear
            />
        );
    };

    /**
     * @description onSelectChange onChange handler for dropdown button selection and updates state
     * @param {*} event
     * @param {String} key unique key
     * @memberof DrcDataGrid
     */
    onSelectChange(event, key) {
        var selectedFilters = {
            ...this.state.selectedFilters,
            [key]: event.value
        };
        this.dt.filter(event.value, key, 'equals');
        this.setState({ selectedFilters });
    }

    /**
     * @description multiSelectFilter dropdown button filter with multiple select option
     * @param {*} props
     * @param {Array} options Array of options for dropdown filter
     * @param {String} key unique key
     * @return {*} dropdown button filter element
     * @memberof DrcDataGrid
     */
    multiSelectFilter = (props, options, key) => {
        if (!this.state.multiSelectOptions[key]) {
            this.setState({
                multiSelectOptions: {
                    ...this.state.selectedFilters,
                    [key]: options
                }
            });
        }
        return (
            <Dropdown
                key={key}
                style={{ width: '100%', borderRadius: '4px' }}
                placeholder={props.placeholder || 'Select...'}
                value={this.state.selectedFilters[key] ? this.state.selectedFilters[key].join(', ') : null}
                options={this.state.multiSelectOptions[key]}
                itemTemplate={(option) => this.itemTemplate(option, props.template, key)}
                onChange={(e) => this.onMultiSelectChange(e, props, key)}
                appendTo={document.body}
                panelClassName={this.props.classes.select}
                showClear
            />
        );
    };

    /**
     * @description dateRangeFilter returns date range picker element with start and end values if there are selected filters for the given unique key
     * @param {*} props
     * @param {String} key unique key
     * @returns {*} returns date range picker element
     * @memberof DrcDataGrid
     */
    dateRangeFilter(props, key) {
        return (
            <DrcDateRangePicker
                key={key}
                isDateRange={false}
                combineLabelYears={true}
                startDate={this.state.selectedFilters[key] ? this.state.selectedFilters[key].startDate : null}
                endDate={this.state.selectedFilters[key] ? this.state.selectedFilters[key].endDate : null}
                onChange={(first, second) => this.dateRangeChange(first, second, key)}
                required={false}
            />
        );
    }

    /**
     * @description dateRangeChange onChange handler funtions for date range selections
     * @param {Date} startDate
     * @param {Date} endDate
     * @param {String} key unique key
     * @memberof DrcDataGrid
     */
    dateRangeChange(startDate, endDate, key) {
        var selectedFilters = {
            ...this.state.selectedFilters,
            [key]: { startDate, endDate }
        };
        if (startDate === null && endDate === null) selectedFilters[key] = null;
        this.dt.filter({ startDate, endDate }, key, 'custom');
        this.setState({ selectedFilters });
    }

    /**
     * @description makes dropdown for custom select type filters
     * @param {*} element
     * @param {*} props
     * @param {Array} options Array of options
     * @param {*} key
     * @returns {*} dropdown button filter for custom select type filters
     * @memberof DrcDataGrid
     */
    customSelectFilter(element, props, options, key) {
        //makes dropdown for custom select type filters
        return (
            <Dropdown
                key={key}
                style={{ width: '100%', borderRadius: '4px' }}
                placeholder={props.placeholder || 'Select...'}
                value={this.state.selectedFilters[key]}
                options={options}
                onChange={(e) => this.onCustomSelectChange(e, key)}
                appendTo={document.body}
                panelClassName={this.props.classes.select}
                itemTemplate={props.itemTemplate || null}
                showClear
            />
        );
    }

    /**
     * @description onChange handler function for custom select filter
     * @param {*} event
     * @param {*} key
     * @memberof DrcDataGrid
     */
    onCustomSelectChange(event, key) {
        //handle onChange for custom select type filters
        var selectedFilters = {
            ...this.state.selectedFilters,
            [key]: event.value
        };
        this.dt.filter(event.value, key, 'custom');
        this.setState({ selectedFilters });
    }

    /**
     * @description itemTemplate creates element for each item in dropdown list
     * @param {Object} option each option in dropdown list
     * @param {*} template function that returns react component for multiselect dropdown item rendering
     * @param {String} key unique key
     * @returns {*} element for each dropdown item
     * @memberof DrcDataGrid
     */
    itemTemplate(option, template, key) {
        if (template) {
            template(option);
        } else {
            if (this.state.selectedFilters[key]) {
                if (this.state.selectedFilters[key].length > 1 && option.value === this.state.selectedFilters[key].join(', ')) {
                    return null;
                } else if (this.state.selectedFilters[key].includes(option.value)) {
                    return (
                        <div style={{ alignContent: 'center', alignItems: 'center' }}>
                            <CheckIcon />
                            <span style={{ margin: '.5em .25em 0 0' }}>{option.label}</span>
                        </div>
                    );
                } else {
                    return (
                        <div style={{ alignContent: 'center', alignItems: 'center' }}>
                            <CloseIcon />
                            <span style={{ margin: '.5em .25em 0 0' }}>{option.label}</span>
                        </div>
                    );
                }
            } else {
                return (
                    <div style={{ alignContent: 'center', alignItems: 'center' }}>
                        <CloseIcon />
                        <span style={{ margin: '.5em .25em 0 0' }}>{option.label}</span>
                    </div>
                );
            }
        }
    }

    /**
     * @description onMultiSelectChange onChange function handler for multi select dropdown button. This updates state parameter with selected filters.
     * @param {*} event
     * @param {*} props
     * @param {String} key unique key
     * @memberof DrcDataGrid
     */
    onMultiSelectChange(event, props, key) {
        var currentFilter = this.state.selectedFilters[key] || [],
            multiSelectOptions = props.options,
            multiSelectLabel = currentFilter.join(', '),
            multiSelectIndex;

        multiSelectOptions.forEach((option, index) => {
            if (option.value === multiSelectLabel) {
                multiSelectIndex = index;
            }
        });

        if (currentFilter.length > 1 && multiSelectIndex) {
            multiSelectOptions.splice(multiSelectIndex, 1);
        }
        if (event.value === null) {
            //checks if clear operation
            this.dt.filter(event.value, key, 'equals');
            currentFilter = undefined;
        } else {
            if (!currentFilter.includes(event.value)) {
                // Item not in array
                if (currentFilter.length > 0) {
                    // Need to add Option
                    var newOption = this.buildNewOption(currentFilter, multiSelectOptions, event.value);
                    multiSelectOptions.push(newOption);
                }
                currentFilter.push(event.value);
            } else {
                if (currentFilter.length === 1) {
                    currentFilter = null;
                } else if (currentFilter.length === 2) {
                    const index = currentFilter.indexOf(event.value);
                    if (index > -1) {
                        currentFilter.splice(index, 1);
                    }
                } else {
                    const index = currentFilter.indexOf(event.value);
                    if (index > -1) {
                        currentFilter.splice(index, 1);
                    }
                    const newOption = this.buildNewOption(currentFilter, multiSelectOptions, event.value);
                    multiSelectOptions.push(newOption);
                }
            }
            this.dt.filter(currentFilter, key, 'in');
        }

        this.setState({
            selectedFilters: {
                ...this.state.selectedFilters,
                [key]: currentFilter
            },
            multiSelectOptions: {
                ...this.state.selectedFilters,
                [key]: multiSelectOptions
            }
        });
    }

    /**
     * @description buildNewOption builds a new option in case no filter options are present.
     * @param {*} currentFilter
     * @param {*} multiSelectOptions
     * @param {*} newValue
     * @returns {*}
     * @memberof DrcDataGrid
     */
    buildNewOption(currentFilter, multiSelectOptions, newValue) {
        var associatedLabels = {},
            label = '',
            value = '';
        multiSelectOptions.forEach((filter) => {
            associatedLabels[filter.value] = filter.label;
        });
        currentFilter.forEach((filter) => {
            value += filter + ', ';
            label += associatedLabels[filter] + ', ';
        });
        value += newValue;
        label += associatedLabels[newValue];
        return { value, label };
    }

    /**
     * @description displays notification popup
     * @param {Object} data
     * @memberof DrcDataGrid
     */
    view(data) {
        this.growl.show({ severity: 'info', summary: 'Selected', detail: data.key + ' - ' + data.label });
    }

    /**
     * @description removes data object from data list and display delete notification
     * @param {Object} data
     * @memberof DrcDataGrid
     */
    delete(data) {
        let list = [...this.state.data];
        list = list.filter((c) => c.key !== data.key);

        this.growl.show({ severity: 'info', summary: 'Delete', detail: data.key + ' - ' + data.label });
        this.setState({
            data: list
        });
    }

    /**
     * @description returns unordered list of data labels
     * @param {Array} data
     * @returns {*} list element
     * @memberof DrcDataGrid
     */
    displaySelection(data) {
        if (!data || data.length === 0) {
            return <div style={{ textAlign: 'left' }}>No Selection</div>;
        } else {
            if (data instanceof Array)
                return (
                    <ul style={{ textAlign: 'left', margin: 0 }}>
                        {data.map((data, i) => (
                            <li key={data.key}>{data.label}</li>
                        ))}
                    </ul>
                );
            else return <div style={{ textAlign: 'left' }}>Selected: {data.label}</div>;
        }
    }

    /**
     * @description loadRows returns an array with specific number of of rows from the given start index.
     * @param {Number} index start index of list
     * @param {Number}} length length of rows required.
     * @returns {Array} Array of list objects
     * @memberof DrcDataGrid
     */
    loadRows(index, length) {
        let chunk = [];
        for (let i = 0; i < length; i++) {
            chunk[i] = { ...this.props.rows[i], ...{ vin: index + i } };
        }

        return chunk;
    }

    /**
     * @description onScroll handler in case the user scrolls to the end of the page. Will load more data to the row array and append to the list.
     * @param {*} event
     * @memberof DrcDataGrid
     */
    onVirtualScroll = async (event) => {
        let page = this.props.pageSize;

        // check if the records exist
        if (this.props.rows.length < event.first + event.rows) {
            // if user scrolled more than was suppose to
            // find the difference and get all records
            if (this.props.rows.length <= event.first) {
                page = event.rows + (event.first - this.props.rows.length);
            }

            if (event.first >= this.props.totalRecords - this.props.pageSize) {
                this.setState(
                    {
                        from: event.first,
                        to: this.props.pageSize
                    },
                    async () => await this.props.loadData(page)
                );
            } else {
                this.setState(
                    {
                        from: event.first,
                        to: event.rows
                    },
                    async () => await this.props.loadData(page)
                );
            }
        } else {
            this.setState({ currentRows: this.loadRows(event.first, event.rows) });
        }
    };

    /**
     *
     * @returns {*} Data Table with customizable columns and row data
     * @memberof DrcDataGrid
     */
    render() {
        const {
            onRowClick,
            rows,
            pageSize,
            hideCount,
            classes,
            height,
            resultCount,
            className,
            totalRecords,
            lazy,
            virtualScroll,
            gridStyles,
            paginator,
            paginatorTemplate,
            onPage,
            rowsPerPageOptions,
            currentPage,
            showReport,
            currentPageReportTemplate,
            selectionChange,
            onAllRowsUnselect,
            selected,
            dragSelection,
            cellSelection,
            tableSelectionMode,
            simplePaginator,
            compact
        } = this.props;

        const { menu } = this.state;

        //Simple Paginator Setup
        const [simplePageSize, simpleResultSize] = simplePaginator || [0, 0];
        let actualPageSize = (simplePageSize > 0 ? simplePageSize : pageSize) || ROWS_PER_PAGES[0];
        let actualResultSize = (simpleResultSize > 0 ? simpleResultSize : resultCount) || totalRecords || 0;
        let usePaginator = simplePageSize > 0 ? simplePageSize < simpleResultSize : paginator;

        let emptyRowsMessage = this.props.emptyRowsMessage || 'No items found';

        var rowsArray = rows || [],
            rowCount = actualPageSize > 0 ? actualResultSize : rowsArray.length;
        var columns = this.constructColumns();
        var gridPaginatorTemplate = paginatorTemplate || PAGINATOR_TEMPLATE;
        // since each column height is 35px + 25 for padding
        var calculatedHeight = 50 * rowCount;
        var heightAdjusted = parseInt(height);
        var isPaginatorActive = usePaginator && actualResultSize > 0;

        if (calculatedHeight < heightAdjusted) {
            heightAdjusted = calculatedHeight + 50 + 'px'; // 50 more is to offset em's
        } else {
            heightAdjusted = height + 'px';
        }

        var reportTemplate = showReport ? currentPageReportTemplate || 'Showing {first} to {last} of {totalRecords} entries' : '';

        return (
            <div className={`${classes.root} ${classes.grid} ${compact ? classes.gridCompact : null} ${gridStyles} ${className ? className : null}`}>
                {menu.length > 0 && <ContextMenu model={this.state.menu} ref={(el) => (this.cm = el)} onHide={() => this.setState({ selected: null })} />}
                <DataTable
                    currentPageReportTemplate={reportTemplate}
                    first={currentPage}
                    paginator={isPaginatorActive}
                    paginatorTemplate={gridPaginatorTemplate}
                    rowsPerPageOptions={rowsPerPageOptions || ROWS_PER_PAGES}
                    onPage={onPage}
                    editMode={this.props.editMode || null}
                    ref={(el) => (this.dt = el)}
                    value={isPaginatorActive ? rows : lazy ? this.state.currentRows : rows}
                    scrollable={true}
                    scrollHeight={heightAdjusted || '500px'}
                    scrollwidth={'100%'}
                    style={{ height: 'auto' }}
                    emptyMessage={emptyRowsMessage}
                    onContextMenu={menu.length > 0 ? (e) => this.cm.show(e.originalEvent) : null}
                    onRowClick={onRowClick}
                    rows={actualPageSize}
                    totalRecords={actualResultSize || rows.length}
                    virtualScroll={virtualScroll}
                    onVirtualScroll={this.onVirtualScroll}
                    lazy={lazy}
                    columnResizeMode={this.props.columnResizeMode || 'expand'}
                    {...(this.props.resizableColumns ? { resizableColumns: this.props.resizableColumns } : { resizableColumns: false })}
                    virtualRowHeight={this.props.virtualRowHeight || 28}
                    rowClassName={this.props.rowClassName || null}
                    selection={selected}
                    onSelectionChange={selectionChange}
                    {...(this.props.selectAll ? { selectAll: this.props.selectAll } : {})}
                    {...(this.props.onSelectAllChange ? { onSelectAllChange: this.props.onSelectAllChange } : {})}
                    {...(tableSelectionMode ? { selectionMode: tableSelectionMode } : {})}
                    cellSelection={cellSelection || false}
                    dragSelection={dragSelection || false}
                    onAllRowsUnselect={onAllRowsUnselect}
                    // contextMenuSelection={this.state.selected}
                    // onContextMenuSelectionChange={(e) => this.setState({ selected: e.value })}
                    // onContextMenu={(e) => this.cm.show(e.originalEvent)}
                    // scrollHeight="250px"
                    // footer={this.displaySelection(this.state.selected)}
                >
                    {columns}
                </DataTable>
                {!hideCount && !showReport ? (
                    <React.Fragment>
                        <br />
                        <span className={`gridCount ${classes.label}`}>
                            {rowCount > 0
                                ? (this.state.filteredRecordsCount > 0 && this.state.filteredRecordsCount !== rowCount ? `Found: ${this.state.filteredRecordsCount} - ` : ``) +
                                  `Total : ${rowCount}`
                                : emptyRowsMessage}
                        </span>
                    </React.Fragment>
                ) : null}
            </div>
        );
    }
}

export default withStyles(styles)(DrcDataGrid);
