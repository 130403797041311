import { useNavigate, useLocation, useParams } from 'react-router-dom';

export const withRouter = (Component) => {
    const Wrapper = (props) => {
        const history = useNavigate();
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return <Component history={history} {...props} router={{ location, navigate, params }} />;
    };
    return Wrapper;
};
