import { ExceptionsPage, syncTranslations } from '@driscollsinc/ggs-dashboard-module-ui';
import { Breadcrumbs, Typography } from '@mui/material';
import { withOktaAuth } from '@okta/okta-react';
import { setPageTitleAction } from 'actions/actions';
import { setSettlementWorkbenchData } from 'actions/settlementWorkbenchAction';
import DrcWrapper from 'components/DrcWrapper';
import Stepper from 'components/Stepper';
import { designColors } from 'data/constants';
import useDashboard from 'hooks/v2/useDashboard';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { withRouter } from 'routes/withRouter';
import DuExcelUtilities from 'utils/excelUtility';
import { getLanguage } from 'utils/helper';

function Exceptions(props) {
    const [exceptionAttributes, setExceptionAttributes] = useState([]);
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchText, setSearchText] = useState();
    const [loading, setLoading] = useState(false);
    const [loadingPercent, setLoadingPercent] = useState('');
    const { getExceptions } = useDashboard(props.oktaAuth);
    const { i18n } = useTranslation();
    const [params] = useSearchParams();
    const language = localStorage.getItem('GGS_UserLang');
    let exportData = { columns: {}, data: [], totalItems: 0 };
    const category = decodeURIComponent(params.get('Type'));
    const dataType = decodeURIComponent(params.get('datatype'));
    const label = decodeURIComponent(params.get('label'));
    const poolweek = decodeURIComponent(params.get('poolweek'));
    const items = decodeURIComponent(params.get('items'));
    const itemPerPage = parseInt(items) || 5000;

    useEffect(() => {
        syncTranslations(getLanguage(language));
    }, [language]);

    const exportAsExcel = (data) => {
        const excelColumns = exceptionAttributes.map((col) => ({
            key: col.accessorKey,
            name: col.accessorKey
        }));
        DuExcelUtilities.Write('GGS- Data.xlsx', excelColumns, data);
    };

    const handleFileExport = async () => {
        setLoading(true);
        downloadPage(0);
    };

    const downloadPage = async (page: any) => {
        try {
            setLoadingPercent(Math.min(100, Math.round((page / itemPerPage / (totalCount / itemPerPage)) * 100)) + '%');
            const data = await getExceptions({ limit: itemPerPage + '', offset: page + '', ...getSearchParams() }, searchText);
            exportData.data = exportData?.data?.concat(data.data);
            if (page < totalCount - itemPerPage) {
                page += itemPerPage;
                page = page >= totalCount ? totalCount - itemPerPage : page;
                downloadPage(page);
            } else {
                exportAsExcel(exportData);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const getSearchParams = (): { [key: string]: string } => {
        let entries = {};
        params.forEach((value, key) => {
            entries = { ...entries, [key]: value };
        });
        return entries;
    };

    return (
        <DrcWrapper>
            <div style={{ padding: '0px 20px', height: '100%' }}>
                <ExceptionsPage
                    breadcrumbs={
                        <Breadcrumbs sx={{ color: 'black', '@media (prefers-color-scheme: dark)': { color: `#a7a7a7 !important` } }}>
                            <Typography sx={{ color: designColors.blue, '@media (prefers-color-scheme: dark)': { color: 'white' } }} fontWeight={500}>
                                Home
                            </Typography>
                            <Typography>Exception</Typography>
                        </Breadcrumbs>
                    }
                    title={'Exception'}
                    loading={loading}
                    loadingPercent={loadingPercent}
                    exceptionAttributes={exceptionAttributes}
                    fetchEntries={async (page, searchString, params) => {
                        if (page === 0) {
                            setLoading(true);
                        }
                        params = { ...params, Type: category };
                        const data = await getExceptions(
                            { limit: 100, offset: page * 100, Type: category, datatype: dataType, label: label, poolweek: poolweek },
                            searchString
                        );
                        setLoading(false);
                        setTotalCount(data.totalItems);
                        setSearchText(searchString);
                        setExceptionAttributes(data.columns);
                        setData((currData) => [...currData, ...data.data]);
                        return data;
                    }}
                    handleFileExport={handleFileExport}
                />
            </div>
        </DrcWrapper>
    );
}

const mapStateToProps = ({ settlementWorkbenchReducer, rootReducer }) => ({
    total: settlementWorkbenchReducer.totalRecords,
    pageTitle: rootReducer.pageTitle,
    records: settlementWorkbenchReducer.records
});

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setSettlementWorkbenchData: (data) => dispatch(setSettlementWorkbenchData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withRouter(Exceptions)));
