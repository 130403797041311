import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Translate } from 'react-localize-redux';
import DrcButton from './DrcButton';
import DrcIconButton from './DrcIconButton';
import DrcDialog from './DrcDialog';
import GetAppIcon from '@mui/icons-material/GetApp';
import { isIOS, isSafari, isEdge, isChrome, isMobile } from 'react-device-detect';

const useStyles = makeStyles({
    btn: {
        position: 'fixed',
        bottom: 0,
        background: 'transparent !important',
        borderColor: 'transparent !important',
        margin: 0
    }
});

function DrcPwaInstallHelper() {
    const classes = useStyles();
    const [pwaDialogOpen, setPwsDialogOpen] = useState(false);

    const getInfo = () => {
        if (isIOS || isSafari) {
            return (
                <div>
                    <p>
                        <Translate id="pwa.apple1" />
                    </p>
                    <Translate id="pwa.apple2" options={{ renderInnerHtml: true }} />
                </div>
            );
        } else if (isEdge) {
            return (
                <div>
                    <Translate id="pwa.edge" options={{ renderInnerHtml: true }} />
                </div>
            );
        } else if (isChrome) {
            if (isMobile) {
                return (
                    <div>
                        <Translate id="pwa.chromeMobile" options={{ renderInnerHtml: true }} />
                    </div>
                );
            }
            return (
                <div>
                    <Translate id="pwa.chrome" options={{ renderInnerHtml: true }} />
                </div>
            );
        }

        return (
            <div>
                <Translate id="pwa.sorry" />
            </div>
        );
    };

    return (
        <>
            <DrcIconButton
                className={classes.btn}
                onClick={() => {
                    setPwsDialogOpen(true);
                }}
            >
                <GetAppIcon />
            </DrcIconButton>
            <Translate>
                {({ translate }) => (
                    <DrcDialog
                        title={translate('pwa.title', null, { renderInnerHtml: false })}
                        open={pwaDialogOpen}
                        onClose={() => {
                            setPwsDialogOpen(false);
                        }}
                        buttons={
                            <>
                                <DrcButton
                                    onClick={() => {
                                        setPwsDialogOpen(false);
                                    }}
                                >
                                    {translate('buttons.okay', null, { renderInnerHtml: false })}
                                </DrcButton>
                            </>
                        }
                    >
                        {getInfo()}
                    </DrcDialog>
                )}
            </Translate>
        </>
    );
}

export default DrcPwaInstallHelper;
