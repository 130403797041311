import React from 'react';
import { Divider } from '@mui/material';
import DrcWrapper from '../../../components/DrcWrapper';
import PrimeReactTable from '../../../components/PrimeReactTable';
import Stepper from '../../../components/Stepper';
import { withStyles, withTheme } from '@mui/styles';
import { DrcTranslate } from 'drc/driscolls-react-components/index';
import GridStyles from '../../../styles/gridStyles';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast, setPageTitleAction } from '../../../actions/actions';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { makeColumns, displayErrorMessage } from '../../../utils/helper';
import ActionsFormatter from '../../../components/cellFormatters/ActionFormatter';
import MasterDataUtilities from '../../../data/MasterDataUtilities';
import { Call } from '@driscollsinc/driscolls-display-rules';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import GGSFormInput from '../../../components/GGSFormInput';
import Form from 'react-validation/build/form';
import { journalAccountDsCol, LOOKUP_FIELDS } from '../../../data/constants';

const pageTitle = 'Journal Account';
const styles = (theme) => ({
    input: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'center'
        }
    },
    gridStyles: GridStyles.styles(theme, '300px', '300px'),
    tableContainer: {
        margin: theme.spacing(0, 3)
    },
    root: {
        '& .select': {
            top: 2
        },
        margin: '-5px 0px 0px'
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    pageActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
});

class JournalAccount extends React.Component {
    state = {
        data: [],
        pageNum: 0,
        selectedRow: [],
        pageSize: 50,
        totalCount: 0,
        columns: [],
        rowData: null,
        editingRow: {}
    };
    excludingFields = ['Actions'];
    readOnly = false;
    uniqueKeyField = 'AccountNumber';
    widthIsSufficient = window.innerWidth > 955;
    widthIsInSufficient = false;

    validationFields = {};

    componentDidMount() {
        this.loadData();
        window.addEventListener('resize', this.updateDataTable);
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDataTable);
    }

    editData = async (_event, data, _index) => {
        this.setState({
            editingRow: {
                ...data,
                PackageRate: data.getPreviousData().PackageRate + '',
                FreightRate: data.getPreviousData().FreightRate + '',
                [data[this.uniqueKeyField]]: true
            }
        });
    };
    cancelEdit = (_event, _rowData) => {
        this.setState({ editingRow: {} });
    };
    cannotEdit = (_row) =>  !(this.props.isUserAdmin && this.props.selectedRole.includes('-Admin'));
    deleteData = async (_rowData) => null;
    confirmEdit = async (_event, _rowData) => {
        try {
            this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            var data = await Call(
                {
                    name: 'JournalAccount',
                    url: '',
                    method: 'PUT',
                    options: {},
                    fakeData: {
                        LineMemo: '' + this.state.editingRow['LineMemo'],
                        AccountName: this.state.editingRow['AccountName'],
                        ModifiedBy: loggedInUser,
                        ModifiedDateTime: new Date().toISOString()
                    }
                },
                token
            );
            if (!data) {
                this.showError();
            } else {
                this.props.hideLoadingScreenAction();
                this.setState({ editingRow: {} });
                this.loadData();
                this.props.showToast(<DrcTranslate>{'Updated Successfully'}</DrcTranslate>, true);
            }
        } catch (error) {
            this.showError(error);
        }
    };
    cannotDelete = (_row) => null;

    getActionsCell = (row) => {
        return (
            <ActionsFormatter
                rowEdit={true}
                uniqueKeyField={this.uniqueKeyField}
                objState={this}
                records={this.state.data}
                initRowEdit={this.editData}
                confirmEdit={this.confirmEdit}
                cancelEdit={this.cancelEdit}
                cannotDelete={this.cannotDelete}
                cannotEdit={this.cannotEdit}
                deleteAttribute={this.deleteData}
                row={row}
                rowUnderEditUniqueKeyValue={row[this.uniqueKeyField]}
            />
        );
    };

    updateTableData = (response, hasFrozenColumns = true) => {
        let data = response?.display?.Data || [];
        let columns = data.length
            ? makeColumns(Object.keys(data[0]), this.excludingFields, this.getActionsCell, true, ['LineMemo', 'AccountName'], [], journalAccountDsCol)
            : [];
        this.setState({
            data,
            totalCount: response?.display?.TotalCount || 0,
            columns,
            unfreeze: !hasFrozenColumns
        });
    };
    updateDataTable = (_event, force = false) => {
        if (window.innerWidth > 955 && !this.widthIsSufficient) {
            this.widthIsSufficient = true;
            this.widthIsInSufficient = false;
            this.updateTableData(this.response || [], true);
        } else if (window.innerWidth <= 955 && !this.widthIsInSufficient) {
            this.widthIsInSufficient = true;
            this.widthIsSufficient = false;
            this.updateTableData(this.response || [], false);
        } else if (force) {
            this.updateTableData(this.response || [], true);
        }
    };

    loadData = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            var data = await Call(
                {
                    name: 'JournalAccount',
                    url: '',
                    method: 'GET',
                    fakeData: {
                        data: {
                            Data: [
                                {
                                    AccountNumber: '11310005',
                                    TransactionType: 'Debit',
                                    LineMemo: '水果销售收入 Fruit Sales Revenue Recognition @ <YY>W<WK>',
                                    AccountName: '400379 卓莓企业管理（上海）有限公司',
                                    ReportType: 'Preliminary',
                                    DisplayOrder: '1',
                                    EntityType: 'FamilyEntity',
                                    Entity: 'All',
                                    Comments: 'sum(FinalSHBooking) : Transactions with FamilyEntity Mapping',
                                    CreatedBy: 'Script',
                                    CreatedDateTime: '2022-04-25 17:28:38',
                                    ModifiedBy: 'Script',
                                    ModifiedDateTime: '2022-04-25 17:28:38'
                                }
                            ],
                            TotalCount: 1
                        }
                    }
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }
            this.response = data;
            this.updateDataTable(null, true);
            this.props.hideLoadingScreenAction();
        } catch (error) {
            this.showError(error);
        }
    };
    getRowEditor = (key, col, others) => {
        var field = this.validationFields[key] ?? {};
        switch (key) {
            case 'LineMemo':
            case 'AccountName':
                return this.inputTextEditor(key, col, others, field.customProps, field.validations);
        }
    };

    inputTextEditor = (key, _col, _others, customProps, validations = []) => {
        return (
            <Form>
                <GGSFormInput
                    className={this.props.classes.input}
                    onChange={(e) => {
                        return this.setState(() => ({
                            editingRow: { ...this.state.editingRow, [key]: e.target.value }
                        }));
                    }}
                    value={this.state.editingRow[key]}
                    validations={validations}
                    {...customProps}
                />
            </Form>
        );
    };

    onPage = (event) => {
        this.setState({ pageNum: event.first });
        this.loadData();
    };

    showError = (err, msg) => {
        this.props.hideLoadingScreenAction();
        this.props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };
    applyFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadData());
    };

    toggleShowFilter = () => this.setState({ showFilter: !this.state.showFilter });

    resetFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadData());
    };

    render() {
        const { classes } = this.props;
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        return (
            <DrcWrapper>
                <Stepper page={pageTitle} isDataSetup />
                <Divider />
                <div className={classes.tableContainer}>
                    <PrimeReactTable
                        value={this.state.data}
                        columns={this.state.columns}
                        editMode="row"
                        frozenWidth={'70px'}
                        tableSelectionMode="checkbox"
                        uniqueKeyField={this.uniqueKeyField}
                        editingRows={this.state.editingRow}
                        paginator={this.state.data.length > 0}
                        currentPage={this.state.pageNum}
                        pageSize={this.state.pageSize}
                        onPage={this.onPage}
                        totalRecords={this.state.totalCount}
                        editor={(key, col, others) => {
                            return this.getRowEditor(key, col, others);
                        }}
                    />
                </div>
            </DrcWrapper>
        );
    }
}
const mapStateToProps = ({ masterReducer, filterReducer, rootReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    userLang: masterReducer.userLang,
    uomLookUp: masterReducer[LOOKUP_FIELDS.UOMLookUp],
    pageTitle: rootReducer.pageTitle,
    filters: filterReducer.filters,
    isUserAdmin: rootReducer.userIsAdmin,
    selectedRole: rootReducer.selectedRole
});

const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withTheme(withStyles(styles)(JournalAccount))));
