import { userBusinessUnit } from '../utils/helper';
import { BUSINESS_UNIT_COLUMN_MAP } from './BusinessUnitColumnMapping';

export const designColors = {
    blue: '#0076a5',
    grey: '#D4D4D4',
    green: '#4a773c',
    red: '#da291c',
    pink: '#c63663',
    purple: '#6f5091',
    yellow: '#fdda24',
    hover: '#11a2de',
    white: '#FFFFFF',
    lightGreen: '#Abf987',
    lightGrey: 'hsl(0deg 0% 85%)'
};
//Using converted date from DDR
export const dateFields = [];

//Using converted date time from DDR
export const dateTimeFields = [];

export const percentFields = [
    // 'TurnOverRateWK1',
    // 'TurnOverRateWK2',
    // 'TurnOverRateWK3',
    'DamageLoss',
    'GrowerOwnPercentage',
    'FamilyEntityPercentage',
    'CommissionRate',
    'VATPercent',
    'VarianceNormalSettlementUnitPrice',
    'VarianceNormalSettlementUnitPriceWithRepack',
    'DSAServiceFee'
];

export const decimalFields = [
    'Amount',
    'CommissionPercent',
    'FixedFeeCostAmount',
    'InvoiceAmount',
    'Quantity',
    'SettlementAvgUnitPrice',
    'ItemRate',
    'OpeningBalanceKG',
    'SalesQty',
    'ReceiptQty',
    'Balance',
    'SalesOrderSold',
    'ClosingBalanceKG',
    'IncludeQuality',
    'DamageLoss',
    'QtyOrdered',
    'SettlementCommissionAmt',
    'SettlementLineAmount',
    'SettlementNetAmount',
    'SoldQty',
    'OriginalQty',
    'AdvanceAmount',
    'PFQFactor',
    'PFQScore',
    'PFQUnitPrice',
    'OpeningQty',
    'NormalSalesQty',
    'NormalSalesAmount',
    'NormalSalesUnitPrice',
    'DSharedSalesQty',
    'DSharedSalesAmount',
    'DSharedSalesUnitPrice',
    'AbnormalSalesQty',
    'AbnormalSalesAmount',
    'AbnormalSalesUnitPrice',
    'SampleDamageSalesQty',
    'QualityIssueSalesQty',
    'RepackagingSalesQty',
    'OnHand',
    'InTransit',
    'ClosingQty',
    'SettlementUnitPrice',
    'SettlementUnitPriceWithRepack',
    'PrelimAverageSettlementPriceWK1',
    'PrelimAverageSettlementPriceWK2',
    'PrelimAverageSettlementPriceWK3',
    'FinalAverageSettlementPrice',
    'FinalTrueUpAverageSettlementPrice',
    'PrelimWODamageAverageSettlementPrice',
    'SoldQtyWK1',
    'SoldQtyWK2',
    'SoldQtyWK3',
    'TurnOverRateWK1',
    'TurnOverRateWK2',
    'TurnOverRateWK3',
    'RepackageMovement',
    'RepackageClosingQty',
    'TrayToKilogram',
    'VarianceNormalSettlementUnitPrice',
    'VarianceNormalSettlementUnitPriceWithRepack',
    'RepackageActualOnHand',
    'CustomClearanceFee',
    'FreightCustomToFDC',
    'RepackagingCharge',
    'CommissionRate',
    'TotalTraysReceived',
    'UOMConversionRate',
    'TotalKilosReceived',
    'AveragePricePerTray',
    'AveragePricePerKg',
    'GrossPoolAmount',
    'PackageRate',
    'PackageDeduction',
    'FreightRate',
    'FreightDeduction',
    'PkgNetFruitReturnPricePerKg',
    'NetFruitBeforeDAPshare',
    'NetFruitReturnPricePerKg',
    'DAPShare',
    'CommissionNetPoolReturn',
    'CommissionNetFruitReturnPricePerKg',
    'VATPercent',
    'VATShareGrower',
    'VATNetPoolReturn',
    'VATNetFruitReturnPricePerKg',
    'Price',
    'ShareGrDriscollsPercent',
    'ShareGrGrowerPercent',
    'MidSizeGrDriscollsPercent',
    'MidSizeGrGrowerPercent',
    'TotalKilosReceived',
    'DriscollsXishuangbannaAP',
    'DriscollsQujingAP',
    'SharePercentage',
    'ShareYunnan',
    'PackageRate',
    'ShareSevenknot',
    'DriscollsSevenknotAP',
    'PkgNetReturnToGrower',
    'ShareXishuangbanna',
    'PackagingIssurance',
    'PackagingCredit',
    'DriscollsYunnanAP',
    'ShareGrowerAllowance',
    'NetFruitReturnPricePerKg',
    'TotalTraysReceived',
    'ShareGrowerDeduction',
    'CommissionRate',
    'ShareQujing',
    'NetReturnToGrower',
    'AllDeductNetReturnToGrower',
    'TotalCashToGrower',
    'TrayToKilogram',
    'ServiceDeductionAmount',
    'ExchangeRate',
    'PreliminarySHBooking',
    'FinalSHBooking',
    'PreliminaryGrowerBooking',
    'FinalGrowerBooking',
    'PreliminaryFamilyEntityBooking',
    'FinalFamilyEntityBooking',
    'Blueberry',
    'Raspberry',
    'Strawberry',
    'Blackberry',
    'QuantityKg',
    'Credit',
    'Debit',
    'Total',
    'GrowerOwn',
    'DriscollsAmount',
    'GrowerAmount',
    'GrowerOwnPercentage',
    'FamilyEntityPercentage',
    'LastWeekRepackSKUTransferBack',
    'RepackSKUTransferBack',
    'Others1',
    'TransferRate',
    'DMSHShare',
    'PoolWeekAveragePricePerKg',
    'NetPoolReturn',
    'DomesticFreight',
    'SettledAveragePricePerTray',
    'UnitReturnPerKg',
    'TransNetFruitReturnPricePerKg',
    'DSAServiceFeeCost',
    'PoolingAmount',
    'AdvanceRate',
    'TotalCostsDeducted',
    'DSAServiceFee',
    'DirectSalesReturn',
    'AdvancePaid',
    'NormalSalesIncludeDshared',
    'DisposalQty',
    'RepackMovementSalesQty',
    'RemainingPayment'
];

export const currencyCols = [
    'SettledAveragePricePerTray',
    'PoolWeekAveragePricePerKg',
    'TransNetFruitReturnPricePerKg',
    'TotalCostsDeducted',
    'DirectSalesReturn',
    'UnitReturnPerKg',
    'DSAServiceFeeCost',
    'DomesticFreight',
    'PoolingAmount',
    'DMSHShare',
    'NetPoolReturn',
    'AdvancePaid',
    'CommissionNetFruitReturnPricePerKg',
    'CustomClearanceFee',
    'SettledAveragePricePerTray',
    'FreightCustomToFDC',
    'RepackagingCharge',
    'Others1',
    'RemainingPayment'
];

export const drawerWidth = 200;
export const convertDateToIso = (value) => {
    // function to convert date in YYYY-MM-DD format with correct time zone
    const date = new Date(value);
    const offset = date.getTimezoneOffset();
    const yourDate = new Date(date.getTime() - offset * 60 * 1000);
    return yourDate.toISOString().split('T')[0];
};

export const getAccordionColor = (_theme, status) => {
    let color = '';
    switch (status) {
        case 'Not Run':
            color = designColors.red;
            break;
        case 'Approved':
            color = designColors.green;
            break;
        case 'Pending Approval':
            color = designColors.yellow;
            break;
        case 'Processed':
            color = designColors.blue;
            break;
        case 'Processing':
            color = '#FEF4BD';
            break;
        case 'Rejected':
            color = designColors.purple;
            break;
        case 'Failed':
            color = '#000';
            break;
        case 'Published':
            color = designColors.grey;
            break;
        case 'Partially Published':
            color = designColors.lightGreen;
            break;
        default:
            color = designColors.red;
    }
    return color;
};

export const statusTypes = [
    'Not Run',
    'Failed',
    'Processing',
    'Processed',
    'Pending Approval',
    'Approved',
    'Rejected',
    'Published',
    'Partially Published'
];
export const poolStatus = ['New', 'Approved', 'Closed'];

export const middlewareNoLoaderConfig = {
    showLoadingScreen: () => null,
    hideLoadingScreen: () => null
};
export const middlewareNoMappingConfig = {
    overrideRequestMapping: true,
    overrideResponseMapping: true
};

export const middlewareConfig = (noLoader, noMapping, loadingMessage) => {
    let config = {};
    if (noLoader) {
        config = { ...config, ...middlewareNoLoaderConfig };
    }

    if (noMapping) {
        config = { ...config, ...middlewareNoMappingConfig };
    }

    if (loadingMessage) {
        config = { ...config, loadingMessage };
    }

    return config;
};

export const receiptLinesColumns = () => {
    return BUSINESS_UNIT_COLUMN_MAP[userBusinessUnit()]['receiptLinesColumns'];
};

export const salesInvoiceLineColumns = () => {
    return BUSINESS_UNIT_COLUMN_MAP[userBusinessUnit()]['salesInvoiceLineColumns'];
};

export const inventoryOnHandColumns = () => {
    return BUSINESS_UNIT_COLUMN_MAP[userBusinessUnit()]['inventoryOnHandColumns'];
};

export const goodsInTransitColumns = () => {
    return BUSINESS_UNIT_COLUMN_MAP[userBusinessUnit()]['goodsInTransitColumns'];
};

export const ServiceDeductionColumns = () => {
    return BUSINESS_UNIT_COLUMN_MAP[userBusinessUnit()]['ServiceDeductionColumns'];
};

export const growerSettlementReport = () => {
    return BUSINESS_UNIT_COLUMN_MAP[userBusinessUnit()]['growerSettlementReport'];
};

export const excludeAveragePricingOptions = [
    { label: 'Yes', value: 'Y' },
    { label: 'No', value: 'N' }
];

export const commodityOptions = [
    { label: 'Blackberry', value: 'Blackberry' },
    { label: 'Blueberry', value: 'Blueberry' },
    { label: 'Raspberry', value: 'Raspberry' },
    { label: 'Strawberry', value: 'Strawberry' }
];

export const geographyOptions = [
    { label: 'Domestic', value: 'Domestic' },
    { label: 'International', value: 'International' }
];

export const currencyOptions = [
    { label: 'CNY', value: 'CNY' },
    { label: 'USD', value: 'USD' },
    { label: 'EUR', value: 'EUR' }
];

export const currencySymbols = {
    CNY: '¥',
    USD: '$',
    EUR: '€'
};

export const BU_CURRENCY = {
    DOC: '¥',
    DOTA: '$',
    DEMEA: '€'
};

export const BU = ['DOC', 'DOTA', 'DEMEA'];

export const businessUnitOptions = [
    { label: 'DOC', value: 'DOC' },
    { label: 'DOTA', value: 'DOTA' },
    { label: 'DEMEA', value: 'DEMEA' }
];

export const reportTypeOptions = [
    { label: 'Domestic', value: 'Domestic' },
    { label: 'National', value: 'National' },
    { label: 'international', value: 'international' }
];

// this is added in UI as Report Type
export const versionOptions = [
    { label: 'Preliminary', value: 'Preliminary' },
    { label: 'Final', value: 'Final' }
];

export const growerCategoryOptions = [
    { label: 'Transfer Rate', value: 'Transfer Rate' },
    { label: 'Advance Rate', value: 'Advance Rate' }
];

export const reportOptions = [
    // { label: 'Settlement Report', value: 'Settlement Report' },
    { label: 'Grower Settlement Report', value: 'GrowerSettlementReport' },
    { label: 'Weekly Sales Report', value: 'WeeklySales' },
    { label: 'Inventory Movement', value: 'InventoryMovement' },
    { label: 'Average Sales Price', value: 'AverageSalesPrice' },
    { label: 'Turn Over Rate Domestic', value: 'TurnOverRateDomestic' },
    { label: 'Turn Over Rate Import', value: 'TurnOverRateImport' },
    { label: 'Item Inventory Outbound', value: 'ItemInventoryOutbound' },
    { label: 'Summary Domestic', value: 'SummaryDomestic' },
    { label: 'Summary Costa', value: 'SummaryCosta' },
    { label: 'Aged Inventory', value: 'AgedInventory' },
    { label: 'Receipt By Cost Center', value: 'ReceiptByCostCenter' },
    { label: 'Receipt By Grower', value: 'ReceiptByGrower' },
    { label: 'JE - Shanghai', value: 'Shanghai' },
    { label: 'JE - CC Allocation', value: 'CCAllocation' },
    { label: 'JE - Yunnan', value: 'Yunnan' },
    { label: 'JE - Xishuangbanna', value: 'Xishuangbanna' },
    { label: 'JE - Qujing', value: 'Qujing' },
    { label: 'JE - Sevenknot', value: 'Sevenknot' },
    { label: 'JE-SH-Report', value: 'SH Report' },
    { label: 'Summary CNY', value: 'SummaryCNY' },
    { label: 'Summary Peru', value: 'SummaryPeru' },
    { label: 'Summary Chile', value: 'SummaryChile' },
    { label: 'Import Receipt By Cost Center', value: 'ImportReceiptByCostCenter' },
    { label: 'Import Grower Settlement', value: 'ImportGrowerSettlement' }
    // { label: 'Average Price & Turn Over Rates', value: 'Average Price & Turn Over Rates' }
];

export const categoryOptions = [
    { label: 'Direct Pack', value: 'Direct Pack' },
    { label: 'Repackaging Usage', value: 'Repackaging Usage' }
];

export const customerCodeOptions = [{ label: 'Costco', value: 'Costco' }];

export const LOOKUP_FIELDS = {
    LocationLookUp: 'LocationLookUp',
    CommodityLookup: 'CommodityLookup',
    CustomerCodeCategoryLookUp: 'CustomerCodeCategoryLookUp',
    UOMLookUp: 'UOMLookUp',
    GrowerCategoryLookUp: 'GrowerCategoryLookUp',
    ApplicationLookUp: 'ApplicationLookUp',
    EstimateGroupLookUp: 'EstimateGroupLookUp',
    CoolerNameLookUp: 'CoolerNameLookUp',
    FamilyEntityLookUp: 'FamilyEntityLookUp',
    CommodityOffsetWeekLookUp: 'CommodityOffsetWeekLookUp',
    GrowerFixedCategoryLookUp: 'GrowerFixedCategoryLookUp',
    AdjustmentCategory: 'AdjustmentCategory',
    GrowerModule: 'GrowerModule'
};

export const avgPriceReportColumnsMapping = () => {
    return BUSINESS_UNIT_COLUMN_MAP[userBusinessUnit()]['avgPriceReportColumnsMapping'];
};

export const weeklySalesReportColumnsMapping = () => {
    return BUSINESS_UNIT_COLUMN_MAP[userBusinessUnit()]['weeklySalesReportColumns'];
};

export const inventoryMovementReportColumnsMapping = () => {
    return BUSINESS_UNIT_COLUMN_MAP[userBusinessUnit()]['inventoryMovementReportColumns'];
};

export const averageSalesPriceReportColumnsMapping = () => {
    return BUSINESS_UNIT_COLUMN_MAP[userBusinessUnit()]['averageSalesPriceReportColumns'];
};

export const turnoverRateReportColumnsMapping = () => {
    return BUSINESS_UNIT_COLUMN_MAP[userBusinessUnit()]['turnoverRateReportColumns'];
};

export const inventoryOutboundReportColumnsMapping = () => {
    return BUSINESS_UNIT_COLUMN_MAP[userBusinessUnit()]['inventoryOutboundReportColumns'];
};

export const agedInventoryReportColumnsMapping = () => {
    return BUSINESS_UNIT_COLUMN_MAP[userBusinessUnit()]['agedInventoryReportColumns'];
};

export const settlementReportColumns = () => {
    return BUSINESS_UNIT_COLUMN_MAP[userBusinessUnit()]['settlementReportColumns'];
};

export const settlementWorkBenchSubmitterColumns = () => {
    return BUSINESS_UNIT_COLUMN_MAP[userBusinessUnit()]['settlementWorkBenchSubmitterColumns'];
};

export const settlementWorkBenchApproverColumns = () => {
    return BUSINESS_UNIT_COLUMN_MAP[userBusinessUnit()]['settlementWorkBenchApproverColumns'];
};

export const summaryDomesticColumns = () => {
    return BUSINESS_UNIT_COLUMN_MAP[userBusinessUnit()]['summaryDomesticColumns'];
};

export const costCenterColumns = () => {
    return BUSINESS_UNIT_COLUMN_MAP[userBusinessUnit()]['costCenterColumns'];
};

export const poolWeekDsColumns = {
    // BusinessUnit: 'BusinessUnit'
    PoolWeek: 'Pool Week',
    PoolWeekStartDate: 'Pool Week Start Date',
    PoolWeekEndDate: 'Pool Week End Date',
    CreatedBy: 'Created By',
    CreatedDateTime: 'Created Date Time'
};

const dateColumns = {
    ModifiedBy: 'Modified By',
    ModifiedDateTime: 'Modified Date Time',
    CreatedBy: 'Created By',
    CreatedDateTime: 'Created Date Time'
};

export const itemUomConversionDsColumns = {
    ItemID: 'Item ID',
    Description: 'Description',
    EstimateGroup: 'Estimate Group',
    Commodity: 'Commodity',
    TrayToKilogram: 'Tray To Kilogram',
    Abbreviation: 'Abbreviation',
    // BusinessUnit: 'Business Unit',
    // ItemListID: 'ItemListID',
    // Lang: 'Lang',
    ...dateColumns
};

export const itemShippingRatesDsColumns = {
    ItemID: 'Item ID',
    Commodity: 'Commodity',
    Currency: 'Currency',
    PackageUOM: 'Package UOM',
    PackageRate: 'Package Rate',
    FreightUOM: 'Freight UOM',
    FreightRate: 'Freight Rate',
    // "ItemShipRateID": 'ItemShipRateID,
    // BusinessUnit: 'Business Unit',
    ModifiedBy: 'Modified By',
    ModifiedDateTime: 'Modified Date Time',
    CreatedBy: 'Created By',
    CreatedDateTime: 'Created Date Time'
};

export const repackagingSkuMappingDsColumns = {
    RepackageItemID: 'Repackage Item ID',
    RepackageItemDescription: 'Repackage Item Description',
    OriginalItemID: 'Original Item ID',
    OriginalItemDescription: 'Original Item Description',
    // BusinessUnit: 'Business Unit',
    // RepackageItemMapID: 'Repackage Item Map ID,
    CustomerCode: 'Customer Code',
    ModifiedBy: 'Modified By',
    ModifiedDateTime: 'Modified Date Time',
    CreatedBy: 'Created By',
    CreatedDateTime: 'Created Date Time'
};

export const growerCategoryDsColumns = {
    GrowerName: 'Grower Name',
    VendorCode: 'Vendor Code',
    ContactEmailId: 'Contact Email Id',
    AccountNo: 'Account No',
    InactiveFlag: 'Inactive Flag',
    CustomerCode: 'Customer Code',
    ParentVendorCode: 'Parent Vendor Code',
    Category: 'Category',
    Geography: 'Geography',
    FamilyEntity: 'Family Entity',
    LocationCode: 'Location Code',
    CostCenter: 'Cost Center',
    ShortName: 'Short Name',
    Module: 'Module',

    // BusinessUnit: 'BusinessUnit',
    ...dateColumns
};

export const settlementRateDsColumns = {
    Commodity: 'Commodity',
    LocationCode: 'Location Code',
    CoolerName: 'Cooler Name',
    ApplicationNetSuite: 'Application',
    ApplicationFullName: 'Application Full Name',
    CommissionRate: 'Commission Rate',
    StartDateActive: 'Start Date Active',
    EndDateActive: 'End Date Active',
    // BusinessUnit: 'BusinessUnit',
    ...dateColumns
};

export const customerCodeDsColumns = {
    // BusinessUnit: 'BusinessUnit',
    CustomerCode: 'Customer Code',
    Category: 'Category',
    ExcludeNormalSalesQty: 'Exclude Normal Sales Qty',
    ExcludeNormalSalesAmt: 'Exclude Normal Sales Amt',
    ExcludeSettlementPriceQty: 'Exclude Settlement Price Qty',
    ExcludeSettlementPriceAmt: 'Exclude Settlement Price Amt',
    ...dateColumns
};

export const vatRateDsColumns = {
    Country: 'Country',
    State: 'State',
    // BusinessUnit: 'Business Unit',
    VATPercent: 'VAT Percent',
    EffectiveStartDate: 'Effective Start Date',
    EffectiveEndDate: 'Effective End Date',
    // VATPercentID: 'VAT Percent ID,
    ...dateColumns
};

export const GrowerFixedPriceColumns = {
    GrowerFixedPriceRateID: 'Grower ID',
    VendorCode: 'Vendor Code',
    GrowerName: 'Grower Name',
    Category: 'Category',
    Currency: 'Currency',
    Price: 'Price',
    BusinessUnit: 'Business Unit',
    EffectiveStartDate: 'Effective Start Date',
    EffectiveEndDate: 'Effective End Date',
    ...dateColumns
};

export const growerPercentageColumns = {
    GrowerSettlementRateID: 'Grower Percentage ID',
    Category: 'Category',
    Commodity: 'Commodity',
    GrowerOwnPercentage: 'Grower Own Percentage',
    FamilyEntityPercentage: 'Family Entity Percentage',
    EffectiveStartDate: 'Effective Start Date',
    EffectiveEndDate: 'Effective End Date',
    ...dateColumns
};

export const shipmentDetailsColumns = {
    BusinessUnit: 'Business Unit',
    PoolWeek: 'Pool Week',
    ...dateColumns
};

export const repackageMovementColumns = {
    Category: 'Category',
    RepackageItemID: 'Repackage Item ID',
    OriginalItemID: 'Original Item ID',
    OpeningBalanceKG: 'Opening Balance KG',
    SalesQty: 'Sales Quantity',
    ReceiptQty: 'Receipt Quantity',
    SalesOrderSold: 'Sales Order Sold',
    ClosingBalanceKG: 'Closing Balance KG',
    IncludeQuality: 'Include Quality',
    DamageLoss: 'Damage Loss',
    Remark: 'Remark',
    CustomerCode: 'Customer Code',
    BusinessUnit: 'Business Unit',
    PoolWeek: 'Pool Week',
    // RepackageMovementID: 'Repackage Movement ID',
    ...dateColumns
};

export const lookUpDsColumns = {
    LookupType: 'Lookup Type',
    LookupCode: 'Lookup Code',
    Meaning: 'Meaning',
    Description: 'Description',
    StartDateActive: 'Start Date Active',
    EndDateActive: 'End Date Active',
    BusinessUnit: 'Business Unit',
    // DisplaySequence: 'Display Sequence',
    EnabledFlag: 'Enabled Flag',
    // Tag: Tag,
    ...dateColumns
};

export const conversionRateDsColumns = {
    // Id: 'Id',
    FromSymbol: 'From Currency',
    ToSymbol: 'To Currency',
    ExchangeRate: 'Exchange Rate',
    RateDate: 'Rate Date'
    // ExchangeRateText: 'Exchange Rate Text',
    // DriscollRate: 'Driscoll Rate',
    // ExchangeSymbol: 'CNYUSD',
    // ExchangeSource: 'Exchange Source',
    // PullDate: 'Pull Date',
    // PullType: 'Pull Type',
    // RateDelay: 'Rate Delay',
    // RequestedBy: 'Requested By',
    // LastResult: 'Last Result',
    // LastResultMessage: 'LastResult Message',
    // ModifiedBy: 'Modified By',
    // ModifiedDateTime: 'Modified Date Time',
    // CreatedBy: 'Created By',
    // CreatedDateTime: 'Created Date Time'
};
export const ShipmentDsColumns = {
    ShipmentNumber: 'Shipment Number',
    GrowerID: 'Grower ID',
    ItemID: 'Item ID',
    FDC: 'FDC',
    Trays: 'Trays',
    ContainerNumber: 'Container Number',
    ReportType: 'Report Type',
    CustomClearanceFee: 'Custom Clearance Fee',
    FreightCustomToFDC: 'Freight Custom To FDC',
    RepackagingCharge: 'Repackaging Charge',
    Others1: 'Others 1',
    Others2: 'Others 2',
    Others3: 'Others 3',
    ...dateColumns
};
export const AdjustmentDsColumns = {
    GrowerID: 'Grower ID',
    PoolWeek: 'Pool Week',
    Category: 'Category',
    Comments: 'Comments',
    Currency: 'Currency',
    AdjustmentAmount: 'Adjustment Amount',
    BeforeCalculation: 'Before Calculation',
    ModifiedBy: 'Modified By',
    ModifiedDateTime: 'Modified Date Time',
    CreatedBy: 'Created By',
    CreatedDateTime: 'Created Date Time'
};
export const ImportSettelementRateColumns = {
    Module: 'Module',
    Variety: 'Variety',
    Breeder: 'Breeder',
    CCPercent: 'CC Percent',
    DSAServiceFee: 'DSA Service Fee'
};
export const APPROVER_GROUP_ID = {
    'DEV_GGS-DOC-Approver': '00g14xzu8be8gCBPw0h8',
    'TST_GGS-DOC-Approver': '00g14y9kcg4OSwWCJ0h8',
    'UAT_GGS-DOC-Approver': '00g16h5ypllLMHyui0h8',
    'QA_GGS-DOTA-Approver': '00g27prtwpsTjt6vk0h8',
    'STG_GGS-DOC-Approver': '00ghaakn157Iuuxf3357',
    'STG_GGS-DOTA-Approver': '00ghaahppjmfFZNlF357',
    'STG_GGS-DEMEA-Approver': '00ghaaoe0m678ePlf357',
    'PRD_GGS-DOC-Approver': '00ghaajbj3WSQiNnV357',
    'PRD_GGS-DOTA-Approver': '00ghaal40xZX5gmhb357',
    'PRD_GGS-DEMEA-Approver': '00ghaajta1O55DH2R357',
    'DEV_GGS-DOTA-Approver': '00g15lbixkg4pdtJt0h8',
    'TST_GGS-DOTA-Approver': '00g15lcjc44mdxDMt0h8'
};

export const overlayLevelOne = {
    TotalTraysReceived: { name: 'Total Trays Received', formula: '=a' },
    TotalKilosReceived: { name: 'Total Kilos Received', formula: 'c=a*kg convert rate' },
    AveragePricePerTray: { name: 'Pool Week Average Price/Tray', formula: '=b' },
    AveragePricePerKg: { name: 'Pool Week Average Price/Kilo', formula: 'd=b/kg convert rate' },
    GrossPoolAmount: { name: 'Gross Pool Amount', formula: '=c*d' }
};

export const overlayLevelTwo = {
    PackageDeduction: { name: 'Total PKG Ded', formula: '=-c*PKG rate (m)' },
    FreightDeduction: { name: 'Total Freight Deduction', formula: '=-c*Trans rate (n)' },
    // NetFruitBeforeDAPshare: { name: 'Net Fruit before DAP Share', formula: '=c*(d-m-n)' },
    NetFruitReturnPricePerKg: { name: 'Net Fruit Return Price/Kilo (Avg)', formula: '=d-m-n' }
};

export const commissionRateOverlay = {
    // TotalKilosReceived: { name: 'Total Kilos Received', formula: '=c', hidePriceTag: true },
    NetFruitBeforeDAPshare: { name: 'Net Fruit before DAP Share', formula: '=c*(d-m-n)' },
    DAPShare: { name: 'DAP Share', formula: '=c*(d-m-n)*commission rate% (cr%)' },
    CommissionNetPoolReturn: { name: 'Net Pool Return', formula: '=c*(d-m-n)*(1-cr%)' },
    CommissionNetFruitReturnPricePerKg: { name: 'Net Fruit Return Price/Kilo (Avg)', formula: '=(d-m-n)*(1-cr%)' }
};

export const vatRateReturnOverlay = {
    CommissionNetPoolReturn: { name: 'Pool Return', formula: '=c*(d-m-n)*(1-cr%)' },
    VATShareGrower: { name: 'VAT Part Share-Grower', formula: '=c*(d-m-n)*(1-cr%)*VAT rate% *(1-cr%)' },
    VATNetPoolReturn: { name: 'Net Pool Return', formula: '=c*(d-m-n)*(1-cr%)*(1+VAT rate% *(1-cr%))' },
    VATNetFruitReturnPricePerKg: { name: 'Net Fruit Return Price/Kilo (Avg)', formula: '=(d-m-n)*(1-cr%)*(1+VAT rate% *(1-cr%))' }
};

export const receiptByGrowerColumns = {
    Category: 'Category',
    GrowerID: 'Grower ID',
    GrowerName: 'Grower Name',
    CostCenter: 'Cost Center',
    Application: 'Application',
    Location: 'Location',
    TotalTraysReceived: 'Total Trays Received',
    PreliminarySHBooking: 'Preliminary SH Booking',
    FinalSHBooking: 'Final SH Booking',
    PreliminaryGrowerBooking: 'Preliminary Grower Booking',
    FinalGrowerBooking: 'Final Grower Booking',
    PreliminaryFamilyEntityBooking: 'Preliminary Family Entity Booking',
    FinalFamilyEntityBooking: 'Final Family Entity Booking'
};

export const importReceiptByCostCenterColumns = {
    Commodity: 'Commodity',
    ItemID: 'Item ID',
    Module: 'Module',
    CommissionRate: 'Commission Rate',
    GrowerID: 'Grower ID',
    GrowerName: 'Grower Name',
    GrowerLocation: 'Grower Location',
    SubGrower: 'Sub Grower',
    Abbreviation: 'Abbreviation',
    Variety: 'Variety',
    Location: 'Location',
    TrayToKilogram: 'Tray To Kilogram',
    TotalTraysReceived: 'Total Trays Received'
};
export const importSettlementReportColumns = {};

export const jeShanghaiFamilyEntity = {
    Date: 'Date',
    ReversalDate: 'Reversal Date',
    Memo: 'Memo',
    AccountNumber: 'Account Number',
    Debit: 'Debit',
    Credit: 'Credit',
    LineMemo: 'Line Memo',
    Subsidiary: 'Subsidiary',
    Name: 'Name',
    Commodity: 'Commodity'
};

export const jeShanghaiFinal = {
    Date: 'Date',
    'Vendor Invoice': 'Vendor Invoice',
    Vendor: 'Vendor',
    Account: 'Account',
    'Vendor Bill Expenses: Account': 'Vendor Bill Expenses: Account',
    Amount: 'Amount',
    'Vendor Bill Expenses: Memo': 'Vendor Bill Expenses: Memo',
    Commodity: 'Commodity'
};

export const jeSevenknotCols = {
    AccountNumber: 'Account Number',
    Debit: 'Debit',
    Credit: 'Credit',
    LineMemo: 'Line Memo',
    AccountName: 'Account Name',
    Subsidiary: 'Subsidiary',
    CostCenter: 'Cost Center',
    Commodity: 'Commodity'
};

export const jeSHFamilyEntity = {
    Code: 'Code',
    CostCenter: 'Cost Center',
    QuantityKg: 'Quantity Kg'
};

export const jeSHEntryBookingFinal = {
    // ReportHeader: 'ReportHeader',
    GrowerName: 'Grower Name',
    Category: 'Category',
    Raspberry: 'Raspberry',
    Blueberry: 'Blueberry',
    Blackberry: 'Blackberry',
    Strawberry: 'Strawberry'
};
export const jeCcAllocationFamilyEntityCol = {
    // GrowerID: 'Grower ID',
    GrowerName: 'Grower',
    JECode: 'Code',
    JECostCenter: 'Cost Center',
    QuantityKg: 'Qty',
    GrowerAmount: 'Grower Amount',
    DriscollsAmount: 'Driscolls Amount',
    Total: 'Total'
};

export const jeCcAllocationPrelimCol = {
    FamilyEntity: 'Family Entity',
    JECostCenter: 'JE Cost Center',
    GrowerOwn: 'Grower Own',
    DriscollsAmount: 'Driscolls Amount',
    Total: 'Total'
};

export const summaryCNYCol = {
    Commodity: 'Commodity',
    Abbreviation: 'SPEC',
    ItemID: 'Item ID',
    CommissionRate: 'Commission Rate',
    TotalTraysReceived: 'Total Trays Received',
    TotalKilosReceived: 'Total Kilos Received',
    SettledAveragePricePerTray: 'Settled Average Price/Tray',
    PoolWeekAveragePricePerKg: 'Pool Week Average Price/Kilo',
    DomesticFreight: 'Total Trans',
    PoolingAmount: 'Pooling Amount',
    TransNetFruitReturnPricePerKg: 'Trans Net Fruit Return Price/Kilo (Avg)',
    DMSHShare: 'DMSH Share',
    NetPoolReturn: 'Net Pool Return',
    CommissionNetFruitReturnPricePerKg: 'Net Fruit Return Price Per/Kilo (Avg)',
    CustomClearanceFee: 'Import Custom Clearance Fee',
    FreightCustomToFDC: 'Freight From Custom To FDC',
    RepackagingCharge: 'Repackaging Charge',
    Others1: 'Others',
    TotalCostsDeducted: 'Total Costs Deducted',
    DirectSalesReturn: 'Direct Sales Return',
    UnitReturnPerKg: 'Unit Return/Kg',
    DSAServiceFeeCost: 'DSA Service Fee 2% / 5%'
    // DSAServiceFeeCost: 'DSA Service Fee Cost'
};

export const summaryPeruCol = {
    Commodity: 'Commodity',
    Abbreviation: 'SPEC',
    ItemID: 'Item ID',
    CommissionRate: 'Commission Rate',
    TotalTraysReceived: 'Total Trays Received',
    TotalKilosReceived: 'Total Kilos Received',
    SettledAveragePricePerTray: 'Settled Average Price/Tray',
    PoolWeekAveragePricePerKg: 'Pool Week Average Price/Kilo',
    DomesticFreight: 'Domestic Freight',
    PoolingAmount: 'Pooling Amount',
    DMSHShare: 'DMSH Share',
    NetPoolReturn: 'Net Pool Return',
    CustomClearanceFee: 'Import Custom Clearance Fee',
    FreightCustomToFDC: 'Freight From Custom To FDC',
    RepackagingCharge: 'Repackaging Charge',
    Others1: 'Others',
    TotalCostsDeducted: 'Total Costs Deducted',
    DirectSalesReturn: 'Direct Sales Return',
    UnitReturnPerKg: 'Unit Return/Kg',
    AdvancePaid: 'Advance Paid',
    RemainingPayment: 'Remaining Payment'
};

export const summaryChileCol = {
    ...summaryPeruCol
};

export const journalAccountDsCol = {
    AccountNumber: 'Account Number',
    TransactionType: 'Transaction Type',
    LineMemo: 'Line Memo',
    AccountName: 'Account Name',
    ReportType: 'Report Type',
    DisplayOrder: 'Display Order',
    EntityType: 'Entity Type',
    Entity: 'Entity',
    Comments: 'Comments',
    CreatedBy: 'Created By',
    CreatedDateTime: 'Created Date Time',
    ModifiedBy: 'Modified By',
    ModifiedDateTime: 'Modified Date Time'
};

export const summaryCostaCols = {
    BusinessUnit: 'Business Unit',
    PoolWeek: 'Pool Week',
    Commodity: 'Commodity',
    Abbreviation: 'SPEC',
    ItemID: 'Item ID',
    CommissionRate: 'Commission Rate',
    TotalTraysReceived: 'Total Trays Received',
    TotalKilosReceived: 'Total Kilos Received',
    NormalSalesUnitPrice: 'Normal Average Price/tray',
    AveragePricePerTray: 'Settled Average Price/Tray',
    AveragePricePerKg: 'Pool Week Average Price/Kg',
    PackageDeduction: 'Total Package Deduction',
    FreightDeduction: 'Total Trans Freight Deduction',
    PKGNetFruitReturnPricePerKg: 'PKG Net Fruit Return Price/Kg(Avg)',
    NetFruitBeforeDAPShare: 'Net Fruit Before DAP Share',
    DAPShare: 'DMSH Share',
    CommissionNetPoolReturn: 'Commission Net Pool Return',
    CommissionNetFruitReturnPricePerKg: 'Commission Net Fruit Return Price/Kg',
    TrayToKilogram: 'Tray To Kilogram',
    PackageRate: 'Package Rate',
    FreightRate: 'Freight Rate',
    VATPercent: 'VAT Percent',
    VATShareGrower: 'VAT Share Grower',
    VATNetPoolReturn: 'VAT Net Pool Return',
    VATNetFruitReturnPricePerKg: 'VAT Net Fruit Return Price/Kg(Avg)',
    TotalAmountDueToGrower: 'Amount Due To Grower'
};
