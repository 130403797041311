import React, { useMemo, useState } from 'react';
import ApplicationContext from './ApplicationContext';
import { IAssignableCriteriaList, IBusinesRuleType } from 'types/BusinesRuleType';

const AppProvider = (props: any) => {
    const [assignmentListForItems, setAssignmentListForItems] = useState<IAssignableCriteriaList>();
    const [businessRuleTypes, setBusinessRuleTypes] = useState([] as IBusinesRuleType[]);
    const value = useMemo(
        () => ({ assignmentListForItems, setAssignmentListForItems, businessRuleTypes, setBusinessRuleTypes }),
        [assignmentListForItems, setAssignmentListForItems]
    );
    return <ApplicationContext.Provider value={value}>{props.children}</ApplicationContext.Provider>;
};

export default AppProvider;
