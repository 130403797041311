export const SettlementEngineStatus = {
    PROCESSING: 'PROCESSING',
    FAILED: 'PROCESSING FAILED',
    NEW: 'NEW',
    PROCESSED: 'PROCESSED',
    PENDINGAPPROVAL: 'PENDINGAPPROVAL',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
    PAYMENTINPROGRESS: 'PAYMENTINPROGRESS',
    PUBLISHINGINPROGRESS: 'PUBLISHINGINPROGRESS',
    POSTED_TO_ORACLE: 'POSTED TO ORACLE',
    ORACLE_POSTING_FAILED: 'ORACLE POSTING FAILED',
    PUBLISHED: 'PUBLISHED',
    SETTLED: 'SETTLED'
};
