import React from 'react';
import { withStyles } from '@mui/styles';

/**
 * @ignore
 */
const styles = (theme) => ({
    root: {
        maxWidth: '400px',
        margin: 'auto',
        border: '1px solid #ddd',
        padding: '10px',
        backgroundColor: theme.light.primary,
        [theme.darkTheme]: {
            backgroundColor: theme.dark.primary
        }
    },
    alignWithLogin: {
        marginTop: '-9px'
    },
    text: {
        textAlign: 'right',
        color: theme.light.text.primary,
        [theme.darkTheme]: {
            color: theme.dark.text.primary,
        }
    },
    link: {
        cursor: 'pointer',
        padding: 0,
        textDecoration: 'underline',
        color: theme.light.text.primary,
        [theme.darkTheme]: {
            color: theme.dark.text.primary
        }
    },
    oktaItem: {
        textDecoration: 'underline',
        padding: 5,
    },
    oktaContainer: {
        padding: 10,
        border: '1px solid #bbb',
        borderRadius: 2
    }
});

/**
 * @description DrcSignInVersionFooter is a styled component to show application version, package version and env
 * @class DrcSignInVersionFooter
 * @property {*} classes custom styles
 * @property {*} errorMessage if any error message will be displayed
 * @example
 * <DrcSignInVersionFooter errorMessage={''}/>
 * @category General
 * @tags ui versions footer bottom login
 * @extends {React.Component}
 */
class DrcSignInVersionFooter extends React.Component {
    constructor(props) {
        super(props);

        var hostname = window.location.hostname.toLowerCase();
        var environment = '';

        if (hostname.includes('dev') || hostname.includes('localhost')) {
            environment = 'Development';
        } else if (hostname.includes('tst') || hostname.includes('test')) {
            environment = 'Test';
        } else if (!!props.allowAllEnvironments && hostname.includes('uat')) {
            environment = 'UAT';
        } else if ((!!props.allowAllEnvironments && hostname.includes('uat2')) || hostname.includes('qa')) {
            environment = 'UAT 2';
        } else if ((!!props.allowAllEnvironments && hostname.includes('stg')) || hostname.includes('stage')) {
            environment = 'Stage';
        } else if (!!props.allowAllEnvironments) {
            environment = 'Production';
        } else {
            environment = '';
        }

        let options = JSON.parse(localStorage.getItem('okta-token-storage'));
        let oktaGroups = options?.accessToken?.claims?.groups || [];

        this.state = {
            versions: (window.Versions || []).sort((a, b) => {
                return a.name.localeCompare(b.name);
            }),
            environment,
            showOkta: false,
            oktaGroups
        };
    }

    toggleMenu = () => {
        this.setState({ showOkta: !this.state.showOkta });
        window.dispatchEvent(new CustomEvent('resize'))
    };

    /**
     *
     *
     * @return {*}
     * @memberof DrcSignInVersionFooter
     */
    render() {
        if (this.state.environment.length === 0) {
            return null;
        }

        const { classes, errorMessage, selfAlign } = this.props;
        const { versions, environment, showOkta, oktaGroups } = this.state;

        return (
            <div className={`${classes.root} ${selfAlign ? '' : classes.alignWithLogin}`}>
                {errorMessage}
                <div className="row">
                    <div className={`col-xs-6 ${classes.text}`}>Application Version:</div>
                    <div className="col-xs-6">{process.env.REACT_APP_VERSION}</div>
                </div>
                {versions.map((packageVersion) => (
                    <div className="row" key={packageVersion.package}>
                        <div className={`col-xs-6 ${classes.text}`}>{packageVersion.name}:</div>
                        <div className="col-xs-6">{packageVersion.version}</div>
                    </div>
                ))}
                {environment !== 'Production' ? (
                    <>
                        <div className="row">
                            <div className={`col-xs-6 ${classes.text}`}>Environment:</div>
                            <div className="col-xs-6">{environment || 'N/A'}</div>
                        </div>
                        {oktaGroups.length > 0 && (<>
                            <div className="row">
                                <div className={`col-xs-6 ${classes.text}`}>OKTA Groups:</div>
                                <div className="col-xs-6">
                                    <a className={classes.link} onClick={this.toggleMenu}>
                                        {showOkta?'Hide':'Show'}
                                    </a>
                                </div>
                            </div>
                            {showOkta ? (
                                <div className={`row  ${classes.oktaContainer}`}>
                                    {oktaGroups.map((record) => {
                                        return (
                                            <div key={record} className={`col-xs-12 ${classes.oktaItem}`}>
                                                {record}
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : null}
                        </>)}
                    </>
                ) : null}
            </div>
        );
    }
}

export default withStyles(styles)(DrcSignInVersionFooter);
