import React from 'react';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { Call } from '@driscollsinc/driscolls-display-rules';
import { DrcButton, DrcDialog, DrcTranslate, DrcDatePicker, DrcSelect, DrcCheckbox, DrcTooltip } from 'drc/driscolls-react-components/index';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { Divider } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ReactComponent as Add } from '../../../Images/SVG/Add_Enabled.svg';
import Form from 'react-validation/build/form';
import APIEndPoints from '../../../services/api';
import GridStyles from '../../../styles/gridStyles';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast, setPageTitleAction } from '../../../actions/actions';
import ActionsFormatter from '../../../components/cellFormatters/ActionFormatter';
import DrcWrapper from '../../../components/DrcWrapper';
import Stepper from '../../../components/Stepper';
import PrimeReactTable from '../../../components/PrimeReactTable';
import GGSFormInput from '../../../components/GGSFormInput';
import { displayErrorMessage, getAppliedFilters, makeColumns, userBusinessUnit } from '../../../utils/helper';
import { alphaNumeric, maxlen } from '../../../utils/validations';
import { lookUpDsColumns, convertDateToIso, businessUnitOptions, LOOKUP_FIELDS } from '../../../data/constants';
import FilterListSharp from '@mui/icons-material/FilterListSharp';
import Filter from '../../../components/Filter/Filter';
import { format, isValid } from 'date-fns';
import { lookupFilter } from '../../../actions/LookupAction';
import MasterDataUtilities from '../../../data/MasterDataUtilities';

const pageTitle = 'Lookup';
const styles = (theme) => ({
    input: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'left'
        }
    },
    gridStyles: GridStyles.styles(theme, '300px', '300px'),
    tableContainer: {
        margin: theme.spacing(0, 3)
    },
    selectedClass: {
        width: '45px !important',
        padding: '0 10px !important'
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'end'
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    pageActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    errorClass: {
        color: 'red',
        fontSize: '10px',
        marginTop: '-2px'
    }
});

class LookUp extends React.Component {
    state = {
        data: [],
        columns: ['LookupId', 'LookupType', 'LookupCode', 'Meaning', 'Description', 'EnabledFlag'],
        showAddNew: false,
        pageSize: 10,
        pageNum: 0,
        editingRow: {},
        selectedLookupType: '',
        lookupTypes: ['Location'],
        newRecord: {},
        invalidFields: [],
        dateError: '',
        savebuttonDisabled: true
    };
    uniqueKeyField = 'LookupId';
    excludingFields = ['Actions', 'LookupId', 'displaySequence', 'isDeleted'];
    validationFields = {
        // Description: {
        //     key: 'Description',
        //     validations: [alphaNumeric, maxlen],
        //     customProps: { maxlen: 500, required: true }
        // },
        LookupType: {
            key: 'LookupType',
            validations: [alphaNumeric, maxlen],
            customProps: { maxlen: 50, required: true }
        },
        LookupCode: {
            key: 'LookupCode',
            validations: [alphaNumeric, maxlen],
            customProps: { maxlen: 50, required: true }
        },
        Meaning: {
            key: 'Meaning',
            validations: [alphaNumeric, maxlen],
            customProps: { maxlen: 100 }
        },
        EnabledFlag: {
            key: 'EnabledFlag',
            validations: [maxlen],
            customProps: { maxlen: 1, required: true }
        },
        BusinessUnit: {
            key: 'BusinessUnit',
            validations: [maxlen, alphaNumeric],
            customProps: { maxlen: 25, required: true }
        },
        Tag: {
            key: 'Tag',
            validations: [maxlen, alphaNumeric],
            customProps: { maxlen: 100 }
        },
        StartDateActive: {
            key: 'StartDateActive',
            validations: [alphaNumeric],
            customProps: {}
        },
        EndDateActive: {
            key: 'EndDateActive',
            validations: [alphaNumeric],
            customProps: {}
        },
        Attribute1: {
            key: 'Attribute1',
            validations: [maxlen, alphaNumeric],
            customProps: { maxlen: 500 }
        },
        Attribute2: {
            key: 'Attribute2',
            validations: [maxlen, alphaNumeric],
            customProps: { maxlen: 500 }
        },
        Attribute3: {
            key: 'Attribute3',
            validations: [maxlen, alphaNumeric],
            customProps: { maxlen: 500 }
        },
        Attribute4: {
            key: 'Attribute4',
            validations: [maxlen, alphaNumeric],
            customProps: { maxlen: 500 }
        },
        Attribute5: {
            key: 'Attribute5',
            validations: [maxlen, alphaNumeric],
            customProps: { maxlen: 500 }
        }
    };

    componentDidMount() {
        this.loadData();
        window.addEventListener('resize', this.updateDataTable);
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }
    }

    componentDidUpdate(_prevProps, prevState) {
        if (
            this.state.newRecord.StartDateActive !== prevState.newRecord.StartDateActive ||
            this.state.newRecord.EndDateActive !== prevState.newRecord.EndDateActive ||
            this.state.editingRow.StartDateActive !== prevState.editingRow.StartDateActive ||
            this.state.editingRow.EndDateActive !== prevState.editingRow.EndDateActive
        )
            this.checkDateValidation();
    }

    checkDateValidation() {
        if (
            (new Date(this.state.newRecord.StartDateActive) > new Date(this.state.newRecord.EndDateActive) ||
                new Date(this.state.editingRow.StartDateActive) > new Date(this.state.editingRow.EndDateActive)) &&
            (this.state.editingRow.EndDateActive || this.state.newRecord.EndDateActive)
        ) {
            if (!this.state.dateError)
                return this.setState({
                    dateError: <DrcTranslate>{'End Date should be greater than start date'}</DrcTranslate>,
                    invalidFields: ['EndDateActive']
                });
        } else {
            if (this.state.dateError) return this.setState({ dateError: '', invalidFields: [] });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDataTable);
    }

    loadData = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            let data = await Call(
                {
                    name: LOOKUP_FIELDS.CommodityLookup,
                    url: APIEndPoints.GET_LOOKUPVALUES(
                        this.state.pageNum / this.state.pageSize + 1,
                        this.state.pageSize,
                        userBusinessUnit(),
                        'y',
                        getAppliedFilters(this.props.filters)
                    ),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }

            this.response = data;
            this.updateDataTable(null, true);
            this.props.hideLoadingScreenAction();
        } catch (error) {
            this.showError(error);
        }
    };

    getActionsCell = (row) => {
        return (
            <ActionsFormatter
                rowEdit={true}
                uniqueKeyField={this.uniqueKeyField}
                objState={this}
                records={this.state.data}
                initRowEdit={this.editData}
                confirmEdit={this.confirmEdit}
                cancelEdit={this.cancelEdit}
                cannotDelete={this.cannotDelete}
                cannotEdit={this.cannotEdit}
                deleteAttribute={this.deleteData}
                row={row}
                checkRowValidation={() => !!this.state.invalidFields.length}
                rowUnderEditUniqueKeyValue={row[this.uniqueKeyField]}
            />
        );
    };

    updateTableData = (response, hasFrozenColumns = true) => {
        let data = response?.display?.Data || [];
        let columns = data.length
            ? makeColumns(
                  Object.keys(data[0]),
                  this.excludingFields,
                  this.getActionsCell,
                  true,
                  ['Description', 'EndDateActive', 'StartDateActive', 'LookupCode', 'Meaning', 'EnabledFlag'],
                  [],
                  lookUpDsColumns
              )
            : [];
        this.setState({
            data,
            totalCount: response?.display?.TotalCount || 0,
            columns,
            unfreeze: !hasFrozenColumns
        });
    };

    updateDataTable = (_event, force = false) => {
        if (window.innerWidth > 955 && !this.widthIsSufficient) {
            this.widthIsSufficient = true;
            this.widthIsInSufficient = false;
            this.updateTableData(this.response || [], true);
        } else if (window.innerWidth <= 955 && !this.widthIsInSufficient) {
            this.widthIsInSufficient = true;
            this.widthIsSufficient = false;
            this.updateTableData(this.response || [], false);
        } else if (force) {
            this.updateTableData(this.response || [], true);
        }
    };

    showError = (err, msg) => {
        this.props.hideLoadingScreenAction();
        this.props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    onPage = (event) => {
        this.setState({ pageNum: event.first });
        this.loadData();
    };

    deleteData = (_rowData) => null;

    editData = (_event, data, _index) => {
        this.setState({
            editingRow: {
                ...data,
                [data[this.uniqueKeyField]]: true,
                StartDateActive: this.convertDate(data.getPreviousData().StartDateActive),
                EndDateActive: data['EndDateActive'] ? this.convertDate(data.getPreviousData().EndDateActive) : null
            },
            invalidFields: []
        });
    };

    cancelEdit = (_event, _rowData) => {
        this.setState({ editingRow: {}, invalidFields: [] });
    };

    cannotEdit = (_row) => !(this.props.isUserAdmin && this.props.selectedRole.includes('-Admin'));
    cannotDelete = (_row) => !(this.props.isUserAdmin && this.props.selectedRole.includes('-Admin'));

    confirmEdit = async (_event, _rowData) => {
        try {
            this.props.showLoadingScreenAction('Updating data');
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            let data = await Call(
                {
                    name: LOOKUP_FIELDS.CommodityLookup,
                    url: APIEndPoints.PUT_LOOKUPVALUES(),
                    method: 'PUT',
                    options: {},
                    data: {
                        LookupId: this.state.editingRow['LookupId'],
                        LookupCode: this.state.editingRow['LookupCode'],
                        Description: this.state.editingRow['Description'] || '',
                        isDeleted: this.state.editingRow['isDeleted'],
                        BusinessUnit: this.state.editingRow['BusinessUnit'],
                        StartDateActive: this.state.editingRow['StartDateActive'].replace(/\//g, '-'),
                        // ...(this.state.editingRow['EndDateActive']
                        //     ? { EndDateActive: this.state.editingRow['EndDateActive'].replace(/-/g, '/') }
                        //     : {}),
                        EndDateActive: this.state.editingRow['EndDateActive'] ? this.state.editingRow['EndDateActive'].replace(/\//g, '-') : '',
                        CreatedBy: this.state.editingRow['CreatedBy'],
                        LookupType: this.state.editingRow['LookupType'],
                        Meaning: this.state.editingRow['Meaning'],
                        // DisplaySequence: 1,
                        EnabledFlag: this.state.editingRow['EnabledFlag'] ? 'Y' : 'N',
                        CreatedDateTime: this.state.editingRow['CreatedDateTime'],
                        ModifiedDateTime: new Date().toISOString(),
                        ModifiedBy: loggedInUser
                    }
                },
                token
            );
            if (!data || data.display.Status == 409) {
                this.setState({ editingRow: {} });
                this.showError();
            } else if ((data.display.Message || '').includes('start date and end date overlap')) {
                this.setState({ editingRow: {} });
                return this.showError(data.display.Message, 'Start date and End date overlap');
            } else {
                this.props.hideLoadingScreenAction();
                this.setState({ editingRow: {} });
                this.props.showToast(<DrcTranslate>{'Updated Successfully'}</DrcTranslate>, true);
                this.loadData();
            }
        } catch (error) {
            this.setState({ editingRow: {} });
            this.showError(error);
        }
    };
    addNewRecord = async () => {
        try {
            this.props.showLoadingScreenAction('Adding data');
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            let data = await Call(
                {
                    name: LOOKUP_FIELDS.CommodityLookup,
                    url: APIEndPoints.PUT_LOOKUPVALUES(),
                    method: 'POST',
                    options: {},
                    data: {
                        LookupCode: this.state.newRecord['LookupCode'],
                        Description: this.state.newRecord['Description'] || '',
                        isDeleted: 0,
                        BusinessUnit: this.state.newRecord['BusinessUnit'].value,
                        StartDateActive: convertDateToIso(this.state.newRecord['StartDateActive']),
                        EndDateActive: this.state.newRecord['EndDateActive'] ? convertDateToIso(this.state.newRecord['EndDateActive']) : '',
                        Attribute5: this.state.newRecord['Attribute5'] || '',
                        Attribute4: this.state.newRecord['Attribute4'] || '',
                        CreatedBy: loggedInUser,
                        Meaning: this.state.newRecord['Meaning'] || '',
                        ModifiedDateTime: new Date().toISOString(),
                        LookupType: this.state.newRecord['LookupType'],
                        Attribute1: this.state.newRecord['Attribute1'] || '',
                        DisplaySequence: '1',
                        Attribute3: this.state.newRecord['Attribute3'] || '',
                        ModifiedBy: loggedInUser,
                        EnabledFlag: 'Y',
                        Tag: this.state.newRecord['Tag'] || '',
                        Attribute2: this.state.newRecord['Attribute2'] || '',
                        CreatedDateTime: new Date().toISOString()
                    }
                },
                token
            );
            if (data.errors.length && !data.raw) {
                this.setState({ showAddNew: false, newRecord: {} });
                return this.showError(data.errors[0]);
            } else if ((data.display.Message || '').includes('start date and end date overlap')) {
                this.setState({ showAddNew: false, newRecord: {} });
                return this.showError(data.display.Message, 'Start date and End date overlap');
            } else if (data.display.Status === 409) {
                this.setState({ showAddNew: false, newRecord: {} });
                return this.showError(data.display.Message, 'Duplicate Record');
            } else {
                this.props.hideLoadingScreenAction();
                this.setState({ showAddNew: false, newRecord: {} });
                this.props.showToast(<DrcTranslate>{'Added Successfully'}</DrcTranslate>, true);
                this.loadData();
            }
        } catch (error) {
            this.showError(error);
        }
    };

    /**
     * Method that is triggered when validation is detected. This will enable/disable the save button for the add-dialog
     * @function handleValidationDetected
     * @param {*} validate
     * @memberof CustomerCodeCategory
     */
    handleValidationDetected = (validate, fieldName) => {
        if (validate === undefined) {
            return;
        }
        if (validate) {
            if (!this.state.invalidFields.includes(fieldName)) {
                let newFields = [...this.state.invalidFields];
                newFields.push(fieldName);
                this.setState({ invalidFields: newFields });
            }
        } else {
            if (this.state.invalidFields.includes(fieldName))
                this.setState((prevState) => ({ ...prevState, invalidFields: prevState.invalidFields.filter((field) => field !== fieldName) }));
        }
    };
    getRowEditor = (key, col, others) => {
        let field = this.validationFields[key] ?? {};
        switch (key) {
            case 'EndDateActive':
                return this.dateCellEditor(key, true, true);
            case 'StartDateActive':
                return this.dateCellEditor(key, true, false);
            case 'Description':
            case 'Meaning':
            case 'LookupCode':
                return this.inputTextEditor(key, col, others, field.customProps, field.validations);
            case 'EnabledFlag':
                return this.switchEditor(key);
        }
    };
    inputTextEditor = (key, _col, _others, customProps, validations = []) => {
        return (
            <Form>
                <GGSFormInput
                    className={this.props.classes.input}
                    onChange={(e) =>
                        this.setState(() => ({
                            editingRow: { ...this.state.editingRow, [key]: e.target.value }
                        }))
                    }
                    value={this.state.editingRow[key]}
                    validations={validations}
                    {...customProps}
                />
            </Form>
        );
    };

    switchEditor = (key) => {
        return (
            <DrcCheckbox
                checked={this.state.editingRow[key]}
                value={this.state.editingRow[key]}
                onChange={(_e, val) => {
                    this.setState((_prevState) => ({
                        editingRow: { ...this.state.editingRow, [key]: val }
                    }));
                }}
            />
        );
    };

    convertDate = (date) => {
        return isValid(date) ? format(date, 'yyyy/MM/dd') : date;
    };
    dateCellEditor = (key, clearable, errorMsg) => {
        return (
            <>
                <DrcDatePicker
                    clearable={clearable}
                    emptyLabel=" "
                    InputProps={{ readOnly: true }}
                    selectedDate={this.state.editingRow[key] ? new Date(this.state.editingRow[key]) : null}
                    onChange={(e) => {
                        this.setState((prevState) => ({ editingRow: { ...prevState.editingRow, [key]: this.convertDate(e) } }));
                    }}
                />
                {errorMsg && this.state.dateError && (
                    <DrcTooltip tipText={this.state.dateError}>
                        <p style={{ color: 'red', fontSize: '10px', marginTop: '-5px', textOverflow: 'ellipsis' }}>{this.state.dateError || ''}</p>
                    </DrcTooltip>
                )}
            </>
        );
    };
    applyFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadData());
    };

    toggleShowFilter = () => this.setState({ showFilter: !this.state.showFilter });

    resetFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadData());
    };

    render() {
        const { classes } = this.props;
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        return (
            <DrcWrapper>
                <Stepper page={pageTitle} isDataSetup />
                <Divider />{' '}
                <div className={classes.filterContainer}>
                    <Filter
                        showFilter={this.state.showFilter}
                        applyFilter={this.applyFilter}
                        resetFilter={this.resetFilter}
                        filterNames={['LookupType']}
                    />
                </div>
                <div className={classes.pageActions}>
                    <div>
                        <DrcButton onClick={this.toggleShowFilter}>
                            <FilterListSharp className={classes.icon} />
                            <DrcTranslate>{'Filter'}</DrcTranslate>
                        </DrcButton>
                    </div>
                    <DrcButton
                        onClick={(_event) => {
                            this.setState({ showAddNew: true });
                        }}
                        noStyle
                        title="Add"
                        disabled={!(this.props.isUserAdmin && this.props.selectedRole.includes('-Admin'))}
                    >
                        <Add style={{ width: '50px', height: '20px', marginRight: '-10px' }} />
                        <DrcTranslate>{'Add'}</DrcTranslate>
                    </DrcButton>
                </div>
                <div className={classes.tableContainer}>
                    <PrimeReactTable
                        value={this.state.data}
                        columns={this.state.columns}
                        editMode="row"
                        frozenWidth={'100px'}
                        tableSelectionMode="checkbox"
                        uniqueKeyField={this.uniqueKeyField}
                        editingRows={this.state.editingRow}
                        paginator={this.state.data.length > 0}
                        currentPage={this.state.pageNum}
                        pageSize={this.state.pageSize}
                        onPage={this.onPage}
                        totalRecords={this.state.totalCount}
                        editor={(key, col, others) => {
                            return this.getRowEditor(
                                key,
                                col,
                                others,
                                this.validationFields[key] ? this.validationFields[key].validations : [],
                                this.validationFields[key] ? this.validationFields[key].customProps : {}
                            );
                        }}
                    />
                </div>
                <DrcDialog
                    title={'Add New Record'}
                    open={this.state.showAddNew}
                    buttons={
                        <>
                            <DrcButton
                                onClick={() => {
                                    this.setState({ showAddNew: false, newRecord: {} });
                                }}
                            >
                                <DrcTranslate>{'Cancel'}</DrcTranslate>
                            </DrcButton>
                            <DrcButton
                                isPrimary
                                onClick={this.addNewRecord}
                                disabled={
                                    !!this.state.invalidFields.length ||
                                    !(
                                        this.state.newRecord['LookupType'] &&
                                        this.state.newRecord['LookupCode'] &&
                                        this.state.newRecord['Meaning'] &&
                                        this.state.newRecord['StartDateActive']
                                    )
                                }
                            >
                                <DrcTranslate>{'Submit'}</DrcTranslate>
                            </DrcButton>
                        </>
                    }
                >
                    <Form>
                        <GGSFormInput
                            label={'Description'}
                            validations={this.validationFields['Description'] ? this.validationFields['Description'].validations : []}
                            {...(this.validationFields['Description'] ? this.validationFields['Description'].customProps : {})}
                            className={this.props.classes.input}
                            onChange={(e) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, Description: e.target.value } }))}
                            value={this.state.newRecord['Description']}
                            validationDetected={(validate) => this.handleValidationDetected(validate, 'Description')}
                        />
                        <GGSFormInput
                            label={'Lookup Type'}
                            required
                            validations={this.validationFields['LookupType'] ? this.validationFields['LookupType'].validations : []}
                            {...(this.validationFields['LookupType'] ? this.validationFields['LookupType'].customProps : {})}
                            className={this.props.classes.input}
                            onChange={(e) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, LookupType: e.target.value } }))}
                            value={this.state.newRecord['LookupType']}
                            validationDetected={(validate) => this.handleValidationDetected(validate, 'LookupType')}
                        />
                        <GGSFormInput
                            label={'Lookup Code'}
                            required
                            validations={this.validationFields['LookupCode'] ? this.validationFields['LookupCode'].validations : []}
                            {...(this.validationFields['LookupCode'] ? this.validationFields['LookupCode'].customProps : {})}
                            className={this.props.classes.input}
                            onChange={(e) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, LookupCode: e.target.value } }))}
                            value={this.state.newRecord['LookupCode']}
                            validationDetected={(validate) => this.handleValidationDetected(validate, 'LookupCode')}
                        />
                        <GGSFormInput
                            label={'Meaning'}
                            required
                            validations={this.validationFields['Meaning'] ? this.validationFields['Meaning'].validations : []}
                            {...(this.validationFields['Meaning'] ? this.validationFields['Meaning'].customProps : {})}
                            className={this.props.classes.input}
                            onChange={(e) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, Meaning: e.target.value } }))}
                            value={this.state.newRecord['Meaning']}
                            validationDetected={(validate) => this.handleValidationDetected(validate, 'Meaning')}
                        />
                        <DrcSelect
                            label="Business Unit"
                            value={this.state.newRecord['BusinessUnit']}
                            options={businessUnitOptions}
                            validations={this.validationFields['BusinessUnit'] ? this.validationFields['BusinessUnit'].validations : []}
                            {...(this.validationFields['BusinessUnit'] ? this.validationFields['BusinessUnit'].customProps : {})}
                            onChange={(option) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, BusinessUnit: option } }))}
                        />
                        <DrcDatePicker
                            label={'Start Date Active'}
                            selectedDate={this.state.newRecord['StartDateActive'] || null}
                            required
                            InputProps={{ readOnly: true }}
                            onChange={(e) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, StartDateActive: e } }))}
                        />
                        <DrcDatePicker
                            label={'End Date Active'}
                            selectedDate={this.state.newRecord['EndDateActive'] || null}
                            onChange={(e) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, EndDateActive: e } }))}
                            InputProps={{ readOnly: true }}
                        />
                        {/* Removing Enabled Flag Option as it should be alway Yes from the UI */}
                        <p className={classes.errorClass}>{this.state.dateError || ''}</p>
                    </Form>
                </DrcDialog>
            </DrcWrapper>
        );
    }
}
const mapStateToProps = ({ masterReducer, filterReducer, rootReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    pageTitle: rootReducer.pageTitle,
    filters: filterReducer.filters,
    isUserAdmin: rootReducer.userIsAdmin,
    selectedRole: rootReducer.selectedRole
});
const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    lookupFilter: (data) => dispatch(lookupFilter(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withStyles(styles)(LookUp)));
