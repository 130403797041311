import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

/**
 *@ignore
 */
const styles = (theme) => ({
    list: {
        minWidth: '200px'
    },
    icon: {
        minWidth: '24px',
        color: theme.light.text.primary,
        fill: theme.light.text.primary,
        [theme.darkTheme]: {
            color: theme.dark.text.primary,
            fill: theme.dark.text.primary
        }
    },
    link: {
        padding: '0',
        fontSize: '1.2rem',
        textDecoration: 'none',
        color: theme.light.text.primary,
        [theme.darkTheme]: {
            color: theme.dark.text.primary
        }
    },
    iconLink: {
        padding: '0 0 0 6px'
    }
});

/**
 * @description DrcLinkItem is a styled navigation with anchor elements
 * @class DrcLinkItem
 * @example <DrcLinkItem
 * key={'Title'}
 * toggleDrawer={()=>{}}
 * link=''
 * title={'Driscolls'}
 * />
 * @category Input
 * @tags input link
 * @property {string} title key text
 * @property {Object} classes custom styling classes
 * @property {image} icon prop to be clicked
 * @extends {React.Component}
 */
class DrcLinkItem extends React.Component {
    /**
     *
     *
     * @return {*}
     * @memberof DrcLinkItem
     */
    render() {
        const { title, icon, className, classes, link, toggleDrawer } = this.props;
        var linkItem = (
            <ListItem button key={title} disableRipple={true} className={`${classes.list} ${className}`}>
                {icon ? <ListItemIcon className={classes.icon}>{icon}</ListItemIcon> : null}
                <ListItemText disableTypography={true} className={`${classes.link} ${icon ? classes.iconLink : ''}`} primary={title} />
            </ListItem>
        );
        if ((link.url || '').length > 0) {
            return (
                <Link key={link.url} to={link.url} className={classes.link} onClick={toggleDrawer(false)}>
                    {linkItem}
                </Link>
            );
        } else if ((link.href || '').length > 0) {
            return (
                <a key={link.href} href={link.href} target={link.target || '_blank'} className={classes.link} onClick={toggleDrawer(false)}>
                    {linkItem}
                </a>
            );
        }
        return <React.Fragment>{linkItem}</React.Fragment>;
    }
}

export default withStyles(styles)(DrcLinkItem);
