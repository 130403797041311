export const BusinessRuleId = {
    PRICING_POOL_ID: 1,
    PACKAGING_DEDUCTION_ID: 2,
    DRISCOLLS_SHARE_ID: 3,
    SETTLEMENT_POOL_ID: 4,
    PACKAGING_CREDIT_ID: 5,
    GROWER_FINANCE_ID: 6, //Crop Invesment
    HOMI_ID: 7,
    ADVANCE_PAYMENT_ID: 8,
    PLANT_RECOVERY_ID: 9,
    FRESH_CREDIT_ID: 10,
    LOAN_COLLECTION: 11,
    FREIGHT_DEDUCTIOTION_PRE_SHARE: 12,
    PACK_LINE_FEE: 13,
    FREIGHT_DEDUCTIOTION_POST_SHARE: 14,
    ASSESSMENT_CHARGE: 15,
    ADJUSTMENT_PREMIUM_ID: 16,
    ADJUSTMENT_DEDUCTION_ID: 17,
    // PFQ_ADJUSTMENT_ID: 15, //unused
    // ADVANCE_REVERSAL: 14, //unused
    // HOMI_DEDUCTION_ID: 13, //unused
    // PACKAGING_ISSUANCE_ID: 12, //unused
    // ASSESSMENT_ID: 11 //unused
};
