import React, { Component } from 'react';
import { withStyles } from '@mui/styles';

const styles = (theme) => ({
    main: {
        margin: '48px auto 0',
        padding: '24px',
        borderRadius: '0 0 8px 8px',
        backgroundColor: theme.light.primary,
        boxShadow: '0px 4px 8px ' + theme.light.header.shadow,
        [theme.darkTheme]: {
            backgroundColor: theme.dark.primary,
            boxShadow: '0px 4px 8px ' + theme.dark.header.shadow
        }
    },
    handHeld: {
        padding: '0 !important',
        backgroundColor: 'transparent'
    },
    transparent: {
        backgroundColor: 'transparent',
        boxShadow: 'none'
    }
});

/**
 * @class DrcMain
 * @description DRC Main component wrapper
 * @property {number} maxWidth Sets the maximum width of component
 * @property {boolean} handHeld To be set as true if the app is used on a handheld device
 * @property {boolean} transparent Makes Main transparent
 * @example
 * <DrcMain transparent>Hello world!</DrcMain>
 * @donotprint true
 * @category Layout
 * @tags layout main html
 * @extends {Component}
 */
class DrcMain extends Component {
    /**
     * @return {*}
     * @memberof DrcMain
     */
    render() {
        const { style, className, children, maxWidth, transparent, classes, handHeld } = this.props;

        return (
            <main
                className={`${classes.main} ${transparent ? classes.transparent : ''} ${handHeld ? classes.handHeld : ''} ${className}`}
                style={{
                    maxWidth: maxWidth || '1280px',
                    ...style
                }}
            >
                {children}
            </main>
        );
    }
}

export default withStyles(styles)(DrcMain);
