import React from 'react';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import DrcLinkItem from './DrcLinkItem';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/**
 *@ignore
 */
const styles = (theme) => ({
    menuButton: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        boxSizing: 'content-box'
    },
    drawer: {
        maxWidth: '300px'
    },
    link: {
        textDecoration: 'none'
    },
    panel: {
        boxShadow: 'none',
        backgroundColor: theme.light.primary,
        '&:before': {
            opacity: 0
        },
        '&.Mui-expanded': {
            margin: '0',
            backgroundColor: theme.light.secondary,
            '&:before': {
                opacity: 1
            },
            '&:after': {
                bottom: '-1px',
                left: 0,
                right: 0,
                height: '1px',
                content: '""',
                opacity: 1,
                position: 'absolute',
                transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
            }
        },
        [theme.darkTheme]: {
            backgroundColor: theme.dark.primary,
            '&.Mui-expanded': {
                backgroundColor: theme.dark.secondary
            }
        }
    },
    expandIcon: {
        borderRadius: '10px',
        border: '1px solid ' + theme.light.text.secondary,
        color: theme.light.text.primary,
        [theme.darkTheme]: {
            borderRadius: '10px',
            border: '1px solid ' + theme.dark.text.secondary,
            color: theme.dark.text.primary
        }
    },
    panelSummary: {
        minHeight: 0,
        padding: 0,
        '&.Mui-expanded': {
            minHeight: 0
        },
        '& > div': {
            margin: 0,
            '&.Mui-expanded': {
                margin: '0'
            }
        }
    },
    panelDetails: {
        padding: '0'
    },
    panelList: {
        padding: 0,
        width: '100%'
    },
    panelLink: {
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.light.body,
        [theme.darkTheme]: {
            backgroundColor: theme.dark.body
        }
    },
    drawerDiv: {
        backgroundColor: theme.light.primary,
        height: '100%',
        [theme.darkTheme]: {
            backgroundColor: theme.dark.primary
        }
    }
});

/**
 * @description DrcHeaderMenu is to add navigation in the application
 * @class DrcHeaderMenu
 * @example <DrcHeaderMenu />
 * @donotprint true
 * @category Layout
 * @tags layout header head top menu hamburger link
 * @property {*} allLinks links
 * @extends {React.Component}
 */
class DrcHeaderMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            drawOpen: false,
            expanded: ''
        };
    }

    /**
     *
     * @description Method to open and close the menu
     * @param {*} open
     * @memberof DrcHeaderMenu
     */
    toggleDrawer = (open) => () => {
        if (open !== this.state.drawOpen) {
            this.setState({
                drawOpen: open,
                expanded: ''
            });
        }
    };

    /**
     *
     * @description Method to oen and close the panel and panel details
     * @param {*} panel
     * @memberof DrcHeaderMenu
     */
    handleChange = (panel) => (event, isExpanded) => {
        event.stopPropagation();
        this.setState({ expanded: isExpanded ? panel : '' });
    };

    /**
     *
     * @description Main method to render DrcHeaderMenu
     * @return {*}
     * @memberof DrcHeaderMenu
     */
    render() {
        const { allLinks, classes, anchor } = this.props;

        if (allLinks.length <= 0) {
            return null;
        }

        return (
            <React.Fragment>
                <IconButton onClick={this.toggleDrawer(true)} color="inherit" aria-label="Open Menu" className={`${classes.menuButton} menuButton`}>
                    <MenuIcon style={{ width: '100%', height: '100%' }} />
                </IconButton>
                <Drawer anchor={anchor || 'right'} open={this.state.drawOpen} onClose={this.toggleDrawer(false)} className={classes.drawer}>
                    <div tabIndex={0} role="button" onClick={this.toggleDrawer(false)} onKeyDown={this.toggleDrawer(false)} className={classes.drawerDiv}>
                        <List>
                            {allLinks.map((link) =>
                                link.separator ? (
                                    <hr />
                                ) : (link.subLinks || []).length > 0 ? (
                                    <Accordion expanded={this.state.expanded === link.title} onChange={this.handleChange(link.title)} key={link.title} className={classes.panel}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.expandIcon} />} className={classes.panelSummary}>
                                            <DrcLinkItem toggleDrawer={this.toggleDrawer} link={link} title={link.title} icon={link.icon} />
                                        </AccordionSummary>
                                        <AccordionDetails className={classes.panelDetails}>
                                            <List className={classes.panelList}>
                                                {link.subLinks.map((subLink) => (
                                                    <DrcLinkItem
                                                        key={subLink.title}
                                                        toggleDrawer={this.toggleDrawer}
                                                        link={subLink}
                                                        title={subLink.title}
                                                        icon={subLink.icon}
                                                        className={classes.panelLink}
                                                    />
                                                ))}
                                            </List>
                                        </AccordionDetails>
                                    </Accordion>
                                ) : (
                                    <DrcLinkItem key={link.title} toggleDrawer={this.toggleDrawer} link={link} title={link.title} icon={link.icon} />
                                )
                            )}
                        </List>
                    </div>
                </Drawer>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(DrcHeaderMenu);
