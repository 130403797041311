import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import { DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';
import { GlobalHotKeys } from 'react-hotkeys';
import { withRouter } from 'routes/withRouter';;

/**
 *@ignore
 */
var styles = (theme) => ({
    primary: {
        '&:visited': {
            color: `${theme.light.text.inverted} !important`
        },
        '&:hover': {
            backgroundColor: theme.light.accent.brightness(-20),
            color: theme.light.text.inverted,
            [theme.darkTheme]: {
                backgroundColor: theme.light.accent.brightness(-20)
            }
        }
    },
    secondary: {
        border: '1px solid ' + theme.light.accent.primary,
        color: `${theme.light.accent.primary}`,
        '&:hover': {
            backgroundColor: theme.light.secondary,
            color: theme.light.text.primary,
            border: '1px solid ' + theme.dark.accent.brightness(10)
        },
        [theme.darkTheme]: {
            border: '1px solid ' + theme.dark.accent.primary,
            backgroundColor: theme.dark.primary,
            '&:hover': {
                color: theme.dark.text.secondary,
                backgroundColor: theme.dark.secondary,
                border: '1px solid ' + theme.dark.accent.primary
            }
        }
    },
    text: {
        '&:hover': {
            backgroundColor: theme.light.accent.brightness(30),
            [theme.darkTheme]: {
                backgroundColor: theme.light.accent.brightness(-25)
            }
        }
    },
    disabled: {
        pointerEvents: 'none',
        backgroundColor: theme.light.secondary + theme.important,
        color: theme.light.text.disabled + theme.important,
        [theme.darkTheme]: {
            color: theme.dark.text.disabled + theme.important,
            backgroundColor: theme.dark.secondary + theme.important
        }
    },
    poly: {
        color: theme.light.text.primary,
        borderRadius: theme.spacing(2.5) + 'px !important',
        textTransform: 'capitalize',
        margin: theme.spacing(1),
        [theme.darkTheme]: {
            color: theme.dark.text.primary
        }
    },
    polyPrimary: {
        backgroundColor: theme.light.accent.opacity(0.3),
        border: '1px solid ' + theme.light.accent.opacity(0.5),
        [theme.darkTheme]: {
            backgroundColor: theme.dark.accent.opacity(0.3),
            border: '1px solid ' + theme.dark.accent.opacity(0.5)
        },
        '&:hover': {
            backgroundColor: theme.light.accent.opacity(0.25),
            color: theme.light.text.primary,
            border: '1px solid ' + theme.light.accent.opacity(0.25),
            [theme.darkTheme]: {
                backgroundColor: theme.dark.accent.opacity(0.25),
                color: theme.dark.text.primary,
                border: '1px solid ' + theme.dark.accent.opacity(0.75)
            }
        }
    },
    polySecondary: {
        backgroundColor: theme.light.accent.opacity(0.1),
        border: '1px solid ' + theme.light.accent.opacity(0.2),
        [theme.darkTheme]: {
            backgroundColor: theme.dark.accent.opacity(0.1),
            border: '1px solid ' + theme.dark.accent.opacity(0.25)
        },
        '&:hover': {
            border: '1px solid ' + theme.light.accent.opacity(0.25),
            [theme.darkTheme]: {
                backgroundColor: theme.dark.accent.opacity(0.15),
                border: '1px solid ' + theme.dark.accent.opacity(0.5)
            }
        }
    },
    shadowPrimary: {
        boxShadow: theme.light.shadow[0] + ', ' + theme.light.shadow[1],
        [theme.darkTheme]: {
            boxShadow: theme.dark.shadow[0] + ', ' + theme.dark.shadow[1]
        },
        '&:hover': {
            boxShadow: theme.light.shadow[0] + ', ' + theme.light.shadow[1] + ', inset ' + theme.light.shadow[2],
            [theme.darkTheme]: {
                boxShadow: theme.dark.shadow[0] + ', ' + theme.dark.shadow[1] + ', inset ' + theme.dark.shadow[2]
            }
        }
    },
    shadowSecondary: {
        boxShadow: theme.light.shadow[0] + ', ' + theme.light.shadow[1],
        [theme.darkTheme]: {
            boxShadow: theme.dark.shadow[0] + ', ' + theme.dark.shadow[1]
        },
        '&:hover': {
            boxShadow: theme.light.shadow[0] + ', ' + theme.light.shadow[1] + ', inset ' + theme.light.shadow[3],
            [theme.darkTheme]: {
                boxShadow: theme.dark.shadow[0] + ', ' + theme.dark.shadow[1] + ', inset ' + theme.dark.shadow[3]
            }
        }
    },
    hard: {
        borderRadius: '0 !important'
    },
    bottomLine: {
        color: theme.light.text.primary,
        textTransform: 'capitalize',
        margin: theme.spacing(1)
    },
    linePrimary: {
        backgroundColor: theme.light.accent.opacity(0.25),
        border: '1px solid ' + theme.light.accent.opacity(0.5),
        borderBottom: '5px solid ' + theme.light.accent.opacity(0.5),
        [theme.darkTheme]: {
            backgroundColor: theme.dark.accent.opacity(0.2),
            border: '1px solid ' + theme.dark.accent.opacity(0.5),
            color: theme.dark.text.primary,
            borderBottom: '5px solid ' + theme.dark.accent.opacity(0.5)
        },
        '&:hover': {
            backgroundColor: theme.light.accent.opacity(0.15),
            color: theme.light.text.primary,
            border: '1px solid ' + theme.light.accent.opacity(0.75),
            borderBottom: '5px solid ' + theme.light.accent.opacity(0.75),
            [theme.darkTheme]: {
                backgroundColor: theme.dark.accent.opacity(0.25),
                color: theme.dark.text.primary,
                border: '1px solid ' + theme.dark.accent.opacity(0.75),
                borderBottom: '5px solid ' + theme.dark.accent.opacity(0.75)
            }
        }
    },
    lineSecondary: {
        backgroundColor: theme.light.accent.opacity(0.1),
        border: '1px solid ' + theme.light.accent.opacity(0),
        borderBottom: '5px solid ' + theme.light.accent.opacity(0.25),
        [theme.darkTheme]: {
            color: theme.dark.text.primary,
            backgroundColor: theme.dark.accent.opacity(0.1),
            border: '1px solid ' + theme.dark.accent.opacity(0.25),
            borderBottom: '5px solid ' + theme.dark.accent.opacity(0.25)
        },
        '&:hover': {
            border: '1px solid ' + theme.light.accent.opacity(0.25),
            borderBottom: '5px solid ' + theme.light.accent.opacity(0.5),
            backgroundColor: theme.light.accent.opacity(0.15),
            [theme.darkTheme]: {
                backgroundColor: theme.dark.accent.opacity(0.15),
                border: '1px solid ' + theme.dark.accent.opacity(0.5),
                borderBottom: '5px solid ' + theme.dark.accent.opacity(0.5)
            }
        }
    },
    underline: {
        textDecoration: 'underline'
    },
    mark: {
        backgroundColor: theme.light.accent.brightness(-20),
        color: theme.light.text.inverted,
        [theme.darkTheme]: {
            backgroundColor: theme.dark.accent.brightness(-20),
            color: theme.light.text.inverted
        }
    }
});

/**
 * @description The DrcButton is a styled button in the Driscolls format and is highly customizable
 * @class DrcButton
 * @example
 * const isPrimary = false;
 * const isSecondary = true;
 * <DrcButton isPrimary={isPrimary} isSecondary={isSecondary} size="large" onClick={}>Primary</DrcButton>
 * @example
 * <DrcButton ariaLabel='label' onClick={}><icon/></DrcButton>
 * @category Input
 * @tags input button important
 * @property {boolean} IsPrimary Makes the button primary
 * @property {boolean} IsSecondary Makes the button primary
 * @property {boolean} disabled Disables the button
 * @property {string} size The size of the button (small, medium, large)
 * @property {string} ariaLabel Adds a accessible name to the button. Should be used when child is a icon.
 * @extends {React.Component} */
class DrcButton extends React.Component {
    /**
     * Creates an instance of DrcButton.
     * @param {*} props
     * @memberof DrcButton
     *
     */
    constructor(props) {
        super(props);

        const { isPrimary, isSecondary, disabled, className, classes } = this.props;

        this.state = {
            theme: DuThemeUtilities.CurrentButtonTheme(),
            class: `${isPrimary ? classes.primary : ''} ${isSecondary ? classes.secondary : ''} ${!isPrimary && !isSecondary ? classes.text : ''} ${
                disabled ? classes.disabled : ''
            } ${className}`,
            keyMap: {},
            handlers: {}
        };

        this.checkKeyBinding();
        this.updateButtonTheme = this.updateButtonTheme.bind(this);
        this.getClass = this.getClass.bind(this);
    }

    /**
     *
     * @ignore
     * @memberof DrcButton
     */
    componentDidMount() {
        this.updateButtonTheme();
        /** This is a comment in ComponentDidMount */
        this.checkKeyBinding();
    }

    /**
     *
     * @private
     * @memberof DrcButton
     */
    componentDidUpdate() {
        this.updateButtonTheme();
    }

    /**
     *
     *
     * @memberof DrcButton
     */
    checkKeyBinding() {
        /** @type {*} */
        const { keyBinding, children, bindingAnnotation } = this.props;

        if (this.props.keyBinding) {
            var label = this.getLabel(children, bindingAnnotation, keyBinding);
            this.setBinding(keyBinding);

            this.setState({
                label: label
            });
        }
    }

    /**
     *
     *
     * @param {*} binding
     * @memberof DrcButton
     */
    setBinding(binding) {
        var keyMap = {},
            handlers = {};
        if (!this.props.disabled && binding) {
            var structuredBinding;
            if (binding.length === 1) {
                structuredBinding = 'Shift+' + binding.toLowerCase();
            } else {
                structuredBinding = binding;
            }
            var key = structuredBinding.toUpperCase();
            keyMap[key] = structuredBinding;
            handlers[key] = () => {
                if (this.props.to) {
                    this.props.history.push(this.props.to);
                }
                if (this.props.onClick) {
                    this.props.onClick();
                }
            };
        }
        this.setState({
            keyMap,
            handlers
        });
    }

    /**
     *
     *
     * @param {*} child
     * @param {*} annotation
     * @param {*} binding
     * @return {*}
     * @memberof DrcButton
     */
    getLabel(child, annotation, binding) {
        if (child) {
            if (typeof child !== 'string') {
                return child;
            } else {
                var key;
                if (binding.length === 1) {
                    key = child.toLowerCase().indexOf(binding.toLowerCase());
                } else {
                    key = child.toLowerCase().indexOf(binding.slice(-1));
                }
                if (key !== -1) {
                    var returnStrings = {
                        beforeAnnotation: child.slice(0, key),
                        annotation: child.slice(key, key + 1),
                        afterAnnotation: child.slice(key + 1, child.length)
                    };
                    switch (annotation) {
                        case 'parenthesis':
                            return returnStrings.beforeAnnotation + '(' + returnStrings.annotation + ')' + returnStrings.afterAnnotation;
                        case 'highlight':
                            return (
                                <div>
                                    {returnStrings.beforeAnnotation}
                                    <mark className={this.props.classes.mark}>{returnStrings.annotation}</mark>
                                    {returnStrings.afterAnnotation}
                                </div>
                            );
                        case 'underscore':
                        default:
                            return (
                                <div>
                                    {returnStrings.beforeAnnotation}
                                    <span className={this.props.classes.underline}>{returnStrings.annotation}</span>
                                    {returnStrings.afterAnnotation}
                                </div>
                            );
                    }
                } else {
                    return child;
                }
            }
        } else {
            return null;
        }
    }

    /**
     *
     *
     * @memberof DrcButton
     */
    updateButtonTheme() {
        var newTheme = DuThemeUtilities.CurrentButtonTheme();

        //Check for overrides in the props
        const { hard, poly, line } = this.props;

        if (hard || line || poly) {
            newTheme = DuThemeUtilities.ButtonThemes.Standard;
        }

        if (hard) {
            newTheme |= DuThemeUtilities.ButtonThemes.Hard;
        }

        if (line) {
            newTheme |= DuThemeUtilities.ButtonThemes.BottomLine;
        }

        if (poly) {
            newTheme |= DuThemeUtilities.ButtonThemes.Polymorphism;
        }

        if (DuThemeUtilities.ValidButtonTheme(newTheme)) {
            var newClasses = this.getClass(newTheme);

            if (newTheme !== this.state.theme) {
                if (newClasses !== this.state.class) {
                    this.setState({ theme: newTheme, class: newClasses });
                } else {
                    this.setState({ theme: newTheme });
                }
            } else if (newClasses !== this.state.class) {
                this.setState({ class: newClasses });
            }
        }
    }

    /**
     * @description uses parameter that will be shown down here.
     *
     * @param {*} theme parameter description
     * @return {*}
     * @memberof DrcButton
     */
    getClass(theme) {
        const { isPrimary, isSecondary, disabled, className, classes } = this.props;

        var themeClasses = '';

        if (DuThemeUtilities.HasTheme(theme, DuThemeUtilities.ButtonThemes.Hard)) {
            themeClasses += classes.hard + ' ';
        }

        if (DuThemeUtilities.HasTheme(theme, DuThemeUtilities.ButtonThemes.BottomLine)) {
            themeClasses += classes.bottomLine + ' ';

            if (isPrimary) {
                themeClasses += `${classes.linePrimary} `;
            } else if (isSecondary) {
                themeClasses += `${classes.lineSecondary} `;
            }
        }

        if (DuThemeUtilities.HasTheme(theme, DuThemeUtilities.ButtonThemes.Polymorphism)) {
            themeClasses += classes.poly + ' ';

            if (isPrimary) {
                themeClasses += `${classes.polyPrimary} ${classes.shadowPrimary} `;
            } else if (isSecondary) {
                themeClasses += `${classes.polySecondary} ${classes.shadowSecondary} `;
            }
        }

        themeClasses = themeClasses.trim();

        return `${isPrimary ? classes.primary : ''} ${isSecondary ? classes.secondary : ''} ${!isPrimary && !isSecondary ? classes.text : ''} ${themeClasses} ${
            disabled ? classes.disabled : ''
        } ${className}`;
    }

    /**
     *
     *
     * @return {*}
     * @memberof DrcButton
     */
    render() {
        const { isPrimary, isSecondary, title, size, style, onClick, children, fullWidth, floatRight, uppercaseText, disabled, noStyle, href, to, keyBinding, ariaLabel } = this.props;

        var useUppercaseText = uppercaseText;
        var toText = to || '';

        var styleObj = noStyle
            ? style
            : {
                  textTransform: useUppercaseText ? 'uppercase' : 'none',
                  borderRadius: '4px',
                  width: fullWidth ? '100%' : 'inherit',
                  float: floatRight ? 'right' : 'inherit',
                  margin: '8px',
                  ...style
              };

        return (
            <React.Fragment>
                {keyBinding && <GlobalHotKeys keyMap={this.state.keyMap} handlers={this.state.handlers} allowChanges={true} />}
                <Button
                     {...(ariaLabel ? { 'aria-label': ariaLabel } : {})}
                    component={toText.length > 0 ? Link : 'button'}
                    variant={isPrimary ? 'contained' : isSecondary ? 'outlined' : 'text'}
                    size={size || 'medium'}
                    color={'primary'}
                    className={this.state.class}
                    style={styleObj}
                    onClick={onClick}
                    disabled={disabled}
                    title={title}
                    href={href}
                    to={to}
                >
                    {this.state.label || children}
                </Button>
            </React.Fragment>
        );
    }
}

export default withRouter(withStyles(styles)(DrcButton));
