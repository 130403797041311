import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { DrcButton, DrcTranslate } from 'drc/driscolls-react-components/index';
import GridStyles from '../../styles/gridStyles';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast } from '../../actions/actions';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { downloadFile, displayErrorMessage, makeColumns } from '../../utils/helper';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { Call } from '@driscollsinc/driscolls-display-rules';
import APIEndPoints from '../../services/api';
import { designColors, growerSettlementReport, summaryCostaCols } from '../../data/constants';
import PrimeReactTable from '../../components/PrimeReactTable';
import NoData from '../../components/NoData';
import { Typography } from '@mui/material';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import CalculationOverlay from './CalculationOverlay';
import HeaderDate from './HeaderDate';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
const styles = (theme) => ({
    input: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'left'
        }
    },
    gridStyles: GridStyles.styles(theme, '300px', '300px'),
    tableContainer: {
        margin: theme.spacing(3)
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    icon: {
        marginRight: 5
    }
});

class SettlementRecord extends Component {
    state = {
        data: [],
        pageData: [],
        rawData: [],
        pageNum: 0,
        selectedRow: {},
        pageSize: 50,
        totalCount: 0,
        columns: [],
        openCalcDialog: false
    };

    excludingFields = ['CreatedBy', 'ModifiedDateTime', 'ModifiedBy', 'CreatedDateTime', 'SubmittedBy'];
    readOnly = true;
    uniqueKeyField = 'ItemID';
    widthIsSufficient = window.innerWidth > 955;
    widthIsInSufficient = false;

    componentDidMount() {
        this.props.loadFilteredData(this.loadData.bind(this));
        window.addEventListener('resize', this.updateDataTable);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDataTable);
    }

    getTotalRow = (response) => {
        let totalRow = (response?.raw?.data?.resultSet1 || []).reduce(
            (newRow, row) => {
                newRow.TotalTraysReceived = (newRow.TotalTraysReceived || 0) + +(row.TotalTraysReceived || 0);
                newRow.TotalKilosReceived = (newRow.TotalKilosReceived || 0) + +(row.TotalKilosReceived || 0);
                newRow.NetFruitReturnPricePerKg = (newRow.NetFruitReturnPricePerKg || 0) + +(row.NetFruitReturnPricePerKg || 0);
                newRow.NetReturnToGrower = (newRow.NetReturnToGrower || 0) + +(row.NetReturnToGrower || 0);
                newRow.PackagingCredit = (newRow.PackagingCredit || 0) + +(row.PackagingCredit || 0);
                newRow.PackagingIssurance = (newRow.PackagingIssurance || 0) + +(row.PackagingIssurance || 0);
                newRow.PkgNetReturnToGrower = (newRow.PkgNetReturnToGrower || 0) + +(row.PkgNetReturnToGrower || 0);
                newRow.ShareGrowerAllowance = (newRow.ShareGrowerAllowance || 0) + +(row.ShareGrowerAllowance || 0);
                newRow.ShareGrowerDeduction = (newRow.ShareGrowerDeduction || 0) + +(row.ShareGrowerDeduction || 0);
                newRow.TotalCashToGrower = (newRow.TotalCashToGrower || 0) + +(row.TotalCashToGrower || 0);
                return newRow;
            },
            { TotalSumSection: true }
        );
        return totalRow;
    };

    updateTableData = (response, hasFrozenColumns = false) => {
        let data = response?.display?.resultSet1 || [];
        let apiCols = Object.keys(data[0] || {});
        let colOrder = this.props.filters.ReportName === 'GrowerSettlementReport' ? growerSettlementReport() : summaryCostaCols;
        let columns = data.length
            ? makeColumns(
                  Object.keys(data[0]),
                  this.excludingFields,
                  null,
                  false,
                  [],
                  ['ItemID', 'GrowerID'],
                  Object.keys(colOrder).reduce((acc, key) => {
                      if (apiCols.includes(key)) acc[key] = colOrder[key];
                      return acc;
                  }, {})
              )
            : [];
        let start = Math.floor(this.state.pageNum / this.state.pageSize) * this.state.pageSize;
        if (this.props.filters.ReportName === 'GrowerSettlementReport') {
            let newRow = this.getTotalRow(response);
            newRow = Object.keys(newRow).reduce((obj, key) => {
                obj[key] = (+newRow[key]).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                return obj;
            }, {});
            if (data.length) {
                data.push(newRow);
            }
        }
        this.setState({
            data,
            rawData: response?.raw?.data?.resultSet1 || [],
            totalCount: response?.raw?.data?.resultSet1?.length || 0,
            columns,
            unfreeze: !hasFrozenColumns
        });
    };
    updateDataTable = (_event, force = false) => {
        if (window.innerWidth > 955 && !this.widthIsSufficient) {
            this.widthIsSufficient = true;
            this.widthIsInSufficient = false;
            this.updateTableData(this.response || [], true);
        } else if (window.innerWidth <= 955 && !this.widthIsInSufficient) {
            this.widthIsInSufficient = true;
            this.widthIsSufficient = false;
            this.updateTableData(this.response || [], false);
        } else if (force) {
            this.updateTableData(this.response || [], true);
        }
    };

    loadData = async (f) => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
        try {
            let data = await Call(
                {
                    name: 'GrowerSettlementReport',
                    url: APIEndPoints.GET_GROWER_SETTLEMENT_REPORT,
                    method: 'POST',
                    options: {},
                    data: {
                        SearchBy: loggedInUser,
                        ...this.props.filters
                    }
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }
            this.response = data;
            this.updateDataTable(null, true);
            this.props.hideLoadingScreenAction();
        } catch (error) {
            this.showError(error);
        }
    };

    onPage = (event) => {
        let start = Math.floor(event.first / this.state.pageSize) * this.state.pageSize;
        this.setState({ pageNum: event.first, pageData: this.state.data.slice(start, start + this.state.pageSize) });
    };

    /**
     * Method that is triggered when validation is detected. This will enable/disable the save button for the add-dialog
     * @function handleValidationDetected
     * @param {*} validate
     */
    handleValidationDetected = (validate) => {
        if (validate === undefined) {
            return;
        }
        if (validate) {
            this.setState({ saveButtonDisabled: true });
        } else {
            this.setState({ saveButtonDisabled: false });
        }
    };

    showError = (err, msg) => {
        this.props.hideLoadingScreenAction();
        this.props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };
    exportData = async () => {
        try {
            this.props.showLoadingScreenAction(
                <React.Fragment>
                    <h4 style={{ fontSize: '1.5rem' }}>
                        <DrcTranslate>{'Preparing download'}</DrcTranslate>
                    </h4>
                    <div>
                        <DrcTranslate>{'Your download should start in a few seconds.'}</DrcTranslate>
                    </div>
                </React.Fragment>
            );
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            let response = await Call(
                {
                    name: 'GrowerSettlementReport',
                    url: APIEndPoints.GET_GROWER_SETTLEMENT_REPORT,
                    method: 'POST',
                    options: {},
                    data: {
                        SearchBy: loggedInUser,
                        ...this.props.filters
                    }
                },
                token
            );
            let data = response?.display?.resultSet1 || [];
            let apiCols = Object.keys(data[0] || {});
            this.props.hideLoadingScreenAction();
            let colOrder = this.props.filters.ReportName === 'GrowerSettlementReport' ? growerSettlementReport : summaryCostaCols;
            downloadFile(
                `${this.props.filters.ReportName}_${this.props.poolWeek.value}_${this.props.filters.GrowerName}_${this.props.filters.ReportType}.xlsx`,
                data,
                Object.keys(colOrder).reduce((acc, key) => {
                    if (apiCols.includes(key)) acc[key] = colOrder[key];
                    return acc;
                }, {}),
                []
            );
        } catch (err) {
            console.log(err);
        }
    };

    render() {
        const { classes } = this.props;
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        return (
            <div className={classes.tableContainer}>
                <div className={classes.actionContainer}>
                    <Typography variant="h6" noWrap style={{ fontWeight: 'bold', color: designColors.blue, textTransform: 'uppercase' }}>
                        <DrcTranslate>
                            {this.props.filters.ReportName === 'GrowerSettlementReport' ? 'Grower Settlement Report' : 'Summary Costa'}
                        </DrcTranslate>
                    </Typography>
                    {!!this.state.data.length && <HeaderDate recordStatus={this.props.recordStatus} />}
                    <DrcButton onClick={this.exportData} style={{ fontWeight: 'bold', color: designColors.blue }} disabled={!this.state.data.length}>
                        <VerticalAlignBottomIcon className={classes.icon} /> <DrcTranslate>{'Export'}</DrcTranslate>
                    </DrcButton>
                </div>
                {!!this.state.data.length ? (
                    <PrimeReactTable
                        paginator={this.state.data.length > 0}
                        currentPage={this.state.pageNum}
                        onPage={this.onPage}
                        columns={this.state.columns}
                        value={this.state.data}
                        gridStyles={classes.gridStyles}
                        totalRecords={this.state.totalCount}
                        resultCount={this.state.totalCount}
                        pageSize={this.state.totalCount + 1}
                        uniqueKeyField={this.uniqueKeyField}
                        tableSelectionMode="checkbox"
                        showReport={true}
                        currentPageReportTemplate={'Showing {first} to {last} of {totalRecords} entries'}
                        frozenWidth={this.props.filters.ReportName === 'GrowerSettlementReport' ? '200px' : '100px'}
                        // commenting out code as part of bug in GGS-1059, as it suggest we don't need popup now
                        // onRowClick={(e) => {
                        //     this.setState({ openCalcDialog: true, selectedRow: e.data });
                        // }}
                    />
                ) : (
                    <NoData />
                )}
                <CalculationOverlay
                    data={this.state.selectedRow}
                    rawData={this.state.rawData.find((itm) => itm.GrowerSettlementID === this.state.selectedRow.GrowerSettlementID) || {}}
                    onClose={() => this.setState({ selectedRow: {}, openCalcDialog: false })}
                    open={this.state.openCalcDialog}
                />
            </div>
        );
    }
}
const mapStateToProps = ({ masterReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized
});
const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withStyles(styles)(SettlementRecord)));
