import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import DrcLegend from './DrcLegend';
import DrcButton from './DrcButton';
import DrcMain from './DrcMain';
import DrcPanel from './DrcPanel';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { Middleware } from '@driscollsinc/one-ring';
import DrcPropertyDetails from './DrcPropertyDetails';

/**
 *@ignore
 */
const styles = (theme) => ({});

/**
 * @description DrcUserProfile is to render field based on metadata info provided in the Driscolls format and is customizable
 * @class DrcUserProfile
 * @example
 * let env = 'dev' or 'test';
 * <DrcUserProfile
        token={token}
        env={env}
    />
 * @donotprint true
 * @category General
 * @tags ui general profile user info
 * @property {json} token user access token
 * @property {env} env env
 * @extends {React.Component} */
class DrcUserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            globalMetadata: {},
            appMetadata: {},
            globalSettings: {},
            appSettings: {}
        };
    }

    /**
     *@ignore
     */
    componentDidMount() {
        this.onload();
    }

    /**
     *
     * @description method to handle on load or refresh
     * @memberof DrcUserProfile
     */
    onload = () => {
        let token = this.props.token;
        let userId = DuAuthenticationUtilities.GetOktaId(token);
        let decodedToken = DuAuthenticationUtilities.DecodeToken(token);
        let appId = decodedToken.cid || '';
        this.getData(token, userId, appId);
    };

    /**
     *
     * @description method to handle on load or refresh
     * @param {*} token
     * @param {*} userId
     * @param {*} appId
     * @memberof DrcUserProfile
     */
    getData = (token, userId, appId) => {
        // connect api to get app metadata
        this.getAppMetadata(token, appId);
        // connect api to get global metadata
        this.getGlobalMetadata(token);

        // connect api to get user global profile settings
        this.getGlobalProfileSettings(token, userId);
        // connect api to get user app profile settings
        this.getAppProfileSettings(token, userId, appId);

        // connect api to get datatypes
        this.getDatatypes(token);
    };

    /**
     *
     * @description handle api endpoint for metadata
     * @param {*} appId
     * @memberof DrcUserProfile
     */
    SETTINGS = (appId) => {
        return `https://${this.props.env}-api.bus.driscolls.com/Settings/${appId}`;
    };

    /**
     *
     * @description handle api endpoint for profile settings
     * @param {*} userId
     * @param {*} appId
     * @memberof DrcUserProfile
     */
    PROFILE_SETTINGS = (userId, appId) => {
        return `https://${this.props.env}-api.bus.driscolls.com/ProfileSettings/${userId}/${appId}`;
    };

    /**
     *
     * @description handle api endpoint for datatypes
     * @memberof DrcUserProfile
     */
    DATATYPES = `https://${this.props.env}-api.bus.driscolls.com/Datatypes`;

    /**
     *
     * @description method to handle global metadata
     * @param {*} token
     * @memberof DrcUserProfile
     */
    getGlobalMetadata = (token) => {
        // connect API to get global metadata
        Middleware.SendRaw(token, this.SETTINGS('global'))
            .then((data) => {
                let globalMetadata = data.results[0];
                this.setState({ globalMetadata });
            })
            .catch((error) => {
                console.log('Unable to fetch Global Metadata: ', error);
            });
    };

    /**
     *
     * @description method to handle app metadata
     * @param {*} token
     * @param {*} appId
     * @memberof DrcUserProfile
     */
    getAppMetadata = (token, appId) => {
        Middleware.SendRaw(token, this.SETTINGS(appId))
            .then((data) => {
                let appMetadata = data.results[0];
                if (appMetadata.managers) {
                    let isManager = DuAuthenticationUtilities.IsInGroup(token, appMetadata.managers);
                    this.setState({ isManager });
                }
                this.setState({ appMetadata });
            })
            .catch((error) => {
                console.log('Unable to fetch App Metadata: ', error);
            });
    };

    /**
     *
     * @description method to handle user's global profile settings
     * @param {*} token
     * @param {*} userId
     * @memberof DrcUserProfile
     */
    getGlobalProfileSettings = (token, userId) => {
        Middleware.SendRaw(token, this.PROFILE_SETTINGS(userId, 'global'))
            .then((data) => {
                let globalSettings = data.results[0];
                this.setState({ globalSettings });
            })
            .catch((error) => {
                console.log('Unable to fetch User Global Profile Settings: ', error);
            });
    };

    /**
     *
     * @description method to handle user's app profile settings
     * @param {*} token
     * @param {*} userId
     * @param {*} appId
     * @memberof DrcUserProfile
     */
    getAppProfileSettings = (token, userId, appId) => {
        Middleware.SendRaw(token, this.PROFILE_SETTINGS(userId, appId))
            .then((data) => {
                let appSettings = data.results[0];
                this.setState({ appSettings });
            })
            .catch((error) => {
                console.log('Unable to fetch User App Profile Settings: ', error);
            });
    };

    /**
     *
     * @description method to handle datatypes
     * @param {*} token
     * @memberof DrcUserProfile
     */
    getDatatypes = (token) => {
        //connect API to get list of datatypes
        Middleware.SendRaw(token, this.DATATYPES)
            .then((data) => {
                this.setState({ datatypes: data.results });
            })
            .catch((error) => {
                console.log('Unable to fetch Datatypes: ', error);
            });
    };

    /**
     *
     * @description method to handle on change of profile settings
     * @param {*} e
     * @param {*} column
     * @param {*} settingType
     * @param {*} isList
     * @param {*} op
     * @memberof DrcUserProfile
     */
    onChange = (e, column, settingType, isList, op) => {
        let globalSettings = { ...this.state.globalSettings };
        let appSettings = { ...this.state.appSettings };

        let appId = this.state.appId;

        let data = settingType === 'global' ? globalSettings['global'] : appSettings[appId];
        let value = e;
        if (isList && op === 'delete') {
            // remove from the list
            if (!data) {
                data = [];
                data.push({ [column]: [] });
            } else {
                let index = data.findIndex((item) => item[column] !== undefined);
                let filteredData = data[index][column].filter((value) => value !== e);
                data[index][column] = filteredData;
            }
        }
        if (!data && op !== 'delete') {
            data = [];
            data.push({ [column]: isList ? [value] : value });
        } else if (data && op !== 'delete') {
            let index = data.findIndex((item) => item[column] !== undefined);
            if (index !== -1) {
                if (isList) {
                    value = data[index][column];
                    value.push(e);
                }
                data[index] = { [column]: value };
            } else {
                data.push({ [column]: isList ? [value] : value });
            }
        }
        if (settingType === 'global') {
            this.setState({ globalSettings: { userId: globalSettings.userId, global: data } });
        } else {
            this.setState({ appSettings: { user: appSettings.userId, [`${appId}`]: data } });
        }
    };

    /**
     *
     * @description method to handle default values
     * @param {*} type
     * @memberof DrcUserProfile
     */
    getDefaultValues = (type) => {
        switch (type) {
            case 'boolean':
                return false;
            case 'date':
                return new Date().toString();
            case 'number':
                return '';
            case 'text':
                return '';
            case 'list':
                return [];
            default:
                return null;
        }
    };

    /**
     *
     * @description method to check profile setting exists
     * @param {*} data
     * @param {*} field
     * @memberof DrcUserProfile
     */
    checkPropertyExists = (data, field) => {
        return data.some((item) => item[field] !== undefined);
    };

    /**
     *
     * @description method to add profile setting if not exists
     * @param {*} data
     * @param {*} metadata
     * @memberof DrcUserProfile
     */
    addPropertyIfNotExists = (data, metadata) => {
        if (metadata && metadata.settings) {
            metadata.settings.forEach((setting) => {
                let present;
                if (data) {
                    //check if setting name in profileSettings
                    present = this.checkPropertyExists(data, setting.settingName);
                } else {
                    data = [];
                    present = false;
                }
                if (!present) {
                    data.push({
                        [setting.settingName]: this.getDefaultValues(setting.isList ? 'list' : setting.datatype)
                    });
                }
                if (present) {
                    // find index and set values to empty if isList is changed metadata
                    let index = data.findIndex((item) => item[setting.settingName] !== undefined);
                    if (index !== -1) {
                        let value = data[index][setting.settingName];
                        if (setting.isList && !Array.isArray(value)) {
                            data[index][setting.settingName] = [];
                        } else if (!setting.isList && Array.isArray(value)) {
                            data[index][setting.settingName] = '';
                        }
                    }
                }
            });
        }
        console.log(data);
        return data;
    };

    /**
     *
     * @description method to options for profile setting
     * @param {*} setting
     * @memberof DrcUserProfile
     */
    getOptions = (setting) => {
        // check isApi
        if (this.state.datatypes) {
            let record = this.state.datatypes.find((item) => item.datatypeName === setting.datatype);
            if (record.isApi === 'true') {
                // connect API to get Options
            }
        } else return [];
    };

    /**
     *
     * @description method to render profile settings
     * @param {*} data
     * @param {*} metadata
     * @param {*} access
     * @param {*} settingType
     * @memberof DrcUserProfile
     */
    renderProfileSettings = (data, metadata, access, settingType) => {
        let appId = 'global';
        if (settingType !== 'global') {
            appId = this.state.appId;
        }
        let readOnly = access === 'write' ? false : true;
        if (metadata && metadata.settings) {
            data = this.addPropertyIfNotExists(data ? data[`${appId}`] : data, metadata);
            if (this.state.appMetadata) {
                return metadata.settings.map((setting) => {
                    let permission = this.state.isManager ? setting.managerPermissions : setting.userPermissions;
                    if (permission !== 'hidden') {
                        if (permission === access) {
                            let propOptions = [];

                            if (!readOnly) {
                                // connect API to get option list for the field
                                propOptions = this.getOptions(setting);
                            }
                            let index = data.findIndex((item) => item[setting.settingName] !== undefined);
                            if (index !== -1) {
                                return (
                                    <DrcPropertyDetails
                                        key={setting.settingName}
                                        metadata={setting}
                                        propOptions={propOptions}
                                        settingType={settingType}
                                        value={data[index][setting.settingName]}
                                        onChange={this.onChange}
                                        readOnly={readOnly}
                                    />
                                );
                            }
                        }
                    }
                });
            }
        }
    };

    /**
     *
     * @description method to save global profile settings
     * @memberof DrcUserProfile
     */
    saveGlobalProfileSettings = () => {
        //connect to API to save changes
        const token = this.props.token;
        let data = this.state.globalSettings;
        data = this.addPropertyIfNotExists(data && data['global'] ? data['global'] : data, this.state.globalMetadata);
        let userId = DuAuthenticationUtilities.GetOktaId(token);

        let payload = {};
        payload.userId = userId;
        payload.global = data;

        Middleware.SendRaw(token, APIEndpoints.PROFILE_SETTINGS(userId, 'global'), 'PUT', payload)
            .then((data) => {
                this.saveAppProfileSettings();
            })
            .catch((error) => {
                console.log('Unable to save Global profile Settings: ', error);
            });
    };

    /**
     *
     * @description method to render app profile settings
     * @memberof DrcUserProfile
     */
    saveAppProfileSettings = () => {
        const token = this.props.token;
        let decodedToken = DuAuthenticationUtilities.DecodeToken(token);
        let appId = decodedToken.cid || '';
        //connect to API to save changes
        let data = this.state.appSettings;
        data = this.addPropertyIfNotExists(data && data[appId] ? data[appId] : data, this.state.appMetadata);
        let userId = DuAuthenticationUtilities.GetOktaId(token);

        let payload = {};
        payload.userId = userId;
        payload[appId] = data;

        Middleware.SendRaw(token, APIEndpoints.PROFILE_SETTINGS(userId, appId), 'PUT', payload)
            .then((data) => {
                this.onload();
            })
            .catch((error) => {
                console.log('Unable to save App profile Settings: ', error);
            });
    };

    /**
     *
     * @description method to handle save
     * @memberof DrcUserProfile
     */
    onSave = () => {
        this.saveGlobalProfileSettings();
    };

    /**
     *
     * @description method to handle cancel
     * @memberof DrcUserProfile
     */
    onCancel = () => {
        console.log('cancelled!');
    };

    /**
     *
     *
     * @return {*}
     * @memberof DrcUserProfile
     */
    render() {
        const { globalMetadata, appMetadata, globalSettings, appSettings } = this.state;
        return (
            <DrcMain>
                <DrcPanel>
                    <DrcLegend>User Profile Settings</DrcLegend>
                    <div>
                        <DrcLegend>Read-Only fields </DrcLegend>
                        {this.renderProfileSettings(globalSettings, globalMetadata, 'read', 'global')}
                        {this.renderProfileSettings(appSettings, appMetadata, 'read')}
                    </div>
                    <div>
                        <DrcLegend>Editable Fields </DrcLegend>
                        {this.renderProfileSettings(globalSettings, globalMetadata, 'write', 'global')}
                        {this.renderProfileSettings(appSettings, appMetadata, 'write')}
                    </div>
                    <DrcButton onClick={this.onSave}>Save</DrcButton>
                    <DrcButton onClick={this.onCancel}>Cancel</DrcButton>
                </DrcPanel>
            </DrcMain>
        );
    }
}

export default withStyles(styles)(DrcUserProfile);
