import React from 'react';
import { withStyles } from '@mui/styles';
import Snackbar from '@mui/material/Snackbar';
import { DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';

/**
 *@ignore
 */
const styles = (theme) => ({
    snackError: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: theme.light.error,
            color: theme.light.text.error
        },
        '& .MuiSnackbarContent-message span': {
            fontSize: '1.2rem'
        },
        [theme.darkTheme]: {
            '& .MuiSnackbarContent-root': {
                backgroundColor: theme.dark.error,
                color: theme.dark.text.error
            }
        }
    },
    snackAlert: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.green,
            color: theme.light.text.inverted
        },
        '& .MuiSnackbarContent-message span': {
            fontSize: '1.2rem'
        },
        [theme.darkTheme]: {
            '& .MuiSnackbarContent-root': {
                backgroundColor: DuThemeUtilities.DefaultColors.primary.green,
                color: theme.dark.text.primary
            }
        }
    },
    messageList: {
        display: 'block'
    }
});

/**
 * @description DrcPageWarning is a styled page to be shown when some thing goes wrong
 * @class DrcPageWarning
 * @example
 * <DrcPageWarning message={['Waiting on API Integration, this page will not work without API']} />
 * @donotprint true
 * @category General
 * @tags warning message error problem
 * @property {any} message Message of type string or array can be passed
 * @property {Object} classes custom styling classes
 * @property {boolean} isError if true displays error
 * @property {string} anchorVertical position of message
 * @property {string} anchorHorizontal position of message
 * @extends {React.Component}
 */
class DrcPageWarning extends React.Component {
    /**
     *
     *
     * @return {*}
     * @memberof DrcPageWarning
     */
    render() {
        const { className, classes, message, anchorVertical, anchorHorizontal, isError } = this.props;
        var msg = '',
            error = false;

        if (typeof message === 'string' || message instanceof String) {
            msg = <span>{message}</span>;
        } else {
            msg = message.map((m) => (
                <span key={m} className={classes.messageList}>
                    {m}
                </span>
            ));
        }

        if (isError === true || isError === undefined) {
            error = true;
        }

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: anchorVertical || 'bottom',
                    horizontal: anchorHorizontal || 'center'
                }}
                open={true}
                onClose={() => {}}
                className={`${error ? classes.snackError : classes.snackAlert} ${className || ''}`}
                message={msg}
            />
        );
    }
}

export default withStyles(styles)(DrcPageWarning);
