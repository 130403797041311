import React, { Fragment, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getAccordionColor } from '../data/constants';
import PoolDefinitionDialog from '../components/PoolDefinitionDialog/PoolDefinitionDialog';
// SVG icons
import { ReactComponent as AddIcon } from '../Images/SVG/Add_Enabled.svg';
import { ReactComponent as EditIcon } from '../Images/SVG/Edit_Enabled.svg';
import { ReactComponent as CopyIcon } from '../Images/SVG/Copy_Enabled.svg';
import { ReactComponent as DeleteIcon } from '../Images/SVG/Delete_Enabled.svg';

import { DrcDialog, DrcButton, DrcTranslate } from 'drc/driscolls-react-components/index';
import Form from 'react-validation/build/form';
import GGSFormInput from '../components/GGSFormInput';
import { numeric, alphaNumeric, maxlen } from '../utils/validations';

import { connect } from 'react-redux';
import { showLoadingScreenAction, hideLoadingScreenAction } from '../actions/actions';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'routes/withRouter';

const useStyles = makeStyles((theme) => ({
    accordionSummaryStyle: {
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    iconText: {
        paddingRight: '10px',
        width: '50px',
        height: '20px',
        marginRight: '-10px'
    },
    root: {
        backgroundColor: theme.palette.grey['400'],
        marginRight: 10,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.grey['800']} !important`
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: 8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        '& .Mui-expanded': {
            minHeight: '50px !important'
        },
        '& .MuiAccordionSummary-root': {
            height: 50
        }
    },
    expanded: {
        backgroundColor: theme.light.secondary,
        [theme.darkTheme]: {
            backgroundColor: `${theme.dark.secondary} !important`
        }
    },
    panelSummaryLeft: {
        flexDirection: 'row-reverse',
        padding: 0
    },
    detailsRoot: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

// Main Component Function Declaration
const AccordionPoolDefinition = (props) => {
    const [expandAccordion, setExpandAccordion] = useState(false);
    const [openPoolDefinitionDialog, setOpenPoolDefinitionDialog] = useState(false);
    const classes = useStyles();
    const theme = useTheme();

    const [showEditDialog, setShowEditDialog] = useState(false);
    const [editPricingPool, setEditPricingPool] = useState({});
    const [showAddDialog, setShowAddDialog] = useState(false);

    const validationFields = {
        PricingPoolId: {
            displayName: 'Pool Id',
            validations: [numeric, maxlen],
            customProps: { maxlen: 500, required: true }
        },
        Description: {
            displayName: 'Description',
            validations: [alphaNumeric, maxlen],
            customProps: { maxlen: 500, required: true }
        },
        poolStatus: {
            displayName: 'Pool Status',
            validations: [alphaNumeric, maxlen],
            customProps: { maxlen: 500, required: true }
        }
    };

    const postChanges = async () => {
        props.showLoadingScreenAction('Posting data');
        setShowAddDialog(!showAddDialog);

        try {
            // let token = await props.oktaAuth.getAccessToken();
            // let userId = DuAuthenticationUtilities.GetUserId(token) || '';

            // var data = await Call(
            //     {
            //         name: 'PricingPool',
            //         url: APIEndPoints.POST_PRICINGPOOL(),
            //         method: 'POST',
            //         options: {},
            //         data: {
            //             pricingPoolId: editPricingPool.pricingPoolId
            //         }
            //     },
            //     token
            // );
            if (data.errors.length && !data.raw) {
                return showError(data.errors[0]);
            }
            if (!data) {
                showError();
            } else {
                props.hideLoadingScreenAction();
                setPoolWeekData(data?.display?.resultSet1 || []);
            }
        } catch (error) {
            showError(error);
        }

        props.hideLoadingScreenAction();
    };

    const renderInputForm = () => {
        return (
            <Form>
                {Object.keys(validationFields).map((inputField) => {
                    return (
                        <GGSFormInput
                            key={inputField}
                            label={validationFields[inputField].displayName}
                            onChange={(e) =>
                                setEditPricingPool({
                                    ...editPricingPool,
                                    [inputField]: e.target.value
                                })
                            }
                            value={editPricingPool[inputField]}
                            validations={validationFields[inputField].validations}
                            {...validationFields[inputField].customProps}
                        />
                    );
                })}
            </Form>
        );
    };

    return (
        <Fragment>
            <Accordion
                square
                expanded={expandAccordion}
                className={`${classes.root} ${expandAccordion ? classes.expanded : ''} ${props.className}`}
                elevation={props.elevation || 0}
            >
                <AccordionSummary
                    className={`${classes.accordionSummaryStyle} ${classes.panelSummaryLeft}`}
                    onClick={() => setExpandAccordion(!expandAccordion)}
                    expandIcon={
                        <>
                            <Box
                                component="span"
                                mr={1}
                                style={{
                                    padding: '10px 0',
                                    border: `4px solid ${getAccordionColor(theme, props.poolStatus)}`,
                                    borderRadius: 8,
                                    backgroundColor: `${getAccordionColor(theme, props.poolStatus)}`
                                }}
                            />
                            {!expandAccordion ? <ExpandMore /> : <ExpandLessIcon />}
                        </>
                    }
                    IconButtonProps={{ edge: 'start', style: { margin: 0, transform: 'rotate(0deg)', padding: 2 } }}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography
                        onClick={(e) => {
                            e.stopPropagation();
                            if (!props.disableDialog) {
                                setExpandAccordion(false);
                                setOpenPoolDefinitionDialog(!openPoolDefinitionDialog);
                            } else {
                                setExpandAccordion(!expandAccordion);
                            }
                        }}
                        style={{ fontWeight: 'bold', textTransform: 'capitalize', ...props.titleStyle }}
                    >
                        {props.title}
                    </Typography>
                    {!props.disableDialog && (
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <AddIcon
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowAddDialog(!showAddDialog);
                                }}
                                className={classes.iconText}
                            />
                            <EditIcon
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowEditDialog(!showEditDialog);
                                }}
                                className={classes.iconText}
                            />
                            <CopyIcon onClick={() => console.log('copying')} className={classes.iconText} />
                            <DeleteIcon onClick={() => console.log('deleting')} className={classes.iconText} />
                        </Box>
                    )}
                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.detailsRoot }}>{props.children}</AccordionDetails>
            </Accordion>
            {openPoolDefinitionDialog && (
                <PoolDefinitionDialog selectedKey={props.selectedKey} data={props.data} setOpenPoolDefinitionDialog={setOpenPoolDefinitionDialog} />
            )}
            <DrcDialog
                title={<DrcTranslate>{'Add Pricing Pool'}</DrcTranslate>}
                open={showAddDialog}
                buttons={
                    <Fragment>
                        <DrcButton
                            poly
                            line
                            primary
                            onClick={() => {
                                postChanges();
                            }}
                        >
                            <DrcTranslate>{'Save'}</DrcTranslate>
                        </DrcButton>
                        <DrcButton
                            poly
                            line
                            onClick={() => {
                                setEditPricingPool({});
                                setShowAddDialog(!showAddDialog);
                            }}
                        >
                            <DrcTranslate>{'Cancel'}</DrcTranslate>
                        </DrcButton>
                    </Fragment>
                }
            >
                {renderInputForm()}
            </DrcDialog>
            <DrcDialog
                title={<DrcTranslate>{'Edit Pricing Pool'}</DrcTranslate>}
                open={showEditDialog}
                buttons={
                    <Fragment>
                        <DrcButton
                            poly
                            line
                            primary
                            onClick={() => {
                                setShowEditDialog(!showEditDialog);
                            }}
                        >
                            <DrcTranslate>{'Save'}</DrcTranslate>
                        </DrcButton>
                        <DrcButton
                            poly
                            line
                            onClick={() => {
                                setEditPricingPool({});
                                setShowEditDialog(!showEditDialog);
                            }}
                        >
                            <DrcTranslate>{'Cancel'}</DrcTranslate>
                        </DrcButton>
                    </Fragment>
                }
            >
                {renderInputForm()}
            </DrcDialog>
        </Fragment>
    );
};

const mapStateToProps = ({ masterReducer, pricingPoolReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    userLang: masterReducer.userLang,
    parentPools: pricingPoolReducer.parentPools,
    pricingPoolsMap: pricingPoolReducer.pricingPoolsMap,
    settlementPoolsMap: pricingPoolReducer.settlementPoolsMap
});

export default connect(mapStateToProps, { showLoadingScreenAction, hideLoadingScreenAction })(withOktaAuth(withRouter(AccordionPoolDefinition)));
