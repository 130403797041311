import React, { Fragment, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { designColors, BU_CURRENCY } from '../data/constants';
import { DrcIconButton, DrcButton, DrcTranslate } from 'drc/driscolls-react-components/index';
import CloseIcon from '@mui/icons-material/Close';
import BackupIcon from '@mui/icons-material/Backup';
import { withRouter } from 'routes/withRouter';
import { connect } from 'react-redux';
import { Call } from '@driscollsinc/driscolls-display-rules';
import APIEndPoints from '../services/api';
import { withOktaAuth } from '@okta/okta-react';
import { showReportBackLinkAction, showLoadingScreenAction, hideLoadingScreenAction, showToast } from '../actions/actions';
import { setSelectedSettlementRecord } from '../actions/WeeklySettlementStatusAction';
import { userBusinessUnit } from '../utils/helper';
import { DuAuthenticationUtilities, DuDateUtilities } from '@driscollsinc/driscolls-react-utilities';
import DrcFileUploadDialog from './DrcFileUploadDialog';
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.light.primary,
        [theme.darkTheme]: {
            backgroundColor: `${theme.dark.primary} !important`
        },
        borderRadius: 8,
        padding: '16px 8px',
        marginTop: 8
    },
    boxRow: {
        margin: '8px 12px 0 0',
        display: 'flex',
        justifyContent: 'space-between'
    },
    text: {
        color: `${theme.light.buttonBorder} !important`,
        [theme.darkTheme]: {
            color: `${theme.dark.buttonBorder} !important`
        },
        fontWeight: 500
    },
    link: {
        color: `${designColors.blue} !important`,
        [theme.darkTheme]: {
            color: `${designColors.lightGrey} !important`
        },
        fontWeight: 500,
        cursor: 'pointer'
    }
}));

function ExpandedRowDetail(props) {
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const [uploadFileType, setUploadFileType] = useState('');
    const classes = useStyles();
    const showError = (err, msg) => {
        props.hideLoadingScreenAction();
        props.showToast(<DrcTranslate>{msg || 'An Error Occurred'}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    const handleApprove = async (status) => {
        try {
            props.showLoadingScreenAction('Processing');
            let token = await props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            let response = await Call(
                {
                    name: 'GrowerSettlementStatus',
                    url: APIEndPoints.PUT_GROWER_SETTLEMENT_STATUS(),
                    method: 'PUT',
                    options: {},
                    data: {
                        SubmittedBy: props.data.SubmittedBy || '',
                        ApprovedDate: new Date().toISOString(),
                        ApprovedBy: loggedInUser,
                        BusinessUnit: userBusinessUnit(),
                        GrowerSettlementStatusID: props.data.GrowerSettlementStatusID,
                        CreatedBy: props.data.CreatedBy,
                        ModifiedDateTime: new Date().toISOString(),
                        PoolID: props.data.PoolID,
                        PoolWeek: props.selectedPoolWeek.PoolWeek,
                        ModifiedBy: loggedInUser,
                        SubmittedDate: props.data.SubmittedDate || '',
                        CreatedDateTime: props.data.CreatedDateTime,
                        Status: status
                    }
                },
                token
            );
            if (response.errors.length && !response.raw) {
                return showError(response.errors[0]);
            }
            props.setIsExpanded(false);
            await sendEmail(status, loggedInUser, token);
            props.hideLoadingScreenAction();
            props.onLoad();
        } catch (err) {
            props.hideLoadingScreenAction();
            console.log('error : ', err);
        }
    };

    const sendEmail = async (status, loggedInUser, token) => {
        try {
            await Call(
                {
                    name: 'GrowerSettlementStatusEmail',
                    url: APIEndPoints.POST_EMAIL(),
                    method: 'POST',
                    options: {},
                    data: {
                        SendTo: props.data.SubmittedBy, //'kapil.pandey@driscolls.com',
                        Subject: `GGS Grower Settlement Request ${status}`,
                        MessageText: `Hi,<br/>${loggedInUser} has ${status} your GGS Grower Settlement Request Submitted for<br/>PoolWeek: ${
                            props.selectedPoolWeek.PoolWeek
                        }<br/>BusinessUnit: ${userBusinessUnit()}<br/>PoolID: ${props.data.PoolID}<br/>Thanks`,
                        Attachment: null,
                        AttachmentFileName: '',
                        Isxl: false,
                        IsTemplate: false
                    }
                },
                token
            );
        } catch (err) {
            console.log(err);
        }
    };

    const isUploadingDisabled = () => {
        const status = props.selectedSettlementRecord[0].Status;
        return !['Approved', 'Published', 'Partially Published'].includes(status);
    };

    const handleNavigation = (pathname, state) => {
        props.setSelectedSettlementRecord(props.data);
        props.router.history.push({
            pathname,
            state
        });
    };
    let userRole = JSON.parse(localStorage.getItem('GGS_UserRole') || '{}');
    return (
        <Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    {props.data.SubmittedBy && (
                        <Box>
                            <Typography variant="subtitle2" noWrap display="inline">
                                <DrcTranslate>{'Prepared By : '}</DrcTranslate>
                            </Typography>
                            <Typography variant="subtitle2" noWrap display="inline" style={{ color: designColors.blue }}>
                                {props.data.SubmittedBy} on {DuDateUtilities.FormatDateFromIso(props.data.SubmittedDate)}
                            </Typography>
                        </Box>
                    )}
                    {(props.data.Status === 'Approved' || props.data.Status === 'Rejected') && (
                        <Box>
                            <Typography variant="subtitle2" noWrap display="inline">
                                {props.data.Status === 'Rejected' ? (
                                    <DrcTranslate>{'Rejected By'}</DrcTranslate>
                                ) : (
                                    <DrcTranslate>{'Approved By'}</DrcTranslate>
                                )}
                            </Typography>
                            <Typography variant="subtitle2" noWrap display="inline" style={{ color: designColors.blue }}>
                                {props.data.ApprovedBy} on {DuDateUtilities.FormatDateFromIso(props.data.ApprovedDate)}
                            </Typography>
                        </Box>
                    )}
                </div>
                {props.userIsAdmin && props.data.Status === 'Pending Approval' && !props.selectedRole.includes('Admin') ? (
                    <div>
                        <DrcButton onClick={() => handleApprove('Rejected')}>
                            <DrcTranslate>{'Reject'}</DrcTranslate>
                        </DrcButton>
                        <DrcButton onClick={() => handleApprove('Approved')} isPrimary>
                            <DrcTranslate>{'Approve'}</DrcTranslate>
                        </DrcButton>
                    </div>
                ) : (
                    <DrcIconButton
                        onClick={() => {
                            props.setIsExpanded(false);
                        }}
                        isText
                        style={{ cursor: 'pointer' }}
                    >
                        <CloseIcon />
                    </DrcIconButton>
                )}
            </div>
            <div className={classes.container}>
                <Box
                    mr={1}
                    style={{
                        // padding: '8px 0',
                        // border: `4px solid ${getAccordionColor(theme,props.data.status)}`,
                        // borderRadius: 8,
                        // backgroundColor: '${getAccordionColor(theme,props.data.status)}'
                        flex: 1
                    }}
                >
                    <Typography variant="body1" noWrap style={{ fontWeight: 'bold' }}>
                        <DrcTranslate>{'QUANTITY'}</DrcTranslate>
                    </Typography>
                    <Box
                        mr={1}
                        style={{
                            borderRight: '4px solid #D8D8D8',
                            height: '80%'
                        }}
                    >
                        <div className={classes.boxRow}>
                            <Typography
                                display="inline"
                                noWrap
                                onClick={() => {
                                    handleNavigation(`/SettlementWorkbench/${props.selectedPoolWeek.PoolWeek}/Details`, 0);
                                }}
                                className={`${classes.text} ${classes.link}`}
                            >
                                <DrcTranslate>{'Receipts'}</DrcTranslate>
                            </Typography>
                            <Typography
                                display="inline"
                                noWrap
                                onClick={() => {
                                    handleNavigation(`/SettlementWorkbench/${props.selectedPoolWeek.PoolWeek}/Details`, 0);
                                }}
                                className={`${classes.text} ${classes.link}`}
                            >
                                {props.data.ExpectedReceiptQty || 0}
                            </Typography>
                        </div>
                        <div className={classes.boxRow}>
                            <Typography
                                display="inline"
                                noWrap
                                onClick={() => {
                                    handleNavigation(`/SettlementWorkbench/${props.selectedPoolWeek.PoolWeek}/Details`, 1);
                                }}
                                className={`${classes.text} ${classes.link}`}
                            >
                                <DrcTranslate>{'Sales'}</DrcTranslate>
                            </Typography>
                            <Typography
                                display="inline"
                                noWrap
                                onClick={() => {
                                    handleNavigation(`/SettlementWorkbench/${props.selectedPoolWeek.PoolWeek}/Details`, 1);
                                }}
                                className={`${classes.text} ${classes.link}`}
                            >
                                {props.data.SoldQty}
                            </Typography>
                        </div>
                        <div className={classes.boxRow}>
                            <Typography
                                display="inline"
                                noWrap
                                onClick={() => {
                                    handleNavigation(`/SettlementWorkbench/${props.selectedPoolWeek.PoolWeek}/Details`, 2);
                                }}
                                className={`${classes.text} ${classes.link}`}
                            >
                                <DrcTranslate>{'Inventory On-hand'}</DrcTranslate>
                            </Typography>
                            <Typography
                                noWrap
                                className={`${classes.text} ${isUploadingDisabled() ? classes.link : null}`}
                                onClick={() => {
                                    if (isUploadingDisabled()) {
                                        setUploadFileType('Inventory On Hand');
                                        setOpenUploadDialog(true);
                                    }
                                }}
                            >
                                <BackupIcon fontSize="small" />
                            </Typography>
                            <Typography
                                display="inline"
                                noWrap
                                onClick={() => {
                                    handleNavigation(`/SettlementWorkbench/${props.selectedPoolWeek.PoolWeek}/Details`, 2);
                                }}
                                className={`${classes.text} ${classes.link}`}
                            >
                                {props.data.InvOnHandQty || 0}
                            </Typography>
                        </div>
                        <div className={classes.boxRow}>
                            <Typography
                                display="inline"
                                noWrap
                                onClick={() => {
                                    handleNavigation(`/SettlementWorkbench/${props.selectedPoolWeek.PoolWeek}/Details`, 3);
                                }}
                                className={`${classes.text} ${classes.link}`}
                            >
                                <DrcTranslate>{'Goods In Transit'}</DrcTranslate>
                            </Typography>
                            <Typography
                                noWrap
                                className={`${classes.text} ${isUploadingDisabled() ? classes.link : null}`}
                                onClick={() => {
                                    if (isUploadingDisabled()) {
                                        setUploadFileType('Goods In Transit');
                                        setOpenUploadDialog(true);
                                    }
                                }}
                            >
                                <BackupIcon fontSize="small" />
                            </Typography>
                            <Typography
                                display="inline"
                                noWrap
                                onClick={() => {
                                    handleNavigation(`/SettlementWorkbench/${props.selectedPoolWeek.PoolWeek}/Details`, 3);
                                }}
                                className={`${classes.text} ${classes.link}`}
                            >
                                {props.data.InTransitQty || 0}
                            </Typography>
                        </div>
                        <div className={classes.boxRow}>
                            <Typography
                                display="inline"
                                noWrap
                                onClick={() => {
                                    handleNavigation(`/SettlementWorkbench/${props.selectedPoolWeek.PoolWeek}/Details`, 5);
                                }}
                                className={`${classes.text} ${classes.link}`}
                            >
                                <DrcTranslate>{'Repackage Movement'}</DrcTranslate>
                            </Typography>
                            <Typography
                                display="inline"
                                noWrap
                                onClick={() => {
                                    handleNavigation(`/SettlementWorkbench/${props.selectedPoolWeek.PoolWeek}/Details`, 5);
                                }}
                                className={`${classes.text} ${classes.link}`}
                            >
                                {props.data.RepackageSalesQty || 0}
                            </Typography>
                        </div>
                    </Box>
                </Box>
                <Box
                    mr={1}
                    style={{
                        flex: 1
                    }}
                >
                    <Typography variant="body1" noWrap style={{ fontWeight: 'bold' }}>
                        <DrcTranslate>{'AMOUNT'}</DrcTranslate>
                    </Typography>
                    <Box
                        mr={1}
                        style={{
                            borderRight: '4px solid #D8D8D8',
                            height: '80%'
                        }}
                    >
                        <div className={classes.boxRow}>
                            <Typography
                                display="inline"
                                noWrap
                                onClick={() => {
                                    handleNavigation(`/SettlementWorkbench/${props.selectedPoolWeek.PoolWeek}/Details`, 1);
                                }}
                                className={`${classes.text} ${classes.link}`}
                            >
                                <DrcTranslate>{'Sales'}</DrcTranslate>
                            </Typography>
                            <Typography
                                display="inline"
                                noWrap
                                onClick={() => {
                                    handleNavigation(`/SettlementWorkbench/${props.selectedPoolWeek.PoolWeek}/Details`, 1);
                                }}
                                className={`${classes.text} ${classes.link}`}
                            >
                                {BU_CURRENCY[userBusinessUnit()]}
                                {props.data.SalesAmount}
                            </Typography>
                        </div>
                        <div className={classes.boxRow}>
                            <Typography
                                display="inline"
                                noWrap
                                onClick={() => {
                                    handleNavigation(`/SettlementWorkbench/${props.selectedPoolWeek.PoolWeek}/Details`, 4);
                                }}
                                className={`${classes.text} ${classes.link}`}
                            >
                                <DrcTranslate>{'Service Deductions'}</DrcTranslate>
                            </Typography>
                            <Typography
                                display="inline"
                                noWrap
                                onClick={() => {
                                    handleNavigation(`/SettlementWorkbench/${props.selectedPoolWeek.PoolWeek}/Details`, 4);
                                }}
                                className={`${classes.text} ${classes.link}`}
                            >
                                {BU_CURRENCY[userBusinessUnit()]}
                                {props.data.ServiceDeductionAmount}
                            </Typography>
                        </div>
                        <div className={classes.boxRow}>
                            <Typography
                                display="inline"
                                noWrap
                                onClick={() => {
                                    handleNavigation(`/SettlementWorkbench/${props.selectedPoolWeek.PoolWeek}/Details`, 6);
                                }}
                                className={`${classes.text} ${classes.link}`}
                            >
                                <DrcTranslate>{'Import Cost'}</DrcTranslate>
                            </Typography>
                            <Typography
                                noWrap
                                className={`${classes.text} ${isUploadingDisabled() ? classes.link : null}`}
                                onClick={() => {
                                    if (isUploadingDisabled()) {
                                        setUploadFileType('Import Cost');
                                        setOpenUploadDialog(true);
                                    }
                                }}
                            >
                                <BackupIcon fontSize="small" />
                            </Typography>
                            <Typography
                                display="inline"
                                noWrap
                                onClick={() => {
                                    handleNavigation(`/SettlementWorkbench/${props.selectedPoolWeek.PoolWeek}/Details`, 6);
                                }}
                                className={`${classes.text} ${classes.link}`}
                            >
                                {BU_CURRENCY[userBusinessUnit()]}
                                {props.data.ImportCost}
                            </Typography>
                        </div>
                    </Box>
                </Box>
                <Box
                    mr={1}
                    style={{
                        flex: 1
                    }}
                >
                    <Typography variant="body1" noWrap style={{ fontWeight: 'bold' }}>
                        <DrcTranslate>{'ADJUSTMENTS'}</DrcTranslate>
                    </Typography>
                    <Box mr={1}>
                        <div className={classes.boxRow}>
                            <Typography
                                display="inline"
                                noWrap
                                onClick={() => {
                                    handleNavigation(`/SettlementWorkbench/${props.selectedPoolWeek.PoolWeek}/Details`, 7);
                                }}
                                className={`${classes.text} ${classes.link}`}
                            >
                                <DrcTranslate>{'Adjustment Amount'}</DrcTranslate>
                            </Typography>
                            <Typography
                                display="inline"
                                noWrap
                                onClick={() => {
                                    handleNavigation(`/SettlementWorkbench/${props.selectedPoolWeek.PoolWeek}/Details`, 7);
                                }}
                                className={`${classes.text} ${classes.link}`}
                            >
                                {BU_CURRENCY[userBusinessUnit()]}
                                {props.data.Adjustments}
                            </Typography>
                        </div>
                    </Box>
                </Box>
                <DrcFileUploadDialog
                    open={openUploadDialog}
                    setOpenUploadDialog={setOpenUploadDialog}
                    fileType={uploadFileType}
                    refreshData={props.onLoad}
                />
            </div>
        </Fragment>
    );
}

const mapStateToProps = ({ masterReducer, weeklySettlementStatusReducer, rootReducer }) => ({
    currentPoolWeek: masterReducer.currentPoolWeek,
    selectedPoolWeek: weeklySettlementStatusReducer.selectedPoolWeek,
    userIsAdmin: rootReducer.userIsAdmin,
    selectedRole: rootReducer.selectedRole,
    selectedSettlementRecord: weeklySettlementStatusReducer.records
});
export default connect(mapStateToProps, {
    showReportBackLinkAction,
    showLoadingScreenAction,
    hideLoadingScreenAction,
    showToast,
    setSelectedSettlementRecord
})(withOktaAuth(withRouter(ExpandedRowDetail)));
