//DcrMaintenanceDialog
//This is a component that is a modal to edit and add specific data type, it consists of a form to edit or add data and a modal confirmation dialog.
//It also serves as a confirmation dialog to delete data types.
//it only receives and return the data, and call the callbacks from the parent component.

// Now this component  serves and an extension of DrcMaintenanceDialog, it means that is strongly  coupled with DrcMaintenanceDialog,
// it might be used as a standalone component, but you have to revise DrcMaintenanceDialog to check how it is used.

//props:
//openAdd: Arg. type boolean.if true, the modal will be open to add data.
//openEdit: Arg. type boolean.if true, the modal will be open to edit data.
//cancelBtnText: Arg. type string.the text of the cancel button.
//saveBtnText: Arg. type string.the text of the save button.
//onAddNewNo: Arg. type function.the callback function to call when the user clicks the cancel button.
//onAddNewYes: Arg. type function.the callback function to call when the user clicks the save button.
//onAddEditNo: Arg. type function.the callback function to call when the user clicks the cancel button (edit mode).
//onAddEditYes: Arg. type function.the callback function to call when the user clicks the save button (edit mode).
//title: Arg. type string.the title of the modal.
//verticalOrientation: Arg. type boolean.if true, the modal will be vertical.
// columns(recommended): Arg type Array of Objects. Objects must contain key and name
//      pair correlating to the Data types key and the display name for that key. There
//      is also an optional prop called validationType that denotes the validation on
//      the maintenance input options. Right now number is supported and by default a
//      value is treated as type string.
//      ex: [{key:"CriteriaOptions", name:"Berry Type"}, {key:"CriteriaOrder", name:"Type Id", validationType:"number"}]
//confirmAcceptBtnText: Arg. type string.the text of the accept button in the confirmation dialog.
//confirmRejectBtnText: Arg. type string.the text of the reject button in the confirmation dialog.
//confirmDialogTitle: Arg. type string.the title of the confirmation dialog.
//confirmDialogOpen: Arg. type boolean.if true, the confirmation dialog will be open.
//isEdit: Arg. type boolean.if true, the modal will be in edit mode.
//onDialogNo: Arg. type function.the callback function to call when the user clicks the reject button in the confirmation dialog.
//onDialogYes: Arg. type function.the callback function to call when the user clicks the accept button in the confirmation dialog.
//oldValue: Arg. type object.the old value of the data that is being edited.
//editedValue: Arg. type object.the new value of the data that is being edited or deleted.
//generateDialogTextNewYes: Arg. type function.the callback function that must return the body of the confirmation dialog.
//generateDialogTextEditYes: Arg. type function.the callback function that must return the body of the confirmation dialog edit mode.

import React from 'react';
import DrcDialog from './DrcDialog';
import DrcButton from './DrcButton';
import DrcPanel from './DrcPanel';
import DrcKeyValueTable from './DrcKeyValueTable';
import DrcSelect from './DrcSelect';
import DrcSwitch from './DrcSwitch';
import DrcCheckbox from './DrcCheckbox';
import DrcDatePicker from './DrcDatepicker';
import DrcTimePicker from './DrcTimePicker';
import DrcInput from './DrcInput';
import { FormControlLabel } from '@mui/material';
import { Radio } from '@mui/material';
import { DuValidationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { withStyles } from '@mui/styles';

const allowedStringValuesRegex = RegExp(/[,?/#!$%^&*;:{}=_`"~()]/);
const allowedNumberValuesRegex = RegExp(/^[0-9]*$/);
const allowedDecimalValuesRegex = RegExp(/^[0-9]*(\.[0-9]*)?$/);

/**
 *@ignore
 */
const styles = (theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: '100%',
            width: '100%'
        }
    },
    legendTitle: {
        fontSize: '1.5rem'
    }
});

class DrcMaintenanceDialog extends React.Component {
    /**
     *@ignore
     */
    constructor(props) {
        super(props);
        this.state = {
            editArray: [],
            editedValue: {},
            oldValue: {},
            helperText: [],
            readOnlyArray: [],
            errorCount: 0,
            isEnabled: false
        };
    }

    /**
     *@ignore
     */
    componentDidMount() {
        if (this.props.editedValue) {
            this.setState(
                {
                    oldValue: this.props.oldValue,
                    editedValue: this.props.editedValue
                },
                this.buildEditArray
            );
        } else {
            this.buildEditArray();
        }
        this.onAddNewNo = this.onAddNewNo.bind(this);
        this.onAddEditYes = this.onAddEditYes.bind(this);
        this.onAddNewYes = this.onAddNewYes.bind(this);
        this.onSelectValueChange = this.onSelectValueChange.bind(this);
        this.handleSwitchChange = this.handleSwitchChange.bind(this);
        this.onAddEditNo = this.onAddEditNo.bind(this);
        this.onDialogYes = this.onDialogYes.bind(this);
        this.onDialogNo = this.onDialogNo.bind(this);
    }

    /**
     *@ignore
     */
    componentDidUpdate() {
        if (this.state.editArray.length < 1) {
            this.buildEditArray();
        }
    }

    /**
     * @description: select the option selected by the user in select inputs
     * @param {*} optionValue: option to select
     * @param {*} isEdit
     * @param {*} isMulti
     * @param {*} editIsMultiDisabled
     * @param {*} selectOptions
     * @param {*} key
     * @memberof DrcMaintenanceDialog
     * @returns value of the selected option
     */
    setSelectOption = (optionValue, isEdit, isMulti, editIsMultiDisabled, selectOptions, key) => {
        if (optionValue !== undefined) {
            if (isEdit && isMulti && editIsMultiDisabled) {
                return selectOptions.find((opt) => opt.value === optionValue);
            } else if (isMulti) {
                return this.state.editedValue[key];
            } else {
                return selectOptions.find((opt) => opt.value === optionValue);
            }
        } else return null;
    };

    /**
     *@ignore
     */
    onSelectValueChange(option, nameObj, isMulti) {
        const name = nameObj.name;
        var retEdit = this.state.editedValue;
        if (isMulti) {
            retEdit[name] = option;
        } else if (!this.state.editedValue[name] || this.state.editedValue[name].value !== option.value) {
            //todo: allow empty values or null values
            //see which one would make more sense
            retEdit[name] = option.value;
        }
        var submitEnabled = this.allFieldsFilled();
        this.setState({ editedValue: retEdit, isEnabled: submitEnabled }, this.buildEditArray);
    }

    /**
     *@ignore
     */
    handleSwitchChange(name, defaultValue) {
        var retEditArray = { ...this.state.editedValue };
        if (this.state.editedValue[name]) {
            retEditArray[name] = false;
        } else {
            if (this.state.editedValue[name] === undefined && defaultValue) {
                retEditArray[name] = false;
            } else retEditArray[name] = true;
        }

        var submitEnabled = this.allFieldsFilled();
        this.setState({ editedValue: retEditArray, isEnabled: submitEnabled }, this.buildEditArray);
    }

    /**
     *@ignore
     */
    handleRadioButtonChange = (name, type) => {
        var retEditArray = { ...this.state.editedValue };
        retEditArray[name] = type;

        var submitEnabled = this.allFieldsFilled();
        this.setState({ editedValue: retEditArray, isEnabled: submitEnabled }, this.buildEditArray);
    };

    /**
     *@ignore
     */
    handleDateChange = (name, value) => {
        var retEditArray = { ...this.state.editedValue };
        retEditArray[name] = value;

        var submitEnabled = this.allFieldsFilled();
        this.setState({ editedValue: retEditArray, isEnabled: submitEnabled }, this.buildEditArray);
    };

    /**
     * @description: check if all fields are filled
     * @returns true if all fields are filled
     * @memberof DrcMaintenanceDialog
     */
    allFieldsFilled = () => {
        var saveAllowed = true;
        var editSaveAllowed = false;
        const that = this;
        const { columns } = this.props;

        columns.forEach((value) => {
            if ((that.state.editedValue[value.key] === '' || that.state.editedValue[value.key] === undefined) && value.isRequired) {
                saveAllowed = false;
            }

            if (this.props.isEdit) {
                if (that.state.editedValue[value.key] === that.state.oldValue[value.key] && value.isRequired && that.state.editedValue[value.key]) {
                    saveAllowed = false;
                } else {
                    if ((that.state.editedValue[value.key] === '' || that.state.editedValue[value.key] === undefined) && value.isRequired) {
                        editSaveAllowed = false;
                    } else {
                        if (!value.isDisabled) editSaveAllowed = true;
                    }
                }
            }
        });

        return editSaveAllowed || saveAllowed;
    };

    /**
     * @description: build the form based on the columns prop
     * @memberof DrcMaintenanceDialog
     */
    async buildEditArray() {
        const that = this;
        const { verticalOrientation, columns, isEdit } = this.props;
        var returnArray = [];
        var returnReadOnlyArray = [];

        var isDisabled = that.props.editDisabled && isEdit ? true : that.props.addDisabled && !isEdit ? true : false;

        columns.forEach(function (c, i) {
            var columnDisabled = c.editDisabled && isEdit ? true : c.addDisabled && !isEdit ? true : false;

            if (c.isDisabled === true) {
                let displayValue;
                if (!that.state.editedValue[c.key] || that.state.editedValue[c.key] !== c.displayValue) {
                    let editedValue = { ...that.state.editedValue };
                    c.displayValue = editedValue[c.key];
                    displayValue = c.displayValue;
                } else {
                    displayValue = that.state.editedValue[c.key];
                }

                if (!c.isHidden) {
                    returnReadOnlyArray.push(
                        <div className="col-sm-6" key={i}>
                            <DrcKeyValueTable>{[{ key: c.name, value: displayValue }]}</DrcKeyValueTable>
                        </div>
                    );
                }
            } else if (c.inputType === 'Select') {
                var optionValue = that.state.editedValue[c.key] !== undefined ? that.state.editedValue[c.key] : '';
                var option = that.setSelectOption(optionValue, isEdit, c.isMulti, c.editIsMultiDisabled, c.selectOptions, c.key);

                var { name, key, selectOptions, isRequired, isMulti, maxMenuHeight, ...other } = c;

                if (c.defaultValue && that.state.editedValue[c.key] === undefined) {
                    let editedValue = { ...that.state.editedValue };
                    editedValue[key] = c.defaultValue.value;
                    that.setState({ editedValue });
                }

                returnArray.push(
                    <DrcSelect
                        label={name}
                        name={key}
                        options={selectOptions}
                        value={option || c.defaultValue}
                        key={i}
                        isDisabled={isDisabled || columnDisabled}
                        index={that.props.index}
                        onChange={(option, type) => that.onSelectValueChange(option, type, isEdit ? (c.editIsMultiDisabled ? false : !!isMulti) : !!isMulti)}
                        InputLabelProps={{ shrink: true }}
                        className={verticalOrientation ? 'col-xs-12' : 'col-xs-12 col-sm-6'}
                        style={{ marginRight: 0 }}
                        required={isRequired}
                        isMulti={isEdit ? (c.editIsMultiDisabled ? false : !!isMulti) : !!isMulti}
                        maxMenuHeight={maxMenuHeight}
                        {...other}
                    />
                );
            } else if (c.inputType === 'Switch') {
                const { key, isRequired, name, ...other } = c;

                if (c.defaultValue && that.state.editedValue[c.key] === undefined) {
                    let editedValue = { ...that.state.editedValue };
                    editedValue[key] = c.defaultValue;
                    that.setState({ editedValue });
                }

                returnArray.push(
                    <div className={verticalOrientation ? 'col-xs-12' : 'col-xs-12 col-sm-6'} key={i}>
                        <DrcSwitch
                            checked={that.state.editedValue[key] === undefined ? c.defaultValue : that.state.editedValue[key]}
                            value={that.state.editedValue[key]}
                            disabled={isDisabled || columnDisabled}
                            onChange={() => that.handleSwitchChange(c.key, c.defaultValue)}
                            style={{ margin: 'auto', padding: 'auto' }}
                            required={isRequired}
                            {...other}
                        >
                            {name}
                        </DrcSwitch>
                    </div>
                );
            } else if (c.inputType === 'Checkbox') {
                const { key, isRequired, name, ...other } = c;

                returnArray.push(
                    <div className={verticalOrientation ? 'col-xs-12' : 'col-xs-12 col-sm-6'} key={i}>
                        <FormControlLabel
                            control={
                                <DrcCheckbox
                                    checked={that.state.editedValue[key]}
                                    value={that.state.editedValue[key]}
                                    disabled={isDisabled || columnDisabled}
                                    onChange={() => that.handleSwitchChange(key)}
                                    style={{ margin: 'auto', padding: 'auto' }}
                                    required={isRequired}
                                    {...other}
                                ></DrcCheckbox>
                            }
                            label={
                                <React.Fragment>
                                    {name}
                                    {isRequired ? <span className={this.props.classes.required}>*</span> : null}
                                </React.Fragment>
                            }
                        />
                    </div>
                );
            } else if (c.inputType === 'Radio') {
                let checked = true;
                let radioButtons = [];
                const { key, isRequired, name, options, ...other } = c;

                for (let i = 0; i < c.options.length; i++) {
                    if (that.state.editedValue[key]) {
                        if (!that.state.editedValue[key] || that.state.editedValue[key] === options[i]) {
                            checked = true;
                        } else {
                            checked = false;
                        }
                    } else {
                        if (i !== 0) {
                            checked = false;
                        }
                    }

                    radioButtons.push(
                        <FormControlLabel
                            key={'rad_' + i}
                            name={name}
                            value={that.state.editedValue[key]}
                            control={
                                <Radio
                                    checked={checked}
                                    disabled={isDisabled || columnDisabled}
                                    onChange={() => that.handleRadioButtonChange(key, options[i])}
                                    required={isRequired}
                                    className={that.props.classes.radio}
                                    {...other}
                                />
                            }
                            label={options[i]}
                        />
                    );
                }

                if (!that.state.editedValue[key]) {
                    let editedValue = { ...that.state.editedValue };
                    editedValue[key] = options[0];
                    that.setState({ editedValue });
                }

                returnArray.push(
                    <div className={verticalOrientation ? 'col-xs-12' : 'col-xs-12 col-sm-6'} key={i}>
                        {radioButtons}
                    </div>
                );
            } else if (c.inputType === 'Date') {
                const { key, isRequired, name, ...other } = c;

                if (c.defaultValue && that.state.editedValue[c.key] === undefined) {
                    let editedValue = { ...that.state.editedValue };
                    editedValue[key] = c.defaultValue;
                    that.setState({ editedValue });
                }

                returnArray.push(
                    <div className={verticalOrientation ? 'col-xs-12' : 'col-xs-12 col-sm-6'} key={i}>
                        <DrcDatePicker
                            clearable
                            label={name}
                            name={key}
                            disabled={isDisabled || columnDisabled}
                            InputLabelProps={{ shrink: true }}
                            onChange={(val) => that.handleDateChange(key, val)}
                            selectedDate={that.state.editedValue[key] || c.defaultValue}
                            required={isRequired}
                            shouldDisableDate={(date) => that.shouldDisableDate(date, key)}
                            {...other}
                        />
                    </div>
                );
            } else if (c.inputType === 'Time') {
                let { key, isRequired, name, ...other } = c;

                if (c.defaultValue && that.state.editedValue[c.key] === undefined) {
                    let editedValue = { ...that.state.editedValue };
                    editedValue[key] = c.defaultValue;
                    that.setState({ editedValue });
                }

                returnArray.push(
                    <div className={verticalOrientation ? 'col-xs-12' : 'col-xs-12 col-sm-6'} key={i}>
                        <DrcTimePicker
                            clearable
                            label={name}
                            name={key}
                            disabled={isDisabled || columnDisabled}
                            InputLabelProps={{ shrink: true }}
                            onChange={(value) => that.handleTimeChange(key, value)}
                            value={that.state.editedValue[key] || c.defaultValue}
                            required={isRequired}
                            {...other}
                        />
                    </div>
                );
            } else {
                let type = (c.inputType || 'text').toLowerCase();
                let { key, isRequired, name, ...other } = c;

                returnArray.push(
                    <div className={verticalOrientation ? 'col-xs-12' : 'col-xs-12 col-sm-6'} key={i}>
                        <DrcInput
                            label={name}
                            disabled={isDisabled || columnDisabled}
                            name={key}
                            value={that.state.editedValue[key] || ''}
                            onChange={(evt) => that.handleAddEditChange(evt, i)}
                            helperText={that.state.helperText[i]}
                            InputLabelProps={{ shrink: true }}
                            style={{ marginRight: 0 }}
                            required={isRequired}
                            type={type}
                            {...other}
                        />
                    </div>
                );
            }
        });

        if (returnArray.length !== 0 || returnReadOnlyArray.length !== 0) {
            this.setState({
                editArray: returnArray,
                readOnlyArray: returnReadOnlyArray
            });
        }
    }

    /**
     *@ignore
     */
    shouldDisableDate = (date, key) => {
        let column = this.props.columns.find((item) => item.key === key);

        if (!column || !column.enabledDays) {
            return false;
        }

        let index = column.enabledDays.findIndex((day) => day === date.getDay());

        return index < 0;
    };

    /**
     *@ignore
     */
    handleTimeChange = (name, value) => {
        var retEditArray = { ...this.state.editedValue };
        retEditArray[name] = value ? value.getTime() || '' : '';

        var submitEnabled = this.allFieldsFilled();
        this.setState({ editedValue: retEditArray, isEnabled: submitEnabled }, this.buildEditArray);
    };

    /**
     *@ignore
     */
    handleAddEditChange = (event, i) => {
        const name = event.target.name;
        if (this.state.editedValue[name] !== event.target.value) {
            var helperText = this.state.helperText;
            var retEdit = this.state.editedValue;
            var validationType = this.props.columns[i].validationType;

            if (!validationType) {
                if (this.props.columns[i].isRequired) {
                    validationType = 'required';
                }
            }

            var helpText = '';
            switch (this.props.columns[i].validationType || this.props.columns[i].inputType) {
                case 'number':
                    if (!allowedNumberValuesRegex.test(event.target.value) || isNaN(Number(event.target.value))) {
                        helpText = this.state.textOptions.removeIllegalChars;
                    } else {
                        if (!isNaN(this.props.columns[i].minValue) && Number(event.target.value) < this.props.columns[i].minValue) {
                            helpText = this.state.textOptions.useLargerValue;
                        } else if (!isNaN(this.props.columns[i].maxValue) && Number(event.target.value) > this.props.columns[i].maxValue) {
                            helpText = this.state.textOptions.useSmallerValue;
                        } else {
                            helpText = this.validateTextLength(event, i);
                        }
                    }
                    break;
                case 'decimal':
                    if (!allowedDecimalValuesRegex.test(event.target.value) || isNaN(Number(event.target.value))) {
                        helpText = this.state.textOptions.removeIllegalChars;
                    } else {
                        if (!isNaN(this.props.columns[i].minValue) && Number(event.target.value) < this.props.columns[i].minValue) {
                            helpText = this.state.textOptions.useLargerValue;
                        } else if (!isNaN(this.props.columns[i].maxValue) && Number(event.target.value) > this.props.columns[i].maxValue) {
                            helpText = this.state.textOptions.useSmallerValue;
                        } else {
                            helpText = this.validateTextLength(event, i);
                        }
                    }
                    break;
                case 'required':
                    if (event.target.value === '') {
                        helpText = this.state.textOptions.requiredField;
                    } else {
                        helpText = this.validateTextLength(event, i);
                    }
                    break;
                case 'regex':
                    if (!this.props.columns[i].regex.test(event.target.value)) {
                        helpText = this.props.columns[i].regexDescription || this.state.textOptions.correctInput;
                    } else {
                        helpText = this.validateTextLength(event, i);
                    }
                    break;
                case 'email':
                    if (!DuValidationUtilities.EMAIL_REGEXP.test(event.target.value)) {
                        helpText = this.props.columns[i].regexDescription || this.state.textOptions.addValidEmail;
                    } else {
                        helpText = this.validateTextLength(event, i);
                    }
                    break;
                case 'userId':
                    if (!DuValidationUtilities.USER_ID_REGEX.test(event.target.value)) {
                        helpText = this.props.columns[i].regexDescription || this.state.textOptions.addValidUserId;
                    } else {
                        helpText = this.validateTextLength(event, i);
                    }
                    break;
                default:
                    if (allowedStringValuesRegex.test(event.target.value)) {
                        helpText = this.state.textOptions.removeIllegalChars;
                    } else {
                        helpText = this.validateTextLength(event, i);
                    }
                    break;
            }

            helperText[i] = helpText;

            retEdit[name] = event.target.value;
            var newErrorCount = helperText.filter((ht) => ht !== '').length;
            var submitEnabled = this.allFieldsFilled() && newErrorCount <= 0;

            this.setState({ editedValue: retEdit, helperText: helperText, errorCount: newErrorCount, isEnabled: submitEnabled }, this.buildEditArray);
        }
    };

    /**
     *@ignore
     */
    validateTextLength = (event, i) => {
        if (!isNaN(this.props.columns[i].minLength) && (event.target.value || '').length < this.props.columns[i].minLength) {
            return this.state.textOptions.requiredLengthTooSmall;
        } else if (!isNaN(this.props.columns[i].maxLength) && (event.target.value || '').length > this.props.columns[i].maxLength) {
            return this.state.textOptions.requiredLengthTooLarge;
        }

        return '';
    };

    /**
     * @description Function handle when the user clicks on the edit cancel  button, it will call the onAddEditNo callback.
     * @return {*}
     * @memberof DrcMaintenanceDialog
     */
    onAddEditNo() {
        this.setState(
            {
                helperText: [],
                isEnabled: false
            },
            () => {
                this.props.onAddEditNo();
                this.buildEditArray();
            }
        );
    }

    /**
     * @description Function handle when the user clicks on the edit  button, it will call the onAddEditYes callback.
     * @return {*}
     * @memberof DrcMaintenanceDialog
     */
    onAddEditYes() {
        if (this.state.helperText > 0) {
            return;
        }
        const dialogText = this.props.generateDialogTextEditYes(this.state.editedValue);
        this.setState(
            {
                helperText: []
            },
            () => {
                this.props.onAddEditYes(dialogText);
            }
        );
    }

    /**
     * @description Function handle when the user clicks on the add cancel  button, it will call the onAddEditNo callback.
     * @return {*}
     * @memberof DrcMaintenanceDialog
     */
    onAddNewNo() {
        this.setState(
            {
                helperText: [],
                editedValue: {},
                isEnabled: false
            },
            () => {
                this.buildEditArray();
                this.props.onAddNewNo();
            }
        );
    }

    /**
     *
     * @description Function handle when the user clicks on the add new button, it will call the onAddNewYes callback.
     * @return {*}
     * @memberof DrcMaintenanceDialog
     */
    onAddNewYes() {
        if (this.state.errorCount > 0) {
            return;
        }
        const dialogText = this.props.generateDialogTextNewYes(this.state.editedValue);
        this.setState(
            {
                helperText: []
            },
            () => {
                this.props.onAddNewYes(dialogText);
            }
        );
    }

    /**
     * @description Function to handle when the user confirms, it will call the callback onDialogYes
     * @return {*}
     * @memberof DrcMaintenanceDialog
     */
    onDialogYes() {
        this.props.onDialogYes(this.state.oldValue, this.state.editedValue);

        this.setState(
            {
                oldValue: {},
                editedValue: {},
                isEdit: false,
                helperText: [],
                selectedRecords: []
            },
            this.buildEditArray
        );
    }

    /**
     * @description Function to handle when the user cancels in the confirm dialog, it will call the callback onDialogNo
     * @return {*}
     * @memberof DrcMaintenanceDialog
     */
    onDialogNo() {
        this.props.onDialogNo();
        this.setState(
            {
                oldValue: {},
                editedValue: {},
                isEnabled: false,
                isEdit: false,
                helperText: []
            },
            this.buildEditArray
        );
    }

    render() {
        const {
            cancelBtnText,
            saveBtnText,
            openAdd,
            openEdit,
            title,
            classes,
            verticalOrientation,
            confirmAcceptBtnText,
            confirmRejectBtnText,
            confirmDialogTitle,
            confirmDialogOpen
        } = this.props;

        return (
            <>
                {/*Dialog to edit an entity*/}
                {!verticalOrientation ? (
                    <DrcDialog
                        className={classes.dialog}
                        maxWidth={'100%'}
                        open={openEdit}
                        buttons={
                            <React.Fragment>
                                <DrcButton isSecondary onClick={this.onAddEditNo} floatRight>
                                    {cancelBtnText}
                                </DrcButton>
                                <DrcButton isPrimary disabled={!this.state.isEnabled} onClick={this.onAddEditYes} floatRight>
                                    {saveBtnText}
                                </DrcButton>
                            </React.Fragment>
                        }
                        title={title}
                    >
                        <hr />
                        {this.state.readOnlyArray.length === 0 ? null : (
                            <DrcPanel maxWidth="100%" style={{ padding: 10 }}>
                                <div className="row">{this.state.readOnlyArray}</div>
                            </DrcPanel>
                        )}
                        <div className="row" style={{ alignItems: 'center' }}>
                            {this.state.editArray}
                        </div>
                    </DrcDialog>
                ) : (
                    <div className="col-xs-12 col-sm-4">
                        <legend className={classes.legendTitle}>{title}</legend>
                        <hr />
                        {this.state.readOnlyArray.length === 0 ? null : (
                            <DrcPanel maxWidth="100%" style={{ padding: 10 }}>
                                <div className="row">{this.state.readOnlyArray}</div>
                            </DrcPanel>
                        )}
                        {this.state.editArray}
                        <DrcButton isSecondary onClick={this.onAddEditNo} floatRight>
                            {cancelBtnText}
                        </DrcButton>
                        <DrcButton isPrimary disabled={!this.state.isEnabled} onClick={this.onAddEditYes} floatRight>
                            {saveBtnText}
                        </DrcButton>
                    </div>
                )}
                {/*Dialog to add new entity*/}
                <DrcDialog
                    className={classes.dialog}
                    open={openAdd}
                    title={title}
                    buttons={
                        <React.Fragment>
                            <DrcButton isSecondary onClick={this.onAddNewNo} floatRight>
                                {cancelBtnText}
                            </DrcButton>
                            <DrcButton isPrimary disabled={!this.state.isEnabled} onClick={this.onAddNewYes} floatRight>
                                {saveBtnText}
                            </DrcButton>
                        </React.Fragment>
                    }
                >
                    <hr />
                    {this.state.readOnlyArray.length === 0 ? null : (
                        <DrcPanel maxWidth="100%" style={{ padding: 10 }}>
                            <div className="row">{this.state.readOnlyArray}</div>
                        </DrcPanel>
                    )}
                    <div className="row">{this.state.editArray}</div>
                </DrcDialog>
                <DrcDialog
                    className={classes.dialog}
                    title={confirmDialogTitle}
                    open={confirmDialogOpen}
                    buttons={
                        <React.Fragment>
                            <DrcButton isPrimary onClick={this.onDialogYes}>
                                {confirmAcceptBtnText}
                            </DrcButton>
                            <DrcButton isSecondary onClick={this.onDialogNo}>
                                {confirmRejectBtnText}
                            </DrcButton>
                        </React.Fragment>
                    }
                />
            </>
        );
    }
}

export default withStyles(styles)(DrcMaintenanceDialog);
