import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { makeStyles } from '@mui/styles';
import DrcMain from '../Components/DrcMain';
import DrcOktaWidget from '../Components/DrcOktaWidget';
import DrcImage from '../Components/DrcImage';
import DrcLoading from '../Components/DrcLoading';
import { Translate } from 'react-localize-redux';

/**
 *@ignore
 */
var useStyles = makeStyles((theme) => ({
    main: {
        padding: 8
    },
    login: {
        '& > div': {
            marginTop: -1
        },
        '& #okta-sign-in .auth-content': {
            padding: 20
        },
        '& #okta-sign-in .okta-sign-in-header': {
            display: 'none'
        },
        '& #okta-sign-in.auth-container': {
            margin: '0 auto'
        },
        '& #okta-sign-in.auth-container.main-container': {
            boxShadow: '0px 5px 14px 0px rgba(0, 0, 0, 0.62)',
            width: 350,
            maxWidth: '100%',
            marginTop: 0
        },
        '& #okta-sign-in.auth-container .okta-form-label': {
            position: 'relative',
            zIndex: 1,
            margin: '-10px 0 -15px 14px'
        },
        '& #okta-sign-in .o-form .input-fix input': {
            borderRadius: '6px !important'
        },
        '& #okta-sign-in .o-form .okta-form-label label': {
            backgroundColor: '#fff',
            paddingLeft: 3,
            '@media (prefers-color-scheme: dark)': {
                backgroundColor: '#000'
            }
        }
    },
    poly: {
        '& #okta-sign-in.auth-container #okta-signin-submit': {
            borderRadius: 25
        }
    },
    image: {
        position: 'absolute',
        height: '100vh',
        width: '100vw',
        top: 0,
        left: 0,
        objectFit: 'cover',
        zIndex: -5,
        '@media (prefers-color-scheme: dark)': {
            opacity: 0.2
        }
    },
    ssoContainer: {
        position: 'relative'
    },
    ssoDiv: {
        width: 350,
        backgroundColor: '#fff',
        height: 100,
        margin: '0 auto',
        maxWidth: '100%',
        boxShadow: '0px 5px 14px 0px rgba(0, 0, 0, 0.62)',
        borderRadius: '4px ​4px 0 0',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: theme.dark.primary,
            border: '1px solid #fff',
            borderRadius: 0
        },
        '@media only screen and (max-width: 600px)': {
            borderWidth: 0
        }
    }
}));

/**
 *
 *
 * @description Fully-featured and customized login page as per Driscoll's format which is used to authenticate user.
 * @class DrcPageLogin
 * @extends {Component}
 * @property {*} backgroundPng Background image for login page in PNG format
 * @property {*} backgroundWebP Background image for login page in WebP format
 * @example <Route path="/" exact render={(props) => (<DrcPageLogin {...props} poly backgroundPng={BackgroundPng} backgroundWebP={BackgroundWebP}/>)}/>
 */
function DrcPageLogin(props) {
    const { authState, oktaAuth } = useOktaAuth();
    const classes = useStyles();

    const [sessionDetails, setSessionDetails] = useState({
        isChecking: true,
        hasChecked: false,
        hasSession: false,
        isLoggedIn: false
    });

    /**
     *@ignore
     */
    useEffect(() => {
        if (props.setPageTitle) {
            props.setPageTitle(''); //Empty string makes page title go to default, which is good in this case.
        }
    }, []);

    /**
     *@ignore
     */
    useEffect(() => {
        if (oktaAuth && !authState?.isAuthenticated && !sessionDetails.hasChecked) {
            oktaAuth.session
                .exists()
                .then((session) => {
                    if (session) {
                        setSessionDetails({
                            isChecking: true,
                            hasChecked: false,
                            hasSession: true,
                            isLoggedIn: false
                        });
                        oktaAuth.token
                            .getWithoutPrompt({
                                responseType: ['id_token', 'token']
                            })
                            .then((result) => {
                                oktaAuth.tokenManager.setTokens(result.tokens);
                                setSessionDetails({
                                    isChecking: false,
                                    hasChecked: true,
                                    hasSession: true,
                                    isLoggedIn: true
                                });
                            })
                            .catch(() => {
                                setSessionDetails({
                                    isChecking: false,
                                    hasChecked: true,
                                    hasSession: false,
                                    isLoggedIn: false
                                });
                            });
                    } else {
                        setSessionDetails({
                            isChecking: false,
                            hasChecked: true,
                            hasSession: false,
                            isLoggedIn: false
                        });
                    }
                })
                .catch(() => {
                    setSessionDetails({
                        isChecking: false,
                        hasChecked: true,
                        hasSession: false,
                        isLoggedIn: false
                    });
                });
        }
    }, [authState, oktaAuth]);

    /**
     *
     * @description Okta login success handler
     * @param {object} res Response from okta when user log-in
     * @return {*}
     * @memberof DrcPageLogin
     */
    const onSuccess = (res) => {
        if (res.status === 'SUCCESS') {
            return oktaAuth.signInWithRedirect({
                sessionToken: res.session.token
            });
        } else {
            // The user can be in another authentication state that requires further action.
            // For more information about these states, see:
            //   https://github.com/okta/okta-signin-widget#rendereloptions-success-error
        }
    };
    /**
     *
     * @description Okta login failure handler
     * @param {*} err Okta login error message
     * @memberof DrcPageLogin
     */
    const onError = (err) => {
        console.error('error logging in', err);
    };

    return authState?.isAuthenticated ? (
        <Navigate to={props.redirect || '/InitializeApplication/'} />
    ) : (
        <React.Fragment>
            <DrcMain transparent className={classes.main}>
                <DrcImage src={props.backgroundPng} webp={props.backgroundWebP} className={classes.image} alt="" />
                <div className={classes.ssoContainer} style={sessionDetails.isChecking ? {} : { opacity: 0 }}>
                    <div className={classes.ssoDiv}>
                        <DrcLoading
                            text={
                                sessionDetails.isChecking ? (
                                    <Translate id="login.checking" />
                                ) : sessionDetails.hasSession ? (
                                    <Translate id="login.authorizing" />
                                ) : sessionDetails.isLoggedIn ? (
                                    <Translate id="login.loggedIn" />
                                ) : (
                                    <Translate id="login.loggedOut" />
                                )
                            }
                        />
                    </div>
                </div>
                <DrcOktaWidget
                    baseUrl={window.config.OKTA_URL}
                    onSuccess={onSuccess}
                    onError={onError}
                    className={`${classes.login} ${props.poly ? classes.poly : ''}`}
                    config={props.config || {}}
                />
            </DrcMain>
        </React.Fragment>
    );
}

export default DrcPageLogin;
