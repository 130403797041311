import * as ReactDOM from 'react-dom/client';
import { LocalizeProvider } from 'react-localize-redux';
import { Provider } from 'react-redux';
import { SERVICE_WORKER_UPDATE } from './actions/actions';
import App from './App';
import './color.css';
import Middleware from './data/middleware';
import SetupCall from './data/setupcall';
import { Store } from './data/store';
import * as serviceWorker from './serviceWorker';
import { userBusinessUnit } from './utils/helper';
import ErrorBoundary from 'pages/ErrorBoundary';
import AppProvider from 'context/AppProvider';

const isDocUser = userBusinessUnit() === 'DOC';
const root: ReactDOM.Root = ReactDOM.createRoot(document.getElementById('root'));

const renderApp = () =>
    root.render(
        <Provider store={Store}>
            <LocalizeProvider>
                <AppProvider>
                    <App />
                </AppProvider>
            </LocalizeProvider>
        </Provider>
    );

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./App', renderApp);
}

const serviceWorkerConfig = {
    onUpdate: (reg) => Store.dispatch({ type: SERVICE_WORKER_UPDATE, payload: reg })
};

serviceWorker.register(serviceWorkerConfig);
Middleware.ConfigureAllMiddleware();
SetupCall.configureAllSetupCall();
renderApp();
