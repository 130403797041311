import React from 'react';
import DrcTranslate from './DrcTranslate';
import { withStyles } from '@mui/styles';

/**
     * @description DrcCard renders card component.
     * @returns A single Card with card data
     * @props as of now, we do have Card icon, heading, subHeading, url
     * we can add more props according to the requirement
     * @memberof DrcCard
*/


const styles = (theme) => ({
  container: {
      backgroundColor: '#fff',
      boxShadow: '1px 1px 8px 3px #d6d6d6',
      '@media (prefers-color-scheme: dark)': {
          backgroundColor: '#222 !important',
          boxShadow: '1px 1px 8px 3px #22222257',
      },
      display: 'flex',
      gap: '4%',
      margin: '1rem',
      padding: '2rem',
      maxWidth: '340px',
      minWidth: '250px',
      minHeight: '50px',
      borderRadius: '2px',
      cursor: 'pointer'
  },
  heading: {
      color: 'rgb(198, 54, 99)',
      fontSize: '20px'
  },
  icon: {
      width: '25%',
      cursor: 'pointer'
  },
});

/**
 * 
 * @param {*} props 
 * @returns Card
 * <DrcCard 
 * icon={icon}
 * heading={heading}
 * subHeading={subHeading}
 * url={url}
 * classes={classes}
 * clickHandler={clickHandler}
 * />
 * 
 * clickHandler --> It redirects the Card to respected page.
 */

const DrcCard = (props) => {
    const {icon, heading, subHeading, url, classes, clickHandler}= props;
   
    return (
      <div className={classes.container} onClick={clickHandler(url)}>
          <div className={classes.icon}>{icon}</div>
          <div>
              <div className={classes.heading}>{<DrcTranslate>{heading}</DrcTranslate>}</div>
              <div>{subHeading}</div>
          </div>
      </div>
  );
}

export default withStyles(styles)(DrcCard);
