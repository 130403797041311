import React from 'react';
import { withRouter } from 'routes/withRouter';;
import DrcButton from './DrcButton';

/**
 * @description The DrcHistoryButtons has previous and next buttons to go backward and forward using react router
 * @class DrcHistoryButtons
 * @property {json} className className
 * @example
 * <DrcHistoryButtons />
 * @category Input
 * @tags input history button back previous
 * @extends {React.Component} */
class DrcHistoryButtons extends React.Component {
    /**
     * Creates an instance of DrcHistoryButtons.
     * @param {*} props
     * @memberof DrcHistoryButtons
     *
     */
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.goForward = this.goForward.bind(this);
    }

    /**
     *
     * @description method to handle to go backward
     * @memberof DrcHistoryButtons
     */
    goBack() {
        this.props.history.goBack();
    }

    /**
     *
     * @description method to handle to go forward
     * @memberof DrcHistoryButtons
     */
    goForward() {
        this.props.history.goBack();
    }

    /**
     *
     *
     * @return {*}
     * @memberof DrcHistoryButtons
     */
    render() {
        const { className } = this.props;

        return (
            <div>
                <DrcButton isSecondary className={className} uppercaseText style={{ width: '100px', marginLeft: 0 }} onClick={this.goBack}>
                    Previous
                </DrcButton>
                <DrcButton isSecondary className={className} uppercaseText style={{ width: '100px', marginRight: 0 }} onClick={this.goForward} floatRight>
                    Next
                </DrcButton>
            </div>
        );
    }
}

export default withRouter(DrcHistoryButtons);
