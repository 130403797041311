import React from 'react';
import { InputText } from 'primereact/inputtext';
import { withStyles } from '@mui/styles';
import DrcBerrySelect from './DrcBerrySelect';
import DrcDatePicker from './DrcDatepicker';
import DrcSelect from './DrcSelect';
import DrcSwitch from '../Components/DrcSwitch';

/**
 *@ignore
 */
const styles = (theme) => ({
    inputEditor: {
        height: '90%',
        border: '1px solid #D1CBCB',
        [theme.darkTheme]: {
            backgroundColor: '#404040',
            border: '1px solid #5d5b5b',
            color: '#DDD'
        }
    },
    inputEditorInValid: {
        height: '90%',
        border: '1px solid #f44336',
        [theme.darkTheme]: {
            backgroundColor: '#404040',
            border: '1px solid #f44336',
            color: '#DDD'
        }
    }
});

/**
 * @description The DrcDatagridEditor is a styled grid editor in the Driscolls format and is customizable
 * @example
 * <DrcDatagridEditor />
 * @donotprint true
 * @category Tables
 * @tags layout table data rows columns filter actions editor
 * @class DrcDatagridEditor
 * @property {array} options options for select type of field
 * @property {string} editorType type of field (i.e text, int, float, decimal, date, berrySelect, boolean, select)
 * @property {json} rowData row data
 * @property {string} field field to be edited
 * @property {boolean} unique is unique field
 * @property {boolean} required is required field
 * @property {json} gridProps grid props
 * @extends {React.Component} */
class DrcDatagridEditor extends React.Component {
    /**
     * Creates an instance of DrcDatagridEditor.
     * @param {*} props
     * @memberof DrcDatagridEditor
     *
     */
    constructor(props) {
        super(props);
        this.state = { fieldValue: this.props.rowData[this.props.field], isError: false };
    }

    /**
     *@ignore
     */
    componentDidMount() {
        this.setState({ fieldValue: this.props.rowData[this.props.field] });
    }

    /**
     *
     * @description method to handle field value change
     * @param {*} value
     * @memberof DrcDatagridEditor
     */
    onEditorValueChange = (value) => {
        if (
            this.props.columnEditorValidator === undefined ||
            (this.props.columnEditorValidator && this.columnEditorValidator.call(this, value, this.props.rowData, this.props.field))
        ) {
            this.setState({ fieldValue: isNaN(value) ? value : value === '' ? '' : parseInt(value) });
            this.setState({ isError: value === '' && this.props.required });
        }
    };

    /**
     *
     * @description method to update the edited value for the field
     * @param {*} gridProps
     * @param {*} value
     * @memberof DrcDatagridEditor
     */
    onEditorValueCommit = async (gridProps, value) => {
        if (this.props.rowData[this.props.field] !== value) {
            await this.props.onEditorValueCommit(this.props, value);
        }
    };

    /**
     *
     * @description method to handle berry selection
     * @param {*} optn
     * @memberof DrcDatagridEditor
     */
    berrySelect = (optn) => {
        if (
            this.props.columnEditorValidator === undefined ||
            (this.props.columnEditorValidator && this.columnEditorValidator.call(this, value, this.props.rowData, this.props.field))
        ) {
            this.setState({ fieldValue: optn });
            this.props.onEditorValueCommit(this.props, optn.label);
        }
    };

    /**
     *
     * @description method to handle date selection
     * @param {*} filterDate
     * @memberof DrcDatagridEditor
     */
    datePickerChange(filterDate) {
        if (
            this.props.columnEditorValidator === undefined ||
            (this.props.columnEditorValidator && this.columnEditorValidator.call(this, value, this.props.rowData, this.props.field))
        ) {
            this.setState({ fieldValue: filterDate });
            this.props.onEditorValueCommit(this.props, filterDate.toDateString());
        }
    }

    /**
     *
     * @description method to handle dropdown list selection
     * @param {*} value
     * @param {*} action
     * @memberof DrcDatagridEditor
     */
    handleSelectChange = (value, action) => {
        if (
            this.props.columnEditorValidator === undefined ||
            (this.props.columnEditorValidator && this.columnEditorValidator.call(this, value, this.props.rowData, this.props.field))
        ) {
            this.setState({ fieldValue: value });
            this.props.onEditorValueCommit(this.props, value.value);
        }
    };

    /**
     *
     * @description method to handle switch selection
     * @param {*} event
     * @param {*} checked
     * @memberof DrcDatagridEditor
     */
    handleSwitchChange(event, checked) {
        if (
            this.props.columnEditorValidator === undefined ||
            (this.props.columnEditorValidator && this.columnEditorValidator.call(this, checked, this.props.rowData, this.props.field))
        ) {
            this.setState({ fieldValue: checked });
            this.props.onEditorValueCommit(this.props, checked);
        }
    }

    /**
     * @description method to filter keyboard input based on specified datatype
     * @param {*} dataType
     * @memberof DrcDatagridEditor
     */
    getKeyFilter = (dataType) => {
        switch (dataType) {
            case 'text':
                return null;
            case 'int':
                return 'int';
            case 'decimal':
            case 'float':
                return 'num';
            default:
                return null;
        }
    };

    /**
     *
     * @return {*}
     * @memberof DrcDatagridEditor
     */
    render() {
        const textTypes = ['text', 'int', 'float', 'decimal'];
        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                {textTypes.includes(this.props.editorType) ? (
                    <InputText
                        type="text"
                        className={this.state.isError ? this.props.classes.inputEditorInValid : this.props.classes.inputEditor}
                        value={this.state.fieldValue}
                        onChange={(e) => this.onEditorValueChange(e.target.value)}
                        onBlur={(e) => this.onEditorValueCommit(this.props, e.target.value)}
                        required={this.props.required}
                        keyfilter={this.getKeyFilter(this.props.editorType)}
                    />
                ) : null}
                {this.props.editorType === 'berryselect' ? <DrcBerrySelect value={this.state.fieldValue} onChange={this.berrySelect} required={this.props.required} /> : null}
                {this.props.editorType === 'date' ? (
                    <DrcDatePicker
                        selectedDate={this.state.fieldValue ? this.state.fieldValue : null}
                        onChange={(date) => this.datePickerChange(date)}
                        required={this.props.required}
                    />
                ) : null}
                {this.props.editorType === 'select' ? (
                    <DrcSelect
                        onChange={this.handleSelectChange}
                        label={this.props.field}
                        value={this.state.fieldValue}
                        options={this.props.options}
                        required={this.props.required}
                    />
                ) : null}
                {this.props.editorType === 'boolean' ? (
                    <DrcSwitch
                        checked={this.state.fieldValue === undefined ? c.defaultValue : this.state.fieldValue}
                        value={this.state.fieldValue}
                        onChange={(event, checked) => this.handleSwitchChange(event, checked)}
                        style={{ margin: 'auto', padding: 'auto' }}
                        required={this.props.required}
                    >
                        {name}
                    </DrcSwitch>
                ) : null}
            </div>
        );
    }
}

export default withStyles(styles)(DrcDatagridEditor);
