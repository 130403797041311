import React, { useEffect } from 'react';
import List from '@mui/material/List';
import { makeStyles } from '@mui/styles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { designColors } from '../data/constants';
import { withRouter } from 'routes/withRouter';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    listItemRoot: {
        color: '#000 !important',
        backgroundColor: `${designColors.grey} !important`,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`,
            color: `${theme.palette.common.white} !important`
        },
        fontWeight: 'bold',
        minWidth: 32,
        paddingLeft: theme.spacing(4)
    },
    selected: {
        backgroundColor: '#fff !important',
        color: `${designColors.blue} !important`,
        fontWeight: 'bold',
        paddingLeft: theme.spacing(4)
    }
}));

function NestedMenuItem(props) {
    const classes = useStyles();
    const [selectedIndex, setSelectedSubitemIndex] = React.useState(-1);
    const { menu, selected, setSelectedIndex } = props;
    const navigate = useNavigate();
    useEffect(() => {
        menu.items?.map((itm, indx) => {
            if (selected && itm.route.includes(props.router.location.pathname)) setSelectedSubitemIndex(indx);
            return itm.route;
        });
    }, [props.router.location.pathname]);
    return (
        <List component="div" disablePadding>
            {menu.items.map((itm, indx) => (
                <ListItem
                    button
                    className={selectedIndex === indx ? classes.selected : classes.listItemRoot}
                    key={indx}
                    onClick={(e) => {
                        e.stopPropagation();
                        setSelectedIndex();
                        setSelectedSubitemIndex(indx);
                        navigate(itm.route);
                    }}
                >
                    <ListItemText primary={itm.name} />
                </ListItem>
            ))}
        </List>
    );
}
export default withRouter(NestedMenuItem);
