import React from 'react';
import { makeStyles } from '@mui/styles';
import Popover from '@mui/material/Popover';
import InfoIcon from '@mui/icons-material/Info';
import DrcSignInVersionFooter from './DrcSignInVersionFooter';
import DrcButton from './DrcButton';

const useStyles = makeStyles((theme) => ({
    versionInfo: {
        position: 'fixed',
        bottom: 10,
        right: 10,
        cursor: 'pointer',
        '@media print': {
            display: 'none'
        }
    },
    popover: {
        '& .MuiPaper-elevation8': {
            maxWidth: 400,
            width: '100%'
        }
    },
    popoverContent: {
        padding: theme.spacing(1),
        background: theme.light.accent.brightness(50),
        '@media (prefers-color-scheme: dark)': {
            background: theme.dark.accent.brightness(-30)
        }
    }
}));

/**
 *
 * @description Component that tell the Application Version, API Version,Driscoll's React Components Version, Driscoll's React Utilities Version and Environment info
 * @component
 * @param {object} props Properties passed to the the component
 * @return {JSX.Element} return the version information
 * @example  <DrcVersionInfo allowClick={true} />
 * @category General
 * @tags general ui version info style
 */
const DrcVersionInfo = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    /**
     *
     *
     * @description Handler for onclick event
     * @param {*} event onclick event props
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className={classes.versionInfo}>
            <InfoIcon aria-describedby={id} variant="contained" onClick={handleClick}></InfoIcon>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                className={classes.popover}
            >
                <div className={classes.popoverContent}>
                    <DrcSignInVersionFooter allowAllEnvironments selfAlign />
                    {props.allowClick ? (
                        <DrcButton isPrimary to={props.releaseTo || '/Releases/'}>
                            Releases
                        </DrcButton>
                    ) : null}
                </div>
            </Popover>
        </div>
    );
};

export default DrcVersionInfo;
