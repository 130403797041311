import React, { Component } from 'react';
import PrimeReactTable from '../../../components/PrimeReactTable';
import { withStyles, withTheme } from '@mui/styles';
import { DrcButton, DrcDialog, DrcTranslate, DrcSelect, DrcMain, DrcCheckbox } from 'drc/driscolls-react-components/index';
import GridStyles from '../../../styles/gridStyles';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast } from '../../../actions/actions';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { makeColumns, userBusinessUnit, makeOptions, displayErrorMessage } from '../../../utils/helper';
import ActionsFormatter from '../../../components/cellFormatters/ActionFormatter';
import { ReactComponent as Add } from '../../../Images/SVG/Add_Enabled.svg';
import MasterDataUtilities from '../../../data/MasterDataUtilities';
import { Call } from '@driscollsinc/driscolls-display-rules';
import APIEndPoints from '../../../services/api';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { decimal, maxlen, precision } from '../../../utils/validations';
import GGSFormInput from '../../../components/GGSFormInput';
import Form from 'react-validation/build/form';
import { designColors, AdjustmentDsColumns, currencyOptions, LOOKUP_FIELDS } from '../../../data/constants';
import NoData from '../../../components/NoData';

const styles = (theme) => ({
    cardContainer: {
        backgroundColor: designColors.lightGrey,
        borderTop: '2px solid #E0E0E0',
        width: '82%',
        padding: '20px',
        marginBottom: '30px',
        borderRadius: '10px',
        margin: '5px',
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.grey['A700']} !important`,
            borderTop: '2px solid #000000'
        }
    },
    container: {
        width: '100%',
        maxWidth: 'none !important',
        margin: '8px 0',
        borderRadius: '8px'
    },
    gridStyles: GridStyles.styles(theme, 'calc(100vh - 340px)'),
    input: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'left'
        }
    },
    root: {
        '& .select': {
            top: 2
        },
        margin: '-5px 0 0px'
    },
    selectedClass: {
        width: '45px !important',
        padding: '0 10px !important'
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'end'
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    pageActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
});

class AmountAdjustment extends Component {
    state = {
        data: [],
        pageNum: 0,
        selectedRow: [],
        pageSize: 50,
        totalCount: 0,
        columns: [],
        rowData: null,
        editingRow: {},
        showAddNew: false,
        newRecord: {},
        growerOptions: [],
        invalidFields: []
    };
    validationFields = {
        GrowerID: {
            key: 'GrowerID',
            validations: [],
            customProps: {}
        },
        PoolWeek: {
            key: 'PoolWeek',
            validations: [],
            customProps: {}
        },
        Category: {
            key: 'Category',
            validations: [],
            customProps: {}
        },
        Comments: {
            key: 'Comments',
            validations: [maxlen],
            customProps: { maxlen: 100 }
        },
        Currency: {
            key: 'Currency',
            validations: [],
            customProps: {}
        },
        AdjustmentAmount: {
            key: 'AdjustmentAmount',
            validations: [decimal, precision],
            customProps: { precision: 2 }
        },
        BeforeCalculation: {
            key: 'BeforeCalculation',
            validations: [maxlen],
            customProps: { maxlen: 1, required: true }
        }
    };
    excludingFields = ['Actions'];
    readOnly = false;
    uniqueKeyField = 'ManualAdjustmentID';
    widthIsSufficient = window.innerWidth > 955;
    widthIsInSufficient = false;

    componentDidMount() {
        this.loadData();
        this.getGrowerList();
        window.addEventListener('resize', this.updateDataTable);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDataTable);
    }

    editData = (_event, data, _index) => {
        this.setState({ editingRow: { ...data, [data[this.uniqueKeyField]]: true }, invalidFields: [] });
    };

    cancelEdit = (_event, _rowData) => {
        this.setState({ editingRow: {}, invalidFields: [] });
    };
    cannotEdit = (_row) => null;

    deleteData = (_rowData) => null;

    confirmEdit = async (_event, _rowData) => {
        try {
            this.props.showLoadingScreenAction('Updating data');
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            let data = await Call(
                {
                    name: 'ManualAdjustment',
                    url: APIEndPoints.PUT_MANUAL_ADJUSTMENT(),
                    method: 'PUT',
                    options: {},
                    data: {
                        Comments: this.state.editingRow['Comments'],
                        BeforeCalculation: this.state.editingRow['BeforeCalculation'],
                        BusinessUnit: this.state.editingRow['BusinessUnit'],
                        AdjustmentAmount: (this.state.editingRow['AdjustmentAmount'] || '').replace(/,/g, '').toString(),
                        PoolWeek: this.state.editingRow['PoolWeek'],
                        Currency: this.state.editingRow['Currency'],
                        ModifiedDateTime: new Date().toISOString(),
                        ModifiedBy: loggedInUser,
                        Category: this.state.editingRow['Category'],
                        GrowerID: this.state.editingRow['GrowerID'],
                        ManualAdjustmentID: this.state.editingRow['ManualAdjustmentID']
                    }
                },
                token
            );
            if ((data.errors.length && !data.raw) || data.raw.data.Status === 409) {
                this.setState({ editingRow: {} });
                return this.showError(data.errors[0]);
            } else {
                this.props.hideLoadingScreenAction();
                this.setState({ editingRow: {} });
                this.props.showToast(<DrcTranslate>{'Updated Successfully'}</DrcTranslate>, true);
                this.loadData();
            }
        } catch (error) {
            this.setState({ editingRow: {} });
            this.showError(error);
        }
    };
    cannotDelete = (_row) => null;

    addNewRecord = async () => {
        try {
            this.props.showLoadingScreenAction('Adding data');
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            let data = await Call(
                {
                    name: 'ManualAdjustment',
                    url: APIEndPoints.PUT_MANUAL_ADJUSTMENT(),
                    method: 'POST',
                    options: {},
                    data: {
                        Comments: this.state.newRecord['Comments'],
                        BeforeCalculation: this.state.newRecord['BeforeCalculation'],
                        BusinessUnit: userBusinessUnit(),
                        CreatedBy: loggedInUser,
                        AdjustmentAmount: this.state.newRecord['AdjustmentAmount'],
                        ModifiedDateTime: new Date().toISOString(),
                        PoolWeek: this.state.selectedPoolWeek.value,
                        Currency: this.state.newRecord['Currency'].value,
                        ModifiedBy: loggedInUser,
                        Category: this.state.newRecord['Category'].value,
                        CreatedDateTime: new Date().toISOString(),
                        GrowerID: this.state.newRecord['GrowerID'].value
                    }
                },
                token
            );
            if (data.errors.length && !data.raw) {
                this.setState({ showAddNew: false, newRecord: {} });
                return this.showError(data.errors[0]);
            } else {
                this.props.hideLoadingScreenAction();
                this.setState({ showAddNew: false, newRecord: {} });
                this.props.showToast(<DrcTranslate>{'Added Successfully'}</DrcTranslate>, true);
                this.loadData();
            }
        } catch (error) {
            this.showError(error);
        }
    };

    getActionsCell = (row) => {
        return (
            <ActionsFormatter
                rowEdit={true}
                uniqueKeyField={this.uniqueKeyField}
                objState={this}
                records={this.state.data}
                initRowEdit={this.editData}
                confirmEdit={this.confirmEdit}
                cancelEdit={this.cancelEdit}
                cannotDelete={this.cannotDelete}
                cannotEdit={this.cannotEdit}
                deleteAttribute={this.deleteData}
                row={row}
                checkRowValidation={() => !!this.state.invalidFields.length}
                rowUnderEditUniqueKeyValue={row[this.uniqueKeyField]}
            />
        );
    };

    updateTableData = (response, hasFrozenColumns = true) => {
        let data = response?.display?.Data || [];
        let columns = data.length
            ? makeColumns(
                  Object.keys(data[0]),
                  this.excludingFields,
                  this.getActionsCell,
                  true,
                  ['Comments', 'AdjustmentAmount', 'PoolWeek', 'Currency', 'Category', 'GrowerID'],
                  [],
                  AdjustmentDsColumns
              )
            : [];
        this.setState({
            data,
            totalCount: response?.display?.TotalCount || 0,
            columns,
            unfreeze: !hasFrozenColumns
        });
    };
    updateDataTable = (_event, force = false) => {
        if (window.innerWidth > 955 && !this.widthIsSufficient) {
            this.widthIsSufficient = true;
            this.widthIsInSufficient = false;
            this.updateTableData(this.response || [], true);
        } else if (window.innerWidth <= 955 && !this.widthIsInSufficient) {
            this.widthIsInSufficient = true;
            this.widthIsSufficient = false;
            this.updateTableData(this.response || [], false);
        } else if (force) {
            this.updateTableData(this.response || [], true);
        }
    };

    loadData = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            let data = await Call(
                {
                    name: 'ManualAdjustment',
                    url: APIEndPoints.GET_MANUAL_ADJUSTMENT(
                        userBusinessUnit(),
                        this.props.selectedPoolWeek.PoolWeek || this.props.match.params.poolWeek
                    ),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }
            this.response = data;
            this.updateDataTable(null, true);
            this.props.hideLoadingScreenAction();
        } catch (error) {
            this.showError(error);
        }
    };
    getGrowerList = async () => {
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            let data = await Call(
                {
                    name: 'GrowerList',
                    url: APIEndPoints.GET_GROWER_LIST(
                        this.state.pageNum / this.state.pageSize + 1,
                        this.state.pageSize,
                        userBusinessUnit(),
                        this.props.userLang
                    ),
                    method: 'GET',
                    options: {}
                },
                token
            );
            const growerOptions = makeOptions(data.raw.data.Data, 'VendorCode', 'VendorCode');
            this.setState((prevState) => ({ ...prevState, growerOptions }));
        } catch (error) {
            this.showError(error);
        }
    };

    getRowEditor = (key, col, others) => {
        let field = this.validationFields[key] ?? {};
        switch (key) {
            case 'Category':
                return this.selectCellEditor(key, col, others);
            case 'Currency':
                return this.selectCellEditor(key, col, others, currencyOptions);
            case 'GrowerID':
                return this.selectCellEditor(key, col, others, this.state.growerOptions);
            case 'PoolWeek':
                return this.selectCellEditor(key, col, others, makeOptions(this.props.yearPoolWeeks, 'PoolWeek', 'PoolWeek'));
            case 'AdjustmentAmount':
            case 'Comments':
                return this.inputTextEditor(key, col, others, field.validations, field.customProps);
        }
    };
    selectCellEditor = (key, _col, _others, options) => {
        return (
            <DrcSelect
                options={options ? options : makeOptions(this.props.adjustmentCategoryLookUp, 'LookupCode', 'LookupCode')}
                data-id={key}
                label="GrowerFixedPrice"
                hideLabel={true}
                className={this.props.classes.root}
                onChange={(option) => {
                    this.setState(() => ({
                        editingRow: { ...this.state.editingRow, [key]: option.value }
                    }));
                }}
                value={(options ? options : makeOptions(this.props.adjustmentCategoryLookUp, 'LookupCode', 'LookupCode')).find(
                    (itm) => itm.value === this.state.editingRow[key]
                )}
                menuPortalTarget={document.body}
                menuShouldBlockScroll={true}
                styles={{
                    menuList: (base) => ({
                        ...base,
                        backgroundColor: this.props.theme.palette.common.white,
                        [this.props.theme.darkTheme]: {
                            backgroundColor: this.props.theme.palette.common.black
                        },
                        border: '1px solid lightgray',
                        width: 'auto',
                        zIndex: 9999
                    })
                }}
                menuPlacement={'auto'}
            />
        );
    };
    inputTextEditor = (key, _col, _others, validations = [], customProps = {}) => {
        return (
            <Form>
                <GGSFormInput
                    type="text"
                    className={this.props.classes.input}
                    onChange={(e) =>
                        this.setState(() => ({
                            editingRow: { ...this.state.editingRow, [key]: e.target.value }
                        }))
                    }
                    value={this.state.editingRow[key]}
                    validations={validations}
                    {...customProps}
                    maxvalue={100}
                    validationDetected={(validate) => this.handleValidationDetected(validate, key)}
                />
            </Form>
        );
    };

    onPage = (event) => {
        this.setState({ pageNum: event.first });
        this.loadData();
    };

    /**
     * Method that is triggered when validation is detected. This will enable/disable the save button for the add-dialog
     * @function handleValidationDetected
     * @param {*} validate
     */
    handleValidationDetected = (validate, fieldName) => {
        if (validate === undefined) {
            return;
        }
        if (validate) {
            if (!this.state.invalidFields.includes(fieldName)) {
                let newFields = [...this.state.invalidFields];
                newFields.push(fieldName);
                this.setState({ invalidFields: newFields });
            }
        } else {
            if (this.state.invalidFields.includes(fieldName))
                this.setState((prevState) => ({ ...prevState, invalidFields: prevState.invalidFields.filter((field) => field !== fieldName) }));
        }
    };

    showError = (err, msg) => {
        this.props.hideLoadingScreenAction();
        this.props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    render() {
        const { classes } = this.props;
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        return (
            <div className={classes.cardContainer}>
                <div className={classes.pageActions}>
                    <DrcButton
                        onClick={(_event) => {
                            this.setState({ showAddNew: true });
                        }}
                        noStyle
                        title="Add"
                        style={{ marginLeft: 'auto' }}
                    >
                        <Add style={{ width: '50px', height: '20px', marginRight: '-10px' }} />
                        <DrcTranslate>{'Add'}</DrcTranslate>
                    </DrcButton>
                </div>
                <DrcMain className={classes.container}>
                    {!!this.state.data.length ? (
                        <PrimeReactTable
                            value={this.state.data}
                            columns={this.state.columns}
                            editMode="row"
                            frozenWidth={'80px'}
                            rowSelect={false}
                            selectionMode="multiple"
                            tableSelectionMode="checkbox"
                            selected={this.state.selectedRow}
                            selectedFrozen={true}
                            selectedClass={classes.selectedClass}
                            selectionChange={(e) => {
                                this.setState({ selectedRow: e.value });
                            }}
                            uniqueKeyField={this.uniqueKeyField}
                            editingRows={this.state.editingRow}
                            paginator={this.state.data.length > 0}
                            currentPage={this.state.pageNum}
                            pageSize={this.state.pageSize}
                            onPage={this.onPage}
                            totalRecords={this.state.totalCount}
                            editor={(key, col, others) => {
                                return this.getRowEditor(
                                    key,
                                    col,
                                    others,
                                    this.validationFields[key] ? this.validationFields[key].validations : [],
                                    this.validationFields[key] ? this.validationFields[key].customProps : {}
                                );
                            }}
                        />
                    ) : (
                        <NoData />
                    )}
                </DrcMain>
                <DrcDialog
                    title={'Add New Record'}
                    open={this.state.showAddNew}
                    buttons={
                        <>
                            <DrcButton
                                onClick={() => {
                                    this.setState({ showAddNew: false, newRecord: {} });
                                }}
                            >
                                <DrcTranslate>{'Cancel'}</DrcTranslate>
                            </DrcButton>
                            <DrcButton
                                isPrimary
                                onClick={this.addNewRecord}
                                disabled={
                                    !!this.state.invalidFields.length ||
                                    !(
                                        this.state.newRecord.Currency &&
                                        this.state.selectedPoolWeek &&
                                        this.state.newRecord.Category &&
                                        this.state.newRecord.GrowerID &&
                                        this.state.newRecord.AdjustmentAmount &&
                                        this.state.newRecord.Comments
                                    )
                                }
                            >
                                <DrcTranslate>{'Submit'}</DrcTranslate>
                            </DrcButton>
                        </>
                    }
                >
                    <Form>
                        <DrcSelect
                            label="Grower ID"
                            required
                            onChange={(option) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, GrowerID: option } }))}
                            value={this.state.newRecord['GrowerID']}
                            className={this.props.classes.input}
                            options={this.state.growerOptions}
                        />
                        <DrcSelect
                            label={'Select Pool Week'}
                            required
                            onChange={(e) => this.setState({ selectedPoolWeek: e })}
                            value={this.state.selectedPoolWeek}
                            className={this.props.classes.input}
                            options={makeOptions(this.props.yearPoolWeeks, 'PoolWeek', 'PoolWeek')}
                        />
                        <DrcSelect
                            label="Category"
                            required
                            onChange={(option) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, Category: option } }))}
                            value={this.state.newRecord['Category']}
                            className={this.props.classes.input}
                            options={makeOptions(this.props.adjustmentCategoryLookUp, 'LookupCode', 'LookupCode')}
                        />
                        <GGSFormInput
                            type="text"
                            label={'Comments'}
                            required
                            validations={this.validationFields['Comments'] ? this.validationFields['Comments'].validations : []}
                            {...(this.validationFields['Comments'] ? this.validationFields['Comments'].customProps : {})}
                            className={this.props.classes.input}
                            onChange={(e) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, Comments: e.target.value } }))}
                            value={this.state.newRecord['Comments']}
                            maxvalue={100}
                            validationDetected={(validate) => this.handleValidationDetected(validate, 'Comments')}
                        />
                        <DrcSelect
                            label="Currency"
                            required
                            onChange={(option) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, Currency: option } }))}
                            value={this.state.newRecord['Currency']}
                            className={this.props.classes.input}
                            options={currencyOptions}
                        />
                        <GGSFormInput
                            type="text"
                            label={'Adjustment Amount'}
                            required
                            validations={this.validationFields['AdjustmentAmount'] ? this.validationFields['AdjustmentAmount'].validations : []}
                            {...(this.validationFields['AdjustmentAmount'] ? this.validationFields['AdjustmentAmount'].customProps : {})}
                            className={this.props.classes.input}
                            onChange={(e) =>
                                this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, AdjustmentAmount: e.target.value } }))
                            }
                            value={this.state.newRecord['AdjustmentAmount']}
                            validationDetected={(validate) => this.handleValidationDetected(validate, 'AdjustmentAmount')}
                        />
                        <div style={{ display: 'flex', color: '#222a', marginTop: '10px' }}>
                            <DrcTranslate>{'Before Calculation '}</DrcTranslate>

                            <DrcCheckbox
                                style={{ marginTop: '-10px' }}
                                checked={this.state.newRecord['BeforeCalculation'] === 'Y'}
                                value={this.state.newRecord['BeforeCalculation']}
                                onChange={(_e, val) => {
                                    this.setState((prevState) => ({
                                        newRecord: { ...prevState.newRecord, BeforeCalculation: val ? 'Y' : 'N' }
                                    }));
                                }}
                            />
                        </div>
                    </Form>
                </DrcDialog>
            </div>
        );
    }
}
const mapStateToProps = ({ masterReducer, filterReducer, weeklySettlementStatusReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    filters: filterReducer.filters,
    currentPoolWeek: masterReducer.currentPoolWeek,
    selectedPoolWeek: weeklySettlementStatusReducer.selectedPoolWeek,
    yearPoolWeeks: masterReducer.yearPoolWeeks,
    adjustmentCategoryLookUp: masterReducer[LOOKUP_FIELDS.AdjustmentCategory]
});
const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withTheme(withStyles(styles)(AmountAdjustment))));
