import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { DrcButton, DrcTranslate } from 'drc/driscolls-react-components/index';
import GridStyles from '../../styles/gridStyles';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast } from '../../actions/actions';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { downloadFile, makeColumns } from '../../utils/helper';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { Call } from '@driscollsinc/driscolls-display-rules';
import APIEndPoints from '../../services/api';
import {
    agedInventoryReportColumnsMapping,
    averageSalesPriceReportColumnsMapping,
    avgPriceReportColumnsMapping,
    designColors,
    inventoryMovementReportColumnsMapping,
    inventoryOutboundReportColumnsMapping,
    turnoverRateReportColumnsMapping,
    weeklySalesReportColumnsMapping
} from '../../data/constants';
import PrimeReactTable from '../../components/PrimeReactTable';
import NoData from '../../components/NoData';
import { Typography } from '@mui/material';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import HeaderDate from './HeaderDate';
const styles = (theme) => ({
    input: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'left'
        }
    },
    gridStyles: GridStyles.styles(theme, '300px', '300px'),
    tableContainer: {
        margin: theme.spacing(3)
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    icon: {
        marginRight: 5
    }
});

class AveragePriceReport extends Component {
    state = {
        data: [],
        pageNum: 0,
        pageSize: 50,
        totalCount: 0,
        columns: [],
        rowData: null
    };

    excludingFields = ['ItemInvSummaryID', 'CreatedBy', 'ModifiedDateTime', 'ModifiedBy', 'CreatedDateTime', 'SubmittedBy'];
    readOnly = true;
    uniqueKeyField = 'ItemID';
    widthIsSufficient = window.innerWidth > 955;
    widthIsInSufficient = false;

    componentDidMount() {
        this.props.loadFilteredData(this.loadData.bind(this));
        window.addEventListener('resize', this.updateDataTable);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDataTable);
    }

    getColumns = (key) => {
        switch (key) {
            case 'InventoryMovement':
                return inventoryMovementReportColumnsMapping();
            case 'WeeklySales':
                return weeklySalesReportColumnsMapping();
            case 'AverageSalesPrice':
                return averageSalesPriceReportColumnsMapping();
            case 'TurnOverRateDomestic':
            case 'TurnOverRateImport':
                return turnoverRateReportColumnsMapping();
            case 'ItemInventoryOutbound':
                return inventoryOutboundReportColumnsMapping();
            case 'AgedInventory':
                return agedInventoryReportColumnsMapping();
            default:
                return avgPriceReportColumnsMapping();
        }
    };

    getTotalRow = (response) => {
        if (this.props.reportType.value === 'WeeklySales') {
            let totalRow = (response?.raw?.data?.Data || []).reduce(
                (newRow, row) => {
                    newRow.NormalSalesQty = (newRow.NormalSalesQty || 0) + +(row.NormalSalesQty || 0);
                    newRow.NormalSalesAmount = (newRow.NormalSalesAmount || 0) + +(row.NormalSalesAmount || 0);
                    newRow.DSharedSalesQty = (newRow.DSharedSalesQty || 0) + +(row.DSharedSalesQty || 0);
                    newRow.DSharedSalesAmount = (newRow.DSharedSalesAmount || 0) + +(row.DSharedSalesAmount || 0);
                    newRow.AbnormalSalesAmount = (newRow.AbnormalSalesAmount || 0) + +(row.AbnormalSalesAmount || 0);
                    newRow.AbnormalSalesQty = (newRow.AbnormalSalesQty || 0) + +(row.AbnormalSalesQty || 0);
                    newRow.SampleDamageSalesQty = (newRow.SampleDamageSalesQty || 0) + +(row.SampleDamageSalesQty || 0);
                    newRow.QualityIssueSalesQty = (newRow.QualityIssueSalesQty || 0) + +(row.QualityIssueSalesQty || 0);
                    newRow.RepackagingSalesQty = (newRow.RepackagingSalesQty || 0) + +(row.RepackagingSalesQty || 0);
                    newRow.ServiceDeductionAmount = (newRow.ServiceDeductionAmount || 0) + +(row.ServiceDeductionAmount || 0);
                    return newRow;
                },
                { TotalSumSection: true }
            );
            return totalRow;
        } else if (this.props.reportType.value === 'InventoryMovement') {
            let totalRow = (response?.raw?.data?.Data || []).reduce(
                (newRow, row) => {
                    newRow.LastWeekOnHand = (newRow.LastWeekOnHand || 0) + +(row.LastWeekOnHand || 0);
                    newRow.LastWeekInTransit = (newRow.LastWeekInTransit || 0) + +(row.LastWeekInTransit || 0);
                    newRow.LastWeekRepackSKUTransferBack = (newRow.LastWeekRepackSKUTransferBack || 0) + +(row.LastWeekRepackSKUTransferBack || 0);
                    newRow.ReceiptQty = (newRow.ReceiptQty || 0) + row.ReceiptQty;
                    newRow.NormalSalesIncludeDshared = (newRow.NormalSalesIncludeDshared || 0) + +(row.NormalSalesIncludeDshared || 0);
                    newRow.AbnormalSalesQty = (newRow.AbnormalSalesQty || 0) + +(row.AbnormalSalesQty || 0);
                    newRow.DisposalQty = (newRow.DisposalQty || 0) + +(row.DisposalQty || 0);
                    newRow.RepackMovementSalesQty = (newRow.RepackMovementSalesQty || 0) + +(row.RepackMovementSalesQty || 0);
                    newRow.ClosingQty = (newRow.ClosingQty || 0) + +(row.ClosingQty || 0);
                    newRow.RepackSKUTransferBack = (newRow.RepackSKUTransferBack || 0) + +(row.RepackSKUTransferBack || 0);
                    newRow.OnHand = (newRow.OnHand || 0) + +(row.OnHand || 0);
                    newRow.InTransit = (newRow.InTransit || 0) + +(row.InTransit || 0);
                    return newRow;
                },
                { TotalSumSection: true }
            );
            return totalRow;
        }
        return false;
    };

    updateTableData = (response, hasFrozenColumns = false) => {
        let data = response?.display?.Data || [];
        let columns = data.length
            ? makeColumns(Object.keys(data[0]), this.excludingFields, null, false, [], [], this.getColumns(this.props.reportType.value))
            : [];
        let newRow = this.getTotalRow(response);
        if (newRow !== false && data.length) {
            newRow = Object.keys(newRow).reduce((obj, key) => {
                obj[key] = (+newRow[key]).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                return obj;
            }, {});
            data.push(newRow);
        }
        this.setState({
            data,
            totalCount: response?.raw?.data?.TotalCount || 0,
            columns,
            unfreeze: !hasFrozenColumns
        });
    };
    updateDataTable = (_event, force = false) => {
        if (window.innerWidth > 955 && !this.widthIsSufficient) {
            this.widthIsSufficient = true;
            this.widthIsInSufficient = false;
            this.updateTableData(this.response || [], true);
        } else if (window.innerWidth <= 955 && !this.widthIsInSufficient) {
            this.widthIsInSufficient = true;
            this.widthIsSufficient = false;
            this.updateTableData(this.response || [], false);
        } else if (force) {
            this.updateTableData(this.response || [], true);
        }
    };

    loadData = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            var data = await Call(
                {
                    name: 'AveragePriceReport',
                    url: APIEndPoints.GET_AVERAGE_PRICE(this.props.poolWeek.value, null, null, this.props.filters),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }
            this.response = data;
            this.updateDataTable(null, true);
            this.props.hideLoadingScreenAction();
        } catch (error) {
            this.showError(error);
        }
    };

    onPage = (event) => {
        this.setState({ pageNum: event.first });
        this.loadData();
    };

    showError = (err, msg) => {
        this.props.hideLoadingScreenAction();
        this.props.showToast(<DrcTranslate>{msg || 'An Error Occurred'}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    exportData = async () => {
        try {
            this.props.showLoadingScreenAction(
                <React.Fragment>
                    <h4 style={{ fontSize: '1.5rem' }}>
                        <DrcTranslate>{'Preparing download'}</DrcTranslate>
                    </h4>
                    <div>
                        <DrcTranslate>{'Your download should start in a few seconds.'}</DrcTranslate>
                    </div>
                </React.Fragment>
            );
            this.props.hideLoadingScreenAction();
            downloadFile(
                `${this.props.reportType.value}_${this.props.poolWeek.value}${(this.props.filters || '')
                    .split(/\&|\=/g)
                    .reduce((filterValue, key, indx) => {
                        if (this.props.reportType.value === key) return filterValue;
                        if (indx > 0 && indx % 2 === 0) filterValue += '_' + key;
                        return filterValue;
                    }, '')}.xlsx`,
                this.state.data,
                this.getColumns(this.props.reportType.value),
                []
            );
        } catch (err) {
            console.log(err);
        }
    };

    render() {
        const { classes } = this.props;
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        return (
            <div className={classes.tableContainer}>
                <div className={classes.actionContainer}>
                    <Typography variant="h6" noWrap style={{ fontWeight: 'bold', color: designColors.blue, textTransform: 'uppercase' }}>
                        <DrcTranslate>{this.props.reportType.label}</DrcTranslate>
                    </Typography>
                    {!!this.state.data.length && <HeaderDate recordStatus={this.props.recordStatus} />}
                    <DrcButton onClick={this.exportData} style={{ fontWeight: 'bold', color: designColors.blue }} disabled={!this.state.data.length}>
                        <VerticalAlignBottomIcon className={classes.icon} /> <DrcTranslate>{'Export'}</DrcTranslate>
                    </DrcButton>
                </div>
                {!!this.state.data.length ? (
                    <PrimeReactTable
                        paginator={this.state.data.length > 0}
                        currentPage={this.state.pageNum}
                        onPage={this.onPage}
                        columns={this.state.columns}
                        value={this.state.data}
                        gridStyles={classes.gridStyles}
                        totalRecords={this.state.totalCount}
                        resultCount={this.state.totalCount}
                        pageSize={this.state.totalCount + 1}
                        uniqueKeyField={this.uniqueKeyField}
                        tableSelectionMode="checkbox"
                        showReport={true}
                        currentPageReportTemplate={'Showing {first} to {last} of {totalRecords} entries'}
                        //frozenWidth="90px"
                    />
                ) : (
                    <NoData />
                )}
            </div>
        );
    }
}
const mapStateToProps = ({ masterReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized
});
const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withStyles(styles)(AveragePriceReport)));
