import React, { useEffect } from 'react';
import ListItem from '@mui/material/ListItem';
import { makeStyles } from '@mui/styles';
import NestedMenuItem from './NestedMenuItem';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { designColors } from '../data/constants';
import { connect } from 'react-redux';
import { DrcTooltip } from 'drc/driscolls-react-components/index';

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4)
    },
    menuBox: {
        display: 'flex',
        borderRadius: '10px',
        width: '45px',
        border: '2px solid white',
        padding: '8px',
        alignContent: 'center !important'
    },
    selectedBorder: {
        border: `2px solid ${designColors.blue}`
    },
    listItemRoot: {
        color: '#fff !important',
        backgroundColor: `${designColors.blue} !important`,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        },
        fontWeight: 'bold',
        minWidth: 32
    },
    selected: {
        backgroundColor: '#fff !important',
        color: `${designColors.blue} !important`,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.grey['800']} !important`,
            color: '#fff !important'
        },
        fontWeight: 'bold'
    }
}));

function NestedMenu(props) {
    const [isExpanded, setExpanded] = React.useState(false);
    const classes = useStyles();

    const { menu, drawerOpen, selected, setSelectedIndex, handleDrawerOpen } = props;
    useEffect(() => {
        if (isExpanded && !drawerOpen) {
            //|| !selected
            setExpanded(false);
        }
    }, [drawerOpen, selected]);
    return (
        <>
            <ListItem
                className={selected ? classes.selected : classes.listItemRoot}
                button
                onClick={(e) => {
                    e.stopPropagation();
                    handleDrawerOpen();
                    setExpanded(!isExpanded);
                }}
            >
                {drawerOpen ? (
                    <>
                        <ListItemText primary={menu.name} />
                        {isExpanded ? <ExpandLess /> : <ExpandMore />}
                    </>
                ) : (
                    <DrcTooltip tipText={menu.name}>
                        <div className={`${classes.menuBox} ${selected ? classes.selectedBorder : null}`}>
                            <ListItemIcon className={selected ? classes.selected : classes.listItemRoot}>
                                {selected ? menu.selectedIcon : menu.icon}
                            </ListItemIcon>
                        </div>
                    </DrcTooltip>
                )}
            </ListItem>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <NestedMenuItem menu={menu} setSelectedIndex={setSelectedIndex} selected={selected} />
            </Collapse>
        </>
    );
}

const mapStateToProps = (state) => ({
    drawerOpen: state.rootReducer.drawerOpen
});

export default connect(mapStateToProps, null)(NestedMenu);
