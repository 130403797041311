import React from 'react';
import { DrcButton, DrcTranslate } from 'drc/driscolls-react-components/index';
import { makeStyles } from '@mui/styles';
import { ReactComponent as Journal } from '../../Images/SVG/Journal.svg';
import { ReactComponent as Instance } from '../../Images/SVG/PricingPoolInstance.svg';
import { ReactComponent as Export } from '../../Images/SVG/ExportReport.svg';
import { designColors } from '../../data/constants';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row'
    },
    button: {
        color: designColors.blue
    },
    icon: {
        width: '50px',
        height: '20px',
        marginRight: '-10px'
    }
}));
export default function PageActions(props) {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            {/* <DrcButton ariaLabel="Journals" onClick={() => {}} className={classes.button}>
                <Journal className={classes.icon} /> <DrcTranslate>{'Journals'}</DrcTranslate>
            </DrcButton> */}
            {/* <DrcButton ariaLabel="Pricing Pool Instance" onClick={() => {}} className={classes.button}>
                <Instance className={classes.icon} /> Pricing Pool Instance
            </DrcButton> */}
            <DrcButton ariaLabel="Export" onClick={props.exportData} className={classes.button}>
                <Export className={classes.icon} /> <DrcTranslate>{'Export'}</DrcTranslate>
            </DrcButton>
        </div>
    );
}
