import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { Middleware } from '@driscollsinc/one-ring';
import { DuDateUtilities } from '@driscollsinc/driscolls-react-utilities';
import GoodIcon from '@mui/icons-material/CheckCircle';
import UnknownIcon from '@mui/icons-material/Help';
import BadIcon from '@mui/icons-material/Error';

const HEALTH_UNKNOWN = 0;
const HEALTH_GOOD = 1;
const HEALTH_BAD = 2;

/**
 * @description DrcHealthCheck Component that receives an  API and check its state.
 * @class DrcHealthCheck
 * @property {string} apiName name of the API.
 * @property {string} url url of the API.
 * @property {string} apiMethod method to call the API.
 * @property {function} confirmationFunction callback that indicates if there was a confirmation.
 * @property {function} done callback when  the API call ended.
 * @example <DrcHealthCheck apiName="test" apiUrl="http://test.test", apiMethod= "GET", confirmationFunction={callback} done={callback} />
 * @category Environment
 * @tags environment apis health check verify running available
 * @extends {React.Component}
 */
class DrcHealthCheck extends Component {
    /**
     * Creates an instance of DrcHealthCheck.
     * @param {*} props
     * @memberof DrcHealthCheck
     */
    constructor(props) {
        super(props);

        this.state = {
            apiName: this.props.apiName,
            apiUrl: this.props.apiUrl,
            apiMethod: this.props.apiMethod,
            confirmationFunction:
                this.props.confirmationFunction ||
                (() => {
                    return true;
                }),
            health: HEALTH_UNKNOWN,
            duration: ' - ',
            execution: ' - '
        };
    }

    /**
     *@ignore
     */
    componentDidMount() {
        this.loadApi();
    }

    /**
     * @description call the API to check its state.
     * @memberof DrcHealthCheck
     */
    loadApi = () => {
        if (this.state.health !== HEALTH_UNKNOWN) {
            return;
        }

        const token = this.props.authState?.accessToken;
        const start = new Date();

        Middleware.Send(
            '',
            token,
            this.state.apiUrl,
            this.state.apiMethod,
            {},
            {
                overrideRequestMapping: true,
                overrideResponseMapping: true
            }
        )
            .then((data) => {
                const end = new Date();
                const duration = DuDateUtilities.TimeSpanToString(start, end);

                if (data && data.response) {
                    this.setState({ health: HEALTH_BAD, duration, execution: DuDateUtilities.FormatDateTimeFromIso(start.toISOString(), true) });
                    (this.props.done || (() => {}))(this.state.apiName, HEALTH_BAD);
                    return;
                }

                var result = this.state.confirmationFunction(data) ? HEALTH_GOOD : HEALTH_BAD;

                this.setState({ health: result, duration, execution: DuDateUtilities.FormatDateTimeFromIso(start.toISOString(), true) });
                (this.props.done || (() => {}))(this.state.apiName, result);

                return;
            })
            .catch((e) => {
                const end = new Date();
                const duration = DuDateUtilities.TimeSpanToString(start, end);

                this.setState({ health: HEALTH_BAD, duration, execution: DuDateUtilities.FormatDateTimeFromIso(start.toISOString(), true) });
                (this.props.done || (() => {}))(this.state.apiName, HEALTH_BAD);
            });
    };

    /**
     * @description render method for the DrcHealthCheck, part of the react component lifecyle.
     * @return {*} the view for this component.
     * @memberof DrcHealthCheck
     */
    render() {
        const { apiName, health, duration, execution } = this.state;

        return (
            <div className={`row ${this.props.className} health_${health}`} key={apiName}>
                <div className="col-sm-8 col-md-5 name">{apiName}</div>
                <div className="col-sm-4 col-md-2 health">
                    {health === HEALTH_UNKNOWN ? (
                        <div>
                            <UnknownIcon />
                            <span>Unknown</span>
                        </div>
                    ) : health === HEALTH_GOOD ? (
                        <div>
                            <GoodIcon />
                            <span>Good</span>
                        </div>
                    ) : (
                        <div>
                            <BadIcon />
                            <span>Bad</span>
                        </div>
                    )}
                </div>
                <div className="col-sm-8 col-md-3 execution">{execution}</div>
                <div className="col-sm-4 col-md-2 duration">{duration}</div>
            </div>
        );
    }
}

export default withOktaAuth(DrcHealthCheck);
