import { Box } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import charImage from 'Images/broken.svg';
import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useNavigate } from 'react-router-dom';
import DrcTranslate from '../Components/DrcTranslate';
import DrcAuthenticationUtilities from '../Utilities/DrcAuthenticationUtilities';
import { NAMESPACE } from 'i18n';
import {
    DriscollsCheckbox,
    DriscollsDataTable,
    DriscollsDataTableExpander,
    DriscollsTooltip,
    DriscollsTranslatedValue,
    IDriscollsDataTableProps,
    PaginationPosition,
    TooltipPosition
} from '@driscollsinc/style-guide-web2.0';
import { DriscollsButton } from '@driscollsinc/style-guide-web2.0';
import { ButtonVariant } from '@driscollsinc/style-guide-web2.0';

/**
 * @description The DrcPageNotAuthorized is a styled not authorized screen in the Driscolls format and is customizable
 * @class DrcPageNotAuthorized
 * @example
 * <DrcPageNotAuthorized />
 * @property {string} logOutUrl Logout url - default - '/Logout/'
 * @property {string} logInUrl LogIn url - default - '/'
 * @extends {React.Component} */
function DrcPageNotAuthorized(props) {
    const { authState, oktaAuth } = useOktaAuth();
    const history = useNavigate();

    const [sessionDetails, setSessionDetails] = useState({
        isChecking: true,
        hasChecked: false,
        hasSession: false,
        isLoggedIn: false
    });

    const [status, setStatus] = useState('ini');

    useEffect(() => {
        let temp = sessionDetails.isChecking ? (
            <Translate id="login.reauthorizing" />
        ) : sessionDetails.hasSession ? (
            <Translate id="login.authorizing" />
        ) : sessionDetails.isLoggedIn ? (
            <Translate id="login.loggedIn" />
        ) : (
            <Translate id="login.loggedOut" />
        );
        setStatus(temp);
    }, [sessionDetails]);

    const [isAuthenticated, setIsAuthenticated] = useState(null);

    /**
     *@ignore
     */
    useEffect(() => {
        let isOktaAuthenticated = authState?.isAuthenticated;

        if (isAuthenticated != isOktaAuthenticated) {
            setIsAuthenticated(isOktaAuthenticated);
        }

        if (!isOktaAuthenticated) {
            var credentials = DrcAuthenticationUtilities.GetUsernameAndPasswordFromQuery(window.location.search);

            if (credentials !== null) {
                DrcAuthenticationUtilities.SignIn(credentials.username, credentials.password);
            }
        }
    });

    /**
     *@ignore
     */
    useEffect(() => {
        if (props.setPageTitle) {
            props.setPageTitle('Not Authorized'); //Empty string makes page title go to default, which is good in this case.
        }
    }, []);

    /**
     *@ignore
     */
    useEffect(() => {
        if (oktaAuth && !authState?.isAuthenticated && !sessionDetails.hasChecked) {
            oktaAuth.session
                .exists()
                .then((session) => {
                    if (session) {
                        setSessionDetails({
                            isChecking: true,
                            hasChecked: false,
                            hasSession: true,
                            isLoggedIn: false
                        });
                        oktaAuth.token
                            .getWithoutPrompt({
                                responseType: ['id_token', 'token']
                            })
                            .then((result) => {
                                oktaAuth.tokenManager.setTokens(result.tokens);
                                setSessionDetails({
                                    isChecking: false,
                                    hasChecked: true,
                                    hasSession: true,
                                    isLoggedIn: true
                                });
                            })
                            .catch(() => {
                                setSessionDetails({
                                    isChecking: false,
                                    hasChecked: true,
                                    hasSession: false,
                                    isLoggedIn: false
                                });
                            });
                    } else {
                        setSessionDetails({
                            isChecking: false,
                            hasChecked: true,
                            hasSession: false,
                            isLoggedIn: false
                        });
                    }
                })
                .catch(() => {
                    setSessionDetails({
                        isChecking: false,
                        hasChecked: true,
                        hasSession: false,
                        isLoggedIn: false
                    });
                });
        }
    }, [authState, oktaAuth]);

    return (
        <div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ display: 'flex', height: '100vH', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Box component="img" src={charImage} sx={{ width: 400 }} />
                <aside
                    style={{
                        backgroundColor: 'rgba(255, 249, 235, 0.5)',
                        width: '80%',
                        borderColor: 'rgba(209, 142, 0, 0.3)',
                        padding: '10px',
                        borderRadius: '10px'
                    }}
                >
                    <p style={{ textAlign: 'center' }}>
                        <DriscollsTranslatedValue value="Not Authenticated" nameSpacing={NAMESPACE.DASHBOARD} />
                    </p>
                </aside>
                <DriscollsButton
                    variant={ButtonVariant.PRIMARY}
                    onClick={() => {
                        history(props.logInUrl || '/');
                    }}
                >
                    Login
                </DriscollsButton>
            </div>
        </div>
    );
}

export default DrcPageNotAuthorized;
