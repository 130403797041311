import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { format, formatDistance, formatDistanceToNow, isFuture } from 'date-fns';
import BugIcon from '@mui/icons-material/BugReport';
import DrcPanel from './DrcPanel';
import DrcLegend from './DrcLegend';
import DrcButton from './DrcButton';

/**
 * @ignore
 */
const styles = (theme) => ({
    root: {
        border: '2px solid ' + theme.light.border.middle,
        backgroundColor: theme.light.secondary + theme.important,
        [theme.darkTheme]: {
            border: '2px solid ' + theme.dark.border.middle,
            backgroundColor: theme.dark.secondary + theme.important
        }
    },
    legend: {
        marginTop: '0',
        width: 'inherit'
    },
    legendTitle: {
        fontSize: '1.5rem',
        display: 'inline-block',
        margin: 0
    },
    pOutage: {
        opacity: '0.7',
        marginTop: '-10px',
        marginBottom: '25px'
    },
    pNotes: {
        margin: '5px 0 5px 15px'
    },
    pSchedule: {
        fontSize: '1.3rem',
        opacity: '0.7'
    },
    hr: {
        marginTop: '20px'
    },
    version: {
        float: 'right',
        fontSize: '1.7rem',
        margin: 0,
        fontWeight: 'normal'
    },
    userStoryTitle: {
        fontSize: '1.25rem'
    },
    link: {
        color: theme.light.accent.primary,
        fontWeight: 'bold',
        [theme.darkTheme]: {
            color: theme.dark.accent.primary
        }
    },
    taskPanel: {
        padding: '8px'
    },
    task: {
        marginTop: '10px'
    },
    taskHeading: {
        marginTop: 0,
        paddingBottom: '10px',
        borderBottom: '1px solid ' + theme.light.border.middle,
        fontSize: '1.125rem',
        [theme.darkTheme]: {
            borderBottom: '1px solid ' + theme.dark.border.middle
        }
    },
    taskRequirement: {
        float: 'right',
        fontSize: '1.125rem'
    },
    copyBtn: {
        margin: '0 !important',
        marginTop: '-8px !important'
    }
});

/**
 * @description DrcReleaseInfo is a component used to render the information about the release of a new version of the application
 * @class DrcReleaseInfo
 * @property {string} key Current version of application
 * @property {string} version Current version of application
 * @property {string} title Title
 * @property {*} description Description of tasks completed in current application version
 * @property {*} relatedTasks List of tasks completed in current application version
 * @property {string} controlsVersion Version of controls used
 * @property {string} utilityVersion Version of utilities used
 * @property {string} oneRingVersion Version of one ring used
 * @property {date} scheduledReleaseUtc Scheduled release time in UTC
 * @example
 * <DrcReleaseInfo
        key={'0.29.0'}
        title={'Example DrcReleaseInfo'}
        version={'0.29.0'}
        controlsVersion={'4.0.0'}
        utilityVersion={'3.4.0'}
        oneRingVersion={'2.0.0'}
        relatedTasks={[
            {
                userStory: 'POC-92',
                partial: true,
                requirement: 'Create Documentation',
                subTask: 'POC-153',
                notes: 'Created code documentation for multiple components'
            }
        ]}
        scheduledReleaseUtc={new Date('2021-01-31T15:00:00')}
    >
        <ul>
            <li>Added documentation for DrcReleaseInfo</li>
            <li>Added documentation for DrcMain</li>
            <li>Added documentation for DrcDateRangePicker</li>
        </ul>
    </DrcReleaseInfo>
 * @category General
 * @tags ui release info version
 * @extends {Component}
 */
class DrcReleaseInfo extends Component {
    /**
     * Creates an instance of DrcReleaseInfo.
     * @param {*} props
     * @memberof DrcReleaseInfo
     */
    constructor(props) {
        super(props);

        this.showTasks = this.showTasks.bind(this);
        this.jiraLink = this.jiraLink.bind(this);
        this.updateText = this.updateText.bind(this);
        this.getTasksForUserStory = this.getTasksForUserStory.bind(this);
        this.getTaskNumber = this.getTaskNumber.bind(this);
        this.copy = this.copy.bind(this);
    }
    /**
     * @description Function to create link to JIRA task
     * @param {*} taskId
     * @return {*}
     * @memberof DrcReleaseInfo
     */
    jiraLink(taskId) {
        return (
            <a className={this.props.classes.link} href={'https://driscollsit.atlassian.net/browse/' + taskId} target="_blank" rel="noopener noreferrer">
                {taskId}
            </a>
        );
    }

    /**
     * @description Helper function to determine if update is partial or complete
     * @param {*} partial
     * @param {*} update
     * @return {*}
     * @memberof DrcReleaseInfo
     */
    updateText(partial, update) {
        return partial ? (update ? 'Partial Update' : 'Partial') : update ? 'Update' : 'Complete';
    }

    /**
     * @description Function to get task number from string
     * @param {*} task
     * @return {*}
     * @memberof DrcReleaseInfo
     */
    getTaskNumber(task) {
        return task.substring(task.indexOf('-') + 1);
    }

    /**
     * @description Function to group tasks by user story and sort them in ascending order
     * @param {*} tasks
     * @param {*} userStoryNumber
     * @return {*}
     * @memberof DrcReleaseInfo
     */
    getTasksForUserStory(tasks, userStoryNumber) {
        var tasksForUS = tasks.filter((task) => task.userStory === userStoryNumber);

        return tasksForUS.sort((taskA, taskB) => this.getTaskNumber(taskA.subTask) - this.getTaskNumber(taskB.subTask));
    }

    /**
     * @description Function to map over tasks and display them
     * @param {*} tasks
     * @return {*}
     * @memberof DrcReleaseInfo
     */
    showTasks(tasks) {
        tasks = tasks || [];

        if (tasks.length <= 0) {
            return null;
        }

        var { classes } = this.props;
        var userStoryNumbers = [];

        tasks.forEach((task) => {
            var userStoryNumber = task.parent || task.userStory || '';

            if (!userStoryNumbers.includes(userStoryNumber) && userStoryNumber.length > 0) {
                userStoryNumbers.push(userStoryNumber);
            }
        });

        if (userStoryNumbers.length <= 0) {
            return <p style={{ color: 'red' }}>Incorrect Task Properties, please correct the formatting of your release notes.</p>;
        }

        userStoryNumbers = userStoryNumbers.sort((a, b) => {
            return this.getTaskNumber(a) - this.getTaskNumber(b);
        });

        return (
            <React.Fragment>
                <hr className={classes.hr} />
                <h2 className={classes.userStoryTitle}>Related User Stories and Requirements:</h2>
                {userStoryNumbers.map((userStory) => {
                    var tasksForUS = this.getTasksForUserStory(tasks, userStory) || [];

                    if (tasksForUS.length <= 0) {
                        return null;
                    }

                    var requirement = tasksForUS[0].requirement;
                    var isBug = requirement.toLowerCase().includes('bug');

                    return (
                        <DrcPanel key={userStory} className={classes.taskPanel}>
                            <h3 className={classes.taskHeading}>
                                Parent Issue: {this.jiraLink(userStory)}&nbsp;
                                <span className={classes.taskRequirement}>
                                    {isBug ? <BugIcon /> : 'Requirement: '}
                                    {tasksForUS[0].requirement}
                                </span>
                            </h3>
                            {tasksForUS.map((task) => (
                                <div key={task.subTask} className={classes.task}>
                                    {this.updateText(task.partial, task.update)}
                                    &nbsp;-&nbsp;
                                    {this.jiraLink(task.subTask)}
                                    {task.notes ? <p className={classes.pNotes}>{task.notes}</p> : null}
                                </div>
                            ))}
                        </DrcPanel>
                    );
                })}
            </React.Fragment>
        );
    }

    /**
     * @description Function to copy release info item based on browser
     * @param {*} event
     * @memberof DrcReleaseInfo
     */
    copy(event) {
        var element = document.getElementById('releaseNote' + this.props.version);

        event.currentTarget.style.display = 'none';

        if (document.selection) {
            // IE
            var range = document.body.createTextRange();
            range.moveToElementText(element);
            range.select();

            document.execCommand('copy');

            document.selection.empty();
        } else if (window.getSelection) {
            // eslint-disable-next-line no-redeclare
            var range = document.createRange();
            range.selectNode(element);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);

            document.execCommand('copy');

            window.getSelection().removeAllRanges();
        }

        event.currentTarget.style.display = 'block';
    }

    /**
     * @return {*}
     * @memberof DrcReleaseInfo
     */
    render() {
        const {
            children,
            version,
            controlsVersion,
            utilityVersion,
            oneRingVersion,
            title,
            scheduledReleaseUtc,
            scheduledReleaseStartUtc,
            scheduledReleaseEndUtc,
            relatedTasks,
            isMilestone,
            classes,
            key
        } = this.props;

        var scheduleReleaseStartUtcFixed = scheduledReleaseStartUtc;
        if (scheduledReleaseUtc) {
            scheduleReleaseStartUtcFixed = scheduledReleaseUtc;
        }

        var dateDistance = formatDistanceToNow(scheduleReleaseStartUtcFixed);
        var alreadyHappened = false;

        if (isFuture(scheduleReleaseStartUtcFixed)) {
            dateDistance = 'in ' + dateDistance;
        } else {
            dateDistance = dateDistance + ' ago';
            alreadyHappened = true;
        }

        return (
            <DrcPanel key={key} id={'releaseNote' + version} className={classes.root}>
                <DrcLegend className={classes.legend}>
                    <h2 className={classes.version}>{version}</h2>&nbsp;
                    <h2 className={classes.legendTitle}>{title}</h2>
                </DrcLegend>
                {oneRingVersion && oneRingVersion.length > 0 ? <p>API Management Version: {oneRingVersion}</p> : null}
                {controlsVersion && controlsVersion.length > 0 ? <p>Component Version: {controlsVersion}</p> : null}
                {utilityVersion && utilityVersion.length > 0 ? <p>Utility Version: {utilityVersion}</p> : null}
                <p className={classes.pSchedule}>
                    {alreadyHappened ? 'Released' : 'Scheduled'} Date: {format(scheduleReleaseStartUtcFixed, 'EEEE MMM dd yyyy h:mm bbbb')}
                </p>
                {isMilestone === false && scheduledReleaseEndUtc ? (
                    <p className={classes.pOutage}>
                        Estimated Outage:&nbsp;
                        {scheduledReleaseEndUtc && scheduledReleaseEndUtc != scheduleReleaseStartUtcFixed ? (
                            formatDistance(scheduleReleaseStartUtcFixed, scheduledReleaseEndUtc)
                        ) : (
                            <span>None</span>
                        )}
                    </p>
                ) : null}
                {children}
                {this.showTasks(relatedTasks)}
                <hr className={classes.hr} />
                {<span>{dateDistance}&nbsp;</span>}
                <DrcButton floatRight onClick={this.copy} className={classes.copyBtn}>
                    Copy
                </DrcButton>
            </DrcPanel>
        );
    }
}

export default withStyles(styles)(DrcReleaseInfo);
