import React from 'react';
import Typography from '@mui/material/Typography';
import { DrcTranslate } from 'drc/driscolls-react-components/index';
import { connect } from 'react-redux';

function PageHeader(props) {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
                <Typography variant="h6" display="inline" style={{ paddingRight: '8px' }}>
                    <DrcTranslate>{'Settlement Pool ID'}</DrcTranslate> :
                </Typography>
                <Typography variant="h6" display="inline" style={{ paddingRight: '3rem' }}>
                    {props.selectedSettlementRecord.PoolID}
                </Typography>
            </div>

            <div>
                <Typography variant="body1" display="inline" style={{ paddingRight: '2rem' }}>
                    <DrcTranslate>{'Status'}</DrcTranslate>
                </Typography>
                <Typography variant="body1" display="inline" style={{ paddingRight: '2rem' }}>
                    <DrcTranslate>
                        {props.selectedSettlementRecord.PoolIDStatus == 1
                            ? 'Open'
                            : props.selectedSettlementRecord.PoolIDStatus == 0
                            ? 'Not Run'
                            : 'Closed'}
                    </DrcTranslate>
                </Typography>
            </div>
        </div>
    );
}

const mapStateToProps = ({ masterReducer, weeklySettlementStatusReducer }) => ({
    currentPoolWeek: masterReducer.currentPoolWeek,
    selectedPoolWeek: weeklySettlementStatusReducer.selectedPoolWeek,
    selectedSettlementRecord: weeklySettlementStatusReducer.selectedSettlementRecord
});
export default connect(mapStateToProps, null)(PageHeader);
