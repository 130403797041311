import { syncTranslations } from '@driscollsinc/ggs-business-rules-module-ui';
import { DriscollsTranslatedValue } from '@driscollsinc/style-guide-web2.0';
import { withStyles } from '@mui/styles';
import { withOktaAuth } from '@okta/okta-react';
import { CellContext } from '@tanstack/react-table';
import { setPageTitleAction, showToast } from 'actions/actions';
import { setSettlementWorkbenchData } from 'actions/settlementWorkbenchAction';
import DrcWrapper from 'components/DrcWrapper';
import useGAAnalytics from 'hooks/v2/useGAAnalytics';
import useLang from 'hooks/v2/useLang';
import useSettlement from 'hooks/v2/useSettlement';
import {} from 'primereact/api';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getLanguage } from 'utils/helper';
import GridStyles from '../../../styles/gridStyles';
import { DriscollsDataTable } from '@driscollsinc/style-guide-web2.0';
import { BusinessRulesAssignments } from '@driscollsinc/ggs-business-rules-module-ui';

const styles = (theme) => ({
    input: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'left'
        }
    },
    gridStyles: GridStyles.styles(theme, 'calc(100vh - 340px)'),
    tableContainer: {
        margin: theme.spacing(3)
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    icon: {
        marginRight: 5
    }
});

function AssignmentView(props) {
    const { logEvent, logError } = useGAAnalytics();

    const language = localStorage.getItem('GGS_UserLang');

    const { getFilterDropdownValues } = useSettlement(props.oktaAuth);

    useEffect(() => {
        syncTranslations(getLanguage(language));
    }, [language]);

    return (
        <DrcWrapper>
            <div style={{ padding: '0px 20px', height: 'inherit' }}>
                <BusinessRulesAssignments
                    columns={['Brand', 'BerryType', 'ItemName', 'ItemNbr', 'FieldType'].map((column) => ({
                        key: column,
                        accessorKey: column
                    }))}
                    getAssignments={async () => {
                        const data = await getFilterDropdownValues(null, null, null, null, null, null, 'GET_ASSIGNABLE_LIST');
                        return data[0].AssignableItemList;
                    }}
                />
            </div>
        </DrcWrapper>
    );
}

const mapStateToProps = ({ settlementWorkbenchReducer, rootReducer }) => ({
    userIsAdmin: rootReducer.userIsAdmin,
    total: settlementWorkbenchReducer.totalRecords,
    pageTitle: rootReducer.pageTitle,
    records: settlementWorkbenchReducer.records,
    showToast: rootReducer.showToast
});

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    showToast: (message, type) => dispatch(showToast(message, type)),
    setSettlementWorkbenchData: (data) => dispatch(setSettlementWorkbenchData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withStyles(styles)(AssignmentView)));
