import React, { useState, useEffect } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { Box } from '@mui/material';

import { DrcDialog, DrcButton, DrcTranslate, DrcChipsMultiSelect } from 'drc/driscolls-react-components/index';
import Form from 'react-validation/build/form';
import { alphaNumeric, maxlen } from '../../../utils/validations';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';

import APIEndPoints from '../../../services/api';

import { Call } from '@driscollsinc/driscolls-display-rules';
import { ReactComponent as NoteAdd } from '../../../Images/SVG/Add_Enabled.svg';
import { showToast, showLoadingScreenAction, hideLoadingScreenAction } from '../../../actions/actions';

const PoolTableCountryFields = {
    CountryCode: {
        displayName: 'Country Code',
        validations: [alphaNumeric, maxlen],
        customProps: { maxlen: 500, required: true }
    },
    Description: {
        displayName: 'Description',
        validations: [alphaNumeric, maxlen],
        customProps: { maxlen: 500, required: true }
    }
};

const useStyles = makeStyles((theme) => ({}));

const DestinationCountriesTab = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [stateData, setStateData] = useState([]);
    const [editPoolTableDestCountry, setEditPoolTableCountry] = useState({});
    const [pageNum, setPageNum] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [onPage, setOnPage] = useState(1);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const columns = ['PoolID', 'VendorAccountNum', 'Description', 'DataAreaId'];

    const [countryData, setCountryData] = useState([]);
    const [countryConfig, setCountryConfig] = useState({ DestCountrySelected: '', DestCountrySelection: 0 });

    const initialAddPoolTableDestCoutnry = {
        PoolID: props.selectedKey.Identification.PoolID,
        DataareaID: props.selectedKey.Identification.DataareaID
    };

    useEffect(() => {
        setupData();
    }, []);

    const setupData = () => {
        setCountryConfig(props.selectedKey.DestCountry);
        setEditPoolTableCountry({
            ...initialAddPoolTableDestCoutnry
        });
    };

    const showError = (err, msg) => {
        props.hideLoadingScreenAction();
        props.showToast(<DrcTranslate>{msg || 'An Error Occurred'}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    const AddPoolTableDestCountry = async () => {
        props.showLoadingScreenAction('Posting data');

        try {
            let token = await props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            var data = await Call(
                {
                    name: 'PUT_POOLTABLECOUNTRY',
                    url: APIEndPoints.PUTPOST_POOLTABLECOUNTRY(),
                    method: 'POST',
                    options: {},
                    data: {
                        PoolTableCountry: countryData
                            .filter((country) => {
                                return country.selected;
                            })
                            .map((country) => {
                                return { CountryCode: country.CountryCode, Description: country.Description };
                            }),
                        PoolID: editPoolTableDestCountry['PoolID'],
                        DataareaID: editPoolTableDestCountry['DataareaID'],
                        DateTime: new Date().toISOString(),
                        RequestedBy: loggedInUser
                    }
                },
                token
            );
            if (data.errors.length && !data.raw) {
                setShowAddDialog(false);
                setEditPoolTableCountry({ ...initialAddPoolTableDestCoutnry });
                return showError(data.errors[0]);
            } else if (data.display.Status === 409) {
                setShowAddDialog(false);
                setEditPoolTableCountry({ ...initialAddPoolTableDestCoutnry });
                return showError((data.display.Message, 'Duplicate Record'));
            } else {
                props.hideLoadingScreenAction();
                setShowAddDialog(false);
                setEditPoolTableCountry({ ...initialAddPoolTableDestCoutnry });
                props.showToast(<DrcTranslate>{'Added Successfully'}</DrcTranslate>, true);
            }
        } catch (error) {
            showError(error);
        }
        props.hideLoadingScreenAction();
    };

    const handleSelectChange = (country, isSelected) => {
        let newData = countryData.reduce((arr, data) => {
            if (country.key === data.CountryCode) {
                data.selected = isSelected;
            }
            arr.push(data);
            return arr;
        }, []);
        setCountryData(newData);
    };
    return (
        <>
            <Typography>
                <DrcTranslate>{'Destination Countries'}</DrcTranslate>
            </Typography>
            <Box
                sx={{
                    margin: 'auto',
                    marginRight: 'inherit'
                }}
            >
                <NoteAdd onClick={() => setShowAddDialog(true)} style={{ width: '50px', height: '20px', marginRight: '-10px' }} />
            </Box>
            <DrcDialog
                title={<DrcTranslate>{'Add Destination Country'}</DrcTranslate>}
                open={showAddDialog}
                buttons={
                    <>
                        <DrcButton
                            poly
                            line
                            primary
                            onClick={() => {
                                AddPoolTableDestCountry();
                            }}
                        >
                            <DrcTranslate>{'Save'}</DrcTranslate>
                        </DrcButton>
                        <DrcButton
                            poly
                            line
                            onClick={() => {
                                setEditPoolTableCountry({ ...initialAddPoolTableDestCoutnry });
                                setShowAddDialog(!showAddDialog);
                            }}
                        >
                            <DrcTranslate>{'Cancel'}</DrcTranslate>
                        </DrcButton>
                    </>
                }
            >
                <Form>
                    <DrcChipsMultiSelect
                        chips={countryData
                            .filter((country) => country.selected)
                            .map((country) => {
                                return { key: country.CountryCode, value: country.CountryCode, label: country.Description };
                            })}
                        options={countryData.map((country) => {
                            return { key: country.CountryCode, value: country.CountryCode, label: country.Description };
                        })}
                        onSelectChange={(country) => handleSelectChange(country, true)}
                        onChipDelete={(country) => handleSelectChange(country, false)}
                    />
                </Form>
            </DrcDialog>
        </>
    );
};

const mapStateToProps = ({ masterReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    userLang: masterReducer.userLang
});

export default connect(mapStateToProps, { showToast, showLoadingScreenAction, hideLoadingScreenAction })(withOktaAuth(DestinationCountriesTab));
