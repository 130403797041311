import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import DrcMain from '../Components/DrcMain';
import DrcPanel from '../Components/DrcPanel';
import DrcLegend from '../Components/DrcLegend';
import DrcHealthCheck from '../Components/DrcHealthCheck';
import { DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';

const styles = (theme) => ({
    row: {
        padding: '8px 12px',
        margin: 6,
        '&:nth-child(odd)': {
            backgroundColor: DuThemeUtilities.DefaultColors.text.white.main,
            [theme.darkTheme]: {
                backgroundColor: DuThemeUtilities.DefaultColors.text.black.main
            }
        },
        '& > div': {
            marginTop: 4
        },
        '& > div.health': {
            marginTop: 0
        },
        '&.health_0 .health': {
            color: DuThemeUtilities.DefaultColors.primary.yellow
        },
        '&.health_1 .health': {
            color: DuThemeUtilities.DefaultColors.primary.green
        },
        '&.health_2 .health': {
            color: DuThemeUtilities.DefaultColors.primary.red
        },
        '& .health span': {
            display: 'inline-block',
            verticalAlign: 'top',
            paddingTop: 4,
            marginLeft: 6
        },
        '& .health svg': {
            fontSize: '2rem'
        }
    },
    header: {
        fontWeight: 'bold',
        borderBottom: '2px solid ' + DuThemeUtilities.DefaultColors.text.black.light,
        [theme.darkTheme]: {
            borderBottom: '2px solid ' + DuThemeUtilities.DefaultColors.text.white.light
        },
        marginBottom: 12,
        '& div': {
            fontSize: '1.2rem'
        }
    },
    margin: {
        margin: 6
    }
});

/**
 * @description DrcPageHealthCheck  Page that receives an array of APIs and show the health of each API.
 * @class DrcPageHealthCheck
 * @property {string} appName name of the application.
 * @property {array} apis APIs that want to be checked.
 * @property {object} classes customized style for DrcPageHealthCheck.
 * @example 
 * const apisList = [{name:'test', url: 'http://test.test', method: 'GET'},{name:'test2', url: 'http://test2.test', method: 'GET'}]
 * <DrcPageHealthCheck apis={apisList} />
 * @extends {React.Component}
 */
class DrcPageHealthCheck extends Component {

    /**
     * Creates an instance of DrcPageHealthCheck.
     * @param {*} props
     * @memberof DrcPageHealthCheck
     */
    constructor(props) {
        super(props);

        this.state = { countGood: null, countBad: null, statuses: [] };
    }

    /**
     * @description will check how many API calls failed and how many succeed
     * @param {string} name name of the API
     * @param {number} status status of the API, 1 = Good, 2 = Bad
     * @memberof DrcPageHealthCheck
     */
    statusUpdate = (name, status) => {
        if (this.state.statuses.findIndex((s) => s.name === name) >= 0) {
            return;
        }

        var countGood = this.state.countGood + (status === 1 ? 1 : 0);
        var countBad = this.state.countBad + (status === 2 ? 1 : 0);
        var statuses = [...this.state.statuses];

        statuses.push({ name, status });

        this.setState({ countGood, countBad, statuses });
    };

    /**
     * @description render method for the DrcPageHealthCheck, part of the react component lifecyle.
     * @return {*} the view for this component.
     * @memberof DrcPageHealthCheck
     */
    render() {
        const { appName, apis, classes } = this.props;
        const { countGood, countBad } = this.state;

        const isDoneTesting = countGood + countBad >= apis.length;

        return (
            <DrcMain transparent>
                <DrcPanel>
                    <DrcLegend>{appName} Health Check</DrcLegend>
                    <div className={`row ${classes.row} ${classes.header}`}>
                        <div className="col-sm-8 col-md-5 name">Name</div>
                        <div className="col-sm-4 col-md-2 health">Health</div>
                        <div className="col-sm-8 col-md-3 executed">Executed</div>
                        <div className="col-sm-4 col-md-2 duration">Duration</div>
                    </div>
                    {(apis || []).map((api) => (
                        <DrcHealthCheck
                            apiName={api.name}
                            apiUrl={api.url}
                            apiMethod={api.method || 'GET'}
                            className={classes.row}
                            confirmationFunction={api.confirmationFunction}
                            done={this.statusUpdate}
                        />
                    ))}
                    <br />
                    <h2 id="testing" className={classes.margin}>
                        Testing Status: <span id="testingPhrase">{isDoneTesting ? 'Testing Complete' : 'Testing Pending'}</span>
                    </h2>
                    <p id="overall" className={classes.margin}>
                        Overall Status: <span id="overallWord">{isDoneTesting ? (countBad > 0 ? 'Bad' : 'Good') : 'Pending'}</span>
                    </p>
                    <p className={classes.margin}>Good Responses: {countGood !== null ? countGood : '-'}</p>
                    <p className={classes.margin}>Bad Responses: {countBad !== null ? countBad : '-'}</p>
                </DrcPanel>
            </DrcMain>
        );
    }
}

export default withStyles(styles)(DrcPageHealthCheck);
