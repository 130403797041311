import { withStyles } from '@mui/styles';
import { DuValidationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { DrcTooltip } from 'drc/driscolls-react-components/index';
import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
const styles = (theme) => ({
    dataTableCell: {
        fontSize: 11,
        paddingRight: 8
    },
    emailAddress: {
        fontWeight: 600,
        fontSize: 11,
        color: '#4a773c',
        textDecoration: 'underline'
    },
    totalField: {
        fontWeight: 'bold'
    }
});

class LabelFormatter extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { row, field, classes } = this.props;
        const isEmail = DuValidationUtilities.EMAIL_REGEXP.test(row[field]);
        const value = row[field];

        return isEmail ? (
            <span>
                <a className={classes.emailAddress} href={'mailto:' + value} title={value}>
                    {value}
                </a>
            </span>
        ) : !isNaN(value) && value % 1 !== 0 ? (
            <DrcTooltip tipText={parseFloat(value || 0).toFixed(2)}>
                <Typography noWrap className={`${classes.dataTableCell} ${row.TotalSumSection ? classes.totalField : ''}`}>
                    {parseFloat(value || 0).toFixed(2)}
                </Typography>
            </DrcTooltip>
        ) : (
            <DrcTooltip tipText={value}>
                <Typography noWrap className={`${classes.dataTableCell} ${row.TotalSumSection ? classes.totalField : ''}`}>
                    {value}
                </Typography>
            </DrcTooltip>
        );
    }
}

export default withStyles(styles)(LabelFormatter);
