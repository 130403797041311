import { Home } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { NAMESPACE } from 'i18n';
import { useNavigate } from 'react-router';
import charImage from 'Images/404 Error-rafiki.svg';
import DrcTranslate from '../Components/DrcTranslate';
import { ROUTES } from 'constants/routes';

function DrcPageNotFoundV2() {
    const navigate = useNavigate();
    return (
        <div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
            <IconButton onClick={() => navigate(ROUTES.BUSINESS_RULES)} sx={{ position: 'fixed', top: 100, left: 100 }}>
                <Home fontSize="large" />
            </IconButton>
            <div style={{ display: 'flex', height: '100vH', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Box component="img" src={charImage} sx={{ width: 400 }} />
                <aside
                    style={{
                        backgroundColor: 'rgba(255, 249, 235, 0.5)',
                        width: '80%',
                        borderColor: 'rgba(209, 142, 0, 0.3)',
                        padding: '10px',
                        borderRadius: '10px'
                    }}
                >
                    <p style={{ textAlign: 'center' }}>
                        <DrcTranslate>{'Page Not Found'}</DrcTranslate>
                    </p>
                </aside>
            </div>
        </div>
    );
}

export default DrcPageNotFoundV2;
