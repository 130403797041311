import React, { useState, useEffect } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import {  Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { Box } from '@mui/material';

import { DrcDialog, DrcButton, DrcTranslate, DrcChipsMultiSelect } from 'drc/driscolls-react-components/index';
import Form from 'react-validation/build/form';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';

import APIEndPoints from '../../../services/api';

import { Call } from '@driscollsinc/driscolls-display-rules';
import { ReactComponent as NoteAdd } from '../../../Images/SVG/Add_Enabled.svg';
import { showToast, showLoadingScreenAction, hideLoadingScreenAction } from '../../../actions/actions';
import { userBusinessUnit } from '../../../utils/helper';
const useStyles = makeStyles((theme) => ({}));

const RegionTab = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [editPoolTableRegion, setEditPoolTableRegion] = useState({});

    const [regionData, setRegionData] = useState([]);
    const [regionConfig, setRegionConfig] = useState({ RegionSelected: '', RegionSelection: 0 });

    const initialAddPoolTableRegion = {
        PoolID: props.selectedKey.Identification.PoolID,
        DataareaID: props.selectedKey.Identification.DataareaID
    };

    useEffect(() => {
        setupData();
        loadData();
    }, []);

    const updateRegion = (regionArray) => {
        var updatedRegionData = [];
        if (regionConfig.GrowerSelection === 0) {
            regionArray.forEach((grower) => {
                updatedRegionData.push({ ...grower, selected: true });
            });
        } else {
            let selectedRegion = regionConfig.GrowerSelected.split(',');
            regionArray.forEach((grower) => {
                updatedRegionData.push({
                    ...grower,
                    selected: selectedRegion.contains((sel) => {
                        return sel === grower.GRLRegion;
                    })
                });
            });
        }
        return updatedRegionData;
    };

    const loadData = async () => {
        props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await props.oktaAuth.getAccessToken();
        try {
            var data = await Call(
                {
                    name: 'RegionList',
                    url: APIEndPoints.GET_REGION_LIST('', '', userBusinessUnit(), props.userLang, ''),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }

            var growersData = updateRegion(data.converted.Data);
            setRegionData(growersData);

            props.hideLoadingScreenAction();
        } catch (error) {
            showError(error);
        }
    };

    const setupData = () => {
        setRegionConfig(props.selectedKey.Region);
        setEditPoolTableRegion({
            ...initialAddPoolTableRegion
        });
    };

    const showError = (err, msg) => {
        props.hideLoadingScreenAction();
        props.showToast(<DrcTranslate>{msg || 'An Error Occurred'}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    const UpdatePoolTableRegion = async () => {
        props.showLoadingScreenAction('Posting data');

        try {
            let token = await props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            var data = await Call(
                {
                    name: 'PUT_POOLTABLEREGION',
                    url: APIEndPoints.PUTPOST_POOLTABLEREGION(),
                    method: 'PUT',
                    options: {},
                    data: {
                        PoolTableRegion: regionData
                            .filter((region) => {
                                return region.selected;
                            })
                            .map((region) => {
                                return { GRLRegion: region.GRLRegion, Description: region.Description };
                            }),
                        PoolID: editPoolTableRegion['PoolID'],
                        DataareaID: editPoolTableRegion['DataareaID'],
                        DateTime: new Date().toISOString(),
                        RequestedBy: loggedInUser
                    }
                },
                token
            );
            if (data.errors.length && !data.raw) {
                setShowEditDialog(false);
                setEditPoolTableRegion({ ...initialAddPoolTableRegion });
                return showError(data.errors[0]);
            } else if (data.display.Status === 409) {
                setShowEditDialog(false);
                setEditPoolTableRegion({ ...initialAddPoolTableRegion });
                return showError((data.display.Message, 'Duplicate Record'));
            } else {
                props.hideLoadingScreenAction();
                setShowEditDialog(false);
                setEditPoolTableRegion({ ...initialAddPoolTableRegion });
                props.showToast(<DrcTranslate>{'Added Successfully'}</DrcTranslate>, true);
            }
        } catch (error) {
            showError(error);
        }
        loadData();
        props.hideLoadingScreenAction();
    };

    const handleSelectChange = (region, isSelected) => {
        let newData = regionData.reduce((arr, data) => {
            if (region.key === data.GRLRegion) {
                data.selected = isSelected;
            }
            arr.push(data);
            return arr;
        }, []);
        setRegionData(newData);
    };

    return (
        <>
            <Typography>
                <DrcTranslate>{'Region'}</DrcTranslate>
            </Typography>
            <Box
                sx={{
                    margin: 'auto',
                    marginRight: 'inherit'
                }}
            >
                <NoteAdd onClick={() => setShowEditDialog(true)} style={{ width: '50px', height: '20px', marginRight: '-10px' }} />
            </Box>
            <DrcDialog
                title={<DrcTranslate>{'Select Region'}</DrcTranslate>}
                open={showEditDialog}
                buttons={
                    <>
                        <DrcButton
                            poly
                            line
                            primary
                            onClick={() => {
                                UpdatePoolTableRegion();
                            }}
                        >
                            <DrcTranslate>{'Save'}</DrcTranslate>
                        </DrcButton>
                        <DrcButton
                            poly
                            line
                            onClick={() => {
                                setEditPoolTableRegion({});
                                setShowEditDialog(false);
                            }}
                        >
                            <DrcTranslate>{'Cancel'}</DrcTranslate>
                        </DrcButton>
                    </>
                }
            >
                <Form>
                    <DrcChipsMultiSelect
                        chips={regionData
                            .filter((region) => region.selected)
                            .map((region) => {
                                return { key: region.GRLRegion, value: region.GRLRegion, label: region.Description };
                            })}
                        options={regionData.map((grower) => {
                            return { key: grower.GRLRegion, value: grower.GRLRegion, label: grower.Description };
                        })}
                        onSelectChange={(region) => handleSelectChange(region, true)}
                        onChipDelete={(region) => handleSelectChange(region, false)}
                    />
                </Form>
            </DrcDialog>
        </>
    );
};

const mapStateToProps = ({ masterReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    userLang: masterReducer.userLang
});

export default connect(mapStateToProps, { showToast, showLoadingScreenAction, hideLoadingScreenAction })(withOktaAuth(RegionTab));
