export const EventConstants = {
    WEB_APP_ERROR: 'Web_APP_error',
    EXCEPTION: 'exception',
    SEARCH: 'search',
    USER_LOGGED_IN: 'user_logged_in',
    LOGIN_ERROR: 'loginerror',
    GLOBAL_ERROR: 'globalError',
    OKTA_USER_DEACTIVATION: 'OktaUserDeactivation',
    COGNITO_USER_DEACTIVATION: 'CognitoUserDeactivation',
    GGS_ENGINE_RUN: 'GGS_Engine_Run',
    BUSINESS_RULE_TAB_CHANGE: 'Business_Rule_TabChange',
    BUSINES_RULE_SAVE_EXCEPTION: 'Business_Rule_Save_Exception',
    BUSINESS_RULE_ADD_NEW_RULE: 'Business_Rule_Add_New_Rule',
    BUSINESS_RULE_SEARCH: 'Business_Rule_Search',
    BUSINESS_RULE_DELETE: 'Business_Rule_Delete',
    BUSINESS_RULE_COPY: 'Business_Rule_Copy',
    BUSINESS_RULE_EDIT: 'Business_Rule_Edit',
    BUSINESS_RULE_REJECT: 'Business_Rule_Reject',
    BUSINESS_RULE_BULK_REJECT: 'Business_Rule_Bulk_Reject',
    BUSINESS_RULE_APPROVE: 'Business_Rule_Approve',
    BUSINESS_RULE_BULK_APPROVE: 'Business_Rule_Bulk_Approve',
    BUSINESS_RULE_VIEW_HISTORY: 'Business_Rule_View_History',
    BUSINESS_RULE_PRICING_POOL_SELECTION: 'Business_Rule_View_History',
    BUSINESS_RULE_UPDATE: 'Business_Rule_Update',
    BUSINESS_RULE_FILTER_CHANGE: 'Business_Rule_Filter_Change',
    SLOW_SERVER_CALL: 'Slow_Server_Call',
    ORACLE_SYNC: 'Oracle_Data_Sync',
    PUBLISH_TO_GROWER: 'Publish_To_Grower',
    POST_TO_ORACLE: 'Post_To_Oracle',

};
