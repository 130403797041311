import React from 'react';
import { Divider } from '@mui/material';
import DrcWrapper from '../../../components/DrcWrapper';
import PrimeReactTable from '../../../components/PrimeReactTable';
import Stepper from '../../../components/Stepper';
import { withStyles } from '@mui/styles';
import { DrcButton, DrcDialog, DrcTranslate } from 'drc/driscolls-react-components/index';
import GridStyles from '../../../styles/gridStyles';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast, setPageTitleAction } from '../../../actions/actions';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { displayErrorMessage, getAppliedFilters, makeColumns, userBusinessUnit } from '../../../utils/helper';
import ActionsFormatter from '../../../components/cellFormatters/ActionFormatter';
import { ReactComponent as Add } from '../../../Images/SVG/Add_Enabled.svg';
import Grid from '@mui/material/Grid';
import MasterDataUtilities from '../../../data/MasterDataUtilities';
import { Call } from '@driscollsinc/driscolls-display-rules';
import APIEndPoints from '../../../services/api';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { alphaNumeric, numeric, maxlen } from '../../../utils/validations';
import GGSFormInput from '../../../components/GGSFormInput';
import Form from 'react-validation/build/form';
import { repackagingSkuMappingDsColumns } from '../../../data/constants';
import FilterListSharp from '@mui/icons-material/FilterListSharp';
import Filter from '../../../components/Filter/Filter';

const pageTitle = 'Repackaging SKU Mapping';
const styles = (theme) => ({
    input: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'left'
        }
    },
    gridStyles: GridStyles.styles(theme, '300px', '300px'),
    tableContainer: {
        margin: theme.spacing(0, 3)
    },
    selectedClass: {
        width: '45px !important',
        padding: '0 10px !important'
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'end'
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    pageActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
});

class RepackagingSkuMapping extends React.Component {
    state = {
        data: [],
        pageNum: 0,
        selectedRow: [],
        pageSize: 50,
        totalCount: 0,
        columns: [],
        rowData: null,
        editingRow: {},
        showAddNew: false,
        newRecord: {},
        showDialog: false,
        deleteId: null,
        invalidFields: []
    };
    validationFields = {
        CustomerCode: {
            key: 'CustomerCode',
            validations: [alphaNumeric, maxlen],
            customProps: { maxlen: 100 }
        },
        OriginalItemDescription: {
            key: 'OriginalItemDescription',
            validations: [maxlen],
            customProps: { maxlen: 255 }
        },
        OriginalItemId: {
            key: 'OriginalItemId',
            validations: [numeric, maxlen],
            customProps: { maxlen: 6 }
        },
        RepackageItemDescription: {
            key: 'RepackageItemDescription',
            validations: [maxlen],
            customProps: { maxlen: 255 }
        },
        RepackingItemId: {
            key: 'RepackingItemId',
            validations: [numeric, maxlen],
            customProps: { maxlen: 6 }
        }
    };
    excludingFields = ['Actions', 'RepackageItemMapID'];
    readOnly = false;
    uniqueKeyField = 'RepackageItemMapID';
    widthIsSufficient = window.innerWidth > 955;
    widthIsInSufficient = false;

    componentDidMount() {
        this.loadData();
        window.addEventListener('resize', this.updateDataTable);

        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDataTable);
    }

    editData = (_event, data, _index) => {
        this.setState({ editingRow: { ...data, [data[this.uniqueKeyField]]: true } });
    };

    cancelEdit = (_event, _rowData) => {
        this.setState({ editingRow: {} });
    };
    cannotEdit = (_row) => !(this.props.isUserAdmin && this.props.selectedRole.includes('-Admin'));

    deleteData = (rowData) => {
        this.setState({ showDialog: true, deleteId: rowData.RepackageItemMapID });
    };
    deleteHandler = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            var data = await Call(
                {
                    name: 'RepackagingSkuMapping',
                    url: APIEndPoints.PUT_REPACKAGING_SKU_MAPPING(),
                    method: 'DELETE',
                    options: {},
                    data: { ID: this.state.deleteId }
                },
                token
            );
            if (!data) {
                this.showError();
            } else {
                this.props.hideLoadingScreenAction();
                this.loadData();
                this.props.showToast(<DrcTranslate>{'Deleted Successfully'}</DrcTranslate>, true);
            }
        } catch (error) {
            this.showError();
        }
        this.setState({ showDialog: false });
    };

    confirmEdit = async (_event, _rowData) => {
        try {
            this.props.showLoadingScreenAction('Updating data');
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            var data = await Call(
                {
                    name: 'RepackagingSkuMapping',
                    url: APIEndPoints.PUT_REPACKAGING_SKU_MAPPING(),
                    method: 'PUT',
                    options: {},
                    data: {
                        OriginalItemDescription: this.state.editingRow['OriginalItemDescription'],
                        BusinessUnit: this.state.editingRow['BusinessUnit'],
                        OriginalItemID: this.state.editingRow['OriginalItemID'],
                        RepackageItemMapID: this.state.editingRow['RepackageItemMapID'],
                        CreatedBy: this.state.editingRow['CreatedBy'],
                        RepackageItemID: this.state.editingRow['RepackageItemID'],
                        ModifiedDateTime: new Date().toISOString(),
                        CustomerCode: this.state.editingRow['CustomerCode'],
                        ModifiedBy: loggedInUser,
                        RepackageItemDescription: this.state.editingRow['RepackageItemDescription'],
                        CreatedDateTime: '2021-12-02T10:50:37'
                    }
                },
                token
            );
            if (!data) {
                this.setState({ editingRow: {} });
                this.showError();
            } else {
                this.props.hideLoadingScreenAction();
                this.setState({ editingRow: {} });
                this.props.showToast(<DrcTranslate>{'Updated Successfully'}</DrcTranslate>, true);
                this.loadData();
            }
        } catch (error) {
            this.setState({ editingRow: {} });
            this.showError(error);
        }
    };
    cannotDelete = (_row) => !(this.props.isUserAdmin && this.props.selectedRole.includes('-Admin'));

    addNewRecord = async () => {
        try {
            this.props.showLoadingScreenAction('Adding data');
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            var data = await Call(
                {
                    name: 'RepackagingSkuMapping',
                    url: APIEndPoints.PUT_REPACKAGING_SKU_MAPPING(),
                    method: 'POST',
                    options: {},
                    data: {
                        OriginalItemDescription: this.state.newRecord['OriginalDescription'],
                        BusinessUnit: userBusinessUnit(),
                        OriginalItemID: parseInt(this.state.newRecord['OriginalDescriptionId']),
                        CreatedBy: loggedInUser,
                        RepackageItemID: parseInt(this.state.newRecord['RepackingItemId']),
                        ModifiedDateTime: new Date().toISOString(),
                        CustomerCode: this.state.newRecord['CustomerCode'],
                        ModifiedBy: loggedInUser,
                        RepackageItemDescription: this.state.newRecord['RepackingDescription'],
                        CreatedDateTime: new Date().toISOString()
                    }
                },
                token
            );
            if (data.errors.length && !data.raw) {
                this.setState({ showAddNew: false, newRecord: {} });
                return this.showError(data.errors[0]);
            } else if (data.display.Status === 409) {
                this.setState({ showAddNew: false, newRecord: {} });
                return this.showError(data.display.Message, 'Duplicate Record');
            } else {
                this.props.hideLoadingScreenAction();
                this.setState({ showAddNew: false, newRecord: {} });
                this.props.showToast(<DrcTranslate>{'Added Successfully'}</DrcTranslate>, true);
                this.loadData();
            }
        } catch (error) {
            this.showError(error);
        }
    };

    getActionsCell = (row) => {
        return (
            <ActionsFormatter
                rowEdit={true}
                uniqueKeyField={this.uniqueKeyField}
                objState={this}
                isDeletable
                records={this.state.data}
                initRowEdit={this.editData}
                confirmEdit={this.confirmEdit}
                cancelEdit={this.cancelEdit}
                cannotDelete={this.cannotDelete}
                cannotEdit={this.cannotEdit}
                deleteAttribute={this.deleteData}
                row={row}
                rowUnderEditUniqueKeyValue={row[this.uniqueKeyField]}
            />
        );
    };

    updateTableData = (response, hasFrozenColumns = true) => {
        let data = response?.display?.Data || [];
        let columns = data.length
            ? makeColumns(
                  Object.keys(data[0]),
                  this.excludingFields,
                  this.getActionsCell,
                  true,
                  ['RepackageItemDescription', 'OriginalItemDescription'],
                  [],
                  repackagingSkuMappingDsColumns
              )
            : [];
        this.setState({
            data,
            totalCount: response?.display?.TotalCount || 0,
            columns,
            unfreeze: !hasFrozenColumns
        });
    };
    updateDataTable = (_event, force = false) => {
        if (window.innerWidth > 955 && !this.widthIsSufficient) {
            this.widthIsSufficient = true;
            this.widthIsInSufficient = false;
            this.updateTableData(this.response || [], true);
        } else if (window.innerWidth <= 955 && !this.widthIsInSufficient) {
            this.widthIsInSufficient = true;
            this.widthIsSufficient = false;
            this.updateTableData(this.response || [], false);
        } else if (force) {
            this.updateTableData(this.response || [], true);
        }
    };

    loadData = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            var data = await Call(
                {
                    name: 'RepackagingSkuMapping',
                    url: APIEndPoints.GET_REPACKAGING_SKU_MAPPING(
                        this.state.pageNum / this.state.pageSize + 1,
                        this.state.pageSize,
                        userBusinessUnit(),
                        getAppliedFilters(this.props.filters)
                    ),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }
            this.response = data;
            this.updateDataTable(null, true);
            this.props.hideLoadingScreenAction();
        } catch (error) {
            this.showError(error);
        }
    };

    inputTextEditor = (key, _col, _others, customProps, validations = []) => {
        return (
            <Form>
                <GGSFormInput
                    className={this.props.classes.input}
                    onChange={(e) =>
                        this.setState(() => ({
                            editingRow: { ...this.state.editingRow, [key]: e.target.value }
                        }))
                    }
                    value={this.state.editingRow[key]}
                    validations={validations}
                    {...customProps}
                />
            </Form>
        );
    };

    onPage = (event) => {
        this.setState({ pageNum: event.first });
        this.loadData();
    };

    /**
     * Method that is triggered when validation is detected. This will enable/disable the save button for the add-dialog
     * @function handleValidationDetected
     * @param {*} validate
     */
    handleValidationDetected = (validate, fieldName) => {
        if (validate === undefined) {
            return;
        }
        if (validate) {
            if (!this.state.invalidFields.includes(fieldName)) {
                let newFields = [...this.state.invalidFields];
                newFields.push(fieldName);
                this.setState({ invalidFields: newFields });
            }
        } else {
            if (this.state.invalidFields.includes(fieldName))
                this.setState((prevState) => ({ ...prevState, invalidFields: prevState.invalidFields.filter((field) => field !== fieldName) }));
        }
    };

    showError = (err, msg) => {
        this.props.hideLoadingScreenAction();
        this.props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    applyFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadData());
    };

    toggleShowFilter = () => this.setState({ showFilter: !this.state.showFilter });

    resetFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadData());
    };
    render() {
        const { classes } = this.props;
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        return (
            <DrcWrapper>
                <Stepper page={pageTitle} isDataSetup />
                <Divider />
                <div className={classes.filterContainer}>
                    <Filter
                        showFilter={this.state.showFilter}
                        applyFilter={this.applyFilter}
                        resetFilter={this.resetFilter}
                        filterNames={['RepackageItemId', 'OriginalItemId', 'CustomerCode']}
                    />
                </div>
                <div className={classes.pageActions}>
                    <div>
                        <DrcButton onClick={this.toggleShowFilter}>
                            <FilterListSharp className={classes.icon} />
                            <DrcTranslate>{'Filter'}</DrcTranslate>
                        </DrcButton>
                    </div>
                    {!!this.state.columns.length && (
                        <div className={classes.actionContainer}>
                            <DrcButton
                                onClick={(_event) => {
                                    this.setState({ showAddNew: true });
                                }}
                                noStyle
                                title="Add"
                                disabled={!(this.props.isUserAdmin && this.props.selectedRole.includes('-Admin'))}
                            >
                                <Add style={{ width: '50px', height: '20px', marginRight: '-10px' }} />
                                <DrcTranslate>{'Add'}</DrcTranslate>
                            </DrcButton>
                            {/* <DrcButton onClick={(event) => {}} noStyle title="Add">
                            <FileCopyOutlinedIcon />
                            Copy
                        </DrcButton> */}
                        </div>
                    )}
                </div>
                <div className={classes.tableContainer}>
                    <PrimeReactTable
                        value={this.state.data}
                        columns={this.state.columns}
                        editMode="row"
                        frozenWidth={'100px'}
                        rowSelect={false}
                        selectionMode="multiple"
                        tableSelectionMode="checkbox"
                        selected={this.state.selectedRow}
                        selectedFrozen={true}
                        selectedClass={classes.selectedClass}
                        selectionChange={(e) => {
                            this.setState({ selectedRow: e.value });
                        }}
                        uniqueKeyField={this.uniqueKeyField}
                        editingRows={this.state.editingRow}
                        paginator={this.state.data.length > 0}
                        currentPage={this.state.pageNum}
                        pageSize={this.state.pageSize}
                        onPage={this.onPage}
                        totalRecords={this.state.totalCount}
                        editor={(key, col, others) => {
                            return this.inputTextEditor(
                                key,
                                col,
                                others,
                                this.validationFields[key] ? this.validationFields[key].customProps : {},
                                this.validationFields[key] ? this.validationFields[key].validations : []
                            );
                        }}
                    />
                </div>
                <DrcDialog
                    title={'Add New Record'}
                    open={this.state.showAddNew}
                    buttons={
                        <>
                            <DrcButton
                                onClick={() => {
                                    this.setState({ showAddNew: false, newRecord: {} });
                                }}
                            >
                                <DrcTranslate>{'Cancel'}</DrcTranslate>
                            </DrcButton>
                            <DrcButton
                                isPrimary
                                onClick={this.addNewRecord}
                                disabled={
                                    !!this.state.invalidFields.length ||
                                    !(
                                        this.state.newRecord.RepackingItemId &&
                                        this.state.newRecord.RepackingDescription &&
                                        this.state.newRecord.OriginalDescriptionId &&
                                        this.state.newRecord.OriginalDescription &&
                                        this.state.newRecord.CustomerCode
                                    )
                                }
                            >
                                <DrcTranslate>{'Submit'}</DrcTranslate>
                            </DrcButton>
                        </>
                    }
                >
                    <Form>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <GGSFormInput
                                label={'Repackage Item Id'}
                                required
                                validations={this.validationFields['RepackingItemId'] ? this.validationFields['RepackingItemId'].validations : []}
                                {...(this.validationFields['RepackingItemId'] ? this.validationFields['RepackingItemId'].customProps : {})}
                                className={this.props.classes.input}
                                onChange={(e) =>
                                    this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, RepackingItemId: e.target.value } }))
                                }
                                validationDetected={(validate) => this.handleValidationDetected(validate, 'RepackingItemId')}
                            />
                            <GGSFormInput
                                label={'Repackage Item Description'}
                                required
                                validations={
                                    this.validationFields['RepackingItemDescription']
                                        ? this.validationFields['RepackingItemDescription'].validations
                                        : []
                                }
                                {...(this.validationFields['RepackingItemDescription']
                                    ? this.validationFields['RepackingItemDescription'].customProps
                                    : {})}
                                className={this.props.classes.input}
                                onChange={(e) =>
                                    this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, RepackingDescription: e.target.value } }))
                                }
                                value={this.state.newRecord['RepackingDescription']}
                                validationDetected={(validate) => this.handleValidationDetected(validate, 'RepackingDescription')}
                            />
                            <GGSFormInput
                                label={'Original Item Id'}
                                required
                                validations={this.validationFields['OriginalItemId'] ? this.validationFields['OriginalItemId'].validations : []}
                                {...(this.validationFields['OriginalItemId'] ? this.validationFields['OriginalItemId'].customProps : {})}
                                className={this.props.classes.input}
                                onChange={(e) =>
                                    this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, OriginalDescriptionId: e.target.value } }))
                                }
                                validationDetected={(validate) => this.handleValidationDetected(validate, 'OriginalItemId')}
                            />
                            <GGSFormInput
                                label={'Original Item Description'}
                                required
                                validations={
                                    this.validationFields['OriginalItemDescription']
                                        ? this.validationFields['OriginalItemDescription'].validations
                                        : []
                                }
                                {...(this.validationFields['OriginalItemDescription']
                                    ? this.validationFields['OriginalItemDescription'].customProps
                                    : {})}
                                className={this.props.classes.input}
                                onChange={(e) =>
                                    this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, OriginalDescription: e.target.value } }))
                                }
                                value={this.state.newRecord['OriginalDescription']}
                                validationDetected={(validate) => this.handleValidationDetected(validate, 'OriginalDescription')}
                            />
                            <GGSFormInput
                                label={'Customer Code'}
                                required
                                validations={this.validationFields['CustomerCode'] ? this.validationFields['CustomerCode'].validations : []}
                                {...(this.validationFields['CustomerCode'] ? this.validationFields['CustomerCode'].customProps : {})}
                                className={this.props.classes.input}
                                onChange={(e) =>
                                    this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, CustomerCode: e.target.value } }))
                                }
                                value={this.state.newRecord['CustomerCode']}
                                validationDetected={(validate) => this.handleValidationDetected(validate, 'CustomerCode')}
                            />
                        </Grid>
                    </Form>
                </DrcDialog>
                <DrcDialog
                    title="Confirm Delete"
                    open={this.state.showDialog}
                    buttons={
                        <>
                            <DrcButton
                                onClick={() => {
                                    this.setState({ showDialog: false });
                                }}
                            >
                                Oops Just Kidding
                            </DrcButton>
                            <DrcButton isPrimary onClick={this.deleteHandler}>
                                Delete
                            </DrcButton>
                        </>
                    }
                ></DrcDialog>
            </DrcWrapper>
        );
    }
}
const mapStateToProps = ({ masterReducer, filterReducer, rootReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    userLang: masterReducer.userLang,
    pageTitle: rootReducer.pageTitle,
    filters: filterReducer.filters,
    isUserAdmin: rootReducer.userIsAdmin,
    selectedRole: rootReducer.selectedRole
});
const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withStyles(styles)(RepackagingSkuMapping)));
